import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Box, Typography, TextField, Button, FormControl, InputLabel, Select, MenuItem, Grid } from '@mui/material';
import SDadminHeader from './SDadminHeader';
import Top20px from '../screens/Top20px';

const UploadUtube = () => {
    const [title, setTitle] = useState('');
    const [src, setSrc] = useState('');
    const [category, setCategory] = useState('live');
    const [liveVideos, setLiveVideos] = useState([]);  // State for live videos
    const [shortsVideos, setShortsVideos] = useState([]); // State for shorts videos

    const handleUpload = async (e) => {
        e.preventDefault(); // Prevent default form submission
        const response = await axios.post('https://backend.shubadinam.com/youtube/upload', {
            title,
            src,
            category,
        });
        console.log(response.data);
        fetchVideos(); // Refresh the video list after upload
    };

    const fetchVideos = async () => {
        const liveResponse = await axios.get('https://backend.shubadinam.com/youtubevideos/live');
        const shortsResponse = await axios.get('https://backend.shubadinam.com/youtubevideos/shorts');

        // Update state for live and shorts videos
        setLiveVideos(liveResponse.data);
        setShortsVideos(shortsResponse.data);
    };

    useEffect(() => {
        fetchVideos(); // Fetch videos when component mounts
    }, []);
    console.log(liveVideos);

    return (
        <Container>
            <SDadminHeader />
            <Top20px />
            <br />
            <br />
            <br />
            <Box sx={{ mt: 8 }}>
                <Typography variant="h5" align="center" gutterBottom>
                    YouTube Video Uploader
                </Typography>

                <form onSubmit={handleUpload}>
                    <Box sx={{ mb: 2 }}>
                        <TextField
                            label="Video Title"
                            variant="outlined"
                            fullWidth
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            required
                        />
                    </Box>
                    <Box sx={{ mb: 2 }}>
                        <TextField
                            label="YouTube Link"
                            variant="outlined"
                            fullWidth
                            value={src}
                            onChange={(e) => setSrc(e.target.value)}
                            required
                        />
                    </Box>
                    <Box sx={{ mb: 2 }}>
                        <FormControl fullWidth sx={{ textIndent: 20 }}>
                            <InputLabel>Category</InputLabel>
                            <Select
                                value={category}
                                onChange={(e) => setCategory(e.target.value)}
                                label="Category"
                            >
                                <MenuItem value="live">Live</MenuItem>
                                <MenuItem value="shorts">Shorts</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <Button variant="contained" color="primary" type="submit">
                        Upload Video
                    </Button>
                </form>

                <Box sx={{ p: 3 }}>
                    {/* Display Live Videos */}
                    <Typography variant="h5" sx={{ mt: 4 }}>
                        Live Videos
                    </Typography>
                    <Box sx={{ mt: 2 }}>
                        <Grid container spacing={2}>
                            {liveVideos && liveVideos.length > 0 && liveVideos.slice().reverse().map((video) => (
                                <Grid item xs={12} sm={6} md={4} key={video.id}>
                                    <Box sx={{ mb: 2 }}>
                                        <Typography variant="h6">{video.title}</Typography>
                                        <iframe
                                            width="100%" // Responsive width
                                            height="180"
                                            src={video.src}
                                            title={video.title}
                                            frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowFullScreen
                                        ></iframe>
                                    </Box>
                                </Grid>
                            ))}

                        </Grid>
                    </Box>

                    {/* Display Shorts Videos */}
                    <Typography variant="h5" sx={{ mt: 4 }}>
                        Shorts Videos
                    </Typography>
                    <Box sx={{ mt: 2 }}>
                        <Grid container spacing={2}>
                            {shortsVideos.slice().reverse().map((video) => (
                                <Grid item xs={12} sm={6} md={4} key={video.id}>
                                    <Box sx={{ mb: 2 }}>
                                        <Typography variant="h6">{video.title}</Typography>
                                        <iframe
                                            width="100%" // Responsive width
                                            height="180"
                                            src={video.src}
                                            title={video.title}
                                            frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                            allowFullScreen
                                        ></iframe>
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </Container>
    );
};

export default UploadUtube;

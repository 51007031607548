import React, { useState, useEffect } from 'react';
import { Grid, FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const languageData = {
  Any: {},

  Tamil: {
    month: ["CHITHIRAI/சித்திரை", "VAIGAASI/வைகாசி", "AANI/ஆனி", "AADI/ஆடி", "AAVANI/ஆவணி", "PURATTASI/புரட்டாசி", "AYPPASI/ஐப்பசி", "KARTHIGAI/கார்த்திகை", "MARGAZHI/மார்கழி", "THAI/தை", "MAASI/மாசி", "PANGUNI/பங்குனி"],
    nakshathira: ["ASHWINI/அஸ்வினி", "BHARANI/பரணி", "KRITTIKA/கிருத்திகை", "ROHINI/ரோகிணி", "MRIGHASIRA/மிருகசிரம்", "ARDRA/திருவாதிரை", "PUNARVASU/புனர்பூசம்", "PUSHYA/பூசம்", "ASHLESHA/ஆயில்யம்", "MAGHA/மகம்", "PURVA PHALGUNI/பூரம்", "UTTARA PHALGUNI/உத்திரம்", "HASTA/ஹஸ்தம்", "CHITRA/சித்திரை", "SWATI/சுவாதி", "VISHAKA/விசாகம்", "ANURADHA/அனுஷம்", "JYESTHA/கேட்டை", "MOOLA/மூலம்", "PURVASHADA/பூராடம்", "UTTARASHADA/உத்திராடம்", "SHARAVAN/திருவோணம்", "DHANISHTA/அவிட்டம்", "SHATABISHA/சதயம்", "PURVABHADRA/பூரட்டாதி", "UTTARABHADRA/உத்திரட்டாதி", "REVATI/ரேவதி"],
    Paksham: ["SHUKLA PAKSHA/சுக்ல பக்ஷம்", "KRISHNA PAKSHA/கிருஷ்ண பக்ஷம்"],
    tithis: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "PURNIMA/பௌர்ணமி", "AMAVASYA/அமாவாசை"]
  },

  Telugu: {
    month: ["CHAITHRAMU/చైత్రము", "VAISAAKHAMU/వైశాఖము", "JYESTHAMU/జ్యేష్ఠము", "ASHADHAMU/ఆషాఢము", "SRAVANAMU/శ్రావణము", "BHADHRAPADAMU/భాద్రపదము", "AASVAYUJAMU/ఆశ్వయుజము", "KARTHIKAMU/కార్తీకము", "MAARGASEERSHAMU/మార్గశీర్షము", "PUSHYAMU/పుష్యము", "MAKHAMU/మఖము", "PHALGUNAMU/ఫాల్గుణము"],
    nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
    Paksham: ["SHUKLA PAKSHA/శుక్ల పక్షం", "KRISHNA PAKSHA/కృష్ణ పక్షం"],
    tithis: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి/పౌర్ణమి", "AMAVASYA/అమావాస్య"],
  },

  Kannada: {
    month: ["CHAITRA/ಚೈತ್ರ", "VAISHAKHA/ವೈಶಾಖ", "JYESHTHA/ಜ್ಯೇಷ್ಠ", "ASHADHA/ಆಷಾಢ", "SAHRAVANA/ಶ್ರಾವಣ", "BHADRAPADA/ಭಾದ್ರಪದ", "ASHWEJA/ಆಶ್ವೇಜ", "KARTIKA/ಕಾರ್ತೀಕ", "MARGASHIRSHA/ಮಾರ್ಗಶೀರ್ಷ", "PAUSHA/ಪೌಷಾ", "MAGHA/ಮಾಘ", "PHALGUNA/ಫಲ್ಗುಣ"],
    nakshathira: ["ASHWINI/ಅಶ್ವಿನಿ", "BHARANI/ಭರಣಿ", "KRITTIKA/ಕೃತ್ತಿಕಾ", "ROHINI/ರೋಹಿಣಿ", "MRIGHASIRA/ಮೃಘಾಸಿರ", "ARDRA/ಆರ್ದ್ರಾ", "PUNARVASU/ಪುನರ್ವಸು", "PUSHYA/ಪುಷ್ಯ", "ASHLESHA/ಆಶ್ಲೇಷಾ", "MAGHA/ಮಾಘ", "PURVA PHALGUNI/ಪೂರ್ವ ಫಲ್ಗುಣಿ", "UTTARA PHALGUNI/ಉತ್ತರ ಫಲ್ಗುಣಿ", "HASTA/ಹಸ್ತ", "CHITRA/ಚಿತ್ರಾ", "SWATI/ಸ್ವಾತಿ", "VISHAKA/ವಿಶಾಕ", "ANURADHA/ಅನುರಾಧಾ", "JYESTHA/ಜ್ಯೇಷ್ಠ", "MOOLA/ಮೂಲಾ", "PURVASHADA/ಪೂರ್ವಾಷಾಡ", "UTTARASHADA/ಉತ್ತರಾಷ್ಟ", "SHARAVAN/ಶರವಣ", "DHANISHTA/ಧನಿಷ್ಠಾ", "SHATABISHA/ಶತಬಿಷಾ", "PURVABHADRA/ಪೂರ್ವಾಭದ್ರ", "UTTARABHADRA/ಉತ್ತರಾಭಾದ್ರ", "REVATI/ರೇವತಿ"],
    Paksham: ["SHUKLA PAKSHA/ಶುಕ್ಲ ಪಕ್ಷ", "KRISHNA PAKSHA/ಕೃಷ್ಣ ಪಕ್ಷ"],
    tithis: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "PURNIMA/ಪೌರ್ಣಿಮಿ", "AMAVASYA/ಅಮಾವಾಸ್ಯೆ"],
  },

  Malayalam: {
    month: ["MEENAM/മീനം", "MEDAM/മെഡാം", "EDAVAM/എദാവം", "MITHUNAM/മിഥുനം", "KARKADAKAM/കാർകിഡകാം", "CHINGAM/ചിങ്കാം", "KANNI/കാനി", "THULAM/തുലാം", "VRISHCHIKAM/വ്രിഷിക്കാം", "DHANU/ഭാനു", "MAKARAM/മകരം", "KUMBHAM/കുംഹാം"],
    nakshathira: ["ASHWINI/അശ്വനി", "BHARANI/ഭരണി", "KRITTIKA/കൃത്തിക", "ROHINI/രോഹിണി", "MRIGHASIRA/മൃഗശിര", "ARDRA/അർദ്ധ", "PUNARVASU/പൂർണ്ണവാസു", "PUSHYA/പൂഷ്യ", "ASHLESHA/അശ്വതി", "MAGHA/മകം", "PURVA PHALGUNI/പുർവഫാൽഗുണി", "UTTARA PHALGUNI/ഉത്തരാഫാൽഗുണി", "HASTA/ഹസ്ത", "CHITRA/ചിത", "SWATI/സ്വാതി", "VISHAKA/വിശാഖം", "ANURADHA/അനുരാധ", "JYESTHA/തിരുവാതിര", "MOOLA/മൂലം", "PURVASHADA/പുർവാഷാഢ", "UTTARASHADA/ഉത്തരാഷാഢ", "SHARAVAN/ശ്രാവണം", "DHANISHTA/ധനിഷ്ഠ", "SHATABISHA/ചിത്തിര", "PURVABHADRA/പുർവാഭദ്ര", "UTTARABHADRA/ഉത്തരാഭദ്ര", "REVATI/രേവതി"],
    Paksham: ["SHUKLA PAKSHA/വെളുത്ത पक्षം", "KRISHNA PAKSHA/കറുത്ത ಪಕ್ಷം"],
    tithis: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "PURNIMA/പൂർണ്ണിമ", "AMAVASYA/അമാവാസി"],
  },

  Hindi: {
    month: ["CHAITRA/चैत्र", "VAISAKHA/वैशाख", "JYESTHA/ज्येष्ठा", "ASHADHA/आषाढ़", "SHRAVANA/श्रावण", "BHADRAPADA/भाद्रपद", "ASHVIN/अश्विन", "KATIKA/कार्तिक", "MARGASIRSA/मार्गशीर्ष", "PAUSHA/पौष", "MAGH/माघ", "PHALGUNA/फाल्गुन"],
    nakshathira: ["ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशिरा", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/अश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वफाल्गुनी", "UTTARA PHALGUNI/उत्तराफाल्गुनी", "HASTA/हस्त", "CHITRA/चित्रा", "SWATI/स्वाति", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूल", "PURVASHADA/पूर्वाषाढ़", "UTTARASHADA/उत्तराषाढ़", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
    Paksham: ["SHUKLA PAKSHA/शुक्ल पक्ष", "KRISHNA PAKSHA/कृष्ण पक्ष"],
    tithis: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा", "AMAVASYA/अमावस्या"],
  },

  Gujarati: {
    month: ["CHAITRA/ચૈત્ર", "VAISHAKHA/વૈશાખા", "JETHA/જેઠા", "ASHADHA/અષાઢ", "SHRAVANA/શ્રાવણ", "BHADRAVO/ભાદરવો", "ASO/આસો", "KARKAT/કારતક", "MAGSHAR/માગશર", "POSH/પોષ", "MAHA/મહા", "PHAGUNA/ફાગુના"],
    nakshathira: ["ASHWINI/અશ્વિની", "BHARANI/ભરણી", "KRITTIKA/કૃત્તિકા", "ROHINI/રોહિણી", "MRIGHASIRA/મૃગશીરા", "ARDRA/આર્દ્રા", "PUNARVASU/પુનર્વસુ", "PUSHYA/પુષ્ય", "ASHLESHA/અશ્લેષા", "MAGHA/મઘા", "PURVA PHALGUNI/પૂર્વ ફાલ્ગુની", "UTTARA PHALGUNI/ઉત્તર ફાલ્ગુની", "HASTA/હસ્ત", "CHITRA/ચિત્રા", "SWATI/સ્વાતિ", "VISHAKA/વિશાખા", "ANURADHA/અનુરાધા", "JYESTHA/જ્યેષ્ઠા", "MOOLA/મૂળ", "PURVASHADA/પૂર્વ શાડ", "UTTARASHADA/ઉત્તર શાડ", "SHRAVANA/શ્રાવણ", "DHANISHTA/ધનિષ્ઠા", "SHATABISHA/શતભિષા", "PURVABHADRA/પૂર્વભાદ્ર", "UTTARABHADRA/ઉત્તરભાદ્ર", "REVATI/રેવતી"],
    Paksham: ["SHUKLA PAKSHA/શુક્લ પક્ષ", "KRISHNA PAKSHA/કૃષ્ણ પક્ષ"],
    tithis: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "PURNIMA/પૂર્ણિમા", "AMAVASYA/આમાસ્યા"],
  },

  Bengali: {
    month: ["CHAITRA/চৈত্র", "BOISHAKH/বৈশাখ", "JOISHTHO/জ্যৈষ্ঠ", "ASHARH/আষাঢ়", "SHRABON/শ্রাবণ", "BHADRO/ভাদ্র", "ASHSHIN/আশ্বিন", "KARTIK/কার্তিক", "AGRAHAYAN/অগ্রহায়ণ", "POUSH/পৌষ", "MAGH/মাঘ", "FALGUN/ফাল্গুন"],
    nakshathira: ["ASHWINI/অশ্বিনী", "BHARANI/ভরণী", "KRITTIKA/কৃত্তিকা", "ROHINI/রোহিণী", "MRIGHASIRA/মৃগশিরা", "ARDRA/আর্দ্রা", "PUNARVASU/পুনর্বসু", "PUSHYA/পুষ্য", "ASHLESHA/অশ্লেষা", "MAGHA/মঘা", "PURVA PHALGUNI/পূর্ব ফাল্গুনী", "UTTARA PHALGUNI/উত্তর ফাল্গুনী", "HASTA/হস্ত", "CHITRA/চিত্রা", "SWATI/স্বাতী", "VISHAKA/বিশাখা", "ANURADHA/অনুরাধা", "JYESTHA/জ্যেষ্ঠা", "MOOLA/মূল", "PURVASHADA/পূর্বষাঢ়", "UTTARASHADA/উত্তরষাঢ়", "SHARAVAN/শ্রাবণ", "DHANISHTA/ধনিষ্ঠা", "SHATABHISHA/শতভিষা", "PURVABHADRA/পূর্বভাদ্র", "UTTARABHADRA/উত্তরভাদ্র", "REVATI/রেবতী"],
    Paksham: ["SHUKLA PAKSHA/শুক্ল পক্ষ", "KRISHNA PAKSHA/কৃষ্ণ পক্ষ"],
    tithis: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "PURNIMA/পূর্ণিমা", "AMAVASYA/অমাবস্যা"],
  },

  Marathi: {
    month: ["CHAITRA/चैत्र", "VAISAKHA/वैशाख", "JYESTHA/ज्येष्ठा", "ASHADHA/आषाढ़", "SHRAVANA/श्रावण", "BHADRAPADA/भाद्रपद", "ASHVIN/अश्विन", "KATIKA/कार्तिक", "MARGASIRSA/मार्गशीर्ष", "PAUSHA/पौष", "MAGH/माघ", "PHALGUNA/फाल्गुन"],
    nakshathira: ["ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशिरा", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/अश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वफाल्गुनी", "UTTARA PHALGUNI/उत्तराफाल्गुनी", "HASTA/हस्त", "CHITRA/चित्रा", "SWATI/स्वाति", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मुळा", "PURVASHADA/पूर्वाषाढा", "UTTARASHADA/उत्तराषाढा", "SHARAVANA/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABHISHA/शतभिषा", "PURVABHADRA/पूर्वाभाद्रपद", "UTTARABHADRA/उत्तराभाद्रपद", "REVATI/रेवती"],
    Paksham: ["SHUKLA PAKSHA/शुक्ल पक्ष", "KRISHNA PAKSHA/कृष्ण पक्ष"],
    tithis: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पञ्चमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पौर्णिमा", "AMAVASYA/अमावस्या"],
  },

};

const RoughThree = () => {
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [months, setMonths] = useState([]);
  const [nakshatras, setNakshatras] = useState([]);
  const [pakshams, setPakshams] = useState([]);
  const [tithis, setTithis] = useState([]);

  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedNakshatra, setSelectedNakshatra] = useState('');
  const [selectedPaksham, setSelectedPaksham] = useState('');
  const [selectedTithi, setSelectedTithi] = useState('');

  const [selectionType, setSelectionType] = useState(""); // Control between Nakshatra and Tithi

  useEffect(() => {
    // Set initial data based on the selected language
    if (selectedLanguage) {
      setMonths(languageData[selectedLanguage].month);
      setNakshatras(languageData[selectedLanguage].nakshathira);
      setPakshams(languageData[selectedLanguage].Paksham);
      setTithis(languageData[selectedLanguage].tithis);

      // Reset selections
      setSelectedMonth('');
      setSelectedNakshatra('');
      setSelectedPaksham('');
      setSelectedTithi('');
    }
  }, [selectedLanguage]);

  const handleLanguageChange = (e) => {
    const language = e.target.value;
    setSelectedLanguage(language);
  };

  const handleMonthChange = (e) => {
    setSelectedMonth(e.target.value);
  };

  const handleNakshatraChange = (e) => {
    setSelectedNakshatra(e.target.value);
  };

  const handlePakshamChange = (e) => {
    setSelectedPaksham(e.target.value);
  };

  const handleTithiChange = (e) => {
    setSelectedTithi(e.target.value);
  };

  const handleSelectionTypeChange = (e) => {
    setSelectionType(e.target.value);
    // Reset selections when toggling
    setSelectedNakshatra('');
    setSelectedTithi('');
    setSelectedPaksham('');
  };

  return (
    <Grid container spacing={3}>



      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel id="selectionType-label">Select Type</InputLabel>
          <Select
            labelId="selectionType-label"
            value={selectionType}
            onChange={handleSelectionTypeChange}
          >
            <MenuItem value="" disabled>Select a type</MenuItem>
            <MenuItem value="Nakshatra">Nakshatra</MenuItem>
            <MenuItem value="Tithi">Tithi</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel id="language-label">Select Language</InputLabel>
          <Select
            labelId="language-label"
            value={selectedLanguage}
            onChange={handleLanguageChange}
          >
            {Object.keys(languageData).map((language) => (
              <MenuItem key={language} value={language}>{language}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel id="month-label">Select Month</InputLabel>
          <Select
            labelId="month-label"
            value={selectedMonth}
            onChange={handleMonthChange}
            disabled={!months.length}
          >
            <MenuItem value="" disabled>Select a month</MenuItem>
            {months.map((month) => (
              <MenuItem key={month} value={month}>{month}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>


      {selectionType === 'Nakshatra' && (
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id="nakshatra-label">Select Nakshatra</InputLabel>
            <Select
              labelId="nakshatra-label"
              value={selectedNakshatra}
              onChange={handleNakshatraChange}
              disabled={!nakshatras.length}
            >
              <MenuItem value="" disabled>Select a nakshatra</MenuItem>
              {nakshatras.map((nakshatra) => (
                <MenuItem key={nakshatra} value={nakshatra}>{nakshatra}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      )}

      {selectionType === 'Tithi' && (
        <>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="paksham-label">Select Paksham</InputLabel>
              <Select
                labelId="paksham-label"
                value={selectedPaksham}
                onChange={handlePakshamChange}
                disabled={!pakshams.length}
              >
                <MenuItem value="" disabled>Select a paksham</MenuItem>
                {pakshams.map((paksham) => (
                  <MenuItem key={paksham} value={paksham}>{paksham}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="tithi-label">Select Tithi</InputLabel>
              <Select
                labelId="tithi-label"
                value={selectedTithi}
                onChange={handleTithiChange}
                disabled={!tithis.length}
              >
                <MenuItem value="" disabled>Select a tithi</MenuItem>
                {tithis.map((tithi) => (
                  <MenuItem key={tithi} value={tithi}>{tithi}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default RoughThree;

import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Grid, Card, CardContent, CardMedia, Typography, Button, Container } from '@mui/material';
import LandingHeader from "../components/LandingHeader";
import FooterComponent from "../components/footer";
import BottomSpace from "./BottomSpace";

const NakshatraBlogs = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top when component mounts
    }, []);

    // Array of blog data
    const blogData = [
        {
            title: "Nakshatra Food",
            description: "In Hindu astrology, a person's birth star, also known as Nakshatra, plays a significant role in their life and is believed to influence various aspects, including food preferences.",
            image: require("../images/boxesImages/food1.jpg"),
            link: "/nakshatra-foods"
        },
        {
            title: "Nakshatra Characteristics",
            description: "This is a general overview of the characteristics associated with each Janma Nakshatra or Birth Star in Vedic astrology.",
            image: require("../images/boxesImages/char1.jpg"),
            link: "/nakshatra-characteristics"
        },
        {
            title: "Nakshatra Deity",
            description: "In Hindu way of life, Nakshatras are lunar mansions or constellations that play a significant role in determining one's personality, behaviour, and destiny.",
            image: require("../images/boxesImages/deity1.png"),
            link: "/nakshatra-deities"
        },
        {
            title: "Nakshatra Likes and Dislikes",
            description: "This is a general overview of some tendencies and preferences associated with each Nakshatra.",
            image: require("../images/boxesImages/likes11.jpg"),
            link: "/nakshatra-likes-dislikes"
        },
    ];

    return (
        <HelmetProvider>
            <Helmet>
                <title>Nakshatra Blogs</title>
                <meta name="description" content="Nakshatra Blogs - Janma Nakshatra/Tithi Birthday Reminder Service." />
            </Helmet>
            <Container maxWidth="lg" sx={{ p: 4, mt: 15 }}>
                <LandingHeader />
                <Typography variant="h4" align="center" sx={{ marginBottom: 4, fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" }}>
                    Nakshatra Related Blogs
                </Typography>

                <Grid container spacing={4}>
                    {blogData.map((blog, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            <NavLink to={blog.link} style={{ textDecoration: 'none' }}>
                                <Card elevation={3} sx={{ height: '100%' }}>
                                    <CardMedia
                                        component="img"
                                        height="160"
                                        image={blog.image}
                                        alt={blog.title}
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="h5">
                                            {blog.title}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            {blog.description}
                                        </Typography>
                                    </CardContent>
                                    <Button size="medium" variant="contained" color="primary" sx={{ margin: 2, textTransform: "none" }}>
                                        Read more
                                    </Button>
                                </Card>
                            </NavLink>
                        </Grid>
                    ))}
                </Grid>

                <BottomSpace />
            </Container>
            <FooterComponent />
        </HelmetProvider>
    );
};

export default NakshatraBlogs;

import React, { useRef, useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import ScrollToTop from 'react-scroll-to-top';
import FooterComponent from "../components/footer";
import { Box, Grid, Card, CardContent, CardMedia, Typography, Button, TextField, InputAdornment } from '@mui/material';
import LandingHeader from "../components/LandingHeader";
import SearchIcon from '@mui/icons-material/Search'; // Import the Search icon

const BlogScreen = () => {
    const [searchQuery, setSearchQuery] = useState('');

    const cardData = [
        {
            title: 'Nakshatra Food',
            imageSrc: require("../images/boxesImages/food1.jpg"),
            content: "A person's birth star, also known as Nakshatra, plays a significant role in their life and is believed to influence various aspects, including food preferences.",
            link: '/nakshatra-foods'
        },
        {
            title: 'Nakshatra Characteristics',
            imageSrc: require("../images/boxesImages/char1.jpg"),
            content: "This is a general overview of the characteristics associated with each Janma Nakshatra or Birth Star in Vedic astrology.",
            link: '/nakshatra-characteristics'
        },
        {
            title: 'Nakshatra Deities',
            imageSrc: require("../images/boxesImages/deity1.png"),
            content: "In Hindu way of life, Nakshatras are lunar mansions or constellations that play a significant role in determining one's personality, behavior, and destiny.",
            link: '/nakshatra-deities'
        },
        {
            title: 'Nakshatra Likes and Dislikes',
            imageSrc: require("../images/boxesImages/likes11.jpg"),
            content: "This is a general overview of some tendencies and preferences associated with each Nakshatra.",
            link: '/nakshatra-likes-dislikes'
        },
        {
            title: 'Tithi Food',
            imageSrc: require("../images/boxesImages/food2.jpg"),
            content: "Food preferences can vary greatly among individuals, and they are influenced by factors such as culture, personal tastes, and dietary choices.",
            link: '/tithi-foods'
        },
        {
            title: 'Tithi Characteristics',
            imageSrc: require("../images/boxesImages/char2.jpg"),
            content: "This is a general overview of the characteristics associated with each Janma Tithi in Vedic astrology.",
            link: '/tithi-characteristics'
        },
        {
            title: 'Tithi Deities',
            imageSrc: require("../images/boxesImages/deity2.png"),
            content: "In Vedic astrology and Hindu tradition, each Janma Tithi is associated with a presiding deity.",
            link: '/tithi-deities'
        },
        {
            title: 'Tithi Likes and Dislikes',
            imageSrc: require("../images/boxesImages/likes22.jpg"),
            content: "This is a general overview of the likes and dislikes that individuals born on each Janma Tithi might have, according to Vedic astrology.",
            link: '/tithi-likes-dislikes'
        },
    ];

    const filteredCards = cardData.filter(card =>
        card.title.toLowerCase().includes(searchQuery.toLowerCase())
    );

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top when component mounts
    }, []);

    return (
        <Box sx={{ backgroundColor: '#f5f5f5', }}>
            <ScrollToTop smooth />
            <HelmetProvider>
                <Helmet>
                    <title>Blogs</title>
                    <meta name="description" content="Blogs - Janma Nakshatra/Tithi Birthday Reminder Service." />
                </Helmet>
            </HelmetProvider>
            <LandingHeader />

            <Box display="flex" justifyContent="center" mb={3} sx={{ mt: 20 }}>
                <TextField
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon sx={{ color: '#38a1f7' }} />
                            </InputAdornment>
                        ),
                    }}
                    label="Search" // Added label for better accessibility
                    variant="outlined"
                    placeholder='Search title...'
                    size="medium" // Updated to medium size
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    sx={{
                        width: "300px",
                        borderRadius: "6px",
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: 'primary', // Default border color
                            },
                            '&:hover fieldset': {
                                borderColor: '#007bff', // Border color on hover
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: '#007bff', // Border color when focused
                            },
                        },
                    }}
                />
            </Box>

            <Grid container spacing={4} sx={{ p: 3 }} justifyContent="center">
                {filteredCards.map((card, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <NavLink to={card.link} style={{ textDecoration: 'none', color: 'inherit' }}>
                            <Card sx={{ maxWidth: 345 }}>
                                <CardMedia
                                    component="img"
                                    height="160"
                                    image={card.imageSrc}
                                    alt={card.title}
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div" sx={{ fontWeight: 'bold' }}>
                                        {card.title}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        {card.content}
                                    </Typography>
                                </CardContent>
                                <Button size="medium" variant='contained' color="primary" sx={{ margin: '16px' }}>
                                    Read more
                                </Button>
                            </Card>
                        </NavLink>
                    </Grid>
                ))}
            </Grid>

            <FooterComponent />
        </Box>
    );
};

export default BlogScreen;

import React, { useState, useEffect } from 'react';

import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import LandingHeader from '../components/LandingHeader';
import FooterComponent from '../components/footer';

import Top20px from '../screens/Top20px';
import 'react-calendar/dist/Calendar.css';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Alert, Button, IconButton, InputAdornment, Stack, Typography } from '@mui/material';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import SearchIcon from '@mui/icons-material/Search';


import axios from 'axios';
import Swal from "sweetalert2";

const HomamDetails = () => {

    const Navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const selectedOption = queryParams.get('selectedOption');
    const count = queryParams.get('count');
    const serviceName = queryParams.get('serviceName');
    const chosenDate = queryParams.get('chosenDate');
    const amount = queryParams.get('amount');


    if (!count || !serviceName || !chosenDate || !amount) {
        Navigate('/'); // Navigate to the homepage if any value is null or empty
    }

    const sessionData = {
        count,
        serviceName,
        chosenDate,
        amount
    };



    // Store the object in session storage
    sessionStorage.setItem('homam-options', JSON.stringify(sessionData));


    const sessionDetails = JSON.parse(sessionStorage.getItem("homam-options"))

    const [formData, setFormData] = useState({
        staticFields: {
            name: '',
            nakshatra: '',
            address: '',
            state: '',
            city: '',
            pincode: ''
        },
        dynamicFields: Array.from({ length: count - 1 }, () => ({ name: '', nakshatra: '' }))
    });

    const [errors, setErrors] = useState({
        staticFields: {},
        dynamicFields: []
    });

    const [userData, setuserData] = useState([]);
    const [customerType, setcustomerType] = useState([]);


    useEffect(() => {
        const localId = localStorage.getItem("premiumVerified") || localStorage.getItem("SD_Id");

        if (localId) {
            const url = `https://backend.shubadinam.com/get/homam-userdetails?id=${localId}`;

            axios.get(url)
                .then((response) => {

                    const { userType, result } = response.data;
                    const { userName, userNakshathra } = result[0];

                    setcustomerType(userType);

                    const nakshathraArr = ["ASHWINI", "BHARANI", "KRITTIKA", "ROHINI", "MRIGHASIRA", "ARDRA", "PUNARVASU", "PUSHYA", "ASHLESHA", "MAGHA", "PURVA PHALGUNI", "UTTARA PHALGUNI", "HASTA", "CHITRA", "SWATI", "VISHAKA", "ANURADHA", "JYESTHA", "MOOLA", "PURVASHADA", "UTTARASHADA", "SHARAVAN", "DHANISHTA", "SHATABISHA", "PURVABHADRA",
                        "UTTARABHADRA", "REVATI"
                    ]

                    const nakshatraSelected = userNakshathra ? userNakshathra.split('/')[0] : '';

                    const index = nakshathraArr.indexOf(nakshatraSelected);
                    const selectedValue = index !== -1 ? nakshathraArr[index] : '';


                    const updatedFormData = {
                        ...formData,
                        staticFields: {
                            ...formData.staticFields,
                            name: userName || '',
                            nakshatra: selectedValue || ''
                        }
                    }

                    // Check if "added users" exists in session storage
                    let existingUsers = sessionStorage.getItem('added users');


                    // Only update if "added users" does not exist in session storage
                    if (!existingUsers) {
                        setFormData(updatedFormData);

                        setuserData([{ userName: userName || '', userNakshathra: selectedValue || '' }]);
                        sessionStorage.setItem('added users', JSON.stringify(updatedFormData));
                    }



                })
                .catch((error) => {
                    console.error('Error fetching user details:', error);
                });
        } else {
            console.warn('No unique ID found in localStorage.');
        }

    }, []);


    useEffect(() => {

        window.scrollTo(0, 0);

        const storedFormData = sessionStorage.getItem('added users');
        // console.log('Stored Form Data:', storedFormData); // Log stored data
        if (storedFormData) {
            try {
                const parsedData = JSON.parse(storedFormData);
                // console.log('Parsed Form Data:', parsedData); // Log parsed data
                setFormData(parsedData);
            } catch (error) {
                console.error('Error parsing stored form data:', error);
            }
        }
    }, []);



    // Handle input changes
    const handleStaticChange = (field, value) => {
        // console.log(field, value);

        const updatedFormData = {
            ...formData,
            staticFields: {
                ...formData.staticFields,
                [field]: value
            }
        }

        setFormData(updatedFormData)

        sessionStorage.setItem('added users', JSON.stringify(updatedFormData));

    };


    const handleDynamicChange = (index, field, value) => {
        const updatedDynamicFields = [...formData.dynamicFields];
        updatedDynamicFields[index] = { ...updatedDynamicFields[index], [field]: value };
        const updatedFormData = {
            ...formData,
            dynamicFields: updatedDynamicFields
        };
        // Update state
        // if (value !== '') {
        setFormData(updatedFormData);

        sessionStorage.setItem('added users', JSON.stringify(updatedFormData));
        // }

        // sessionStorage.setItem('added users', JSON.stringify(updatedFormData));

    };



    // Handle form submission
    const handleSubmit = (e) => {

        // console.log(formData);

        e.preventDefault();
        const newErrors = {
            staticFields: {},
            dynamicFields: []
        };
        let isValid = true;

        // Validate static fields
        Object.keys(formData.staticFields).forEach(field => {
            if (!formData.staticFields[field]) {
                isValid = false;
                newErrors.staticFields[field] = 'This field is required';
            }
        });

        // Validate dynamic fields
        formData.dynamicFields.forEach((data, index) => {
            if (!data.name || !data.nakshatra) {
                isValid = false;
                newErrors.dynamicFields[index] = {
                    name: !data.name ? 'Name is required' : '',
                    nakshatra: !data.nakshatra ? 'Nakshatra is required' : ''
                };
            }
        });

        if (isValid) {
            // Process form data
            // console.log('Form Data:', formData);

            const staticFields = {
                name: formData.staticFields.name,
                nakshatra: formData.staticFields.nakshatra,
                address: formData.staticFields.address,
                state: formData.staticFields.state,
                city: formData.staticFields.city,
                pincode: formData.staticFields.pincode
            };

            // Serialize dynamicFields
            const dynamicFields = formData.dynamicFields.map((field, index) => ({
                [`dynamicField${index}Name`]: field.name,
                [`dynamicField${index}Nakshatra`]: field.nakshatra
            })).reduce((acc, curr) => ({ ...acc, ...curr }), {});

            // Combine all fields into one object
            const allFields = {
                ...staticFields,
                ...dynamicFields,
                count,
                serviceName,
                chosenDate,
                amount,
                customerType
            };

            // Create query string
            const queryParams = new URLSearchParams(allFields).toString();

            // Navigate to /preview-homam with query parameters
            Navigate(`/preview-homam?${queryParams}`);


        } else {
            setErrors(newErrors);
        }
    };

    const isFormDisabled = () => {
        const { staticFields, dynamicFields } = formData;

        // Check if any static field is empty after trimming
        for (const key in staticFields) {
            if (staticFields[key].trim() === '') {
                return true;
            }
        }

        // Check if any dynamic field is empty after trimming
        if (count > 1) {
            for (const field of dynamicFields) {
                for (const key in field) {
                    if (field[key].trim() === '') {
                        return true;
                    }
                }
            }
        }
        // If all fields are filled, return false (form is not disabled)
        return false;
    };


    const nakshathraArr = ["ASHWINI", "BHARANI", "KRITTIKA", "ROHINI", "MRIGHASIRA", "ARDRA", "PUNARVASU", "PUSHYA", "ASHLESHA", "MAGHA", "PURVA PHALGUNI", "UTTARA PHALGUNI", "HASTA", "CHITRA", "SWATI", "VISHAKA", "ANURADHA", "JYESTHA", "MOOLA", "PURVASHADA", "UTTARASHADA", "SHARAVAN", "DHANISHTA", "SHATABISHA", "PURVABHADRA",
        "UTTARABHADRA", "REVATI"
    ]

    const userNakshathra = userData[0]?.userNakshathra ? userData[0].userNakshathra.split('/')[0] : '';

    const index = nakshathraArr.indexOf(userNakshathra);
    const selectedValue = index !== -1 ? nakshathraArr[index] : '';

    const handleBack = () => {
        window.history.back();
    }


    return (
        <div className='homam-container'>
            <LandingHeader />
            <Top20px />
            <div className='homam-body'>
                {/* <div className='homam-title'>
                    <b>Avahanti Homam</b>
                    <NavLink to='/booking-history'>Booking History</NavLink>
                </div>
                <br /> */}
                <div className='homam-options'>
                    <h5 style={{ textAlign: "start", width: "100%", }}>{sessionDetails ? sessionDetails.serviceName : ""}</h5>
                    <div className='homam-details-options2'>

                        <div className='homam-detail-item'>
                            <strong className='homam-ph-details'>No. of Tickets</strong>
                            <h5 className='homam-ph-details1'>{sessionDetails ? sessionDetails.count : ""}</h5>
                        </div>
                        <div className='homam-detail-item'>
                            <strong className='homam-ph-details'>Homam Time</strong>
                            <h5 className='homam-ph-details1'>{sessionDetails && sessionDetails.serviceName === 'Surya-Namaskaram' ? "7 am" : "6:45 am"}</h5>
                        </div>
                        <div className='homam-detail-item'>
                            <strong className='homam-ph-details'>Homam Date</strong>
                            <h5 className='homam-ph-details1'>
                                {chosenDate ? (sessionDetails ? sessionDetails.chosenDate : "") : 'Invalid Date'}
                            </h5>
                        </div>
                        <div className='homam-detail-item'>
                            <strong className='homam-ph-details'>Total Cost</strong>
                            <h5 className='homam-ph-details1'>&#8377; {sessionDetails ? sessionDetails.amount : ""} /-</h5>
                        </div>
                    </div>
                    <p style={{ fontWeight: '200', padding: "5px" }}>
                        ( Note :{" "}👑 <span style={{ fontWeight: 'bold', color: '#1014ba' }}>Premium </span>customers , cost of 1 free slot  <span style={{ fontWeight: 'bold', color: '#e70000' }}>{" "} Rs.{sessionDetails && sessionDetails.serviceName === "Surya-Namaskaram" ? "125" : "250"} </span>/- will be reduced from total cost.)
                    </p>
                    {/* <br /> */}
                </div>
                <br />
                <div className='homam-gen-details'>
                    <Alert severity="info">
                        <Link
                            to="/premium-checkout"
                            style={{
                                textDecoration: 'underline',
                            }}
                            onMouseEnter={(e) => (e.target.style.textDecoration = 'none')}
                            onMouseLeave={(e) => (e.target.style.textDecoration = 'underline')}
                        >
                            Don't know your nakshatra? Find here.
                        </Link>
                    </Alert>
                    <b style={{ padding: "15px" }}> Details</b>


                    <form onSubmit={handleSubmit}>
                        <br />
                        {/* Static Fields */}
                        <div className='homam-gen-details1'>
                            <TextField
                                className='homam-ph-textfield'
                                required
                                label='Person-1'
                                variant="standard"
                                value={formData.staticFields.name || (userData.length > 0 ? userData[0].userName : '')}
                                onChange={(e) => handleStaticChange('name', e.target.value)}
                                error={!!errors.staticFields.name}
                                helperText={errors.staticFields.name}
                            />

                            <FormControl variant="standard" className='homam-ph-textfield' sx={{ minWidth: 200 }}>
                                <InputLabel id="demo-simple-select-standard-label">Nakshatra</InputLabel>
                                <Select
                                    required
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={formData.staticFields.nakshatra || (userData.length > 0 ? userData[0].userNakshathra : '')}
                                    onChange={(e) => handleStaticChange('nakshatra', e.target.value)}
                                    label="Person-1 nakshatra"
                                >
                                    {nakshathraArr.map((nak, idx) => (
                                        <MenuItem key={idx} value={nak}>{nak}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                        </div>








                        {
                            count > 1 ?
                                <>
                                    <br />
                                    <b style={{ padding: "15px" }}>Add person details</b>
                                </>
                                :
                                <></>
                        }


                        <div >

                            {formData.dynamicFields.map((field, index) => (
                                <Box key={index} className='homam-gen-details2'>

                                    <TextField
                                        required
                                        className='homam-ph-textfield'
                                        label={`Person-${index + 2}`}
                                        variant="standard"
                                        value={field.name || ''}
                                        onChange={(e) => handleDynamicChange(index, 'name', e.target.value)}
                                        error={!!errors.dynamicFields[index]?.name}
                                        helperText={errors.dynamicFields[index]?.name}
                                    />



                                    <FormControl variant="standard" className='homam-ph-textfield' sx={{ minWidth: 200 }}>
                                        <InputLabel id="demo-simple-select-standard-label">{`Person-${index + 2} nakshatra`}</InputLabel>
                                        <Select
                                            required
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            value={field.nakshatra || ""}
                                            onChange={(e) => handleDynamicChange(index, 'nakshatra', e.target.value)}
                                            label="Nakshatra"
                                        >
                                            {
                                                nakshathraArr.map((nak, idx) => {
                                                    return <MenuItem key={idx} value={nak}>{nak}</MenuItem>
                                                })
                                            }
                                        </Select>
                                    </FormControl>


                                </Box>
                            ))}
                        </div>
                        <br />

                        <div className='homam-gen-details'>

                            <Stack direction="column" spacing={1} sx={{ p: 2 }} >
                                <TextField
                                    sx={{
                                        width: { xs: "300", md: "70%" },
                                        m: 2
                                    }}
                                    required
                                    id="outlined-multiline-static"
                                    label="Address line"
                                    variant="outlined"
                                    name='address'
                                    value={formData.staticFields.address}
                                    onChange={(e) => handleStaticChange('address', e.target.value)}
                                />
                                <br />
                                <Box>
                                    <TextField
                                        sx={{
                                            minWidth: 300,
                                            mr: 1,
                                            m: { xs: 1, sm: 1 }
                                        }}
                                        required
                                        id="outlined-multiline-static"
                                        label="State"
                                        variant="outlined"
                                        name='state'
                                        value={formData.staticFields.state}
                                        onChange={(e) => handleStaticChange('state', e.target.value)}
                                    />
                                    <TextField
                                        sx={{
                                            minWidth: 300,
                                            mr: 1,
                                            m: { xs: 1, sm: 1 }
                                        }}
                                        required
                                        id="outlined-multiline-static"
                                        label="City"
                                        variant="outlined"
                                        name='city'
                                        value={formData.staticFields.city}
                                        onChange={(e) => handleStaticChange('city', e.target.value)}
                                    />
                                    <TextField
                                        sx={{
                                            minWidth: 300,
                                            m: { xs: 1, sm: 1 }
                                        }}
                                        required
                                        id="outlined-multiline-static"
                                        label="Pincode"
                                        variant="outlined"
                                        name='pincode'
                                        value={formData.staticFields.pincode}
                                        onChange={(e) => handleStaticChange('pincode', e.target.value)}
                                    />
                                </Box>

                            </Stack>
                        </div>

                        <div className='homam-details-continue'>
                            <br />
                            <i style={{ placeSelf: "start" }}>Note : Fields marked * are mandatory</i>
                            <br />
                            <Box sx={{ width: "100%", display: "flex", alignItems: "end", justifyContent: "end" }} >
                                <Button className='homam-continue-btns' sx={{ marginRight: "10px" }} onClick={() => handleBack()}>Back</Button>

                                {
                                    isFormDisabled() ?
                                        <Button className='homam-continue-btns' sx={{ marginRight: "40px" }} disabled>Continue</Button>
                                        :
                                        <Button className="homam-continue-btns homam-ctn-btn" sx={{ marginRight: "40px" }} type="submit"
                                        >Continue</Button>
                                }
                            </Box>




                        </div>

                    </form>
                    <br />

                </div>


                <br />
            </div >
            <FooterComponent />
        </div >
    );
};

export default HomamDetails;
import React, { useRef } from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css'; // Import Splide styles
import { Button, Box, Typography, Container } from '@mui/material';

const TestimonialScreen = () => {
    const splideRef = useRef(null); // Create a reference for Splide

    const goToPrevSlide = () => {
        if (splideRef.current) {
            splideRef.current.go('<'); // Move to the previous slide
        }
    };

    const goToNextSlide = () => {
        if (splideRef.current) {
            splideRef.current.go('>'); // Move to the next slide
        }
    };

    return (
        <Container maxWidth="md" sx={{ mt: 2, p: 0 }}>
            <Box
                sx={{
                    backgroundColor: 'aliceblue',
                    borderRadius: '10px',
                    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
                    padding: 3,
                    mt: { xs: 2, md: 5 },
                    textAlign: 'center',
                }}
            >
                <Splide
                    ref={splideRef} // Attach the ref to Splide
                    options={{
                        type: 'loop',
                        perPage: 1,
                        pagination: false,
                        arrows: false, // Disable default arrows
                        autoplay: true,
                    }}
                >
                    {/* Slides */}
                    <SplideSlide>
                        <Box>
                            <img
                                style={{
                                    borderRadius: '50%',
                                    boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
                                    width: '80px',
                                    height: '80px',
                                    marginBottom: '16px',
                                }}
                                src={require("../images/profile icon2.jpg")}
                                alt="avatar"
                            />
                            <Typography variant="subtitle1" >
                                "Shubadinam has transformed the way I celebrate my birthday. Embracing Indian traditions is giving a special feel on my birthday, sorry janmadin!"
                            </Typography>
                            <Typography variant="caption" color="textSecondary">- Priyakumari</Typography>
                        </Box>
                    </SplideSlide>
                    <SplideSlide>
                        <Box margin={2}>
                            <img
                                style={{
                                    borderRadius: '50%',
                                    boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
                                    width: '80px',
                                    height: '80px',
                                    marginBottom: '16px',
                                }}
                                src={require("../images/Profile Icon1.jpg")}
                                alt="avatar"
                            />
                            <Typography variant="subtitle1" >
                                "I registered on Shubadinam last month for testing purpose. It was an eye-opening experience. The notification was very insightful. Highly recommend!"
                            </Typography>
                            <Typography variant="caption" color="textSecondary">- Rahul</Typography>
                        </Box>
                    </SplideSlide>
                    <SplideSlide>
                        <Box>
                            <img
                                style={{
                                    borderRadius: '50%',
                                    boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
                                    width: '80px',
                                    height: '80px',
                                    marginBottom: '16px',
                                }}
                                src={require("../images/Profile Icon1.jpg")}
                                alt="avatar"
                            />
                            <Typography variant="subtitle1" >
                                "It's a joy and a satisfying feeling to celebrate birthdays the Indian way. Thanks to Shubadinam for making it easy!"
                            </Typography>
                            <Typography variant="caption" color="textSecondary">- Prashanth</Typography>
                        </Box>
                    </SplideSlide>
                    <SplideSlide>
                        <Box >
                            <img
                                style={{
                                    borderRadius: '50%',
                                    boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
                                    width: '80px',
                                    height: '80px',
                                    marginBottom: '16px',
                                }}
                                src={require("../images/profile icon2.jpg")}
                                alt="avatar"
                            />
                            <Typography variant="subtitle1" >
                                "As someone deeply connected to my Indian roots, I found Shubadinam very useful and definitely recommend it's a must-visit site for anyone seeking to preserve our Hindu identity!"
                            </Typography>
                            <Typography variant="caption" color="textSecondary">- Ananya</Typography>
                        </Box>
                    </SplideSlide>
                </Splide>
                <Box
                    sx={{
                        marginTop: 2,
                        display: 'flex',
                        justifyContent: 'center',
                        gap: 2,
                    }}
                >
                    <Button
                        variant="outlined"
                        onClick={goToPrevSlide}
                        sx={{
                            padding: '5px 20px',
                            backgroundColor: '#1b3e69 !important',
                            color: '#fff !important',
                            transition: 'background-color 0.3s, color 0.3s',
                            textTransform: "none",
                            fontSize: "1.rem",
                            '&:hover': {
                                backgroundColor: '#fff !important',
                                color: '#1b3e69 !important',
                            },
                        }}
                    >
                        Previous
                    </Button>
                    <Button
                        variant="outlined"
                        onClick={goToNextSlide}
                        sx={{
                            padding: '5px 20px',
                            backgroundColor: '#1b3e69 !important',
                            color: '#fff !important',
                            transition: 'background-color 0.3s, color 0.3s',
                            textTransform: "none",
                            fontSize: "1.rem",
                            '&:hover': {
                                backgroundColor: '#fff !important',
                                color: '#1b3e69 !important',
                            },
                        }}
                    >
                        Next
                    </Button>
                </Box>
            </Box>
        </Container>
    );
};

export default TestimonialScreen;

// import { useRef, useState } from 'react';
// import { ControlledMenu, MenuItem, useHover, useMenuState } from '@szhsin/react-menu';
// import '@szhsin/react-menu/dist/index.css';
// import '@szhsin/react-menu/dist/transitions/zoom.css';




// const RoughFour = () => {

//     const ref = useRef(null);
//     const [menuState, toggle] = useMenuState({ transition: true });
//     const { anchorProps, hoverProps } = useHover(menuState.state, toggle);

//     return (
//         <>
//             <div ref={ref} {...anchorProps}>
//                 Hover with transition
//             </div>

//             <ControlledMenu
//                 {...hoverProps}
//                 {...menuState}
//                 anchorRef={ref}
//                 onClose={() => toggle(false)}
//             >
//                 <MenuItem>Cut</MenuItem>
//                 <MenuItem>Copy</MenuItem>
//                 <MenuItem>Paste</MenuItem>
//             </ControlledMenu>
//         </>
//     );
// };

// export default RoughFour;

// import React, { useState } from 'react';
// import { TextField, Button, MenuItem, Typography, Grid, Container, Paper } from '@mui/material';
// import {
//     CitySelect,
//     CountrySelect,
//     StateSelect,
//     LanguageSelect,
// } from "react-country-state-city";
// import "react-country-state-city/dist/react-country-state-city.css";

// const RoughFour = () => {
//     const [isEditing, setIsEditing] = useState(false);
//     const [editValue, SetEditValue] = useState({
//         name: 'John Doe',
//         userId: '12345',
//         gender: 'Male',
//         email: 'johndoe@example.com',
//         phone: '123-456-7890',
//         userCountry: 'USA',
//         userState: 'California'
//     });
//     const [countryid, setCountryid] = useState('');
//     const [stateid, setstateid] = useState('');

//     const handleEdit = () => {
//         setIsEditing(true);
//     };

//     const handleSave = () => {
//         setIsEditing(false);
//         // You can handle the save functionality here
//         console.log('Saved:', editValue);
//     };

//     return (
//         <Container maxWidth="sm" sx={{ backgroundImage: 'url(https://example.com/your-background-image.jpg)', backgroundSize: 'cover', backgroundPosition: 'center', minHeight: '100vh', padding: 4 }}>
//             <Paper elevation={3} sx={{ padding: 3, display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: 'rgba(255, 255, 255, 0.8)' }}>
//                 <Typography variant="h4" gutterBottom>
//                     Profile
//                 </Typography>
//                 <Grid container spacing={3}>
//                     <Grid item xs={12} md={6}>
//                         <TextField
//                             fullWidth
//                             label="Name"
//                             value={editValue.name}
//                             onChange={(e) => SetEditValue({ ...editValue, name: e.target.value })}
//                             InputProps={{ readOnly: !isEditing }}
//                             variant="outlined"
//                         />
//                     </Grid>
//                     <Grid item xs={12} md={6}>
//                         <TextField
//                             fullWidth
//                             label="User ID"
//                             value={editValue.userId}
//                             InputProps={{ readOnly: true }}
//                             variant="outlined"
//                         />
//                     </Grid>
//                     <Grid item xs={12} md={6}>
//                         <TextField
//                             fullWidth
//                             label="Gender"
//                             select
//                             value={editValue.gender}
//                             onChange={(e) => SetEditValue({ ...editValue, gender: e.target.value })}
//                             InputProps={{ readOnly: !isEditing }}
//                             variant="outlined"
//                         >
//                             <MenuItem value="Male">Male</MenuItem>
//                             <MenuItem value="Female">Female</MenuItem>
//                             <MenuItem value="Other">Other</MenuItem>
//                         </TextField>
//                     </Grid>
//                     <Grid item xs={12} md={6}>
//                         <TextField
//                             fullWidth
//                             label="Email"
//                             value={editValue.email}
//                             onChange={(e) => SetEditValue({ ...editValue, email: e.target.value })}
//                             InputProps={{ readOnly: !isEditing }}
//                             variant="outlined"
//                         />
//                     </Grid>
//                     <Grid item xs={12} md={6}>
//                         <TextField
//                             fullWidth
//                             label="Phone"
//                             value={editValue.phone}
//                             onChange={(e) => SetEditValue({ ...editValue, phone: e.target.value })}
//                             InputProps={{ readOnly: !isEditing }}
//                             variant="outlined"
//                         />
//                     </Grid>
//                     <Grid item xs={12} md={6}>
//                         <CountrySelect
//                             onChange={(e) => {
//                                 setCountryid(e.id);
//                                 SetEditValue(prevState => ({
//                                     ...prevState,
//                                     userCountry: e.name
//                                 }));
//                             }}
//                             onTextChange={(e) => {
//                                 SetEditValue(prevState => ({
//                                     ...prevState,
//                                     userCountry: e.target.value
//                                 }));
//                             }}
//                             name="userCountry"
//                             placeHolder="Select Country"
//                             variant="outlined"
//                         />
//                     </Grid>
//                     <Grid item xs={12} md={6}>
//                         <StateSelect
//                             countryid={countryid}
//                             onChange={(e) => {
//                                 setstateid(e.id);
//                                 SetEditValue(prevState => ({
//                                     ...prevState,
//                                     userState: e.name
//                                 }));
//                             }}
//                             onTextChange={(e) => {
//                                 SetEditValue(prevState => ({
//                                     ...prevState,
//                                     userState: e.target.value
//                                 }));
//                             }}
//                             name="userState"
//                             placeHolder="Select State"
//                             readOnly={!isEditing}
//                             variant="outlined"
//                         />
//                     </Grid>
//                     <Grid item xs={12}>
//                         <Button
//                             variant="contained"
//                             color="primary"
//                             onClick={isEditing ? handleSave : handleEdit}
//                             fullWidth
//                             size="large"
//                             sx={{ mt: 2 }}
//                         >
//                             {isEditing ? 'Save' : 'Edit'}
//                         </Button>
//                     </Grid>
//                 </Grid>
//             </Paper>
//         </Container>
//     );
// };

// export default RoughFour;



// DownloadButton.js
import React from 'react';
import axios from 'axios';
import * as XLSX from 'xlsx';

const RoughFour = () => {
    const handleDownload = async () => {
        try {
            // Fetch user details from the backend
            const response = await axios.get('https://backend.shubadinam.com/api/user-details');
            const userDetails = response.data;

            // Convert JSON data to a worksheet
            const worksheet = XLSX.utils.json_to_sheet(userDetails);

            // Create a new workbook and add the worksheet
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'User Details');

            // Generate a buffer and download the Excel file
            XLSX.writeFile(workbook, 'userDetails.xlsx');
        } catch (error) {
            console.error('Error downloading the file:', error);
        }
    };

    return (
        <button onClick={handleDownload}>
            Download User Details
        </button>
    );
};

export default RoughFour;

import React, { useState, useEffect } from 'react';
import SDadminHeader from './SDadminHeader';
import Top20px from '../screens/Top20px';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import axios from 'axios';
import Top10px from '../screens/Top10px';
import { Button, Container, TextField } from '@mui/material';
import { Stack } from '@mui/material';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';
import ScrollToTop from 'react-scroll-to-top';


const CustomTabPanel = (props) => {
    const { value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{props.children}</Box>}
        </div>
    );
};

const a11yProps = (index) => ({
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
});


const HomamAdmin = () => {

    const [data, setData] = useState([]);
    const [data2, setData2] = useState([]);
    const [data3, setData3] = useState([]);
    const [data4, setData4] = useState([]);


    useEffect(() => {
        const url = `https://backend.shubadinam.com/getHomamcutomers/today?homam=Avahanti-homam`

        axios.get(url)
            .then((response) => {
                console.log(response);
                setData(response.data);

            })
            .catch((error) => {
                console.log(error);
            })
    }, [])

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);

        // Send a request based on the selected tab
        switch (newValue) {
            case 0:
                axios.get('https://backend.shubadinam.com/getHomamcutomers/today?homam=Avahanti-homam')
                    .then(response => {
                        console.log('Avahanti Homam data:', response.data);
                        setData(response.data);
                    }).catch(error => {
                        console.error('Error fetching Avahanti Homam data:', error);
                    });
                break;
            case 1:
                axios.get('https://backend.shubadinam.com/getHomamcutomers/today?homam=Ganapathi-homam')
                    .then(response => {
                        console.log('Ganapathi Homam data:', response.data);
                        setData2(response.data);
                    }).catch(error => {
                        console.error('Error fetching Ganapathi Homam data:', error);
                    });
                break;
            case 2:
                axios.get('https://backend.shubadinam.com/getHomamcutomers/today?homam=Surya-Namaskaram')
                    .then(response => {
                        console.log('Surya Namaskar data:', response.data);
                        setData3(response.data);
                    }).catch(error => {
                        console.error('Error fetching Surya Namaskar data:', error);
                    });
                break;
            default:
                break;
        }
    };


    const [link, setLink] = useState('');

    const handleLinkChange = (event) => {
        setLink(event.target.value);
    };

    const handleLink = (h_name) => {
        if (link.trim() === "") {
            alert("Please paste the link !")
            return;
        }

        const today = new Date();

        const options = {
            weekday: 'long',
            day: '2-digit',
            month: '2-digit',
            year: '2-digit'
        };

        const formattedDate = today.toLocaleDateString('en-GB', options);

        const confirmationMessage = `You are about to process the following details:\n\nHomam Name: ${h_name}\nLink: ${link}\nDate: ${formattedDate}\n\nAre you sure you want to proceed?`;

        const userConfirmed = window.confirm(confirmationMessage);

        if (userConfirmed) {

            const data = {
                homamName: h_name,
                homamDate: formattedDate,
                youTubeLink: link
            };

            const url = `https://backend.shubadinam.com/updateLiveLink`;
            axios.post(url, data)
                .then((response) => {
                    console.log(response.data);
                    alert("You are ON LIVE now!")
                })
                .catch((error) => {
                    console.log(error);
                })


        } else {
            // User canceled, do not proceed
            console.log('update canceled.');
        }

    }


    return (
        <div className="sd-adminpage">
            <ScrollToTop smooth />
            <SDadminHeader />
            <Top20px />
            <div className="sd-body-database">
                <Stack direction='column' sx={{ width: "100%" }}>
                    <Box sx={{ width: "100%" }}>
                        <h3 style={{ paddingLeft: "20px" }}>Homam Live Update</h3>
                    </Box>
                    <br />
                    <Box sx={{ width: "100%", }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                <Tab label="Avahanti Homam" {...a11yProps(0)} />
                                <Tab label="Ganapathi Homam" {...a11yProps(1)} />
                                <Tab label="Surya Namaskar" {...a11yProps(2)} />
                                <Tab label="Navagraha" {...a11yProps(3)} />
                            </Tabs>
                        </Box>
                        <CustomTabPanel value={value} index={0} >
                            <Box sx={{ width: '100%', padding: 2 }}>
                                <Typography variant="h6" gutterBottom>
                                    Avahanti Homam Customer Details
                                </Typography>
                                <Stack direction={{ sm: "column", md: "row" }} sx={{ p: 2 }} >

                                    <TextField sx={{ ml: "40%" }} size='small' label="Paste the Link here" onChange={handleLinkChange} />
                                    <Button sx={{ ml: "5%", }} onClick={() => handleLink("Avahanti-Homam")}>
                                        Send Live Link
                                    </Button>
                                </Stack>

                                <TableContainer component={Paper} sx={{ width: { xs: "100%", sm: "100%", md: "70%" }, marginLeft: { md: "5%" } }} >
                                    <Table>
                                        <TableHead>
                                            <TableRow >
                                                <TableCell><strong>S.No</strong></TableCell>
                                                <TableCell><strong>Name</strong></TableCell>
                                                <TableCell><strong>Email</strong></TableCell>
                                                <TableCell><strong>Phone Number</strong></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {data.length === 0 ? (
                                                <TableRow>
                                                    <TableCell sx={{ textAlign: "center" }} colSpan={4}>No Customers today for Avahanti Homam</TableCell>
                                                </TableRow>
                                            ) : (
                                                data.map((customer, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell>{index + 1}</TableCell>
                                                        <TableCell>{customer.accountHolderName}</TableCell>
                                                        <TableCell sx={{ overflow: "auto" }}>{customer.accountHolderEmail}</TableCell>
                                                        <TableCell sx={{ overflow: "auto" }}>{customer.accountHolderPhone}</TableCell>
                                                    </TableRow>
                                                ))
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                            </Box>
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={1}>
                            <Box sx={{ width: '100%', padding: 2 }}>
                                <Typography variant="h6" gutterBottom>
                                    Ganapathi Homam Customer Details
                                </Typography>
                                <Stack direction={{ sm: "column", md: "row" }} sx={{ p: 2 }} >

                                    <TextField sx={{ ml: "40%" }} size='small' label="Paste the Link here" onChange={handleLinkChange} />
                                    <Button sx={{ ml: "5%", }} onClick={() => handleLink("Ganapathi-Homam")}>
                                        Send Live Link
                                    </Button>
                                </Stack>


                                <TableContainer component={Paper} sx={{ width: "70%" }} >
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell><strong>S.No</strong></TableCell>
                                                <TableCell><strong>Name</strong></TableCell>
                                                <TableCell><strong>Email</strong></TableCell>
                                                <TableCell><strong>Phone Number</strong></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {data2.length === 0 ? (
                                                <TableRow>
                                                    <TableCell sx={{ textAlign: "center" }} colSpan={4}>No Customers today for Ganapathi Homam</TableCell>
                                                </TableRow>
                                            ) : (
                                                data2.map((customer, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell>{index + 1}</TableCell>
                                                        <TableCell>{customer.accountHolderName}</TableCell>
                                                        <TableCell>{customer.accountHolderEmail}</TableCell>
                                                        <TableCell>{customer.accountHolderPhone}</TableCell>
                                                    </TableRow>
                                                ))
                                            )}
                                        </TableBody>


                                    </Table>
                                </TableContainer>
                            </Box>
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={2}>
                            <Box sx={{ width: '100%', padding: 2 }}>
                                <Typography variant="h6" gutterBottom>
                                    Surya Namaskar Customer Details
                                </Typography>
                                <Stack direction={{ sm: "column", md: "row" }} sx={{ p: 2 }} >

                                    <TextField sx={{ ml: "40%" }} size='small' label="Paste the Link here" onChange={handleLinkChange} />
                                    <Button sx={{ ml: "5%", }} onClick={() => handleLink("Surya-Namaskaram")}>
                                        Send Live Link
                                    </Button>
                                </Stack>


                                <TableContainer component={Paper} sx={{ width: "70%" }} >
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell><strong>S.No</strong></TableCell>
                                                <TableCell><strong>Name</strong></TableCell>
                                                <TableCell><strong>Email</strong></TableCell>
                                                <TableCell><strong>Phone Number</strong></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {data3.length === 0 ? (
                                                <TableRow>
                                                    <TableCell sx={{ textAlign: "center" }} colSpan={4}>No Customers today for Surya Namaskar</TableCell>
                                                </TableRow>
                                            ) : (
                                                data3.map((customer, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell>{index + 1}</TableCell>
                                                        <TableCell>{customer.accountHolderName}</TableCell>
                                                        <TableCell>{customer.accountHolderEmail}</TableCell>
                                                        <TableCell>{customer.accountHolderPhone}</TableCell>
                                                    </TableRow>
                                                ))
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={3}>
                            <Box sx={{ width: '100%', padding: 2 }}>
                                <Typography variant="h6" gutterBottom>
                                    Navagraha Customer Details
                                </Typography>
                                <Stack direction={{ sm: "column", md: "row" }} sx={{ p: 2 }} >

                                    <TextField sx={{ ml: "40%" }} size='small' label="Paste the Link here" onChange={handleLinkChange} />
                                    <Button sx={{ ml: "5%", }} onClick={() => handleLink("Navagraha")}>
                                        Send Live Link
                                    </Button>
                                </Stack>


                                <TableContainer component={Paper} sx={{ width: "70%" }} >
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell><strong>S.No</strong></TableCell>
                                                <TableCell><strong>Name</strong></TableCell>
                                                <TableCell><strong>Email</strong></TableCell>
                                                <TableCell><strong>Phone Number</strong></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {data4.length === 0 ? (
                                                <TableRow>
                                                    <TableCell sx={{ textAlign: "center" }} colSpan={4}>No Customers today for Navagraha</TableCell>
                                                </TableRow>
                                            ) : (
                                                data4.map((customer, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell>{index + 1}</TableCell>
                                                        <TableCell>{customer.accountHolderName}</TableCell>
                                                        <TableCell>{customer.accountHolderEmail}</TableCell>
                                                        <TableCell>{customer.accountHolderPhone}</TableCell>
                                                    </TableRow>
                                                ))
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </CustomTabPanel>

                    </Box>
                </Stack>


            </div>

        </div>
    );
};

export default HomamAdmin;
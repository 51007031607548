import React, { useState } from "react";
import { useEffect } from "react";

import HeaderComponent from "../components/header";
import FooterComponent from "../components/footer";
import LandingHeader from "../components/LandingHeader";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Top20px from "../screens/Top20px";
import LiveTvIcon from '@mui/icons-material/LiveTv';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { Container, Stack, useMediaQuery } from "@mui/material";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import axios from "axios";
import { useTheme } from '@mui/material/styles';

import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import YouTube from "@mui/icons-material/YouTube";


const bull = (
    <Box
        component="span"
        sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
    >
        •
    </Box>
);




const ShubadinamTv = () => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));


    const [data, setData] = useState([]);
    const [liveLinks, setLiveLinks] = useState([]);

    const [liveVideos, setLiveVideos] = useState([]);
    const [shortsVideos, setShortsVideos] = useState([]);

    useEffect(() => {

        const localId = localStorage.getItem("SD_Id") || localStorage.getItem("premiumVerified")

        if (localId) {
            const url = `https://backend.shubadinam.com/showHomamLive?localId=${localId}`;

            axios.get(url)
                .then((response) => {

                    const show_Live = response.data.homamBookedCustomers;
                    const liveLinkUpdates = response.data.liveLinkUpdate;

                    console.log(liveLinkUpdates, show_Live);
                    const seenHomamNames = new Set();
                    const deDuplicated = liveLinkUpdates.filter(item => {
                        if (!seenHomamNames.has(item.eventName)) {
                            seenHomamNames.add(item.homeventNameamName);
                            return true;
                        }
                        return false;
                    });
                    setData(deDuplicated)


                    const links = liveLinkUpdates.reduce((acc, link) => {
                        acc[link.eventName] = link.youtubeLink;
                        return acc;
                    }, {});
                    setLiveLinks(links);

                })
                .catch((error) => {
                    // console.log(error);
                })

        }

    }, [])

    const fetchVideos = async () => {
        const liveResponse = await axios.get('https://backend.shubadinam.com/youtubevideos/live');
        const shortsResponse = await axios.get('https://backend.shubadinam.com/youtubevideos/shorts');

        // Update state for live and shorts videos
        setLiveVideos(liveResponse.data);
        setShortsVideos(shortsResponse.data);
    };

    useEffect(() => {
        fetchVideos(); // Fetch videos when component mounts
    }, []);


    const extractSrcAndConvert = (iframeString) => {
        // Extract the src part
        const srcMatch = iframeString.match(/src="([^"]+)"/);

        if (!srcMatch) return null; // Return null if src is not found

        const src = srcMatch[1]; // Get the src value

        // Convert the embed link to a watch link
        const videoId = src.split('/embed/')[1].split('?')[0]; // Extract the video ID
        const watchLink = `https://www.youtube.com/watch?v=${videoId}`; // Convert to YouTube watch link
        window.open(watchLink || "", '_blank');
        // return watchLink;
    };


    return (
        <div className="sdinam-tv-overall">
            <HelmetProvider>
                <Helmet>
                    <title>Shubadinam Tv  </title>
                    <meta name="description" content="Shubadinam Tv  - Janma Nakshatra/Tithi Birthday Reminder Service." />
                </Helmet>
            </HelmetProvider>
            <LandingHeader />
            <Top20px />
            <div className="tv-container">
                <div className="tv-container-live">
                    <Stack direction="row" spacing={2}>
                        {
                            data.length !== 0 &&
                            <>
                                <h2>Shubadinam Live</h2>
                                <LiveTvIcon fontSize="large" color="error" />
                            </>
                        }
                    </Stack>
                    <Stack direction={{ sm: 'column', md: 'row' }} spacing={2}>
                        {
                            data.map((homam, index) => (

                                <Container sx={{ py: 2 }}>
                                    <Box
                                        key={index}
                                        sx={{
                                            display: 'grid',
                                            gap: { xs: 3, md: 4 }, // Responsive gap value
                                            bgcolor: 'background.paper',
                                            p: 1,
                                            borderRadius: 2,
                                            justifyContent: 'start',
                                            alignItems: 'start',
                                        }}
                                    >
                                        <Card sx={{ cursor: "pointer" }}
                                            onClick={() => {
                                                extractSrcAndConvert(liveLinks[homam.eventName])
                                                // window.open(youtubeWatchLink || "", '_blank');
                                            }}
                                        >
                                            <CardContent>
                                                <div
                                                    dangerouslySetInnerHTML={{ __html: liveLinks[homam.eventName] }}
                                                />
                                            </CardContent>

                                            <CardActions>
                                                <Button className="tv-button" variant="contained"
                                                    color="primary"
                                                    size="small"
                                                    // href="https://www.youtube.com/watch?v=x1YUd2Ane2s"
                                                    target="_blank"
                                                    sx={{
                                                        textTransform: 'none',
                                                        ':hover': { bgcolor: '#1976d2', color: 'white' }, // Hover effect
                                                    }}>Watch {homam.eventName} Live on Youtube
                                                </Button>
                                            </CardActions>
                                        </Card>


                                    </Box>
                                </Container>

                            ))
                        }


                    </Stack>

                </div>
                <br />


                {

                    liveVideos && liveVideos.length > 0 ?

                        <>
                            {/* top latest */}
                            <div className="tv-container-demo">

                                <Stack direction="row" spacing={2}>
                                    <h2>Top Latest</h2>
                                    <YouTubeIcon fontSize="large" color="error" />
                                </Stack>
                                <Container sx={{ py: 2 }}>
                                    <Box
                                        sx={{
                                            maxWidth: 400,
                                            display: 'grid',
                                            gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
                                            gridAutoRows: '1',
                                            gap: { xs: 3, md: 4 }, // Responsive gap value
                                            bgcolor: 'background.paper',
                                            p: 2,
                                            borderRadius: 2,
                                            justifyContent: 'center',
                                            alignItems: 'start',
                                        }}
                                    >

                                        {/* Video Card */}
                                        <Card sx={{ boxShadow: 3, borderRadius: 3 }}>
                                            <Box
                                                sx={{
                                                    position: 'relative',
                                                    paddingTop: '56.25%', // Maintain aspect ratio (16:9)
                                                    overflow: 'hidden',
                                                    borderRadius: '4px 4px 0 0',
                                                }}
                                            >
                                                <iframe
                                                    src="https://www.youtube.com/embed/x1YUd2Ane2s?si=fMvsYtOp64Sd2DZi"
                                                    title="YouTube video player"
                                                    frameBorder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                    referrerPolicy="strict-origin-when-cross-origin"
                                                    allowFullScreen
                                                    style={{
                                                        position: 'absolute',
                                                        top: 0,
                                                        left: 0,
                                                        width: '100%',
                                                        height: '100%',
                                                    }}
                                                />
                                            </Box>
                                            <CardActions sx={{ justifyContent: 'center', p: 2, backgroundColor: '#d3d3d3 !important' }}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    size="small"
                                                    href="https://www.youtube.com/watch?v=x1YUd2Ane2s"
                                                    target="_blank"
                                                    sx={{
                                                        backgroundColor: '#143d76 !important',
                                                        color: 'white !important',
                                                        textTransform: 'none',
                                                        ':hover': { color: 'white' }, // Hover effect
                                                    }}
                                                >
                                                    Watch Video on Youtube <YouTube sx={{ ml: 1 }} />
                                                </Button>
                                            </CardActions>
                                        </Card>
                                    </Box>
                                </Container>


                            </div>


                            {/*live recents */}
                            <div className="tv-container-demo">
                                <Stack direction="row" spacing={2}>
                                    <h2>Past Live ({liveVideos.length})</h2>
                                    <YouTubeIcon fontSize="large" color="error" />
                                </Stack>
                                <br />
                                <Container sx={{ py: 4 }}>
                                    <Splide
                                        options={{
                                            // type:"fade",
                                            perPage: isMobile ? 1 : 3, // Show 1 slide on mobile, 3 on desktop
                                            rewind: false,
                                            gap: '1rem',
                                            arrows: true, // Enable arrows for navigation
                                            pagination: false, // Disable pagination bullets
                                            padding: { right: '2rem' }, // Add padding to the right for better alignment
                                            breakpoints: {
                                                1200: {
                                                    perPage: 2, // Show 2 slides on smaller laptop screens
                                                },
                                                900: {
                                                    perPage: 1, // Show 1 slide on tablet screens
                                                },
                                            },
                                        }}
                                    >
                                        {liveVideos.slice().reverse().map((short) => (
                                            <SplideSlide key={short.id}>
                                                <Card sx={{ boxShadow: 3, borderRadius: 3 }}>
                                                    <Box
                                                        sx={{
                                                            position: 'relative',
                                                            paddingTop: '56.25%', // Maintain 16:9 aspect ratio
                                                            overflow: 'hidden',
                                                            borderRadius: '4px 4px 0 0',
                                                        }}
                                                    >
                                                        <iframe
                                                            width="100%" // Adjust width dynamically to fit the container
                                                            height="100%" // Ensure height fits to maintain aspect ratio
                                                            src={short.src}
                                                            title={short.title}
                                                            frameBorder="0"
                                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                            allowFullScreen
                                                            style={{
                                                                position: 'absolute',
                                                                top: 0,
                                                                left: 0,
                                                                width: '100%',
                                                                height: '100%',
                                                            }}
                                                        />
                                                    </Box>
                                                    <CardActions sx={{ justifyContent: 'center', p: 2, backgroundColor: '#d3d3d3 !important', }}>
                                                        <Button
                                                            size="medium"
                                                            href={short.href}
                                                            target="_blank"
                                                            sx={{
                                                                backgroundColor: '#143d76 !important',
                                                                border: "1px solid transparent !important",
                                                                color: 'white !important',
                                                                textTransform: 'none',
                                                                ':hover': { color: 'white' }, // Hover effect
                                                            }}
                                                        >
                                                            Watch Video on Youtube <YouTube sx={{ ml: 1 }} />
                                                        </Button>
                                                    </CardActions>
                                                </Card>
                                            </SplideSlide>
                                        ))}
                                    </Splide>
                                </Container>
                            </div>

                            <br />
                            {/* shorts */}
                            <div className="tv-container-demo">
                                <Stack direction="row" spacing={2}>
                                    <h2>Shorts ({shortsVideos.length})</h2>
                                    <YouTubeIcon fontSize="large" color="error" />
                                </Stack>
                                <br />
                                <Container sx={{ py: 4 }}>
                                    <Splide
                                        options={{
                                            perPage: isMobile ? 1 : 3, // Show 1 slide on mobile, 3 on desktop
                                            rewind: false,
                                            gap: '1rem',
                                            arrows: true, // Enable arrows for navigation
                                            pagination: false, // Disable pagination bullets
                                            padding: { right: '2rem' }, // Add padding to the right for better alignment
                                            breakpoints: {
                                                1200: {
                                                    perPage: 2, // Show 2 slides on smaller laptop screens
                                                },
                                                900: {
                                                    perPage: 1, // Show 1 slide on tablet screens
                                                },
                                            },
                                        }}
                                    >
                                        {shortsVideos.slice().reverse().map((short) => (
                                            <SplideSlide key={short.id}>
                                                <Card sx={{ boxShadow: 3, borderRadius: 3 }}>
                                                    <Box
                                                        sx={{
                                                            position: 'relative',
                                                            paddingTop: '56.25%', // Maintain 16:9 aspect ratio
                                                            overflow: 'hidden',
                                                            borderRadius: '4px 4px 0 0',
                                                        }}
                                                    >
                                                        <iframe
                                                            width="100%" // Adjust width dynamically to fit the container
                                                            height="100%" // Ensure height fits to maintain aspect ratio
                                                            src={short.src}
                                                            title={short.title}
                                                            frameBorder="0"
                                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                            allowFullScreen
                                                            style={{
                                                                position: 'absolute',
                                                                top: 0,
                                                                left: 0,
                                                                width: '100%',
                                                                height: '100%',
                                                            }}
                                                        />
                                                    </Box>
                                                    <CardActions sx={{ justifyContent: 'center', p: 2, backgroundColor: '#d3d3d3 !important', }}>
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            size="small"
                                                            href={short.href}
                                                            target="_blank"
                                                            sx={{
                                                                backgroundColor: '#143d76 !important',
                                                                color: 'white !important',
                                                                textTransform: 'none',
                                                                ':hover': { color: 'white' }, // Hover effect
                                                            }}
                                                        >
                                                            Watch Video on Youtube <YouTube sx={{ ml: 1 }} />
                                                        </Button>
                                                    </CardActions>
                                                </Card>
                                            </SplideSlide>
                                        ))}
                                    </Splide>
                                </Container>
                            </div>
                        </>
                        :
                        <>
                            <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom> Loading ...  </Typography>
                        </>

                }




            </div>

            <FooterComponent />

        </div >
    );
};

export default ShubadinamTv;


import React, { useEffect, useState } from "react";
import axios from "axios";
import { Grid, Card, CardContent, Typography, Container, Box, Tab, Tabs, Avatar, Button, IconButton, DialogContent, DialogActions, Dialog } from "@mui/material";
import { PlayArrow } from "@mui/icons-material";
import {
    AppBar,
    Toolbar,
    InputBase,
    List,
    ListItem,
    Drawer,
    Divider,
    ListItemIcon,
    Chip,
    ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import HomeIcon from "@mui/icons-material/Home";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import HistoryIcon from "@mui/icons-material/History";
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { Link } from "react-router-dom";

const suggestions = [
    " Suggestion 1",
    " Suggestion 2",
    " Suggestion 3",
    " Suggestion 4",
    " Suggestion 5",
    " Suggestion 6",
    " Suggestion 7",
    " Suggestion 8",
    " Suggestion 9",
    " Suggestion 10",
];

const RoughOne = () => {
    const [videos, setVideos] = useState({
        home: [],
        shorts: [],
        live: [],
        video: [],
    });

    const [searchValue, setSearchValue] = React.useState("");
    const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

    const [selectedTab, setSelectedTab] = useState(0); // Tab state to track selected tab
    const [channelInfo, setChannelInfo] = useState({ logoUrl: '', description: '' });
    const [showFullDescription, setShowFullDescription] = useState(false); // State for toggling description view
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedVideoId, setSelectedVideoId] = useState(null);

    const handleSearchChange = (event) => {
        setSearchValue(event.target.value);
    };

    const toggleDrawer = (open) => (event) => {
        if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
            return;
        }
        setIsDrawerOpen(open);
    };


    const handleOpenDialog = (videoId) => {
        setSelectedVideoId(videoId);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setSelectedVideoId(null);
    };

    useEffect(() => {
        const fetchVideos = async () => {
            try {
                const response = await axios.get("https://backend.shubadinam.com/api/videos");
                setVideos(response.data.videos);
                setChannelInfo(response.data.channelInfo);
            } catch (error) {
                console.error("Error fetching videos", error);
            }
        };

        fetchVideos();
    }, []);


    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const renderVideo = (videoId) => {
        return (
            <iframe
                width="100%"
                height="315"
                src={`https://www.youtube.com/embed/${videoId}`}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            ></iframe>
        );
    };

    return (

        <Box>

            <AppBar position="fixed" elevation={1} sx={{ backgroundColor: "#ffff" }}>
                <Toolbar>
                    {/* Left menu icon */}
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        onClick={toggleDrawer(true)}
                    >
                        <MenuIcon sx={{ color: "black" }} />
                    </IconButton>

                    {/* Logo */}
                    <Typography
                        color="black"
                        variant="subtitle1"
                        noWrap
                        component="div"
                        sx={{ flexGrow: 1, fontWeight: "bold", cursor: "pointer" }}
                    >
                        Shubadinam Tv
                    </Typography>

                    {/* Search Bar */}
                    <Box
                        sx={{
                            flexGrow: 2,
                            display: "flex",
                            alignItems: "center",
                            backgroundColor: "#eeee",
                            borderRadius: "4px",
                            px: 2,
                            mx: 2,
                        }}
                    >
                        <InputBase
                            placeholder="Search"
                            value={searchValue}
                            onChange={handleSearchChange}
                            sx={{ flexGrow: 1 }}
                        />
                        <IconButton type="button">
                            <SearchIcon />
                        </IconButton>
                    </Box>

                    {/* Account Avatar */}
                    <IconButton edge="end">
                        <Avatar alt="Account" src="/static/images/avatar/1.jpg" />
                    </IconButton>
                </Toolbar>
            </AppBar>

            {/* banner */}
            <Box
                textAlign="center"
                mb={4}
                mt={10}
                sx={{
                    backgroundImage: `url("https://yt3.googleusercontent.com/-LgwNk-u9hG5MX_27ATTo33Ayje1UXOAzVpZgIPjLDaJyQuiIinAyCWZsvRkOda67so7VqJ9lw=w2120-fcrop64=1,00005a57ffffa5a8-k-c0xffffffff-no-nd-rj")`,
                    backgroundSize: "cover", // Ensure the background image covers the entire box
                    backgroundPosition: "center", // Center the image within the box
                    height: { xs: "150px", md: "200px" }, // Set a fixed height
                    width: "100%", // Ensure the width is 100%
                }}
            >
                {/* Content goes here, if needed */}
            </Box>

            {/* Drawer */}
            <Drawer anchor="left" open={isDrawerOpen} onClose={toggleDrawer(false)}>
                <Box
                    sx={{ width: 250 }}
                    role="presentation"
                    onClick={toggleDrawer(false)}
                    onKeyDown={toggleDrawer(false)}
                >
                    <List>
                        <Box sx={{ width: "100%", }}>
                            <img src="https://www.gstatic.com/youtube/img/promos/growth/YTP_logo_social_1200x630.png?days_since_epoch=20055" style={{ width: "100%", height: "auto" }} />
                        </Box>
                        <ListItem button>
                            <ListItemIcon>
                                <HomeIcon />
                            </ListItemIcon>
                            <ListItemText primary="Home" />
                        </ListItem>
                        <ListItem button>
                            <ListItemIcon>
                                <SubscriptionsIcon />
                            </ListItemIcon>
                            <ListItemText primary="Subscriptions" />
                        </ListItem>
                        <ListItem button>
                            <ListItemIcon>
                                <VideoLibraryIcon />
                            </ListItemIcon>
                            <ListItemText primary="Library" />
                        </ListItem>
                        <ListItem button>
                            <ListItemIcon>
                                <HistoryIcon />
                            </ListItemIcon>
                            <ListItemText primary="History" />
                        </ListItem>
                    </List>
                    <Divider />
                </Box>
            </Drawer>

            {/* Splide Carousel */}
            <Box sx={{ alignContent: "center", width: "100%", overflow: "hidden", }}>
                {/* Splide Carousel */}
                <Splide
                    options={{
                        type: 'slide',
                        gap: '1rem',
                        pagination: false, // Show pagination
                        arrows: true, // Enable arrows
                        drag: true, // Allow dragging
                        keyboard: 'global', // Enable global keyboard navigation
                        breakpoints: {
                            2000: {
                                perPage: 9, // Desktop: 4 items per page
                            },
                            1200: {
                                perPage: 6, // Desktop: 4 items per page
                            },
                            900: {
                                perPage: 3, // Tablet: 3 items per page
                            },
                            0: {
                                perPage: 1, // Mobile (small screens): 1 item per page
                            },
                        },
                    }}
                >
                    {/* Map over suggestions and create carousel slides */}
                    {suggestions.map((suggestion, index) => (
                        <SplideSlide key={index} style={{ width: "fit-content", display: "inline-block" }}>
                            <Chip label={suggestion} sx={{ maxWidth: "fit-content", padding: "8px", }} />
                        </SplideSlide>
                    ))}
                </Splide>
            </Box>

            <Container>
                <Box sx={{ textAlign: 'center' }}>

                    {/* Chanel Logo and Description */}
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: { xs: 'column', sm: 'row' }, // Stack vertically on small screens, horizontally on larger screens
                            gap: 2, // Add spacing between the elements
                            padding: 2, // Add padding to the container
                            borderRadius: 2, // Rounded corners for the overall box
                            boxShadow: 0.5, // Add subtle shadow for better UI depth
                        }}
                    >
                        {/* Channel logo */}
                        <Avatar
                            alt="Channel Logo"
                            src={channelInfo?.logoUrl || "default-logo-url.jpg"} // Default if no logoUrl
                            sx={{
                                width: 80,
                                height: 80,
                                borderRadius: '50%', // Ensure the logo is circular
                                border: '3px solid #fff', // Optional border around logo
                                boxShadow: 1, // Subtle shadow around the logo for emphasis
                            }}
                        />

                        {/* Channel Description */}
                        <Box
                            display="flex"
                            flexDirection="row" // Stack elements vertically within the description box
                            sx={{
                                flex: 1, // Allow description box to take up remaining space
                                paddingLeft: 2, // Add left padding to give space from the logo
                            }}
                        >
                            {/* Channel Description */}
                            <Typography variant="body2" color="textSecondary" sx={{ textAlign: "start", lineHeight: 1.6, }}>
                                {showFullDescription
                                    ? channelInfo.description
                                    : `${channelInfo.description.substring(0, 100)}...`}
                                <Typography component={Link} variant="body2"
                                    onClick={() => setShowFullDescription((prev) => !prev)}
                                >
                                    {showFullDescription ? 'Show Less' : 'Read More'}
                                </Typography>

                            </Typography>

                        </Box>
                    </Box>



                    {/* Tabs for Navigation */}
                    <Tabs value={selectedTab} onChange={handleTabChange} sx={{ mt: 2 }}>
                        <Tab label="Home" />
                        <Tab label="Shorts" />
                        <Tab label="Live" />
                        <Tab label="Videos" />
                    </Tabs>

                    <Grid sx={{ marginTop: 4 }}>
                        {/* Tab Content */}
                        <Grid container spacing={3}>
                            {videos[selectedTab === 0
                                ? "home" // Home tab
                                : selectedTab === 1
                                    ? "shorts" // Shorts tab
                                    : selectedTab === 2
                                        ? "live" // Live tab
                                        : "video" // Videos tab
                            ].map((video, index) => (
                                < Grid item xs={12} sm={6} md={4} key={index} onClick={() => handleOpenDialog(video.videoId)}>
                                    <Card
                                        sx={{
                                            maxWidth: 345,
                                            boxShadow: 3,
                                            borderRadius: 2,
                                            cursor: 'pointer',
                                        }}
                                    >
                                        <a
                                            onClick={() => handleOpenDialog(video.videoId)}
                                            style={{
                                                cursor: 'pointer',
                                                display: 'block',
                                                position: 'relative',
                                                width: '100%',
                                                height: '200px', // Adjust height as needed
                                                borderRadius: '8px',
                                                backgroundImage: `url(https://img.youtube.com/vi/${video.videoId}/hqdefault.jpg)`,
                                                backgroundSize: 'cover',
                                                backgroundPosition: 'center',
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    position: 'absolute',
                                                    top: '50%',
                                                    left: '50%',
                                                    transform: 'translate(-50%, -50%)',
                                                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                                    padding: '10px',
                                                    borderRadius: '50%',
                                                    cursor: 'pointer',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    '&:hover': {
                                                        backgroundColor: 'rgba(0, 0, 0, 0.7)',
                                                    },
                                                }}
                                            >
                                                {/* Custom Play Icon */}
                                                <PlayArrow
                                                    sx={{
                                                        color: '#fff',
                                                        fontSize: '3rem',
                                                    }}
                                                />
                                            </Box>
                                        </a>

                                        <CardContent>
                                            <Typography variant="subtitle1" component="div" sx={{ fontWeight: 'bold', color: '#333' }}>
                                                {video.title}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>

                        {/* Dialog to show the selected video */}
                        <Dialog
                            open={openDialog}
                            onClose={handleCloseDialog}
                            maxWidth="md"
                            fullWidth
                        >
                            <DialogContent>
                                <Box sx={{ position: 'relative', paddingTop: '50.25%' /* 16:9 aspect ratio */ }}>
                                    <iframe
                                        width="100%"
                                        height="100%"
                                        src={`https://www.youtube.com/embed/${selectedVideoId}?autoplay=1`}
                                        title="YouTube video player"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            borderRadius: '8px 8px 0 0',
                                        }}
                                    ></iframe>
                                </Box>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCloseDialog} color="primary">
                                    Close
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Grid>

                </Box>


            </Container >

        </Box >
    );
};

export default RoughOne;









// import React, { useState } from "react";
// import { Box, Typography, useMediaQuery, Button, Tabs, Tab } from "@mui/material";
// import { Splide, SplideSlide } from "@splidejs/react-splide";
// import PlayArrowIcon from "@mui/icons-material/PlayArrow"; // Import PlayArrow icon
// import "@splidejs/react-splide/css"; // Import Splide styles

// const videos = [
//     { id: 1, title: "Live Event 1", link: "AY3Yea6hBbI" },
//     { id: 2, title: "Live Event 2", link: "AY3Yea6hBbI" },
//     { id: 3, title: "Live Event 3", link: "your_live_video_3" },
//     { id: 4, title: "Live Event 4", link: "your_live_video_4" },
//     { id: 5, title: "Live Event 5", link: "your_live_video_5" },
// ];

// const shorts = [
//     { id: 1, title: "Short 1", link: "UQR2soXj8Ck" },
//     { id: 2, title: "Short 2", link: "UQR2soXj8Ck" },
//     { id: 3, title: "Short 3", link: "your_short_video_3" },
//     { id: 4, title: "Short 4", link: "your_short_video_4" },
//     { id: 5, title: "Short 5", link: "your_short_video_5" },
// ];

// const RoughOne = () => {
//     const [activeVideo, setActiveVideo] = useState(null);
//     const [tabValue, setTabValue] = useState(0); // To track the active tab
//     const isMobile = useMediaQuery("(max-width:600px)");

//     const handleVideoClick = (link) => {
//         setActiveVideo(link);
//     };

//     const handleTabChange = (event, newValue) => {
//         setTabValue(newValue);
//     };

//     const renderVideos = () => (
//         <Box mb={6}>
//             <Typography variant={isMobile ? "h6" : "h4"} mb={2} color="#fff">
//                 Videos
//             </Typography>
//             <Splide
//                 options={{
//                     perPage: isMobile ? 1 : 3,
//                     gap: "1rem",
//                     pagination: true,
//                     arrows: true,
//                     breakpoints: {
//                         600: {
//                             perPage: 1,
//                         },
//                     },
//                 }}
//             >
//                 {videos.map((video) => (
//                     <SplideSlide key={video.id}>
//                         <Box
//                             sx={{
//                                 backgroundColor: "#202020",
//                                 borderRadius: 2,
//                                 overflow: "hidden",
//                                 boxShadow: 3,
//                             }}
//                         >
//                             {activeVideo === video.link ? (
//                                 <iframe
//                                     width="100%"
//                                     height="200"
//                                     src={`https://www.youtube.com/embed/${video.link}?autoplay=1`}
//                                     title={video.title}
//                                     frameBorder="0"
//                                     allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
//                                     allowFullScreen
//                                 />
//                             ) : (
//                                 <a
//                                     onClick={() => handleVideoClick(video.link)}
//                                     style={{ cursor: "pointer" }}
//                                 >
//                                     <Box
//                                         sx={{
//                                             width: "100%",
//                                             height: "200px",
//                                             display: "flex",
//                                             alignItems: "center",
//                                             justifyContent: "center",
//                                             backgroundColor: "#000",
//                                             position: "relative",
//                                             borderRadius: "8px",
//                                             backgroundImage: `url(https://img.youtube.com/vi/${video.link}/hqdefault.jpg)`,
//                                             backgroundSize: "cover",
//                                             backgroundPosition: "center",
//                                         }}
//                                     >
//                                         <PlayArrowIcon
//                                             sx={{
//                                                 color: "#fff",
//                                                 fontSize: "3rem",
//                                                 position: "absolute",
//                                             }}
//                                         />
//                                     </Box>
//                                 </a>
//                             )}
//                             <Box sx={{ padding: 2 }}>
//                                 <Typography
//                                     variant="subtitle1"
//                                     fontWeight="bold"
//                                     textAlign="center"
//                                     color="#fff"
//                                 >
//                                     {video.title}
//                                 </Typography>
//                             </Box>
//                         </Box>
//                     </SplideSlide>
//                 ))}
//             </Splide>
//         </Box >
//     );

//     const renderShorts = () => (
//         <Box mb={6}>
//             <Typography variant={isMobile ? "h6" : "h4"} mb={2} color="#fff">
//                 Shorts
//             </Typography>
//             <Splide
//                 options={{
//                     perPage: isMobile ? 2 : 4,
//                     gap: "1rem",
//                     pagination: true,
//                     arrows: true,
//                     breakpoints: {
//                         600: {
//                             perPage: 2,
//                         },
//                     },
//                 }}
//             >
//                 {shorts.map((short) => (
//                     <SplideSlide key={short.id}>
//                         <Box
//                             sx={{
//                                 backgroundColor: "#202020",
//                                 borderRadius: 2,
//                                 overflow: "hidden",
//                                 boxShadow: 3,
//                             }}
//                         >
//                             {activeVideo === short.link ? (
//                                 <iframe
//                                     width="100%"
//                                     height="150"
//                                     src={`https://www.youtube.com/embed/${short.link}?autoplay=1`}
//                                     title={short.title}
//                                     frameBorder="0"
//                                     allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
//                                     allowFullScreen
//                                 />
//                             ) : (
//                                 <a
//                                     onClick={() => handleVideoClick(short.link)}
//                                     style={{ cursor: "pointer" }}
//                                 >
//                                     <Box
//                                         sx={{
//                                             width: "100%",
//                                             height: isMobile ? "120px" : "200px",
//                                             display: "flex",
//                                             alignItems: "center",
//                                             justifyContent: "center",
//                                             backgroundColor: "#000",
//                                             position: "relative",
//                                             borderRadius: "8px",
//                                             backgroundImage: `url(https://img.youtube.com/vi/${short.link}/hqdefault.jpg)`,
//                                             backgroundSize: "cover",
//                                             backgroundPosition: "center",
//                                         }}
//                                     >
//                                         <PlayArrowIcon
//                                             sx={{
//                                                 color: "#fff",
//                                                 fontSize: "3rem",
//                                                 position: "absolute",
//                                             }}
//                                         />
//                                     </Box>
//                                 </a>
//                             )}
//                             <Box sx={{ padding: 1 }}>
//                                 <Typography
//                                     variant="subtitle2"
//                                     fontWeight="bold"
//                                     textAlign="center"
//                                 >
//                                     {short.title}
//                                 </Typography>
//                             </Box>
//                         </Box>
//                     </SplideSlide>
//                 ))}
//             </Splide>
//         </Box>
//     );

//     return (
//         <Box sx={{ minHeight: "100vh", padding: 1 }}>
//             {/* Channel Image and Title */}
//             <Box textAlign="center" mb={4}>
//                 <img
//                     src="https://yt3.googleusercontent.com/-LgwNk-u9hG5MX_27ATTo33Ayje1UXOAzVpZgIPjLDaJyQuiIinAyCWZsvRkOda67so7VqJ9lw=w2120-fcrop64=1,00005a57ffffa5a8-k-c0xffffffff-no-nd-rj"
//                     alt="Channel Logo"
//                     style={{ height: "150px", width: "100%" }}
//                 />
//                 <Typography
//                     variant="subtitle1"
//                     color="text.secondary"
//                     mb={3}
//                 >
//                     Watch Live Events and Exciting Shorts!
//                 </Typography>
//                 <Button
//                     variant="contained"
//                     sx={{
//                         backgroundColor: "#ff0000",
//                         color: "#fff",
//                         padding: "10px 20px",
//                         fontSize: "16px",
//                     }}
//                 >
//                     Subscribe Now
//                 </Button>
//             </Box>

//             {/* Tabs for Navigation */}
//             <Tabs
//                 value={tabValue}
//                 onChange={handleTabChange}
//                 centered
//                 sx={{ mb: 4, }}
//             >
//                 <Tab label="Home" />
//                 <Tab label="Videos" />
//                 <Tab label="Shorts" />
//                 <Tab label="Live" />
//             </Tabs>

//             {/* Tab Content */}
//             {tabValue === 0 && (
//                 <Box>
//                     <Typography
//                         variant="h6"
//                         color="text.secondary"
//                         textAlign="center"
//                         mb={3}
//                     >
//                         Welcome to our channel. Browse through our content to find the best of Live events, Videos, and Shorts!
//                     </Typography>

//                     {/* Videos Section */}
//                     <Typography
//                         variant={isMobile ? "h5" : "h4"}
//                         textAlign="left"
//                         mb={2}
//                         sx={{ fontWeight: "bold" }}
//                     >
//                         Videos
//                     </Typography>
//                     {renderVideos()}

//                     {/* Shorts Section */}
//                     <Typography
//                         variant={isMobile ? "h5" : "h4"}
//                         textAlign="left"
//                         mb={2}
//                         sx={{ fontWeight: "bold" }}
//                     >
//                         Shorts
//                     </Typography>
//                     {renderShorts()}
//                 </Box>
//             )}

//             {tabValue === 1 && <Box>
//                 <Typography
//                     variant="h6"
//                     color="text.secondary"
//                     textAlign="center"
//                     mb={3}
//                 >
//                     Welcome to our channel. Browse through our content to find the best of Live events, Videos, and Shorts!
//                 </Typography>
//                 {renderVideos()}
//             </Box>}
//             {tabValue === 2 && <Box>
//                 <Typography
//                     variant="h6"
//                     color="text.secondary"
//                     textAlign="center"
//                     mb={3}
//                 >
//                     Welcome to our channel. Browse through our content to find the best of Live events, Videos, and Shorts!
//                 </Typography>
//                 {renderShorts()}
//             </Box>}
//         </Box>
//     );
// };

// export default RoughOne;




console.log("youtube theme above");


// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import { Grid, Card, CardMedia, CardContent, Typography, Button, Paper, Dialog, DialogActions, DialogContent, Tabs, Tab, Box } from '@mui/material';

// const API_KEY = 'AIzaSyA_Ib8ZoASpObaQZKGx32aVTOKGZ270WI8';  // Replace with your API key
// const CHANNEL_ID = 'UCsNbE7QqnpgjI7cXOTM1mXA';   // Replace with the channel ID

// const RoughOne = () => {
//     const [channelData, setChannelData] = useState(null);
//     const [videos, setVideos] = useState([]);
//     const [filteredVideos, setFilteredVideos] = useState([]);
//     const [error, setError] = useState(null);
//     const [openVideoDialog, setOpenVideoDialog] = useState(false);
//     const [currentVideoId, setCurrentVideoId] = useState(null);
//     const [tabIndex, setTabIndex] = useState(0);
//     const [showFullDescription, setShowFullDescription] = useState(false);

//     useEffect(() => {
//         const fetchChannelData = async () => {
//             try {
//                 const response = await axios.get(
//                     `https://www.googleapis.com/youtube/v3/channels?part=snippet,contentDetails,statistics&id=${CHANNEL_ID}&key=${API_KEY}`
//                 );

//                 if (response.data.items && response.data.items.length > 0) {
//                     const channel = response.data.items[0];
//                     setChannelData(channel.snippet);

//                     // Fetch all videos from the uploads playlist
//                     const uploads = channel.contentDetails.relatedPlaylists.uploads;
//                     fetchVideos(uploads);
//                 } else {
//                     setError("Channel not found or invalid channel ID.");
//                 }
//             } catch (error) {
//                 setError("Error fetching Channel Data: " + error.message);
//                 console.error("Error fetching Channel Data:", error);
//             }
//         };

//         fetchChannelData();
//     }, []);

//     const fetchVideos = async (playlistId) => {
//         try {
//             const videoResponse = await axios.get(
//                 `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=50&playlistId=${playlistId}&key=${API_KEY}`
//             );

//             if (videoResponse.data.items) {
//                 const allVideos = videoResponse.data.items;
//                 setVideos(allVideos);

//                 // Filter videos for the initial tab (Home)
//                 filterVideosByTab(0, allVideos);
//             }
//         } catch (error) {
//             setError("Error fetching videos: " + error.message);
//             console.error("Error fetching videos:", error);
//         }
//     };

//     const filterVideosByTab = (tabIndex, allVideos = videos) => {
//         let filtered = [];
//         if (tabIndex === 0) {
//             filtered = allVideos; // Home: Show all videos
//         } else if (tabIndex === 1) {
//             filtered = allVideos.filter((video) =>
//                 video.snippet.description.toLowerCase().includes("video")
//             );
//         } else if (tabIndex === 2) {
//             filtered = allVideos.filter((video) =>
//                 video.snippet.description.toLowerCase().includes("shorts")
//             );
//         } else if (tabIndex === 3) {
//             filtered = allVideos.filter((video) =>
//                 video.snippet.description.toLowerCase().includes("ganapathi,surya,avahanti")
//             );
//         }
//         setFilteredVideos(filtered);
//     };

//     const handleTabChange = (event, newTabIndex) => {
//         setTabIndex(newTabIndex);
//         filterVideosByTab(newTabIndex);
//     };

//     const handleVideoClick = (videoId) => {
//         setCurrentVideoId(videoId);
//         setOpenVideoDialog(true);
//     };

//     const handleCloseDialog = () => {
//         setOpenVideoDialog(false);
//         setCurrentVideoId(null);
//     };

//     return (
//         <div>
//             <Paper elevation={3} style={{ padding: '20px', marginBottom: '20px' }}>
// <Box textAlign="center" mb={4}>
//     <img
//         src="https://yt3.googleusercontent.com/-LgwNk-u9hG5MX_27ATTo33Ayje1UXOAzVpZgIPjLDaJyQuiIinAyCWZsvRkOda67so7VqJ9lw=w2120-fcrop64=1,00005a57ffffa5a8-k-c0xffffffff-no-nd-rj"
//         alt="Channel Logo"
//         style={{ height: "150px", width: "100%" }}
//     />
//     <Typography
//         variant="subtitle1"
//         color="text.secondary"
//         mb={3}
//     >
//         Watch Live Events and Exciting Shorts!
//     </Typography>
// </Box>

//                 {error ? (
//                     <Typography color="error">{error}</Typography>
//                 ) : channelData ? (
//                     <div style={{ display: 'flex', alignItems: 'center' }}>
//                         <img
//                             src={channelData.thumbnails.high.url}
//                             alt={channelData.title}
//                             style={{ width: '120px', height: '120px', borderRadius: '50%', marginRight: '20px' }}
//                         />
// <div >
//     <Typography variant="h5" gutterBottom style={{ fontWeight: 'bold' }}>
//         {channelData.title}
//     </Typography>
//     <Typography variant="body1" paragraph>
//         {showFullDescription
//             ? channelData.description
//             : `${channelData.description.substring(0, 100)}...`}
//     </Typography>
//     {channelData.description.length > 100 && (
//         <Button
//             size="small"
//             onClick={() => setShowFullDescription((prev) => !prev)}
//             style={{ textTransform: 'none', padding: 0 }}
//         >
//             {showFullDescription ? 'Show Less' : 'Read More'}
//         </Button>
//     )}
// </div>
//                     </div>

//                 ) : (
//                     <p>Loading Channel Data...</p>
//                 )}
//             </Paper>

//             <Tabs value={tabIndex} onChange={handleTabChange} centered>
//                 <Tab label="Home" />
//                 <Tab label="Videos" />
//                 <Tab label="Shorts" />
//                 <Tab label="Live" />
//             </Tabs>

//             <Typography variant="h5" gutterBottom>
//                 Latest Videos
//             </Typography>

//             {filteredVideos.length > 0 ? (
//                 <Grid container spacing={4}>
//                     {filteredVideos.map((video, index) => (
//                         <Grid item xs={12} sm={6} md={4} key={index}>
//                             <Card style={{ height: '100%' }}>
//                                 <CardMedia
//                                     component="img"
//                                     height="200"
//                                     image={video.snippet.thumbnails.medium.url}
//                                     alt={video.snippet.title}
//                                     style={{
//                                         borderRadius: '10px',
//                                         cursor: 'pointer',
//                                     }}
//                                     onClick={() => handleVideoClick(video.snippet.resourceId.videoId)}
//                                 />
//                                 <CardContent style={{ padding: '16px' }}>
//                                     <Typography variant="h6" noWrap>
//                                         {video.snippet.title}
//                                     </Typography>
//                                     <Button
//                                         variant="contained"
//                                         color="primary"
//                                         fullWidth
//                                         onClick={() => handleVideoClick(video.snippet.resourceId.videoId)}
//                                         style={{ marginTop: '10px' }}
//                                     >
//                                         Watch Video
//                                     </Button>
//                                 </CardContent>
//                             </Card>
//                         </Grid>
//                     ))}
//                 </Grid>
//             ) : (
//                 <p>No videos found.</p>
//             )}

//             <Dialog open={openVideoDialog} onClose={handleCloseDialog} maxWidth="md" fullWidth>
//                 <DialogContent>
//                     {currentVideoId && (
//                         <iframe
//                             width="100%"
//                             height="400"
//                             src={`https://www.youtube.com/embed/${currentVideoId}`}
//                             title="YouTube video player"
//                             frameBorder="0"
//                             allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
//                             allowFullScreen
//                         ></iframe>
//                     )}
//                 </DialogContent>
//                 <DialogActions>
//                     <Button onClick={handleCloseDialog} color="primary">
//                         Close
//                     </Button>
//                 </DialogActions>
//             </Dialog>
//         </div>
//     );
// };

// export default RoughOne;



console.log("youtube api v3 above");













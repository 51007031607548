
import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import SecondDataSharing from "../../context/second-data-sharing";

import axios from "axios";


const PricingPoints = () => {


    useEffect(() => {
        if (window.location.pathname.includes('pricing')) {
            document.querySelector('.pricing-free').classList.add('order-1');
            document.querySelector('.pricing-premium').classList.add('order-1');
        }
    }, []);


    const [data, setData] = useState(null);
    const [daysLeft, setDaysLeft] = useState(0);

    const tableTwoData = useContext(SecondDataSharing);
    // console.log(tableTwoData);

    const Navigate = useNavigate();

    const signOut = () => {
        setSignOut(false);
        localStorage.removeItem("isAuthenticated");
        localStorage.removeItem("fkey");
        localStorage.removeItem("homeauth")
        Navigate("/");
    };

    const logoBtn = () => {
        Navigate("/");
    };

    // MENU BAR

    const [isOpenMenu, setOpenMenu] = useState(false);

    const [dashsignOut, setSignOut] = useState(false);

    const [selectedPack, setSelectedPack] = useState('SD FREE'); // State to track the selected pack

    const handleSelectPack = (pack) => {
        setSelectedPack(pack);
    };

    const buttonStyleFree = {
        border: selectedPack === 'SD FREE' ? '2px solid black' : 'none',
        color: selectedPack === 'SD FREE' ? '#ffcc75' : '#fff', // Golden color if selected, black otherwise
        // Add other common styles here
    };

    const buttonStylePremium = {
        border: selectedPack === 'SD PREMIUM' ? '2px solid black' : 'none',
        color: selectedPack === 'SD PREMIUM' ? '#ffcc75' : '#fff', // Golden color if selected, black otherwise
    };

    const handleButtonClick = () => {

        let rupees;
        // Access the selectedPack value here
        // console.log('Selected Pack:', selectedPack);

        if (selectedPack === "SD PREMIUM") {
            Navigate("/checkout");
        }

    };

    const [isGreater768, setGreater768] = useState(false);
    const auth = localStorage.getItem("isAuthenticated");
    const [isFreeUser, setFreeUser] = useState(false);

    const [timeLeft, setTimeLeft] = useState("")


    useEffect(() => {

        const secureId = localStorage.getItem("SD_Id");

        if (secureId) {
            const url = `https://backend.shubadinam.com/getpremium?secureId=${secureId}`

            axios.get(url)
                .then((response) => {
                    // console.log(response.data)
                    setTimeLeft(response.data.timeLimit)
                    calculateDaysLeft(response.data.timeLimit)
                    // setFreeUser(false)
                })
                .catch((error) => {
                    console.log(error)
                    // alert("Something unexpected happen.Please try again after sometime !")
                })

        }

        const isFreeRegister = sessionStorage.getItem("isFree")

        if (auth || isFreeRegister) {
            setFreeUser(true)
        }


        const handleResize = () => {
            setGreater768(window.innerWidth > 768);
        };

        // Initial setup
        handleResize();

        // Listen for window resize events
        window.addEventListener('resize', handleResize);

        // Clean up the event listener when the component is unmounted
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const calculateDaysLeft = (purchaseDate) => {
        const purchaseDateTime = new Date(purchaseDate);
        const currentDate = new Date();
        const endDate = new Date(purchaseDateTime);
        endDate.setDate(purchaseDateTime.getDate() + 365); // Adding 365 days

        const timeDifference = endDate - currentDate;
        const daysLeft = Math.ceil(timeDifference / (1000 * 60 * 60 * 24)); // Convert milliseconds to days
        setDaysLeft(daysLeft);
    };

    const gotoCheckout = (reg) => {
        if (reg) {
            Navigate("/register")
            return
        }
        Navigate("/premium-checkout")
    }






    return (

        <section style={{ padding: "0px" }}>



            {
                isGreater768 ?
                    <div >




                        <h6 style={{ textAlign: "start", color: "black", padding: "10px", fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" }}>
                            <i style={{ fontWeight: "400", fontFamily: "roboto" }}>
                                <span style={{ fontWeight: "600", color: "#004c8e" }}>Trusted by Lakhs of users</span>  to Find, Plan and Celebrate their   <span style={{ fontWeight: "600", color: "#004c8e" }}>TRUE Birthday</span> based on Indian Calendar.</i>
                        </h6>

                        <ul className="premium-background" style={{ color: "black", height: "300px", display: "flex", justifyContent: "space-evenly", flexDirection: "column" }}>
                            <li style={{ borderBottom: "2px solid white" }}>Add family and friends
                                <i style={{ fontFamily: 'Roboto', fontWeight: "100" }}>{" "}(Unlimited Entries){" "}</i>
                                <span><svg class="essential" style={{ height: "15px" }} viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z" /></svg></span></li>
                            <li style={{ borderBottom: "2px solid white" }}>Ads Free{" "}
                                <span><svg class="essential" style={{ height: "15px" }} viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z" /></svg></span>
                            </li>
                            <li style={{ borderBottom: "2px solid white" }}>Email Reminder
                                <i style={{ fontFamily: 'Roboto', fontWeight: "100" }}>{" "}(Globally)</i>
                                <span>-{" "}Multiple{" "}</span><i style={{ fontFamily: 'Roboto', fontWeight: "100" }}>(Advance reminders to plan your celebration)</i></li>
                            <li style={{ borderBottom: "2px solid white" }}>SMS Reminder{" "}
                                <i style={{ fontFamily: 'Roboto', fontWeight: "100" }}>(India only)</i>
                                <span>-{" "}Multiple{" "}</span><i style={{ fontFamily: 'Roboto', fontWeight: "100" }}>(Advance reminders to plan your celebration)</i>
                            </li>
                            <li style={{ borderBottom: "2px solid white" }}>WhatsApp

                                <i style={{ fontFamily: 'Roboto', fontWeight: "100" }}>  (India and Global)</i>
                                <span>-{" "}Multiple{" "}</span><i style={{ fontFamily: 'Roboto', fontWeight: "100" }}>(Advance reminders to plan your celebration)</i>
                            </li>
                            <li style={{ borderBottom: "2px solid white" }}>Nakshatra / Tithi Finder Assistance
                                <span><i style={{ fontFamily: 'Roboto', fontWeight: "100" }}>{"(As per English Date of Birth)"}{" "}</i></span>
                                <span><svg class="essential" style={{ height: "15px" }} viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z" /></svg></span>
                            </li>

                            {/* <li>Live Sathsangh Webinar
                                                <span>-{" "}Multiple</span>
                                            </li> */}
                            {/* <li>Recording Access{" "}
                                                <span><svg class="essential"  style={{ height: "15px" }}  viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z" /></svg></span>
                                            </li> */}

                            <li style={{ borderBottom: "2px solid white" }}>One Free Virtual Puja Seva Slot{" "}
                                <i style={{ fontFamily: 'Roboto', fontWeight: "100" }}>(India only){" "}</i>

                                <span><svg class="essential" style={{ height: "15px" }} viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z" /></svg></span>
                            </li>
                            <li style={{ borderBottom: "2px solid white" }}>Free Delivery For Merchandise{" "}
                                <i style={{ fontFamily: 'Roboto', fontWeight: "100" }}>(India only){" "}</i>

                                <span><svg class="essential" style={{ height: "15px" }} viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z" /></svg></span>
                            </li>

                        </ul>



                        {/* <h5 style={{ textAlign: "start", color: "black", padding: "10px", fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" }}>
                            Join Premium Membership<i style={{ fontFamily: "roboto", fontWeight: "100", fontSize: "medium" }}> and enjoy exclusive benefits that includes</i> Assistance on finding your Indian Janma Dinam <i style={{ fontFamily: "roboto", fontWeight: "100", fontSize: "medium" }}> based on English Date of Birth, </i>Advance reminders<i style={{ fontFamily: "roboto", fontWeight: "100", fontSize: "medium" }}> to plan your celebration</i>, Free Delivery <i style={{ fontFamily: "roboto", fontWeight: "100", fontSize: "medium" }}>  for Merchandise, and many more upcoming premium benefits.</i>
                        </h5> */}






                    </div>
                    :
                    <div >



                        <h6 style={{ textAlign: "start", color: "black", padding: "10px", fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" }}>
                            <i style={{ fontWeight: "400", fontFamily: "roboto" }}>
                                <span style={{ fontWeight: "600", color: "#004c8e" }}>Trusted by Lakhs of users</span>  to Find, Plan and Celebrate their   <span style={{ fontWeight: "600", color: "#004c8e" }}>TRUE Birthday</span> based on Indian Calendar.</i>
                        </h6>

                        <div className="pricing-free-content" style={{ color: "black" }}>
                            <ul className="premium-background" >
                                <li style={{ borderBottom: "2px solid white" }}>Add family and friends
                                    <i style={{ fontFamily: 'Roboto', fontWeight: "100" }}>{" "}(Unlimited Entries)</i>
                                    <span><svg class="essential" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z" /></svg></span></li>
                                <li style={{ borderBottom: "2px solid white" }}>Ads Free
                                    <span><svg class="essential" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z" /></svg></span>
                                </li>
                                <li style={{ borderBottom: "2px solid white" }}>Email Reminder
                                    <i style={{ fontFamily: 'Roboto', fontWeight: "100" }}>{" "}(Globally)</i>
                                    <span>-{" "}Multiple{" "}</span><i style={{ fontFamily: 'Roboto', fontWeight: "100" }}>(Advance reminders to plan your celebration)</i></li>
                                <li style={{ borderBottom: "2px solid white" }}>SMS Reminder{" "}
                                    <i style={{ fontFamily: 'Roboto', fontWeight: "100" }}>(India only)</i>
                                    <span>-{" "}Multiple{" "}</span><i style={{ fontFamily: 'Roboto', fontWeight: "100" }}>(Advance reminders to plan your celebration)</i>
                                </li>
                                <li style={{ borderBottom: "2px solid white" }}>WhatsApp

                                    <i style={{ fontFamily: 'Roboto', fontWeight: "100" }}>  (India and Global)</i>
                                    <span>-{" "}Multiple{" "}</span><i style={{ fontFamily: 'Roboto', fontWeight: "100" }}>(Advance reminders to plan your celebration)</i>
                                </li>
                                <li style={{ borderBottom: "2px solid white" }}>Nakshatra / Tithi Finder Assistance
                                    <span><i style={{ fontFamily: 'Roboto', fontWeight: "100" }}>{"(As per English Date of Birth)"}</i></span>
                                    <span><svg class="essential" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z" /></svg></span>
                                </li>

                                {/* <li>Live Sathsangh Webinar
                                                <span>-{" "}Multiple</span>
                                            </li> */}
                                {/* <li>Recording Access
                                                <span><svg class="essential" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z" /></svg></span>
                                            </li> */}
                                <li style={{ borderBottom: "2px solid white" }}>One Free Virtual Puja Seva Slot{" "}
                                    <i style={{ fontFamily: 'Roboto', fontWeight: "100" }}>(India only){" "}</i>

                                    <span><svg class="essential" style={{ height: "15px" }} viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z" /></svg></span>
                                </li>
                                <li style={{ borderBottom: "2px solid white" }}>Free Delivery For Merchandise{" "}
                                    <i style={{ fontFamily: 'Roboto', fontWeight: "100" }}>(India only)</i>

                                    <span><svg class="essential" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z" /></svg></span>
                                </li>

                            </ul>

                        </div>

                        {/* <h6 className="h6-features" style={{ textAlign: "start", color: "black", padding: "10px", fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" }}>
                            Join Premium Membership<i style={{ fontFamily: "roboto", fontWeight: "100" }}> and enjoy exclusive benefits that includes</i> Assistance on finding your Indian Janma Dinam <i style={{ fontFamily: "roboto", fontWeight: "100" }}> based on English Date of Birth, </i>Advance reminders<i style={{ fontFamily: "roboto", fontWeight: "100" }}> to plan your celebration</i>, Free Delivery <i style={{ fontFamily: "roboto", fontWeight: "100" }}>  for Merchandise, and many more upcoming premium benefits.</i>
                        </h6> */}


                    </div>
            }





        </section>


    );
};

export default PricingPoints;
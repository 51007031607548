import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Confetti from 'react-confetti'
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { Box, CircularProgress, Divider, Typography } from '@mui/material';

const PaymentSuccess = () => {

    const navigate = useNavigate();


    const searchQuery = useSearchParams()[0];
    const refereceNum = searchQuery.get("reference");


    const [count, setCount] = useState(0)
    const [showConfetti, setShowConfetti] = useState(true);

    useEffect(() => {

        const loadScript = async (snippet) => {
            try {

                const script = document.createElement('script');
                script.innerHTML = snippet;
                script.async = true; // Load script asynchronously
                document.body.appendChild(script);
                // console.log(document.scripts);

            } catch (error) {
                console.error('Error occurred while adding script:', error);
            }
        };

        const loadScripts = async () => {
            loadScript(`gtag('event', 'conversion', {
                'send_to': 'AW-11290519266/HT8eCO74qrgZEOLN3ocq',
                'value': 30.0,
                'currency': 'INR',
                'transaction_id': ''
              });`);
        };

        loadScripts();
    }, []);


    useEffect(() => {
        const url = `https://backend.shubadinam.com/premium/checker?reference=${refereceNum}`;

        axios.get(url)
            .then((response) => {
                const pID = response.data[0].premiumId;
                localStorage.setItem("premiumVerified", pID);

                const interval = setInterval(() => {
                    setCount((prevCount) => {
                        // Increment only if count is less than 8
                        if (prevCount < 8) {
                            if (prevCount === 6) {
                                setShowConfetti(false); // Stop Confetti when counter reaches 6
                            }
                            return prevCount + 1;
                        } else {
                            clearInterval(interval); // Clear the interval when count reaches 8
                            const token = localStorage.getItem("token");

                            if (token) {
                                localStorage.setItem("SD_Id", pID);
                                localStorage.setItem('homeauth', 'true');
                                navigate("/dashboard");
                            }

                            return prevCount;
                        }
                    });
                }, 1000);

                return () => clearInterval(interval); // Cleanup interval when component unmounts
            })
            .catch((error) => {
                navigate("/pricing");
            });
    }, [count, refereceNum, navigate]);






    return (
        <Box
            className="redirect-signup"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: '100vh',
                textAlign: 'center',
                padding: 2
            }}
        >
            {showConfetti && <Confetti />}
            <Typography variant="h3" sx={{ mb: 2 }}>
                Thank You For Signing Up...
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 2 }}>
                <Box sx={{ width: '60px', height: '5px', backgroundColor: 'primary.main' }} />
                <Box sx={{
                    width: '10px',
                    height: '10px',
                    borderRadius: '50%',
                    backgroundColor: 'primary.main',
                    margin: '0 5px'
                }} />
                <Box sx={{ width: '10px', height: '10px', borderRadius: '50%', backgroundColor: 'primary.main', margin: '0 5px' }} />
                <Box sx={{ width: '10px', height: '10px', borderRadius: '50%', backgroundColor: 'primary.main', margin: '0 5px' }} />
            </Box>
            <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 1 }}>
                Payment Successful
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
                <strong>Reference Number:</strong> {refereceNum}
            </Typography>
            <Divider sx={{ width: '100%', mb: 2 }} />
            <Typography variant="body2" sx={{ color: 'textSecondary' }}>
                Please wait. This page will redirect you to your Profile in {count} seconds...
            </Typography>
            {count === 0 && (
                <CircularProgress sx={{ mt: 2 }} />
            )}
        </Box>
    );
};

export default PaymentSuccess;
import React, { useEffect } from "react";
import { Container, Typography, Box, Grid } from "@mui/material";
import FooterComponent from "../components/footer";
import ScrollToTop from "react-scroll-to-top";
import Top20px from "./Top20px";
import BottomSpace from "./BottomSpace";
import LandingHeader from "../components/LandingHeader";

const nakshatraData = [
    { name: "Ashwini", description: "Ashwins are the twin celestial physicians. They are associated with good health, wealth, and prosperity. People born under this nakshatra are said to be intelligent, charming, and ambitious." },
    { name: "Bharani", description: "Bharani is associated with the goddess Durga. She is the divine mother and the protector of the universe. People born under this nakshatra are said to be strong, courageous, and compassionate." },
    { name: "Krittika", description: "Krittika is associated with the goddess Parvati. She is the wife of Shiva and the mother of Ganesha. People born under this nakshatra are said to be creative, intelligent, and organized." },
    { name: "Rohini", description: "Rohini is associated with the goddess Lakshmi. She is the goddess of wealth, prosperity, and fortune. People born under this nakshatra are said to be lucky, attractive, and popular." },
    { name: "Mrigashirsha", description: "Mrigashirsha is associated with the god Vishnu. He is the preserver of the universe. People born under this nakshatra are said to be wise, compassionate, and kind." },
    { name: "Ardra", description: "Ardra is associated with the god Shiva. He is the destroyer of the universe. People born under this nakshatra are said to be passionate, intense, and independent." },
    { name: "Punarvasu", description: "Punarvasu is associated with the goddess Saraswati. She is the goddess of knowledge, wisdom, and music. People born under this nakshatra are said to be intelligent, creative, and eloquent." },
    { name: "Pushya", description: "Pushya is associated with the god Brahma. He is the creator of the universe. People born under this nakshatra are said to be intelligent, generous, and kind." },
    { name: "Ashlesha", description: "Ashlesha is associated with the serpent goddess Lakshmi. She is the goddess of wealth, prosperity, and fortune. People born under this nakshatra are said to be wise, intuitive, and resourceful." },
    { name: "Magha", description: "Magha is associated with the god Indra. He is the king of the gods. People born under this nakshatra are said to be powerful, ambitious, and successful." },
    { name: "Purva Phalguni", description: "Purva Phalguni is associated with the goddess Lakshmi. She is the goddess of wealth, prosperity, and fortune. People born under this nakshatra are said to be beautiful, charming, and wealthy." },
    { name: "Uttara Phalguni", description: "Uttara Phalguni is associated with the god Vishnu. He is the preserver of the universe. People born under this nakshatra are said to be wise, compassionate, and kind." },
    { name: "Hasta", description: "Hasta is associated with the goddess Lakshmi. She is the goddess of wealth, prosperity, and fortune. People born under this nakshatra are said to be generous, compassionate, and kind." },
    { name: "Chitra", description: "Chitra is associated with the god Shiva. He is the destroyer of the universe. People born under this nakshatra are said to be passionate, intense, and independent." },
    { name: "Swati", description: "Swati is associated with the god Indra. He is the king of the gods. People born under this nakshatra are said to be powerful, ambitious, and successful." },
    { name: "Vishakha", description: "Vishakha is associated with the goddess Parvati. She is the wife of Shiva and the mother of Ganesha. People born under this nakshatra are said to be creative." },
    { name: "Anuradha", description: "Anuradha is associated with the god Vishnu. He is the preserver of the universe. People born under this nakshatra are said to be talented, successful, and popular." },
    { name: "Jyeshtha", description: "Jyeshtha is associated with the goddess Lakshmi. She is the goddess of wealth, prosperity, and fortune. People born under this nakshatra are said to be wealthy, powerful, and influential." },
    { name: "Mula", description: "Mula is associated with the god Yama. He is the god of death. People born under this nakshatra are said to be wise, intuitive, and resourceful." },
    { name: "Purvashada", description: "Purvashada is associated with the god Surya. He is the god of the sun. People born under this nakshatra are said to be intelligent, creative, and confident." },
    { name: "Uttarashada", description: "Uttarashada is associated with the god Rudra. He is the god of destruction. People born under this nakshatra are said to be passionate, intense, and independent." },
    { name: "Shravana", description: "Shravana is associated with the god Ganesha. He is the god of wisdom, prosperity, and good fortune. People born under this nakshatra are said to be intelligent, compassionate, and kind." },
    { name: "Dhanishtha", description: "Dhanishtha is associated with the god Kubera. He is the god of wealth. People born under this nakshatra are said to be wealthy, successful, and generous." },
    { name: "Shatabhishak", description: "Shatabhishak is associated with the god Vishnu. He is the preserver of the universe. People born under this nakshatra are said to be intelligent, creative, and organized." },
    { name: "Purva Bhadrapada", description: "Purva Bhadrapada is associated with the god Vishnu. He is the preserver of the universe. People born under this nakshatra are said to be intelligent, creative, and organized." },
    { name: "Uttara Bhadrapada", description: "Uttara Bhadrapada is associated with the god Shiva. He is the destroyer of the universe. People born under this nakshatra are said to be passionate, intense, and independent." },
    { name: "Revati", description: "Revati is associated with the goddess Lakshmi. She is the goddess of wealth, prosperity, and fortune. People born under this nakshatra are said to be wealthy, successful, and generous." },
];

const MeaningsPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top when component mounts
    }, []);

    return (
        <Box>

            <Container maxWidth="lg" sx={{ mt: 15 }}>
                <ScrollToTop smooth />
                <LandingHeader />
                <Top20px />
                <Box className="center-container-privacy" sx={{ padding: '20px' }}>
                    <Typography variant="h5" color="primary" sx={{ fontWeight: "bold", marginBottom: 5 }} className="meanings-heading">
                        Janma Nakshatra (Birth Star) Meanings in Hindu Way of life
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        In Hindu way of life, each person is born under a Nakshatra. There are 27 Nakshatras in total, and they are each associated with a different deity, animal, and set of qualities.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Here is a brief overview of the meanings of the 27 Nakshatras:
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        This is a general overview of the characteristics associated with each Janma Nakshatra or Birth Star in Vedic astrology. However, it's important to remember that these traits are broad generalizations and should be taken as a starting point rather than definitive descriptions.
                    </Typography>

                    <Grid container spacing={3} sx={{ mt: 5 }}>
                        {nakshatraData.map((nakshatra, index) => (
                            <Grid item xs={12} sm={6} md={4} key={index}>
                                <Box sx={{ border: '1px solid #ccc', borderRadius: 2, padding: 2 }}>
                                    <Typography variant="h6" color="primary" sx={
                                        { fontWeight: "bold", }
                                    }>
                                        {index + 1}. {nakshatra.name}:
                                    </Typography>
                                    <Typography variant="subtitle1" >
                                        {nakshatra.description}
                                    </Typography>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>

                    <BottomSpace />
                </Box>
            </Container>
            <FooterComponent />

        </Box>
    );
};

export default MeaningsPage;

import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import { ArrowCircleRightSharp, BookOnline, CardGiftcard, GifTwoTone } from '@mui/icons-material';
import { Gift, Event, Star, ShoppingCart } from '@mui/icons-material';

const HomamEvents = () => {
    const navigate = useNavigate();

    const handleRouting = (homam) => {
        navigate(`/${homam}`);
    };

    const events = [
        {
            id: "Avahanti-Homam-instructions",
            title: "Avahanti Homam",
            imgSrc: require("../images/homamImages/lakshmi-01.png"),
            bgcolor: "aliceblue",
            buttonText: "Book Now",
            icon: <Event />
        },
        {
            id: "Ganapathi-Homam-instructions",
            title: "Ganapathi Homam",
            imgSrc: require("../images/homamImages/ganapathi-01.png"),
            bgcolor: "#ffd3b31c",
            buttonText: "Book Now",
            icon: <Event />
        },
        {
            id: "Surya-Namaskaram-instructions",
            title: "Surya Namaskaram",
            imgSrc: require("../images/homamImages/suryan-01.png"),
            bgcolor: "aliceblue",
            buttonText: "Book Now",
            icon: <Event />
        },
        {
            id: "pricing",
            title: "Premium",
            imgSrc: require("../images/homamImages/premium icon-01.png"),
            bgcolor: "#ffd3b31c",
            buttonText: "Buy Now",
            icon: <ShoppingCart />
        },
        {
            id: "https://shop.shubadinam.com",
            title: "Gift Shoppe",
            imgSrc: require("../images/homamImages/gifts-01.png"),
            bgcolor: "aliceblue",
            isExternal: true,
            buttonText: "Gift Now",
            icon: <CardGiftcard />
        }
    ];

    return (
        <Grid container spacing={3} sx={{ marginTop: 1, }}>
            {events.map((event, index) => (
                <Grid item xs={12} sm={6} md={4} key={event.id}>
                    <Box
                        onClick={() => event.isExternal ? window.open(event.id, "_blank") : handleRouting(event.id)}
                        sx={{
                            pt: 2,
                            pb: 2,
                            borderRadius: 2,
                            boxShadow: 1,
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-evenly',
                            height: '100%',
                            cursor: 'pointer',
                            backgroundColor: event.bgcolor,
                            transition: 'transform 1s ease, box-shadow 0.3s ease',
                        }}
                        data-aos="fade-up"
                        data-aos-delay={0}
                        data-aos-offset="-50"
                    >


                        <Box sx={{
                            // p: 1,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: "center",
                        }}>

                            {/* Image Section */}
                            <Box sx={{
                                height: '80px',
                                width: "100px",
                                overflow: 'hidden',
                                borderRadius: '8px',
                                transition: 'transform 0.3s ease',
                                '&:hover': {
                                    transform: "scale(1.05)" // Light background color change on hover
                                }
                            }}>
                                <img
                                    src={event.imgSrc}
                                    alt={event.title}
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'contain',
                                        borderRadius: '8px',
                                        mixBlendMode: "darken"
                                    }}
                                />
                            </Box>


                        </Box>
                        <Box sx={{
                            // p: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-evenly',
                            alignItems: "center",
                        }}>
                            <Typography sx={{ textAlign: "center" }} variant="body2" color="primary" onClick={() => event.isExternal ? window.open(event.id, "_blank") : handleRouting(event.id)}>{event.title}</Typography>

                            {/* Title Section */}
                            <Button
                                endIcon={event.icon}
                                sx={{
                                    width: "150px",
                                    color: 'white',
                                    fontSize: "1rem",  // Adjusted font size for better appearance
                                    fontWeight: 700,
                                    minWidth: 100,
                                    height: "50px",
                                    textAlign: 'center',
                                    borderRadius: 8,
                                    backgroundColor: '#355e83',
                                    transition: 'background-color 0.3s ease, box-shadow 0.3s ease', // Smooth hover transition
                                    textTransform: "none", // Keep text as is
                                    '&:hover': {
                                        border: '1px solid #355e83', // Make the border consistent on hover
                                        backgroundColor: 'white',
                                        color: '#355e83',  // Change text color to match the background
                                        boxShadow: 4, // Added shadow for depth
                                    },

                                }}
                                onClick={() => event.isExternal ? window.open(event.id, "_blank") : handleRouting(event.id)}
                            >
                                {event.buttonText}
                            </Button>

                        </Box>

                    </Box>
                </Grid>
            ))}
        </Grid>
    );
};

export default HomamEvents;

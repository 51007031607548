import React, { useState, useEffect } from "react";
import { Container, Grid, Box, TextField, Button, Typography, Tabs, Tab, InputAdornment } from "@mui/material";
import FooterComponent from "../components/footer";
import LandingHeader from "../components/LandingHeader";
import axios from "axios";
import Swal from "sweetalert2";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import ScrollToTop from "react-scroll-to-top";

import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import MessageIcon from "@mui/icons-material/Message"
import LocationOnIcon from "@mui/icons-material/LocationOn";

const ContactScreen = () => {

    const [secureId, setSecureId] = useState("");
    const [dynamicState, setDynamicState] = useState({
        userName: "",
        userEmail: "",
        userPhone: "",
        userMessage: "",
    });
    const [tabValue, setTabValue] = useState(0);

    useEffect(() => {
        window.scrollTo(0, 0);
        const uniqId = localStorage.getItem("SD_Id");
        setSecureId(uniqId);
    }, []);

    useEffect(() => {
        setDynamicState((prevState) => ({
            ...prevState,
            securedId: secureId,
        }));
    }, [secureId]);

    const handleInput = (e) => {
        setDynamicState({ ...dynamicState, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const url = `https://backend.shubadinam.com/contactmessage`;

        axios.post(url, dynamicState)
            .then((response) => {
                Swal.fire({
                    title: "Thank you for your feedback",
                    text: response.data.message,
                    icon: "success",
                    confirmButtonText: "OK",
                });
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <Box>
            <ScrollToTop smooth />
            <HelmetProvider>
                <Helmet>
                    <title>Contact Us</title>
                    <meta name="description" content="Contact - Janma Nakshatra/Tithi Birthday Reminder Service." />
                </Helmet>
            </HelmetProvider>
            <LandingHeader />

            <Container maxWidth="md" sx={{ mt: 20, mb: 10 }}>
                <Typography variant="h4" align="center" color="primary" sx={{ fontWeight: "bold" }} gutterBottom>
                    Interested? Let's talk
                </Typography>

                <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3, mt: 5 }}>
                    <Tabs
                        value={tabValue}
                        onChange={handleTabChange}
                        centered
                        TabIndicatorProps={{ style: { backgroundColor: "primary" } }}
                    >
                        <Tab
                            label="Contact Form"
                        />
                        <Tab
                            label="Google Maps"
                        />
                    </Tabs>
                </Box>

                {tabValue === 0 && (
                    <Box component="form" onSubmit={handleSubmit} sx={{ mb: 5, mt: 5 }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Full Name"
                                    name="userName"
                                    value={dynamicState.userName}
                                    onChange={handleInput}
                                    required
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <PersonIcon color="secondary" />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Registered Email"
                                    name="userEmail"
                                    value={dynamicState.userEmail}
                                    onChange={handleInput}
                                    required
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <EmailIcon color="secondary" />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Phone Number"
                                    name="userPhone"
                                    value={dynamicState.userPhone}
                                    onChange={handleInput}
                                    required
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <PhoneIcon color="secondary" />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Message"
                                    name="userMessage"
                                    value={dynamicState.userMessage}
                                    onChange={handleInput}
                                    required
                                    multiline
                                    rows={4}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <MessageIcon color="secondary" />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} sm={4} sx={{ mx: "auto" }}>
                                <Button fullWidth type="submit" variant="contained">
                                    Send Message
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                )}

                {tabValue === 1 && (
                    <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d124376.04248286066!2d80.19640319999999!3d13.0514944!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1707122076138!5m2!1sen!2sin"
                            title="Contact Map"
                            width="600"
                            height="450"
                            style={{ border: 0 }}
                            allowFullScreen=""
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                        />
                    </Box>
                )}

                <Box
                    sx={{
                        mt: 5,
                        p: 3,
                        borderRadius: 2,
                        boxShadow: 3, // Adds a shadow effect
                        backgroundColor: "#fff", // Set background color
                    }}
                >
                    <Typography variant="h5" color="secondary" sx={{ fontWeight: 'bold', mb: 3 }}>
                        Contact Details
                    </Typography>

                    <Grid container spacing={2} alignItems="center">
                        <Grid item>
                            <EmailIcon color="primary" />
                        </Grid>
                        <Grid item>
                            <Typography variant="body1">
                                support@shubadinam.com
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} alignItems="center" sx={{ mt: 2 }}>
                        <Grid item>
                            <PhoneIcon color="primary" />
                        </Grid>
                        <Grid item>
                            <Typography variant="body1">
                                +91 8925251938
                            </Typography>
                        </Grid>
                    </Grid>

                    <Typography variant="h6" color="secondary" sx={{ fontWeight: 'bold', mt: 4, mb: 2 }}>
                        Address   <LocationOnIcon color="primary" />
                    </Typography>

                    <Grid container spacing={2} alignItems="center">

                        <Grid item>
                            <Typography variant="body1">
                                UNIT NO. M-1, 1ST FLOOR, Landmark Cyber Park, Sector 67, Gurugram, Haryana
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} alignItems="center" sx={{ mt: 2 }}>

                        <Grid item>
                            <Typography variant="body1">
                                Plot no 36, 13th street, Balaji Nagar, Adambakkam, Chennai - 600088
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Container>

            <FooterComponent />
        </Box>

    );
};

export default ContactScreen;

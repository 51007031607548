import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import HeaderComponent from "../components/header";
import FooterComponent from "../components/footer";
import Top10px from "./Top10px";
import Top20px from "./Top20px";
import ScrollToTop from "react-scroll-to-top";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import LandingHeader from "../components/LandingHeader";


const RefundPolicyPage = () => {

    useEffect(() => {
        window.scroll(0, 0)
    })


    return (
        <div>
            <HelmetProvider>
                <Helmet>
                    <title>Refund Policy </title>
                    <meta name="description" content="Refund Policy  - Janma Nakshatra/Tithi Birthday Reminder Service." />
                </Helmet>
            </HelmetProvider>
            <div className="overall-container-whyus">

                <ScrollToTop smooth />
                <div className="overall-percent">
                    <ScrollToTop smooth />
                    <LandingHeader />
                    {/* <Top10px /> */}
                    {/* <Top20px /> */}
                    <div className="center-container-whyus">


                        <h1 style={{
                            fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif"
                        }}>Refund and Cancellation Policy</h1>
                        <ol>
                            <br />
                            {/* <h2 className="letters-color-subhead">Why Shubadinam.com?</h2> */}
                            <p className="letters-color-subhead1" >Thank you for shopping with Shubadinam,
                            </p>


                            <p className="letters-color-subhead1" >As of now, we do not accept any returns or process refunds.
                            </p>
                            <p className="letters-color-subhead1">
                                Additionally,we do not offer any cancellation services.
                            </p>



                        </ol>





                    </div>
                    <Top20px />
                    {/* <div className="empty-container-footer-whyus"> */}
                    <FooterComponent></FooterComponent>
                    {/* </div> */}
                </div>
            </div>
        </div>
    );
};

export default RefundPolicyPage;
import React, { useEffect } from "react";
import LandingHeader from '../components/LandingHeader';
import FooterComponent from '../components/footer';

import Top20px from '../screens/Top20px';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from "sweetalert2";


import { Container, Grid, Card, CardContent, Typography, Box } from '@mui/material';
import { ArrowRightAltSharp } from "@mui/icons-material";


const InstructionsAvahanti = () => {

    useEffect(() => {
        window.scrollTo(0, 0);

    }, [])

    const navigate = useNavigate();


    const [checked, setChecked] = React.useState(false);

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    const handleRouting = () => {

        const localId = localStorage.getItem("SD_Id") || localStorage.getItem("premiumVerified");
        if (localId) {
            axios.get(`https://backend.shubadinam.com/checkuser?userId=${localId}`)
                .then((response) => {
                    // console.log(response.data);
                    navigate("/Avahanti-Homam-booking")
                })
                .catch((error) => {
                    Swal.fire({
                        title: 'Log In !',
                        text: 'Please login to continue further.',
                        icon: 'Info',
                        confirmButtonText: 'Okay',
                    })
                    navigate("/signin")
                })
        }
        else {
            Swal.fire({
                title: 'Log In !',
                text: 'Please login to continue further.',
                icon: 'info',
                confirmButtonText: 'Okay',
            })
            navigate("/signin")
        }

    }


    return (
        <div className='homam-container'>
            <LandingHeader />
            <Top20px />
            <div className='homam-body-instructions'>
                {/* <div className='homam-title'>
                    <b style={{ fontSize: "22px" }}>Avahanti Homam  (Virtual Participation) Instructions</b>
                </div> */}

                {/* <div className='homam-title2'>
                    <b>Rs. 150/- per person</b>
                    <p> Avahanti Homam is performed for eliminating barriers for students, Businessmen and individuals pursuing spiritual and dharmic activities.</p>
                </div> */}

                {/* <div className='homam-instuctions-list'>
                    <h5 style={{ paddingLeft: "20px" }}>Benefits of Avahanti Homam</h5>
                    <ul>
                        <li>Retention power for Students.</li>
                        <li>Awakening of Intelligence.</li>
                        <li>Health that is required for enjoying the richness.</li>
                        <li>Power of speech to convince others about your ideas.</li>
                        <li>Effective listening.</li>
                        <li>Wealth and prosperity.</li>
                        <li>Non-stop expansion of your company.</li>
                        <li>Spiritual progress to make you an Enlightened professional.</li>
                    </ul>

                </div> */}

                {/* <br /> */}
                {/* <div className='homam-instuctions-list'>

                    <h5 style={{ paddingLeft: "20px" }}> When and Where?</h5>
                    <p style={{ paddingLeft: "20px" }}>Avahanti homam will be conducted on every Shukla Paksha Panchami (Fifth Tithi after Amavasya) by vedic Scholars in the Veda Patashala associated with Shubadinam.com. It will be streamed live at the youtube channel  @shubadinam. Click here to see the timetable.</p>

                    <h5 style={{ paddingLeft: "20px" }}>How to Participate?</h5>
                    <ol>
                        <li style={{ padding: "2px" }}>You can book your slots 2 months in advance by paying a nominal fee. Booking Slots will be opened on 20th of every month. (For Example Booking Slot for September 2024 will be opened on 20th July 2024.)</li>
                        <li style={{ padding: "2px" }}>Provide the Name and Nakshatra details of the person whose name should be included in Sankalpa.</li>
                        <li style={{ padding: "2px" }}>Name and Nakshatra of all yajamans will be read out during the sankalpam that can be watched Live.</li>
                        <li style={{ padding: "2px" }}>Homam Prashad will be sent by Post.</li>
                    </ol>


                </div> */}
                {/* <br /> */}

                <Container maxWidth="lg" sx={{ marginTop: 4 }}>
                    {/* Overview Section */}
                    <Card sx={{ marginBottom: 4, backgroundColor: '#f4f6f8' }}>
                        <CardContent>
                            <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: 2, color: "black !important", }}>
                                Avahanti Homam -
                                <Typography
                                    variant="h6"
                                    sx={{
                                        fontWeight: 'bold',
                                        color: "#d32f2f",  // Red color for highlighting
                                        padding: '8px',
                                        borderRadius: '4px',
                                        display: 'inline-block'
                                    }}
                                >
                                    ₹250
                                    <Typography variant="p" sx={{ fontWeight: "100", color: "black" }}>/person</Typography> <span style={{ fontWeight: "100", fontSize: "medium", color: "black" }}>(including gst).</span>
                                </Typography>
                            </Typography>
                            {/* <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: 2, color: "black !important" }}>
                                Virtual Seva Instructions
                            </Typography> */}
                            <Typography variant="body1">
                                Avahanti Homam is a powerful Vedic ritual designed to eliminate barriers and foster success for students, business professionals, and individuals pursuing spiritual and dharmic paths.
                            </Typography>
                        </CardContent>
                    </Card>

                    {/* Benefits Section */}
                    <Card sx={{ marginBottom: 4, backgroundColor: '#fff3e0' }}>
                        <CardContent>
                            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: 2, color: "#1c0475" }}>
                                Benefits of Avahanti Homam
                            </Typography>
                            <ul>
                                <Typography component="li" variant="body1">Enhances retention power and intelligence for students.</Typography>
                                <Typography component="li" variant="body1">Promotes health and well-being.</Typography>
                                <Typography component="li" variant="body1">Empowers speech and listening abilities for effective communication.</Typography>
                                <Typography component="li" variant="body1">Attracts wealth, prosperity, and continuous business growth.</Typography>
                                <Typography component="li" variant="body1">Supports spiritual progress towards becoming an enlightened professional.</Typography>
                            </ul>
                        </CardContent>
                    </Card>

                    {/* Timing and Location Section */}
                    <Card sx={{ marginBottom: 4, backgroundColor: '#e3f2fd' }}>
                        <CardContent>
                            <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: 2 }}>
                                When and Where
                            </Typography>
                            <Typography variant="body1">
                                <strong>Timing:</strong> Conducted on every Shukla Paksha Panchami (fifth day after Amavasya).
                            </Typography>
                            <Typography variant="body1">
                                <strong>Location:</strong> Performed by Vedic scholars at the Veda Patashala associated with Shubadinam.com.
                            </Typography>
                            <Typography variant="body1">
                                <strong>Streaming:</strong> Watch the Homam live on Shubadinam TV, available through your dashboard.
                            </Typography>
                        </CardContent>
                    </Card>

                    {/* Participation Instructions */}
                    <Card sx={{ marginBottom: 4, backgroundColor: '#f0f4c3' }}>
                        <CardContent>
                            <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: 2 }}>
                                How to Participate
                            </Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="body1">
                                        <strong>Booking Slots:</strong> Book instantly for the upcoming event or pre-book up to 2 months in advance. [Premium customers receive one slot for free.]
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="body1">
                                        <strong>Provide Details:</strong> Enter the name and Nakshatra of the person to be included in the Sankalpa.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="body1">
                                        <strong>Live Sankalpa:</strong> The names and Nakshatras of all yajamans will be read during the Sankalpa and can be viewed live.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="body1">
                                        <strong>Dress Code:</strong> Yajamans are encouraged to wear traditional attire while watching the live stream.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="body1">
                                        <strong>Prashad Delivery:</strong> Homam Prashad will be sent via post.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>

                    {/* Disclaimer Section */}
                    <Card sx={{ backgroundColor: '#ffebee' }}>
                        <CardContent>
                            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                Disclaimer: Please contact a qualified pundit if you want to conduct this Homam exclusively at your residence for more benefits.
                            </Typography>
                        </CardContent>
                    </Card>


                    {/* Checkbox and Buttons */}
                    <Box sx={{ textAlign: 'center', marginBottom: 4, mt: 3 }}>

                        <Box className='homam-continue' sx={{ marginTop: 2 }}>
                            <Button
                                size="large"
                                color="primary"
                                variant="contained"
                                onClick={handleRouting}
                                sx={{ color: '#fff' }}
                                endIcon={<ArrowRightAltSharp />}
                            >
                                Book Slot
                            </Button>
                        </Box>
                    </Box>

                </Container>



            </div>
            <FooterComponent />
        </div>
    );
};

export default InstructionsAvahanti;
import React, { useState, useEffect } from 'react';

import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import LandingHeader from '../components/LandingHeader';
import FooterComponent from '../components/footer';

import Top20px from '../screens/Top20px';
import 'react-calendar/dist/Calendar.css';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Button, CardContent, Grid, Typography } from '@mui/material';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import axios from 'axios';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Stack from '@mui/material/Stack';
import HomamCheckout from '../HomamPayment/HomamCheckout';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Card } from 'react-bootstrap';


const HomamPreview = () => {

    const Navigate = useNavigate();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);


    let localId;
    const localKeyId = localStorage.getItem("SD_Id" || "premiumVerified")

    if (localKeyId) {
        localId = localKeyId
    }


    // Extract staticFields
    const name = queryParams.get('name');
    const nakshatra = queryParams.get('nakshatra');
    const address = queryParams.get('address');
    const state = queryParams.get('state');
    const city = queryParams.get('city');
    const pincode = queryParams.get('pincode');



    // Extract dynamicFields
    const dynamicFields = [];
    for (let i = 0; ; i++) {
        const fieldName = queryParams.get(`dynamicField${i}Name`);
        const fieldNakshatra = queryParams.get(`dynamicField${i}Nakshatra`);
        if (fieldName === null || fieldNakshatra === null) break;
        dynamicFields.push({ name: fieldName, nakshatra: fieldNakshatra });
    }


    const selectedOption = queryParams.get('selectedOption');
    const count = queryParams.get('count');
    const serviceName = queryParams.get('serviceName');
    const chosenDate = queryParams.get('chosenDate');
    const amount = queryParams.get('amount');
    const userType = queryParams.get('customerType');

    let isPremium;
    if (userType === "Premium") {
        isPremium = true;
    }
    else {
        isPremium = false;
    }


    const [finalAmount, setFinalAmount] = useState(amount);

    useEffect(() => {

        window.scrollTo(0, 0);

        if (userType === 'Premium' && count > 1) {
            setFinalAmount(amount - 150);
        } else {
            setFinalAmount(amount);
        }
    }, [userType, amount]);


    const handleEdit = (page) => {
        const serviceNameString = typeof page === 'object' ? page.serviceName : page;
        // console.log(serviceNameString);
        if (serviceNameString) {
            Navigate(`/${serviceNameString}`);
            return;
        }
        else {
            window.history.back();
            return;
        }


        // Navigate(`/${serviceNameString}`);
        // console.log(`/${serviceNameString}`);
    }

    const handleBack = () => {
        window.history.back();
    }

    const combinedFields = [
        { sNo: 1, name: name, nakshatra: nakshatra },
        ...dynamicFields.map((field, index) => ({
            sNo: index + 2,
            name: field.name,
            nakshatra: field.nakshatra
        }))
    ];



    // CHECKOUT HOMAM



    const [orderId, setOrderId] = useState(null);
    const [transactionId, setTransactionId] = useState(null);
    const [qrCodeUrl, setQrCodeUrl] = useState('');

    const dynamicState = {
        name,
        nakshatra,
        address,
        state,
        city,
        pincode,
        dynamicFields,
        count,
        serviceName,
        chosenDate,
        finalAmount,
        localId,
        userType
    };


    const createOrder = async () => {

        try {
            const response = await fetch('https://backend.shubadinam.com/create-homam-order', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(dynamicState),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();

            // console.log(data);
            if (data.order.id) {
                // console.log(data);
                setOrderId(data.order.id);
                setTransactionId(data.order.receipt);
                // handlePayment(data.order.id, data.impId);   
                handlePayment(data.order.id, data.order.amount, data.order.notes.homamPayment, data.impId);

                //  transactionId = data.receipt;

                // if (data.redirect) {
                //   window.location.href = data.redirect;
                // }
            } else {
                alert('Failed to create order');
            }
        } catch (error) {
            console.error('Error:', error.message);
            alert(error.message);
        }
    };

    const handlePayment = (orderId, backendAmount, homamPayment, impId) => {

        // const premiumUserName = dynamicState.userName;
        // const premiumUserPhone = dynamicState.userPhone;
        // const PremiumUserEmail = dynamicState.userEmail;
        // console.log(orderId, impId);
        if (orderId) {
            const options = {
                key: 'rzp_live_gp41aILMbhUm19', // Replace with your Razorpay key
                amount: backendAmount,
                currency: 'INR',
                order_id: orderId,
                name: 'Shubadinam',
                description: 'Shubadinam Transaction',
                // image: shubadinamLogo,
                prefill: {
                    // name: premiumUserName,
                    // email: PremiumUserEmail,
                    // contact: premiumUserPhone,
                },
                // redirect: true,
                // callback_url: "http://localhost:3000/PremiumProfile",

                handler: function (response) {
                    // console.log(response);

                    const url = `https://backend.shubadinam.com/razorpayresponse/homam`;



                    axios.post(url, {
                        paymentId: response.razorpay_payment_id, orderId: response.razorpay_order_id,
                        signatureId: response.razorpay_signature, dynamicState: dynamicState, impId: impId
                    })
                        .then((response) => {

                            // const paymentId= response.razorpay_payment_id
                            Navigate("/homam_thankyou", { replace: true })

                        })
                        .catch((error) => {
                            console.log("Error redirecting : ", error);
                        })
                },
                notes: {
                    address: 'Razorpay Corporate Office',
                    homamPayment: homamPayment
                },
                theme: {
                    color: '#3399cc',
                },
                // method: {
                //     qr: {
                //         url: qrCodeUrl,
                //         format: 'png',
                //         label: 'Scan to Pay',
                //     }
                // }
                config: {
                    display: {
                        hide: [
                            { method: 'paylater' },
                            { method: 'emi' },
                            { method: 'wallet' },
                            { method: 'bank_transfer' },
                        ],
                        preferences: { show_default_blocks: true }
                    }
                },
                method: {
                    //     netbanking: true,
                    //     card: true,
                    //     upi: true,
                    //     wallet: false,
                    //     emi: false,
                    //     bank_transfer: false,
                    // qr: false, // Disable QR Code payment option
                    //     paylater: false // Disable Pay Later option
                }
            };

            const rzp1 = new window.Razorpay(options);

            rzp1.on('payment.success', (response) => {
                // console.log(response);
                // alert(`Payment Successful. Payment ID: ${response.razorpay_payment_id}`);
            });

            rzp1.on('payment.failed', function (response) {
                // alert(response.error.code);
                alert(response.error.description);
                // alert(response.error.source);
                // alert(response.error.step);
                // alert(response.error.reason);
                // alert(response.error.metadata.order_id);
                // alert(response.error.metadata.payment_id);
            })

            rzp1.on('payment.error', (response) => {
                // console.log(response);
                alert(`Payment Failed. Error: ${response.error.description}`);
            });

            rzp1.on('payment.closed', () => {
                console.warn('Payment modal closed by user');
                alert('Payment process was not completed. Please try again.');
            });


            rzp1.open();
        } else {
            alert('Order ID is missing. Please try again.');
        }
    };

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://checkout.razorpay.com/v1/checkout.js';
        script.async = true;
        document.body.appendChild(script);

        script.onload = () => {
            document.getElementById('rzp-button1').addEventListener('click', createOrder);
        };
    }, [orderId]);















    return (
        <div className='homam-container'>
            <LandingHeader />
            <Top20px />
            <div className='homam-body'>
                <div className='homam-options'>
                    <Stack direction="row" width="100%" sx={{ padding: "5px" }}>
                        <h5 style={{ textAlign: "start", width: "100%", fontSize: "x-large" }}>{serviceName}</h5>
                        <Stack>
                            <Button className='homam-edit-btn' variant="outlined" startIcon={<EditIcon />} onClick={() => handleEdit(`${serviceName}-booking`)}>
                                Edit
                            </Button>
                        </Stack>
                    </Stack>
                    <br />

                    <div className='homam-details-options2'>

                        <div className='homam-detail-item'>
                            <strong className='homam-ph-details'>No. of Tickets</strong>
                            <h5 className='homam-ph-details1'>{count}</h5>
                        </div>
                        <div className='homam-detail-item'>
                            <strong className='homam-ph-details'>Homam Time</strong>
                            <h5 className='homam-ph-details1'>{serviceName === 'Surya-Namaskaram' ? "7 am" : "6:45 am"}</h5>
                        </div>
                        <div className='homam-detail-item'>
                            <strong className='homam-ph-details'>Homam Date</strong>
                            <h5 className='homam-ph-details1'>
                                {chosenDate ? chosenDate : 'Invalid Date'}
                            </h5>
                        </div>
                        <div className='homam-detail-item'>
                            <strong className='homam-ph-details'>Total Cost</strong>
                            <h5 className='homam-ph-details1'>
                                &#8377; {isPremium && count > 1 ? (amount - (serviceName === "Surya-Namaskaram" ? 125 : 250)) : amount} /-
                            </h5>

                        </div>
                    </div>
                    <br />
                </div>
                <br />

                <div className='homam-gen-details'>
                    <Stack direction="row" width="100%" sx={{ padding: "5px" }}>
                        <b style={{ textAlign: "start", width: "100%", }}>Homam Checkout</b>
                        <Stack>
                            <Button className='homam-edit-btn' variant="outlined" sx={{ marginRight: "20px" }} startIcon={<EditIcon />} onClick={() => handleEdit()}>
                                Edit
                            </Button>
                        </Stack>
                    </Stack>
                    <br />
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <TableContainer sx={{ width: { xs: 350, md: "50%" } }} component={Paper}>
                            <Table sx={{ width: { xs: 350, md: "100%" } }} aria-label="simple table">
                                <TableHead >
                                    <TableRow>
                                        <TableCell sx={{ width: 70 }}>Person</TableCell>
                                        <TableCell align='center'>Name</TableCell>
                                        <TableCell align='center'>Nakshatra</TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {combinedFields.map((field, index) => (
                                        <TableRow
                                            key={index}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell align='center'>{field.sNo}</TableCell >
                                            <TableCell align='center'>{field.name}</TableCell >
                                            <TableCell align='center'>{field.nakshatra}</TableCell >
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>



                    </div>



                    {/* <div className='homam-details-continue'>

                        <i style={{ placeSelf: "start" }}>Note : Fields marked * are mandatory</i>
                        <br />
                        <Box sx={{ width: "100%", display: "flex", alignItems: "end", justifyContent: "end" }} >
                            <Button className='homam-continue-btns' sx={{ marginRight: "10px" }}>Back</Button>

                            {
                                isFormDisabled() ?
                                    <Button className='homam-continue-btns' sx={{ marginRight: "40px" }} disabled>Continue</Button>
                                    :

                                    <Button className="homam-continue-btns homam-ctn-btn" sx={{ marginRight: "40px" }} type="submit"
                                    >Continue</Button>
                            }
                        </Box>

                    </div> */}

                    <br />
                </div>

                <div className='homam-gen-details'>

                    <div style={{ display: "flex", justifyContent: "center", padding: "20px" }}>
                        <Card sx={{ width: { xs: 350, md: "70%" }, borderRadius: 5, }}>
                            <CardContent sx={{ boxShadow: `0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)` }}>
                                <Typography variant="h6" gutterBottom align="center" sx={{ color: 'c0dffa', fontWeight: 'bold', }}>
                                    Delivery Details
                                </Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#555' }}>
                                            Address:
                                        </Typography>
                                        <Typography variant="body2" sx={{ color: '#333' }}>
                                            {address}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#555' }}>
                                            State:
                                        </Typography>
                                        <Typography variant="body2" sx={{ color: '#333' }}>
                                            {state}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#555' }}>
                                            City: <Typography variant="body2" sx={{ color: '#333' }}>
                                                {city}
                                            </Typography>
                                        </Typography>

                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#555' }}>
                                            Pincode:
                                        </Typography>
                                        <Typography variant="body2" sx={{ color: '#333' }}>
                                            {pincode}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </div>

                    <div className='homam-details-continue'>

                        <br />
                        <Box sx={{ width: "100%", display: "flex", alignItems: "end", justifyContent: "end" }} >
                            <Button className='homam-continue-btns' sx={{ marginRight: "10px" }} onClick={() => handleBack()}>Back</Button>


                            <Button id="rzp-button1" className="homam-continue-btns homam-ctn-btn" sx={{ marginRight: "40px" }}
                            > Pay Now</Button>

                        </Box>




                    </div>
                </div>


                {/* <Stack direction="row" width="100%" sx={{ padding: "5px" }}>
                        <b style={{ textAlign: "start", width: "100%", }}>General Details</b>
                        <Stack>
                            <Button className='homam-edit-btn' variant="outlined" sx={{ marginRight: "20px" }} startIcon={<EditIcon />} onClick={() => handleEdit()}>
                                Edit
                            </Button>
                        </Stack>
                    </Stack>
                    <form onSubmit={handleSubmit}>
                        <div className='homam-gen-details1'>
                            <TextField
                                className='homam-ph-textfield'
                                required
                                label='Name'
                                variant="standard"
                                value={formData.staticFields.name || (userData.length > 0 ? userData[0].userName : '')}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />



                            <FormControl required variant="standard" className='homam-ph-textfield' sx={{ minWidth: 200 }}>
                                <InputLabel id="demo-simple-select-readonly-label">Nakshatra</InputLabel>
                                <Select
                                    required
                                    labelId="demo-simple-select-readonly-label"
                                    id="demo-simple-select-readonly"
                                    value={formData.staticFields.nakshatra}
                                    label="Nakshatra"
                                    inputProps={{ readOnly: true }}
                                >
                                    {nakshathraArr.map((nak, idx) => (
                                        <MenuItem key={idx} value={nak}>{nak}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>


                        </div>
                        <br />
                        <b style={{ padding: "15px" }}>Add person details</b>
                        <div className='homam-gen-details2'>
                            {formData.dynamicFields.map((field, index) => (
                                <Box key={index} sx={{ display: "flex", justifyContent: "center" }} >

                                    <TextField
                                        required
                                        className='homam-ph-textfield'
                                        label={`Person-${index + 2}`}
                                        variant="standard"
                                        value={formData.dynamicFields[index]?.name || ''}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />



                                    <FormControl required variant="standard" className='homam-ph-textfield' sx={{ minWidth: 200 }}>
                                        <InputLabel id="demo-simple-select-readonly-label">{`Person-${index + 2} nakshatra`}</InputLabel>
                                        <Select
                                            required
                                            labelId="demo-simple-select-readonly-label"
                                            id="demo-simple-select-readonly"
                                            value={field.nakshatra || ""}
                                            label="Nakshatra"
                                            inputProps={{ readOnly: true }}
                                        >
                                            {
                                                nakshathraArr.map((nak, idx) => {
                                                    return <MenuItem key={idx} value={nak}>{nak}</MenuItem>
                                                })
                                            }
                                        </Select>
                                    </FormControl>


                                </Box>
                            ))}
                        </div>
                        <br />


                        <div className='homam-details-continue'>

                            <i style={{ placeSelf: "start" }}>Note : Fields marked * are mandatory</i>
                            <br />
                            <Box sx={{ width: "100%", display: "flex", alignItems: "end", justifyContent: "end" }} >
                                <Button className='homam-continue-btns' sx={{ marginRight: "10px" }}>Back</Button>

                                {
                                    isFormDisabled() ?
                                        <Button className='homam-continue-btns' sx={{ marginRight: "40px" }} disabled>Continue</Button>
                                        :

                                        // <HomamCheckout
                                        //     name={name}
                                        //     nakshatra={nakshatra}
                                        //     dynamicFields={dynamicFields}
                                        //     selectedOption={selectedOption}
                                        //     count={count}
                                        //     serviceName={serviceName}
                                        //     chosenDate={chosenDate}
                                        //     amount={amount}
                                        //     userType={userType}
                                        // />

                                        <Button className="homam-continue-btns homam-ctn-btn" sx={{ marginRight: "40px" }} type="submit"
                                        >Continue</Button>
                                }
                            </Box>




                        </div>

                    </form>
                    <br /> */}


                <br />
            </div >
            <FooterComponent />
        </div >
    );
};

export default HomamPreview;
import React, { useContext, useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useRef } from "react";
import axios from "axios";
import DataSharingContext from "../../context/data-sharing-context";
import FooterComponent from "../../components/footer";
import {
    CitySelect,
    CountrySelect,
    StateSelect,
    LanguageSelect,
} from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";
import ProfilePremiumDialog from "../../components/ProfilePremiumDialog";
import ScrollToTop from "react-scroll-to-top";

const PremiumProfile = () => {

    const navigate = useNavigate();

    useEffect(() => {
        const alreadyUser = sessionStorage.getItem('profileauth');

        if (!alreadyUser) {
            // User not authenticated, redirect to homepage
            // navigate("/");
        } else {
            // User authenticated, start timer
            const timer = setTimeout(() => {
                // After 10 minutes, remove from local storage
                sessionStorage.removeItem('profileauth');
                navigate("/");
            }, 10 * 60 * 1000); // 10 minutes in milliseconds

            // Clear the timer if the component unmounts or if the user logs out
            return () => clearTimeout(timer);
        }
    }, []);

    const [countryid, setCountryid] = useState(0);
    const [stateid, setstateid] = useState(0);


    const emailPass = atob(localStorage.getItem("mainMPP"));

    // console.log(emailPass);


    const context = useContext(DataSharingContext);



    const [queryResult, setQueryResult] = useState([]);

    const [dynamicArray, setDynamicArray] = useState([])


    const sentMail = localStorage.getItem("fkey")   // sentMail :this is the mail of the user,sent while first login
    const defaultEmail = atob(sentMail).replace(/"/g, '').trim();
    // console.log(defaultEmail);

    const [dynamicState, DynamicFunction] = useState({
        userName: "",
        userEmail: defaultEmail,
        userGender: "",
        userCountry: "",
        userState: "",
        userRelation: "Myself",
        userCalender: "",
        userMonth: "",
        nakshatraTithi: "",
        userNakshathra: "",
        userPaksham: "",
        userTithi: "",
        userDateTime: "",
        calenderCode: ""

    })

    const [calender, SetCalender] = useState("")

    const [month, SetMonth] = useState("")

    const [nakshathra, SetNakshathra] = useState("")

    const [nakTithi, SetNakTithi] = useState()

    const [Pakshams, SetPaksham] = useState([])

    const [tithiname, SettithiName] = useState([])

    const [months, SetMonths] = useState([])
    const [nakshathras, SetNakshathras] = useState([])
    const [nakTithis, SetNakTithis] = useState([])






    const Calender = [
        {
            name: "Tamil",
            calenderCode: 1,
            month: [{
                name: "CHITHIRAI/சித்திரை",
                nakshathira: ["ASHWINI/அஸ்வினி", "BHARANI/பரணி", "KRITTIKA/கிருத்திகை", "ROHINI/ரோகிணி", "MRIGHASIRA/மிருகசிரம்", "ARDRA/திருவாதிரை", "PUNARVASU/புனர்பூசம்", "PUSHYA/பூசம்", "ASHLESHA/ஆயில்யம்", "MAGHA/மகம்", "PURVA PHALGUNI/பூரம்", "UTTARA PHALGUNI/உத்திரம்", "HASTA/ஹஸ்தம்", "CHITRA/சித்திரை", "SWATI/சுவாதி", "VISHAKA/விசாகம்", "ANURADHA/அனுஷம்", "JYESTHA/கேட்டை", "MOOLA/மூலம்", "PURVASHADA/பூராடம்", "UTTARASHADA/உத்திராடம்", "SHARAVAN/திருவோணம்", "DHANISHTA/அவிட்டம்", "SHATABISHA/சதயம்", "PURVABHADRA/பூரட்டாதி", "UTTARABHADRA/உத்திரட்டாதி", "REVATI/ரேவதி"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/சுக்ல பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "PURNIMA/பௌர்ணமி"],
                    },
                    {
                        name: "KRISHNA PAKSA/கிருஷ்ண பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "AMAVASYA/அமாவாசை"]

                    }
                ]


            },
            {
                name: "VAIGAASI/வைகாசி",
                nakshathira: ["ASHWINI/அஸ்வினி", "BHARANI/பரணி", "KRITTIKA/கிருத்திகை", "ROHINI/ரோகிணி", "MRIGHASIRA/மிருகசிரம்", "ARDRA/திருவாதிரை", "PUNARVASU/புனர்பூசம்", "PUSHYA/பூசம்", "ASHLESHA/ஆயில்யம்", "MAGHA/மகம்", "PURVA PHALGUNI/பூரம்", "UTTARA PHALGUNI/உத்திரம்", "HASTA/ஹஸ்தம்", "CHITRA/சித்திரை", "SWATI/சுவாதி", "VISHAKA/விசாகம்", "ANURADHA/அனுஷம்", "JYESTHA/கேட்டை", "MOOLA/மூலம்", "PURVASHADA/பூராடம்", "UTTARASHADA/உத்திராடம்", "SHARAVAN/திருவோணம்", "DHANISHTA/அவிட்டம்", "SHATABISHA/சதயம்", "PURVABHADRA/பூரட்டாதி", "UTTARABHADRA/உத்திரட்டாதி", "REVATI/ரேவதி"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/சுக்ல பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "PURNIMA/பௌர்ணமி"],
                    },
                    {
                        name: "KRISHNA PAKSA/கிருஷ்ண பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "AMAVASYA/அமாவாசை"]

                    }
                ]


            },
            {
                name: "AANI/ஆனி",
                nakshathira: ["ASHWINI/அஸ்வினி", "BHARANI/பரணி", "KRITTIKA/கிருத்திகை", "ROHINI/ரோகிணி", "MRIGHASIRA/மிருகசிரம்", "ARDRA/திருவாதிரை", "PUNARVASU/புனர்பூசம்", "PUSHYA/பூசம்", "ASHLESHA/ஆயில்யம்", "MAGHA/மகம்", "PURVA PHALGUNI/பூரம்", "UTTARA PHALGUNI/உத்திரம்", "HASTA/ஹஸ்தம்", "CHITRA/சித்திரை", "SWATI/சுவாதி", "VISHAKA/விசாகம்", "ANURADHA/அனுஷம்", "JYESTHA/கேட்டை", "MOOLA/மூலம்", "PURVASHADA/பூராடம்", "UTTARASHADA/உத்திராடம்", "SHARAVAN/திருவோணம்", "DHANISHTA/அவிட்டம்", "SHATABISHA/சதயம்", "PURVABHADRA/பூரட்டாதி", "UTTARABHADRA/உத்திரட்டாதி", "REVATI/ரேவதி"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/சுக்ல பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "PURNIMA/பௌர்ணமி"],
                    },
                    {
                        name: "KRISHNA PAKSA/கிருஷ்ண பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "AMAVASYA/அமாவாசை"]

                    }
                ]

            },
            {
                name: "AADI/ஆடி",
                nakshathira: ["ASHWINI/அஸ்வினி", "BHARANI/பரணி", "KRITTIKA/கிருத்திகை", "ROHINI/ரோகிணி", "MRIGHASIRA/மிருகசிரம்", "ARDRA/திருவாதிரை", "PUNARVASU/புனர்பூசம்", "PUSHYA/பூசம்", "ASHLESHA/ஆயில்யம்", "MAGHA/மகம்", "PURVA PHALGUNI/பூரம்", "UTTARA PHALGUNI/உத்திரம்", "HASTA/ஹஸ்தம்", "CHITRA/சித்திரை", "SWATI/சுவாதி", "VISHAKA/விசாகம்", "ANURADHA/அனுஷம்", "JYESTHA/கேட்டை", "MOOLA/மூலம்", "PURVASHADA/பூராடம்", "UTTARASHADA/உத்திராடம்", "SHARAVAN/திருவோணம்", "DHANISHTA/அவிட்டம்", "SHATABISHA/சதயம்", "PURVABHADRA/பூரட்டாதி", "UTTARABHADRA/உத்திரட்டாதி", "REVATI/ரேவதி"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/சுக்ல பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "PURNIMA/பௌர்ணமி"],
                    },
                    {
                        name: "KRISHNA PAKSA/கிருஷ்ண பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "AMAVASYA/அமாவாசை"]

                    }
                ]

            },
            {
                name: "AAVANI/ஆவணி",
                nakshathira: ["ASHWINI/அஸ்வினி", "BHARANI/பரணி", "KRITTIKA/கிருத்திகை", "ROHINI/ரோகிணி", "MRIGHASIRA/மிருகசிரம்", "ARDRA/திருவாதிரை", "PUNARVASU/புனர்பூசம்", "PUSHYA/பூசம்", "ASHLESHA/ஆயில்யம்", "MAGHA/மகம்", "PURVA PHALGUNI/பூரம்", "UTTARA PHALGUNI/உத்திரம்", "HASTA/ஹஸ்தம்", "CHITRA/சித்திரை", "SWATI/சுவாதி", "VISHAKA/விசாகம்", "ANURADHA/அனுஷம்", "JYESTHA/கேட்டை", "MOOLA/மூலம்", "PURVASHADA/பூராடம்", "UTTARASHADA/உத்திராடம்", "SHARAVAN/திருவோணம்", "DHANISHTA/அவிட்டம்", "SHATABISHA/சதயம்", "PURVABHADRA/பூரட்டாதி", "UTTARABHADRA/உத்திரட்டாதி", "REVATI/ரேவதி"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/சுக்ல பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "PURNIMA/பௌர்ணமி"],
                    },
                    {
                        name: "KRISHNA PAKSA/கிருஷ்ண பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "AMAVASYA/அமாவாசை"]

                    }
                ]

            },
            {
                name: "PURATTASI/புரட்டாசி",
                nakshathira: ["ASHWINI/அஸ்வினி", "BHARANI/பரணி", "KRITTIKA/கிருத்திகை", "ROHINI/ரோகிணி", "MRIGHASIRA/மிருகசிரம்", "ARDRA/திருவாதிரை", "PUNARVASU/புனர்பூசம்", "PUSHYA/பூசம்", "ASHLESHA/ஆயில்யம்", "MAGHA/மகம்", "PURVA PHALGUNI/பூரம்", "UTTARA PHALGUNI/உத்திரம்", "HASTA/ஹஸ்தம்", "CHITRA/சித்திரை", "SWATI/சுவாதி", "VISHAKA/விசாகம்", "ANURADHA/அனுஷம்", "JYESTHA/கேட்டை", "MOOLA/மூலம்", "PURVASHADA/பூராடம்", "UTTARASHADA/உத்திராடம்", "SHARAVAN/திருவோணம்", "DHANISHTA/அவிட்டம்", "SHATABISHA/சதயம்", "PURVABHADRA/பூரட்டாதி", "UTTARABHADRA/உத்திரட்டாதி", "REVATI/ரேவதி"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/சுக்ல பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "PURNIMA/பௌர்ணமி"],
                    },
                    {
                        name: "KRISHNA PAKSA/கிருஷ்ண பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "AMAVASYA/அமாவாசை"]

                    }
                ]

            },
            {
                name: "AYPPASI/ஐப்பசி",
                nakshathira: ["ASHWINI/அஸ்வினி", "BHARANI/பரணி", "KRITTIKA/கிருத்திகை", "ROHINI/ரோகிணி", "MRIGHASIRA/மிருகசிரம்", "ARDRA/திருவாதிரை", "PUNARVASU/புனர்பூசம்", "PUSHYA/பூசம்", "ASHLESHA/ஆயில்யம்", "MAGHA/மகம்", "PURVA PHALGUNI/பூரம்", "UTTARA PHALGUNI/உத்திரம்", "HASTA/ஹஸ்தம்", "CHITRA/சித்திரை", "SWATI/சுவாதி", "VISHAKA/விசாகம்", "ANURADHA/அனுஷம்", "JYESTHA/கேட்டை", "MOOLA/மூலம்", "PURVASHADA/பூராடம்", "UTTARASHADA/உத்திராடம்", "SHARAVAN/திருவோணம்", "DHANISHTA/அவிட்டம்", "SHATABISHA/சதயம்", "PURVABHADRA/பூரட்டாதி", "UTTARABHADRA/உத்திரட்டாதி", "REVATI/ரேவதி"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/சுக்ல பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "PURNIMA/பௌர்ணமி"],
                    },
                    {
                        name: "KRISHNA PAKSA/கிருஷ்ண பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "AMAVASYA/அமாவாசை"]

                    }
                ]

            },
            {
                name: "KARTHIGAI/கார்த்திகை",
                nakshathira: ["ASHWINI/அஸ்வினி", "BHARANI/பரணி", "KRITTIKA/கிருத்திகை", "ROHINI/ரோகிணி", "MRIGHASIRA/மிருகசிரம்", "ARDRA/திருவாதிரை", "PUNARVASU/புனர்பூசம்", "PUSHYA/பூசம்", "ASHLESHA/ஆயில்யம்", "MAGHA/மகம்", "PURVA PHALGUNI/பூரம்", "UTTARA PHALGUNI/உத்திரம்", "HASTA/ஹஸ்தம்", "CHITRA/சித்திரை", "SWATI/சுவாதி", "VISHAKA/விசாகம்", "ANURADHA/அனுஷம்", "JYESTHA/கேட்டை", "MOOLA/மூலம்", "PURVASHADA/பூராடம்", "UTTARASHADA/உத்திராடம்", "SHARAVAN/திருவோணம்", "DHANISHTA/அவிட்டம்", "SHATABISHA/சதயம்", "PURVABHADRA/பூரட்டாதி", "UTTARABHADRA/உத்திரட்டாதி", "REVATI/ரேவதி"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/சுக்ல பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "PURNIMA/பௌர்ணமி"],
                    },
                    {
                        name: "KRISHNA PAKSA/கிருஷ்ண பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "AMAVASYA/அமாவாசை"]

                    }
                ]

            },
            {
                name: "MARGAZHI/மார்கழி",
                nakshathira: ["ASHWINI/அஸ்வினி", "BHARANI/பரணி", "KRITTIKA/கிருத்திகை", "ROHINI/ரோகிணி", "MRIGHASIRA/மிருகசிரம்", "ARDRA/திருவாதிரை", "PUNARVASU/புனர்பூசம்", "PUSHYA/பூசம்", "ASHLESHA/ஆயில்யம்", "MAGHA/மகம்", "PURVA PHALGUNI/பூரம்", "UTTARA PHALGUNI/உத்திரம்", "HASTA/ஹஸ்தம்", "CHITRA/சித்திரை", "SWATI/சுவாதி", "VISHAKA/விசாகம்", "ANURADHA/அனுஷம்", "JYESTHA/கேட்டை", "MOOLA/மூலம்", "PURVASHADA/பூராடம்", "UTTARASHADA/உத்திராடம்", "SHARAVAN/திருவோணம்", "DHANISHTA/அவிட்டம்", "SHATABISHA/சதயம்", "PURVABHADRA/பூரட்டாதி", "UTTARABHADRA/உத்திரட்டாதி", "REVATI/ரேவதி"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/சுக்ல பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "PURNIMA/பௌர்ணமி"],
                    },
                    {
                        name: "KRISHNA PAKSA/கிருஷ்ண பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "AMAVASYA/அமாவாசை"]

                    }
                ]

            },
            {
                name: "THAI/தை",
                nakshathira: ["ASHWINI/அஸ்வினி", "BHARANI/பரணி", "KRITTIKA/கிருத்திகை", "ROHINI/ரோகிணி", "MRIGHASIRA/மிருகசிரம்", "ARDRA/திருவாதிரை", "PUNARVASU/புனர்பூசம்", "PUSHYA/பூசம்", "ASHLESHA/ஆயில்யம்", "MAGHA/மகம்", "PURVA PHALGUNI/பூரம்", "UTTARA PHALGUNI/உத்திரம்", "HASTA/ஹஸ்தம்", "CHITRA/சித்திரை", "SWATI/சுவாதி", "VISHAKA/விசாகம்", "ANURADHA/அனுஷம்", "JYESTHA/கேட்டை", "MOOLA/மூலம்", "PURVASHADA/பூராடம்", "UTTARASHADA/உத்திராடம்", "SHARAVAN/திருவோணம்", "DHANISHTA/அவிட்டம்", "SHATABISHA/சதயம்", "PURVABHADRA/பூரட்டாதி", "UTTARABHADRA/உத்திரட்டாதி", "REVATI/ரேவதி"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/சுக்ல பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "PURNIMA/பௌர்ணமி"],
                    },
                    {
                        name: "KRISHNA PAKSA/கிருஷ்ண பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "AMAVASYA/அமாவாசை"]

                    }
                ]

            },
            {
                name: "MAASI/மாசி",
                nakshathira: ["ASHWINI/அஸ்வினி", "BHARANI/பரணி", "KRITTIKA/கிருத்திகை", "ROHINI/ரோகிணி", "MRIGHASIRA/மிருகசிரம்", "ARDRA/திருவாதிரை", "PUNARVASU/புனர்பூசம்", "PUSHYA/பூசம்", "ASHLESHA/ஆயில்யம்", "MAGHA/மகம்", "PURVA PHALGUNI/பூரம்", "UTTARA PHALGUNI/உத்திரம்", "HASTA/ஹஸ்தம்", "CHITRA/சித்திரை", "SWATI/சுவாதி", "VISHAKA/விசாகம்", "ANURADHA/அனுஷம்", "JYESTHA/கேட்டை", "MOOLA/மூலம்", "PURVASHADA/பூராடம்", "UTTARASHADA/உத்திராடம்", "SHARAVAN/திருவோணம்", "DHANISHTA/அவிட்டம்", "SHATABISHA/சதயம்", "PURVABHADRA/பூரட்டாதி", "UTTARABHADRA/உத்திரட்டாதி", "REVATI/ரேவதி"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/சுக்ல பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "PURNIMA/பௌர்ணமி"],
                    },
                    {
                        name: "KRISHNA PAKSA/கிருஷ்ண பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "AMAVASYA/அமாவாசை"]

                    }
                ]

            },
            {
                name: "PANGUNI/பங்குனி",
                nakshathira: ["ASHWINI/அஸ்வினி", "BHARANI/பரணி", "KRITTIKA/கிருத்திகை", "ROHINI/ரோகிணி", "MRIGHASIRA/மிருகசிரம்", "ARDRA/திருவாதிரை", "PUNARVASU/புனர்பூசம்", "PUSHYA/பூசம்", "ASHLESHA/ஆயில்யம்", "MAGHA/மகம்", "PURVA PHALGUNI/பூரம்", "UTTARA PHALGUNI/உத்திரம்", "HASTA/ஹஸ்தம்", "CHITRA/சித்திரை", "SWATI/சுவாதி", "VISHAKA/விசாகம்", "ANURADHA/அனுஷம்", "JYESTHA/கேட்டை", "MOOLA/மூலம்", "PURVASHADA/பூராடம்", "UTTARASHADA/உத்திராடம்", "SHARAVAN/திருவோணம்", "DHANISHTA/அவிட்டம்", "SHATABISHA/சதயம்", "PURVABHADRA/பூரட்டாதி", "UTTARABHADRA/உத்திரட்டாதி", "REVATI/ரேவதி"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/சுக்ல பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "PURNIMA/பௌர்ணமி"],
                    },
                    {
                        name: "KRISHNA PAKSA/கிருஷ்ண பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "AMAVASYA/அமாவாசை"]

                    }
                ]

            }

            ]
        },
        {
            name: "Malayalam",
            calenderCode: 2,
            month: [
                {
                    name: "MEENAM/മീനം",
                    nakshathira: ["ASHWINI/അശ്വനി", "BHARANI/ഭരണി", "KRITTIKA/കൃത്തിക", "ROHINI/രോഹിണി", "MRIGHASIRA/മൃഗശിര", "ARDRA/അർദ്ധ", "PUNARVASU/പൂർണ്ണവാസു", "PUSHYA/പൂഷ്യ", "ASHLESHA/അശ്വതി", "MAGHA/മകം", "PURVA PHALGUNI/പുർവഫാൽഗുണി", "UTTARA PHALGUNI/ഉത്തരാഫാൽഗുണി", "HASTA/ഹസ്ത", "CHITRA/ചിത", "SWATI/സ്വാതി", "VISHAKA/വിശാഖം", "ANURADHA/അനുരാധ", "JYESTHA/തിരുവാതിര", "MOOLA/മൂലം", "PURVASHADA/പുർവാഷാഢ", "UTTARASHADA/ഉത്തരാഷാഢ", "SHARAVAN/ശ്രാവണം", "DHANISHTA/ധനിഷ്ഠ", "SHATABISHA/ചിത്തിര", "PURVABHADRA/പുർവാഭദ്ര", "UTTARABHADRA/ഉത്തരാഭദ്ര", "REVATI/രേവതി"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/വെളുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "PURNIMA/പൂർണ്ണിമ"],
                        },
                        {
                            name: "KRISHNA PAKSHA/കറുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "AMAVASYA/അമാവാസി"]

                        }
                    ]



                },
                {
                    name: "MEDAM/മെഡാം",
                    nakshathira: ["ASHWINI/അശ്വനി", "BHARANI/ഭരണി", "KRITTIKA/കൃത്തിക", "ROHINI/രോഹിണി", "MRIGHASIRA/മൃഗശിര", "ARDRA/അർദ്ധ", "PUNARVASU/പൂർണ്ണവാസു", "PUSHYA/പൂഷ്യ", "ASHLESHA/അശ്വതി", "MAGHA/മകം", "PURVA PHALGUNI/പുർവഫാൽഗുണി", "UTTARA PHALGUNI/ഉത്തരാഫാൽഗുണി", "HASTA/ഹസ്ത", "CHITRA/ചിത", "SWATI/സ്വാതി", "VISHAKA/വിശാഖം", "ANURADHA/അനുരാധ", "JYESTHA/തിരുവാതിര", "MOOLA/മൂലം", "PURVASHADA/പുർവാഷാഢ", "UTTARASHADA/ഉത്തരാഷാഢ", "SHARAVAN/ശ്രാവണം", "DHANISHTA/ധനിഷ്ഠ", "SHATABISHA/ചിത്തിര", "PURVABHADRA/പുർവാഭദ്ര", "UTTARABHADRA/ഉത്തരാഭദ്ര", "REVATI/രേവതി"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/വെളുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "PURNIMA/പൂർണ്ണിമ"],
                        },
                        {
                            name: "KRISHNA PAKSHA/കറുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "AMAVASYA/അമാവാസി"]

                        }
                    ]

                },
                {
                    name: "EDAVAM/എദാവം",
                    nakshathira: ["ASHWINI/അശ്വനി", "BHARANI/ഭരണി", "KRITTIKA/കൃത്തിക", "ROHINI/രോഹിണി", "MRIGHASIRA/മൃഗശിര", "ARDRA/അർദ്ധ", "PUNARVASU/പൂർണ്ണവാസു", "PUSHYA/പൂഷ്യ", "ASHLESHA/അശ്വതി", "MAGHA/മകം", "PURVA PHALGUNI/പുർവഫാൽഗുണി", "UTTARA PHALGUNI/ഉത്തരാഫാൽഗുണി", "HASTA/ഹസ്ത", "CHITRA/ചിത", "SWATI/സ്വാതി", "VISHAKA/വിശാഖം", "ANURADHA/അനുരാധ", "JYESTHA/തിരുവാതിര", "MOOLA/മൂലം", "PURVASHADA/പുർവാഷാഢ", "UTTARASHADA/ഉത്തരാഷാഢ", "SHARAVAN/ശ്രാവണം", "DHANISHTA/ധനിഷ്ഠ", "SHATABISHA/ചിത്തിര", "PURVABHADRA/പുർവാഭദ്ര", "UTTARABHADRA/ഉത്തരാഭദ്ര", "REVATI/രേവതി"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/വെളുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "PURNIMA/പൂർണ്ണിമ"],
                        },
                        {
                            name: "KRISHNA PAKSHA/കറുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "AMAVASYA/അമാവാസി"]

                        }
                    ]

                },
                {
                    name: "MITHUNAM/മിഥുനം",
                    nakshathira: ["ASHWINI/അശ്വനി", "BHARANI/ഭരണി", "KRITTIKA/കൃത്തിക", "ROHINI/രോഹിണി", "MRIGHASIRA/മൃഗശിര", "ARDRA/അർദ്ധ", "PUNARVASU/പൂർണ്ണവാസു", "PUSHYA/പൂഷ്യ", "ASHLESHA/അശ്വതി", "MAGHA/മകം", "PURVA PHALGUNI/പുർവഫാൽഗുണി", "UTTARA PHALGUNI/ഉത്തരാഫാൽഗുണി", "HASTA/ഹസ്ത", "CHITRA/ചിത", "SWATI/സ്വാതി", "VISHAKA/വിശാഖം", "ANURADHA/അനുരാധ", "JYESTHA/തിരുവാതിര", "MOOLA/മൂലം", "PURVASHADA/പുർവാഷാഢ", "UTTARASHADA/ഉത്തരാഷാഢ", "SHARAVAN/ശ്രാവണം", "DHANISHTA/ധനിഷ്ഠ", "SHATABISHA/ചിത്തിര", "PURVABHADRA/പുർവാഭദ്ര", "UTTARABHADRA/ഉത്തരാഭദ്ര", "REVATI/രേവതി"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/വെളുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "PURNIMA/പൂർണ്ണിമ"],
                        },
                        {
                            name: "KRISHNA PAKSHA/കറുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "AMAVASYA/അമാവാസി"]

                        }
                    ]

                },
                {
                    name: "KARKADAKAM/കാർകിഡകാം",
                    nakshathira: ["ASHWINI/അശ്വനി", "BHARANI/ഭരണി", "KRITTIKA/കൃത്തിക", "ROHINI/രോഹിണി", "MRIGHASIRA/മൃഗശിര", "ARDRA/അർദ്ധ", "PUNARVASU/പൂർണ്ണവാസു", "PUSHYA/പൂഷ്യ", "ASHLESHA/അശ്വതി", "MAGHA/മകം", "PURVA PHALGUNI/പുർവഫാൽഗുണി", "UTTARA PHALGUNI/ഉത്തരാഫാൽഗുണി", "HASTA/ഹസ്ത", "CHITRA/ചിത", "SWATI/സ്വാതി", "VISHAKA/വിശാഖം", "ANURADHA/അനുരാധ", "JYESTHA/തിരുവാതിര", "MOOLA/മൂലം", "PURVASHADA/പുർവാഷാഢ", "UTTARASHADA/ഉത്തരാഷാഢ", "SHARAVAN/ശ്രാവണം", "DHANISHTA/ധനിഷ്ഠ", "SHATABISHA/ചിത്തിര", "PURVABHADRA/പുർവാഭദ്ര", "UTTARABHADRA/ഉത്തരാഭദ്ര", "REVATI/രേവതി"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/വെളുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "PURNIMA/പൂർണ്ണിമ"],
                        },
                        {
                            name: "KRISHNA PAKSHA/കറുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "AMAVASYA/അമാവാസി"]

                        }
                    ]

                },
                {
                    name: "CHINGAM/ചിങ്കാം",
                    nakshathira: ["ASHWINI/അശ്വനി", "BHARANI/ഭരണി", "KRITTIKA/കൃത്തിക", "ROHINI/രോഹിണി", "MRIGHASIRA/മൃഗശിര", "ARDRA/അർദ്ധ", "PUNARVASU/പൂർണ്ണവാസു", "PUSHYA/പൂഷ്യ", "ASHLESHA/അശ്വതി", "MAGHA/മകം", "PURVA PHALGUNI/പുർവഫാൽഗുണി", "UTTARA PHALGUNI/ഉത്തരാഫാൽഗുണി", "HASTA/ഹസ്ത", "CHITRA/ചിത", "SWATI/സ്വാതി", "VISHAKA/വിശാഖം", "ANURADHA/അനുരാധ", "JYESTHA/തിരുവാതിര", "MOOLA/മൂലം", "PURVASHADA/പുർവാഷാഢ", "UTTARASHADA/ഉത്തരാഷാഢ", "SHARAVAN/ശ്രാവണം", "DHANISHTA/ധനിഷ്ഠ", "SHATABISHA/ചിത്തിര", "PURVABHADRA/പുർവാഭദ്ര", "UTTARABHADRA/ഉത്തരാഭദ്ര", "REVATI/രേവതി"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/വെളുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "PURNIMA/പൂർണ്ണിമ"],
                        },
                        {
                            name: "KRISHNA PAKSHA/കറുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "AMAVASYA/അമാവാസി"]

                        }
                    ]

                },
                {
                    name: "KANNI/കാനി",
                    nakshathira: ["ASHWINI/അശ്വനി", "BHARANI/ഭരണി", "KRITTIKA/കൃത്തിക", "ROHINI/രോഹിണി", "MRIGHASIRA/മൃഗശിര", "ARDRA/അർദ്ധ", "PUNARVASU/പൂർണ്ണവാസു", "PUSHYA/പൂഷ്യ", "ASHLESHA/അശ്വതി", "MAGHA/മകം", "PURVA PHALGUNI/പുർവഫാൽഗുണി", "UTTARA PHALGUNI/ഉത്തരാഫാൽഗുണി", "HASTA/ഹസ്ത", "CHITRA/ചിത", "SWATI/സ്വാതി", "VISHAKA/വിശാഖം", "ANURADHA/അനുരാധ", "JYESTHA/തിരുവാതിര", "MOOLA/മൂലം", "PURVASHADA/പുർവാഷാഢ", "UTTARASHADA/ഉത്തരാഷാഢ", "SHARAVAN/ശ്രാവണം", "DHANISHTA/ധനിഷ്ഠ", "SHATABISHA/ചിത്തിര", "PURVABHADRA/പുർവാഭദ്ര", "UTTARABHADRA/ഉത്തരാഭദ്ര", "REVATI/രേവതി"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/വെളുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "PURNIMA/പൂർണ്ണിമ"],
                        },
                        {
                            name: "KRISHNA PAKSHA/കറുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "AMAVASYA/അമാവാസി"]

                        }
                    ]

                },
                {
                    name: "THULAM/തുലാം",
                    nakshathira: ["ASHWINI/അശ്വനി", "BHARANI/ഭരണി", "KRITTIKA/കൃത്തിക", "ROHINI/രോഹിണി", "MRIGHASIRA/മൃഗശിര", "ARDRA/അർദ്ധ", "PUNARVASU/പൂർണ്ണവാസു", "PUSHYA/പൂഷ്യ", "ASHLESHA/അശ്വതി", "MAGHA/മകം", "PURVA PHALGUNI/പുർവഫാൽഗുണി", "UTTARA PHALGUNI/ഉത്തരാഫാൽഗുണി", "HASTA/ഹസ്ത", "CHITRA/ചിത", "SWATI/സ്വാതി", "VISHAKA/വിശാഖം", "ANURADHA/അനുരാധ", "JYESTHA/തിരുവാതിര", "MOOLA/മൂലം", "PURVASHADA/പുർവാഷാഢ", "UTTARASHADA/ഉത്തരാഷാഢ", "SHARAVAN/ശ്രാവണം", "DHANISHTA/ധനിഷ്ഠ", "SHATABISHA/ചിത്തിര", "PURVABHADRA/പുർവാഭദ്ര", "UTTARABHADRA/ഉത്തരാഭദ്ര", "REVATI/രേവതി"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/വെളുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "PURNIMA/പൂർണ്ണിമ"],
                        },
                        {
                            name: "KRISHNA PAKSHA/കറുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "AMAVASYA/അമാവാസി"]

                        }
                    ]

                },
                {
                    name: "VRISHCHIKAM/വ്രിഷിക്കാം",
                    nakshathira: ["ASHWINI/അശ്വനി", "BHARANI/ഭരണി", "KRITTIKA/കൃത്തിക", "ROHINI/രോഹിണി", "MRIGHASIRA/മൃഗശിര", "ARDRA/അർദ്ധ", "PUNARVASU/പൂർണ്ണവാസു", "PUSHYA/പൂഷ്യ", "ASHLESHA/അശ്വതി", "MAGHA/മകം", "PURVA PHALGUNI/പുർവഫാൽഗുണി", "UTTARA PHALGUNI/ഉത്തരാഫാൽഗുണി", "HASTA/ഹസ്ത", "CHITRA/ചിത", "SWATI/സ്വാതി", "VISHAKA/വിശാഖം", "ANURADHA/അനുരാധ", "JYESTHA/തിരുവാതിര", "MOOLA/മൂലം", "PURVASHADA/പുർവാഷാഢ", "UTTARASHADA/ഉത്തരാഷാഢ", "SHARAVAN/ശ്രാവണം", "DHANISHTA/ധനിഷ്ഠ", "SHATABISHA/ചിത്തിര", "PURVABHADRA/പുർവാഭദ്ര", "UTTARABHADRA/ഉത്തരാഭദ്ര", "REVATI/രേവതി"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/വെളുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "PURNIMA/പൂർണ്ണിമ"],
                        },
                        {
                            name: "KRISHNA PAKSHA/കറുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "AMAVASYA/അമാവാസി"]

                        }
                    ]

                },
                {
                    name: "DHANU/ഭാനു",
                    nakshathira: ["ASHWINI/അശ്വനി", "BHARANI/ഭരണി", "KRITTIKA/കൃത്തിക", "ROHINI/രോഹിണി", "MRIGHASIRA/മൃഗശിര", "ARDRA/അർദ്ധ", "PUNARVASU/പൂർണ്ണവാസു", "PUSHYA/പൂഷ്യ", "ASHLESHA/അശ്വതി", "MAGHA/മകം", "PURVA PHALGUNI/പുർവഫാൽഗുണി", "UTTARA PHALGUNI/ഉത്തരാഫാൽഗുണി", "HASTA/ഹസ്ത", "CHITRA/ചിത", "SWATI/സ്വാതി", "VISHAKA/വിശാഖം", "ANURADHA/അനുരാധ", "JYESTHA/തിരുവാതിര", "MOOLA/മൂലം", "PURVASHADA/പുർവാഷാഢ", "UTTARASHADA/ഉത്തരാഷാഢ", "SHARAVAN/ശ്രാവണം", "DHANISHTA/ധനിഷ്ഠ", "SHATABISHA/ചിത്തിര", "PURVABHADRA/പുർവാഭദ്ര", "UTTARABHADRA/ഉത്തരാഭദ്ര", "REVATI/രേവതി"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/വെളുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "PURNIMA/പൂർണ്ണിമ"],
                        },
                        {
                            name: "KRISHNA PAKSHA/കറുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "AMAVASYA/അമാവാസി"]

                        }
                    ]

                },
                {
                    name: "MAKARAM/മകരം",
                    nakshathira: ["ASHWINI/അശ്വനി", "BHARANI/ഭരണി", "KRITTIKA/കൃത്തിക", "ROHINI/രോഹിണി", "MRIGHASIRA/മൃഗശിര", "ARDRA/അർദ്ധ", "PUNARVASU/പൂർണ്ണവാസു", "PUSHYA/പൂഷ്യ", "ASHLESHA/അശ്വതി", "MAGHA/മകം", "PURVA PHALGUNI/പുർവഫാൽഗുണി", "UTTARA PHALGUNI/ഉത്തരാഫാൽഗുണി", "HASTA/ഹസ്ത", "CHITRA/ചിത", "SWATI/സ്വാതി", "VISHAKA/വിശാഖം", "ANURADHA/അനുരാധ", "JYESTHA/തിരുവാതിര", "MOOLA/മൂലം", "PURVASHADA/പുർവാഷാഢ", "UTTARASHADA/ഉത്തരാഷാഢ", "SHARAVAN/ശ്രാവണം", "DHANISHTA/ധനിഷ്ഠ", "SHATABISHA/ചിത്തിര", "PURVABHADRA/പുർവാഭദ്ര", "UTTARABHADRA/ഉത്തരാഭദ്ര", "REVATI/രേവതി"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/വെളുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "PURNIMA/പൂർണ്ണിമ"],
                        },
                        {
                            name: "KRISHNA PAKSHA/കറുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "AMAVASYA/അമാവാസി"]

                        }
                    ]

                },
                {
                    name: "KUMBHAM/കുംഹാം",
                    nakshathira: ["ASHWINI/അശ്വനി", "BHARANI/ഭരണി", "KRITTIKA/കൃത്തിക", "ROHINI/രോഹിണി", "MRIGHASIRA/മൃഗശിര", "ARDRA/അർദ്ധ", "PUNARVASU/പൂർണ്ണവാസു", "PUSHYA/പൂഷ്യ", "ASHLESHA/അശ്വതി", "MAGHA/മകം", "PURVA PHALGUNI/പുർവഫാൽഗുണി", "UTTARA PHALGUNI/ഉത്തരാഫാൽഗുണി", "HASTA/ഹസ്ത", "CHITRA/ചിത", "SWATI/സ്വാതി", "VISHAKA/വിശാഖം", "ANURADHA/അനുരാധ", "JYESTHA/തിരുവാതിര", "MOOLA/മൂലം", "PURVASHADA/പുർവാഷാഢ", "UTTARASHADA/ഉത്തരാഷാഢ", "SHARAVAN/ശ്രാവണം", "DHANISHTA/ധനിഷ്ഠ", "SHATABISHA/ചിത്തിര", "PURVABHADRA/പുർവാഭദ്ര", "UTTARABHADRA/ഉത്തരാഭദ്ര", "REVATI/രേവതി"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/വെളുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "PURNIMA/പൂർണ്ണിമ"],
                        },
                        {
                            name: "KRISHNA PAKSHA/കറുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "AMAVASYA/അമാവാസി"]

                        }
                    ]

                }
            ]
        },
        {
            name: "Telugu",
            calenderCode: 3,
            month: [
                {
                    name: "CHAITHRAMU/చైత్రము",
                    nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/శుక్ల పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి"],
                        },
                        {
                            name: "KRISHNA PAKSHA/కృష్ణ పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "AMAVASYA/అమావాస్య"]

                        }
                    ]
                },
                {
                    name: "VAISAAKHAMU/వైశాఖము",
                    nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/శుక్ల పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి"],
                        },
                        {
                            name: "KRISHNA PAKSHA/కృష్ణ పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "AMAVASYA/అమావాస్య"]

                        }
                    ]

                },
                {
                    name: "JYESTHAMU/జ్యేష్ఠము",
                    nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/శుక్ల పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి"],
                        },
                        {
                            name: "KRISHNA PAKSHA/కృష్ణ పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "AMAVASYA/అమావాస్య"]

                        }
                    ]

                },
                {
                    name: "ASHADHAMU/ఆషాఢము",
                    nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/శుక్ల పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి"],
                        },
                        {
                            name: "KRISHNA PAKSHA/కృష్ణ పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "AMAVASYA/అమావాస్య"]

                        }
                    ]

                },
                // {
                //     name: "Sravanamu (Adhik)/శ్రావణము",
                //     nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
                //     Paksham: [
                //         {
                //             name: "SHUKLA PAKSHA/శుక్ల పక్షం",
                //             tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి"],
                //         },
                //         {
                //             name: "KRISHNA PAKSHA/కృష్ణ పక్షం",
                //             tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "AMAVASYA/అమావాస్య"]

                //         }
                //     ]

                // },
                {
                    name: "SRAVANAMU/శ్రావణము",
                    nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/శుక్ల పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి"],
                        },
                        {
                            name: "KRISHNA PAKSHA/కృష్ణ పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "AMAVASYA/అమావాస్య"]

                        }
                    ]

                },
                {
                    name: "BHADHRAPADAMU/భాద్రపదము",
                    nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/శుక్ల పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి"],
                        },
                        {
                            name: "KRISHNA PAKSHA/కృష్ణ పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "AMAVASYA/అమావాస్య"]

                        }
                    ]

                },
                {
                    name: "AASVAYUJAMU/ఆశ్వయుజము",
                    nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/శుక్ల పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి"],
                        },
                        {
                            name: "KRISHNA PAKSHA/కృష్ణ పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "AMAVASYA/అమావాస్య"]

                        }
                    ]

                },
                {
                    name: "KARTHIKAMU/కార్తీకము",
                    nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/శుక్ల పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి"],
                        },
                        {
                            name: "KRISHNA PAKSHA/కృష్ణ పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "AMAVASYA/అమావాస్య"]

                        }
                    ]

                },
                {
                    name: "MAARGASEERSHAMU/మార్గశీర్షము",
                    nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/శుక్ల పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి"],
                        },
                        {
                            name: "KRISHNA PAKSHA/కృష్ణ పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "AMAVASYA/అమావాస్య"]

                        }
                    ]

                },
                {
                    name: "PUSHYAMU/పుష్యము",
                    nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/శుక్ల పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి"],
                        },
                        {
                            name: "KRISHNA PAKSHA/కృష్ణ పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "AMAVASYA/అమావాస్య"]

                        }
                    ]

                },
                {
                    name: "MAKHAMU/మఖము",
                    nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/శుక్ల పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి"],
                        },
                        {
                            name: "KRISHNA PAKSHA/కృష్ణ పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "AMAVASYA/అమావాస్య"]

                        }
                    ]
                },
                {
                    name: "PHALGUNAMU/ఫాల్గుణము",
                    nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/శుక్ల పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి"],
                        },
                        {
                            name: "KRISHNA PAKSHA/కృష్ణ పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "AMAVASYA/అమావాస్య"]

                        }
                    ]
                }
            ]
        },
        {
            name: "Kannada",
            calenderCode: 4,
            month: [{
                name: "CHAITRA/ಚೈತ್ರ",
                nakshathira: ["ASHWINI/ಅಶ್ವಿನಿ", "BHARANI/ಭರಣಿ", "KRITTIKA/ಕೃತ್ತಿಕಾ", "ROHINI/ರೋಹಿಣಿ", "MRIGHASIRA/ಮೃಘಾಸಿರ", "ARDRA/ಆರ್ದ್ರಾ", "PUNARVASU/ಪುನರ್ವಸು", "PUSHYA/ಪುಷ್ಯ", "ASHLESHA/ಆಶ್ಲೇಷಾ", "MAGHA/ಮಾಘ", "PURVA PHALGUNI/ಪೂರ್ವ ಫಲ್ಗುಣಿ", "UTTARA PHALGUNI/ಉತ್ತರ ಫಲ್ಗುಣಿ", "HASTA/ಹಸ್ತ", "CHITRA/ಚಿತ್ರಾ", "SWATI/ಸ್ವಾತಿ", "VISHAKA/ವಿಶಾಕ", "ANURADHA/ಅನುರಾಧಾ", "JYESTHA/ಜ್ಯೇಷ್ಠ", "MOOLA/ಮೂಲಾ", "PURVASHADA/ಪೂರ್ವಾಷಾಡ", "UTTARASHADA/ಉತ್ತರಾಷಾಢ", "SHARAVAN/ಶರವಣ", "DHANISHTA/ಧನಿಷ್ಠಾ", "SHATABISHA/ಶತಬಿಷಾ", "PURVABHADRA/ಪೂರ್ವಾಭದ್ರ", "UTTARABHADRA/ಉತ್ತರಾಭಾದ್ರ", "REVATI/ರೇವತಿ"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/ಶುಕ್ಲ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "PURNIMA/ಪೌರ್ಣಿಮಿ"],
                    },
                    {
                        name: "KRISHNA PAKSHA/ಕೃಷ್ಣ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "AMAVASYA/ಅಮಾವಾಸ್ಯೆ"]

                    }
                ]


            },
            {
                name: "VAISHAKHA/ವೈಶಾಖ",
                nakshathira: ["ASHWINI/ಅಶ್ವಿನಿ", "BHARANI/ಭರಣಿ", "KRITTIKA/ಕೃತ್ತಿಕಾ", "ROHINI/ರೋಹಿಣಿ", "MRIGHASIRA/ಮೃಘಾಸಿರ", "ARDRA/ಆರ್ದ್ರಾ", "PUNARVASU/ಪುನರ್ವಸು", "PUSHYA/ಪುಷ್ಯ", "ASHLESHA/ಆಶ್ಲೇಷಾ", "MAGHA/ಮಾಘ", "PURVA PHALGUNI/ಪೂರ್ವ ಫಲ್ಗುಣಿ", "UTTARA PHALGUNI/ಉತ್ತರ ಫಲ್ಗುಣಿ", "HASTA/ಹಸ್ತ", "CHITRA/ಚಿತ್ರಾ", "SWATI/ಸ್ವಾತಿ", "VISHAKA/ವಿಶಾಕ", "ANURADHA/ಅನುರಾಧಾ", "JYESTHA/ಜ್ಯೇಷ್ಠ", "MOOLA/ಮೂಲಾ", "PURVASHADA/ಪೂರ್ವಾಷಾಡ", "UTTARASHADA/ಉತ್ತರಾಷಾಢ", "SHARAVAN/ಶರವಣ", "DHANISHTA/ಧನಿಷ್ಠಾ", "SHATABISHA/ಶತಬಿಷಾ", "PURVABHADRA/ಪೂರ್ವಾಭದ್ರ", "UTTARABHADRA/ಉತ್ತರಾಭಾದ್ರ", "REVATI/ರೇವತಿ"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/ಶುಕ್ಲ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "PURNIMA/ಪೌರ್ಣಿಮಿ"],
                    },
                    {
                        name: "KRISHNA PAKSHA/ಕೃಷ್ಣ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "AMAVASYA/ಅಮಾವಾಸ್ಯೆ"]

                    }
                ]


            },
            {
                name: "JYESHTHA/ಜ್ಯೇಷ್ಠ",
                nakshathira: ["ASHWINI/ಅಶ್ವಿನಿ", "BHARANI/ಭರಣಿ", "KRITTIKA/ಕೃತ್ತಿಕಾ", "ROHINI/ರೋಹಿಣಿ", "MRIGHASIRA/ಮೃಘಾಸಿರ", "ARDRA/ಆರ್ದ್ರಾ", "PUNARVASU/ಪುನರ್ವಸು", "PUSHYA/ಪುಷ್ಯ", "ASHLESHA/ಆಶ್ಲೇಷಾ", "MAGHA/ಮಾಘ", "PURVA PHALGUNI/ಪೂರ್ವ ಫಲ್ಗುಣಿ", "UTTARA PHALGUNI/ಉತ್ತರ ಫಲ್ಗುಣಿ", "HASTA/ಹಸ್ತ", "CHITRA/ಚಿತ್ರಾ", "SWATI/ಸ್ವಾತಿ", "VISHAKA/ವಿಶಾಕ", "ANURADHA/ಅನುರಾಧಾ", "JYESTHA/ಜ್ಯೇಷ್ಠ", "MOOLA/ಮೂಲಾ", "PURVASHADA/ಪೂರ್ವಾಷಾಡ", "UTTARASHADA/ಉತ್ತರಾಷಾಢ", "SHARAVAN/ಶರವಣ", "DHANISHTA/ಧನಿಷ್ಠಾ", "SHATABISHA/ಶತಬಿಷಾ", "PURVABHADRA/ಪೂರ್ವಾಭದ್ರ", "UTTARABHADRA/ಉತ್ತರಾಭಾದ್ರ", "REVATI/ರೇವತಿ"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/ಶುಕ್ಲ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "PURNIMA/ಪೌರ್ಣಿಮಿ"],
                    },
                    {
                        name: "KRISHNA PAKSHA/ಕೃಷ್ಣ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "AMAVASYA/ಅಮಾವಾಸ್ಯೆ"]

                    }
                ]


            },
            {
                name: "ASHADHA/ಆಷಾಢ",
                nakshathira: ["ASHWINI/ಅಶ್ವಿನಿ", "BHARANI/ಭರಣಿ", "KRITTIKA/ಕೃತ್ತಿಕಾ", "ROHINI/ರೋಹಿಣಿ", "MRIGHASIRA/ಮೃಘಾಸಿರ", "ARDRA/ಆರ್ದ್ರಾ", "PUNARVASU/ಪುನರ್ವಸು", "PUSHYA/ಪುಷ್ಯ", "ASHLESHA/ಆಶ್ಲೇಷಾ", "MAGHA/ಮಾಘ", "PURVA PHALGUNI/ಪೂರ್ವ ಫಲ್ಗುಣಿ", "UTTARA PHALGUNI/ಉತ್ತರ ಫಲ್ಗುಣಿ", "HASTA/ಹಸ್ತ", "CHITRA/ಚಿತ್ರಾ", "SWATI/ಸ್ವಾತಿ", "VISHAKA/ವಿಶಾಕ", "ANURADHA/ಅನುರಾಧಾ", "JYESTHA/ಜ್ಯೇಷ್ಠ", "MOOLA/ಮೂಲಾ", "PURVASHADA/ಪೂರ್ವಾಷಾಡ", "UTTARASHADA/ಉತ್ತರಾಷಾಢ", "SHARAVAN/ಶರವಣ", "DHANISHTA/ಧನಿಷ್ಠಾ", "SHATABISHA/ಶತಬಿಷಾ", "PURVABHADRA/ಪೂರ್ವಾಭದ್ರ", "UTTARABHADRA/ಉತ್ತರಾಭಾದ್ರ", "REVATI/ರೇವತಿ"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/ಶುಕ್ಲ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "PURNIMA/ಪೌರ್ಣಿಮಿ"],
                    },
                    {
                        name: "KRISHNA PAKSHA/ಕೃಷ್ಣ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "AMAVASYA/ಅಮಾವಾಸ್ಯೆ"]

                    }
                ]


            },
            {
                name: "SAHRAVANA/ಶ್ರಾವಣ",
                nakshathira: ["ASHWINI/ಅಶ್ವಿನಿ", "BHARANI/ಭರಣಿ", "KRITTIKA/ಕೃತ್ತಿಕಾ", "ROHINI/ರೋಹಿಣಿ", "MRIGHASIRA/ಮೃಘಾಸಿರ", "ARDRA/ಆರ್ದ್ರಾ", "PUNARVASU/ಪುನರ್ವಸು", "PUSHYA/ಪುಷ್ಯ", "ASHLESHA/ಆಶ್ಲೇಷಾ", "MAGHA/ಮಾಘ", "PURVA PHALGUNI/ಪೂರ್ವ ಫಲ್ಗುಣಿ", "UTTARA PHALGUNI/ಉತ್ತರ ಫಲ್ಗುಣಿ", "HASTA/ಹಸ್ತ", "CHITRA/ಚಿತ್ರಾ", "SWATI/ಸ್ವಾತಿ", "VISHAKA/ವಿಶಾಕ", "ANURADHA/ಅನುರಾಧಾ", "JYESTHA/ಜ್ಯೇಷ್ಠ", "MOOLA/ಮೂಲಾ", "PURVASHADA/ಪೂರ್ವಾಷಾಡ", "UTTARASHADA/ಉತ್ತರಾಷಾಢ", "SHARAVAN/ಶರವಣ", "DHANISHTA/ಧನಿಷ್ಠಾ", "SHATABISHA/ಶತಬಿಷಾ", "PURVABHADRA/ಪೂರ್ವಾಭದ್ರ", "UTTARABHADRA/ಉತ್ತರಾಭಾದ್ರ", "REVATI/ರೇವತಿ"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/ಶುಕ್ಲ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "PURNIMA/ಪೌರ್ಣಿಮಿ"],
                    },
                    {
                        name: "KRISHNA PAKSHA/ಕೃಷ್ಣ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "AMAVASYA/ಅಮಾವಾಸ್ಯೆ"]

                    }
                ]


            },
            {
                name: "BHADRAPADA/ಭಾದ್ರಪದ",
                nakshathira: ["ASHWINI/ಅಶ್ವಿನಿ", "BHARANI/ಭರಣಿ", "KRITTIKA/ಕೃತ್ತಿಕಾ", "ROHINI/ರೋಹಿಣಿ", "MRIGHASIRA/ಮೃಘಾಸಿರ", "ARDRA/ಆರ್ದ್ರಾ", "PUNARVASU/ಪುನರ್ವಸು", "PUSHYA/ಪುಷ್ಯ", "ASHLESHA/ಆಶ್ಲೇಷಾ", "MAGHA/ಮಾಘ", "PURVA PHALGUNI/ಪೂರ್ವ ಫಲ್ಗುಣಿ", "UTTARA PHALGUNI/ಉತ್ತರ ಫಲ್ಗುಣಿ", "HASTA/ಹಸ್ತ", "CHITRA/ಚಿತ್ರಾ", "SWATI/ಸ್ವಾತಿ", "VISHAKA/ವಿಶಾಕ", "ANURADHA/ಅನುರಾಧಾ", "JYESTHA/ಜ್ಯೇಷ್ಠ", "MOOLA/ಮೂಲಾ", "PURVASHADA/ಪೂರ್ವಾಷಾಡ", "UTTARASHADA/ಉತ್ತರಾಷಾಢ", "SHARAVAN/ಶರವಣ", "DHANISHTA/ಧನಿಷ್ಠಾ", "SHATABISHA/ಶತಬಿಷಾ", "PURVABHADRA/ಪೂರ್ವಾಭದ್ರ", "UTTARABHADRA/ಉತ್ತರಾಭಾದ್ರ", "REVATI/ರೇವತಿ"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/ಶುಕ್ಲ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "PURNIMA/ಪೌರ್ಣಿಮಿ"],
                    },
                    {
                        name: "KRISHNA PAKSHA/ಕೃಷ್ಣ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "AMAVASYA/ಅಮಾವಾಸ್ಯೆ"]

                    }
                ]


            },
            {
                name: "ASHWEJA/ಆಶ್ವೇಜ",
                nakshathira: ["ASHWINI/ಅಶ್ವಿನಿ", "BHARANI/ಭರಣಿ", "KRITTIKA/ಕೃತ್ತಿಕಾ", "ROHINI/ರೋಹಿಣಿ", "MRIGHASIRA/ಮೃಘಾಸಿರ", "ARDRA/ಆರ್ದ್ರಾ", "PUNARVASU/ಪುನರ್ವಸು", "PUSHYA/ಪುಷ್ಯ", "ASHLESHA/ಆಶ್ಲೇಷಾ", "MAGHA/ಮಾಘ", "PURVA PHALGUNI/ಪೂರ್ವ ಫಲ್ಗುಣಿ", "UTTARA PHALGUNI/ಉತ್ತರ ಫಲ್ಗುಣಿ", "HASTA/ಹಸ್ತ", "CHITRA/ಚಿತ್ರಾ", "SWATI/ಸ್ವಾತಿ", "VISHAKA/ವಿಶಾಕ", "ANURADHA/ಅನುರಾಧಾ", "JYESTHA/ಜ್ಯೇಷ್ಠ", "MOOLA/ಮೂಲಾ", "PURVASHADA/ಪೂರ್ವಾಷಾಡ", "UTTARASHADA/ಉತ್ತರಾಷಾಢ", "SHARAVAN/ಶರವಣ", "DHANISHTA/ಧನಿಷ್ಠಾ", "SHATABISHA/ಶತಬಿಷಾ", "PURVABHADRA/ಪೂರ್ವಾಭದ್ರ", "UTTARABHADRA/ಉತ್ತರಾಭಾದ್ರ", "REVATI/ರೇವತಿ"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/ಶುಕ್ಲ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "PURNIMA/ಪೌರ್ಣಿಮಿ"],
                    },
                    {
                        name: "KRISHNA PAKSHA/ಕೃಷ್ಣ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "AMAVASYA/ಅಮಾವಾಸ್ಯೆ"]

                    }
                ]


            },
            {
                name: "KARTIKA/ಕಾರ್ತೀಕ",
                nakshathira: ["ASHWINI/ಅಶ್ವಿನಿ", "BHARANI/ಭರಣಿ", "KRITTIKA/ಕೃತ್ತಿಕಾ", "ROHINI/ರೋಹಿಣಿ", "MRIGHASIRA/ಮೃಘಾಸಿರ", "ARDRA/ಆರ್ದ್ರಾ", "PUNARVASU/ಪುನರ್ವಸು", "PUSHYA/ಪುಷ್ಯ", "ASHLESHA/ಆಶ್ಲೇಷಾ", "MAGHA/ಮಾಘ", "PURVA PHALGUNI/ಪೂರ್ವ ಫಲ್ಗುಣಿ", "UTTARA PHALGUNI/ಉತ್ತರ ಫಲ್ಗುಣಿ", "HASTA/ಹಸ್ತ", "CHITRA/ಚಿತ್ರಾ", "SWATI/ಸ್ವಾತಿ", "VISHAKA/ವಿಶಾಕ", "ANURADHA/ಅನುರಾಧಾ", "JYESTHA/ಜ್ಯೇಷ್ಠ", "MOOLA/ಮೂಲಾ", "PURVASHADA/ಪೂರ್ವಾಷಾಡ", "UTTARASHADA/ಉತ್ತರಾಷಾಢ", "SHARAVAN/ಶರವಣ", "DHANISHTA/ಧನಿಷ್ಠಾ", "SHATABISHA/ಶತಬಿಷಾ", "PURVABHADRA/ಪೂರ್ವಾಭದ್ರ", "UTTARABHADRA/ಉತ್ತರಾಭಾದ್ರ", "REVATI/ರೇವತಿ"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/ಶುಕ್ಲ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "PURNIMA/ಪೌರ್ಣಿಮಿ"],
                    },
                    {
                        name: "KRISHNA PAKSHA/ಕೃಷ್ಣ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "AMAVASYA/ಅಮಾವಾಸ್ಯೆ"]

                    }
                ]


            },
            {
                name: "MARGASHIRSHA/ಮಾರ್ಗಶೀರ್ಷ",
                nakshathira: ["ASHWINI/ಅಶ್ವಿನಿ", "BHARANI/ಭರಣಿ", "KRITTIKA/ಕೃತ್ತಿಕಾ", "ROHINI/ರೋಹಿಣಿ", "MRIGHASIRA/ಮೃಘಾಸಿರ", "ARDRA/ಆರ್ದ್ರಾ", "PUNARVASU/ಪುನರ್ವಸು", "PUSHYA/ಪುಷ್ಯ", "ASHLESHA/ಆಶ್ಲೇಷಾ", "MAGHA/ಮಾಘ", "PURVA PHALGUNI/ಪೂರ್ವ ಫಲ್ಗುಣಿ", "UTTARA PHALGUNI/ಉತ್ತರ ಫಲ್ಗುಣಿ", "HASTA/ಹಸ್ತ", "CHITRA/ಚಿತ್ರಾ", "SWATI/ಸ್ವಾತಿ", "VISHAKA/ವಿಶಾಕ", "ANURADHA/ಅನುರಾಧಾ", "JYESTHA/ಜ್ಯೇಷ್ಠ", "MOOLA/ಮೂಲಾ", "PURVASHADA/ಪೂರ್ವಾಷಾಡ", "UTTARASHADA/ಉತ್ತರಾಷಾಢ", "SHARAVAN/ಶರವಣ", "DHANISHTA/ಧನಿಷ್ಠಾ", "SHATABISHA/ಶತಬಿಷಾ", "PURVABHADRA/ಪೂರ್ವಾಭದ್ರ", "UTTARABHADRA/ಉತ್ತರಾಭಾದ್ರ", "REVATI/ರೇವತಿ"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/ಶುಕ್ಲ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "PURNIMA/ಪೌರ್ಣಿಮಿ"],
                    },
                    {
                        name: "KRISHNA PAKSHA/ಕೃಷ್ಣ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "AMAVASYA/ಅಮಾವಾಸ್ಯೆ"]

                    }
                ]


            },
            {
                name: "PAUSHA/ಪೌಷಾ",
                nakshathira: ["ASHWINI/ಅಶ್ವಿನಿ", "BHARANI/ಭರಣಿ", "KRITTIKA/ಕೃತ್ತಿಕಾ", "ROHINI/ರೋಹಿಣಿ", "MRIGHASIRA/ಮೃಘಾಸಿರ", "ARDRA/ಆರ್ದ್ರಾ", "PUNARVASU/ಪುನರ್ವಸು", "PUSHYA/ಪುಷ್ಯ", "ASHLESHA/ಆಶ್ಲೇಷಾ", "MAGHA/ಮಾಘ", "PURVA PHALGUNI/ಪೂರ್ವ ಫಲ್ಗುಣಿ", "UTTARA PHALGUNI/ಉತ್ತರ ಫಲ್ಗುಣಿ", "HASTA/ಹಸ್ತ", "CHITRA/ಚಿತ್ರಾ", "SWATI/ಸ್ವಾತಿ", "VISHAKA/ವಿಶಾಕ", "ANURADHA/ಅನುರಾಧಾ", "JYESTHA/ಜ್ಯೇಷ್ಠ", "MOOLA/ಮೂಲಾ", "PURVASHADA/ಪೂರ್ವಾಷಾಡ", "UTTARASHADA/ಉತ್ತರಾಷಾಢ", "SHARAVAN/ಶರವಣ", "DHANISHTA/ಧನಿಷ್ಠಾ", "SHATABISHA/ಶತಬಿಷಾ", "PURVABHADRA/ಪೂರ್ವಾಭದ್ರ", "UTTARABHADRA/ಉತ್ತರಾಭಾದ್ರ", "REVATI/ರೇವತಿ"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/ಶುಕ್ಲ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "PURNIMA/ಪೌರ್ಣಿಮಿ"],
                    },
                    {
                        name: "KRISHNA PAKSHA/ಕೃಷ್ಣ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "AMAVASYA/ಅಮಾವಾಸ್ಯೆ"]

                    }
                ]


            },
            {
                name: "MAGHA/ಮಾಘ",
                nakshathira: ["ASHWINI/ಅಶ್ವಿನಿ", "BHARANI/ಭರಣಿ", "KRITTIKA/ಕೃತ್ತಿಕಾ", "ROHINI/ರೋಹಿಣಿ", "MRIGHASIRA/ಮೃಘಾಸಿರ", "ARDRA/ಆರ್ದ್ರಾ", "PUNARVASU/ಪುನರ್ವಸು", "PUSHYA/ಪುಷ್ಯ", "ASHLESHA/ಆಶ್ಲೇಷಾ", "MAGHA/ಮಾಘ", "PURVA PHALGUNI/ಪೂರ್ವ ಫಲ್ಗುಣಿ", "UTTARA PHALGUNI/ಉತ್ತರ ಫಲ್ಗುಣಿ", "HASTA/ಹಸ್ತ", "CHITRA/ಚಿತ್ರಾ", "SWATI/ಸ್ವಾತಿ", "VISHAKA/ವಿಶಾಕ", "ANURADHA/ಅನುರಾಧಾ", "JYESTHA/ಜ್ಯೇಷ್ಠ", "MOOLA/ಮೂಲಾ", "PURVASHADA/ಪೂರ್ವಾಷಾಡ", "UTTARASHADA/ಉತ್ತರಾಷಾಢ", "SHARAVAN/ಶರವಣ", "DHANISHTA/ಧನಿಷ್ಠಾ", "SHATABISHA/ಶತಬಿಷಾ", "PURVABHADRA/ಪೂರ್ವಾಭದ್ರ", "UTTARABHADRA/ಉತ್ತರಾಭಾದ್ರ", "REVATI/ರೇವತಿ"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/ಶುಕ್ಲ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "PURNIMA/ಪೌರ್ಣಿಮಿ"],
                    },
                    {
                        name: "KRISHNA PAKSHA/ಕೃಷ್ಣ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "AMAVASYA/ಅಮಾವಾಸ್ಯೆ"]

                    }
                ]


            },
            {
                name: "PHALGUNA/ಫಲ್ಗುಣ",
                nakshathira: ["ASHWINI/ಅಶ್ವಿನಿ", "BHARANI/ಭರಣಿ", "KRITTIKA/ಕೃತ್ತಿಕಾ", "ROHINI/ರೋಹಿಣಿ", "MRIGHASIRA/ಮೃಘಾಸಿರ", "ARDRA/ಆರ್ದ್ರಾ", "PUNARVASU/ಪುನರ್ವಸು", "PUSHYA/ಪುಷ್ಯ", "ASHLESHA/ಆಶ್ಲೇಷಾ", "MAGHA/ಮಾಘ", "PURVA PHALGUNI/ಪೂರ್ವ ಫಲ್ಗುಣಿ", "UTTARA PHALGUNI/ಉತ್ತರ ಫಲ್ಗುಣಿ", "HASTA/ಹಸ್ತ", "CHITRA/ಚಿತ್ರಾ", "SWATI/ಸ್ವಾತಿ", "VISHAKA/ವಿಶಾಕ", "ANURADHA/ಅನುರಾಧಾ", "JYESTHA/ಜ್ಯೇಷ್ಠ", "MOOLA/ಮೂಲಾ", "PURVASHADA/ಪೂರ್ವಾಷಾಡ", "UTTARASHADA/ಉತ್ತರಾಷಾಢ", "SHARAVAN/ಶರವಣ", "DHANISHTA/ಧನಿಷ್ಠಾ", "SHATABISHA/ಶತಬಿಷಾ", "PURVABHADRA/ಪೂರ್ವಾಭದ್ರ", "UTTARABHADRA/ಉತ್ತರಾಭಾದ್ರ", "REVATI/ರೇವತಿ"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/ಶುಕ್ಲ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "PURNIMA/ಪೌರ್ಣಿಮಿ"],
                    },
                    {
                        name: "KRISHNA PAKSHA/ಕೃಷ್ಣ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "AMAVASYA/ಅಮಾವಾಸ್ಯೆ"]

                    }
                ]


            },
            ]
        },
        {
            name: "Hindi",
            calenderCode: 5,
            month: [
                {
                    name: "CHAITRA/चैत्र",
                    nakshathira:
                        [
                            "ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशिरा", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/अश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वफाल्गुनी", "UTTARA PHALGUNI/उत्तराफाल्गुनी", "HASTA/हस्त", "CHITRA/चित्रा", "SWATI/स्वाति", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूल", "PURVASHADA/पूर्वाषाढ़", "UTTARASHADA/उत्तराषाढ़", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/शुक्ल पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            name: "KRISHNA PAKSHA/कृष्ण पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]

                        }
                    ]
                },
                {
                    name: "VAISAKHA/वैशाख",
                    nakshathira:
                        [
                            "ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशिरा", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/अश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वफाल्गुनी", "UTTARA PHALGUNI/उत्तराफाल्गुनी", "HASTA/हस्त", "CHITRA/चित्रा", "SWATI/स्वाति", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूल", "PURVASHADA/पूर्वाषाढ़", "UTTARASHADA/उत्तराषाढ़", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/शुक्ल पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            name: "KRISHNA PAKSHA/कृष्ण पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]

                        }
                    ]
                },
                {
                    name: "ASHADHA/आषाढ़",
                    nakshathira:
                        [
                            "ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशिरा", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/अश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वफाल्गुनी", "UTTARA PHALGUNI/उत्तराफाल्गुनी", "HASTA/हस्त", "CHITRA/चित्रा", "SWATI/स्वाति", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूल", "PURVASHADA/पूर्वाषाढ़", "UTTARASHADA/उत्तराषाढ़", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/शुक्ल पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            name: "KRISHNA PAKSHA/कृष्ण पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]

                        }
                    ]
                },
                {
                    name: "JYESTHA/ज्येष्ठा",
                    nakshathira:
                        [
                            "ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशिरा", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/अश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वफाल्गुनी", "UTTARA PHALGUNI/उत्तराफाल्गुनी", "HASTA/हस्त", "CHITRA/चित्रा", "SWATI/स्वाति", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूल", "PURVASHADA/पूर्वाषाढ़", "UTTARASHADA/उत्तराषाढ़", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/शुक्ल पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            name: "KRISHNA PAKSHA/कृष्ण पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]

                        }
                    ]
                },
                // {
                //     name: "SHRAVANA(Adhik)/श्रावण",
                //     nakshathira:
                //         [
                //             "ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशिरा", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/अश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वफाल्गुनी", "UTTARA PHALGUNI/उत्तराफाल्गुनी", "HASTA/हस्त", "CHITRA/चित्रा", "SWATI/स्वाति", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूल", "PURVASHADA/पूर्वाषाढ़", "UTTARASHADA/उत्तराषाढ़", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                //     Paksham: [
                //         {
                //             name: "SHUKLA PAKSHA/शुक्ल पक्ष",
                //             tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                //         },
                //         {
                //             name: "KRISHNA PAKSHA/कृष्ण पक्ष",
                //             tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]

                //         }
                //     ]
                // },
                {
                    name: "SHRAVANA/श्रावण",
                    nakshathira:
                        [
                            "ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशिरा", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/अश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वफाल्गुनी", "UTTARA PHALGUNI/उत्तराफाल्गुनी", "HASTA/हस्त", "CHITRA/चित्रा", "SWATI/स्वाति", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूल", "PURVASHADA/पूर्वाषाढ़", "UTTARASHADA/उत्तराषाढ़", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/शुक्ल पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            name: "KRISHNA PAKSHA/कृष्ण पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]

                        }
                    ]
                },
                {
                    name: "BHADRAPADA/भाद्रपद",
                    nakshathira:
                        [
                            "ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशिरा", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/अश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वफाल्गुनी", "UTTARA PHALGUNI/उत्तराफाल्गुनी", "HASTA/हस्त", "CHITRA/चित्रा", "SWATI/स्वाति", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूल", "PURVASHADA/पूर्वाषाढ़", "UTTARASHADA/उत्तराषाढ़", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/शुक्ल पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            name: "KRISHNA PAKSHA/कृष्ण पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]

                        }
                    ]
                },
                {
                    name: "ASHVIN/अश्विन",
                    nakshathira:
                        [
                            "ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशिरा", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/अश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वफाल्गुनी", "UTTARA PHALGUNI/उत्तराफाल्गुनी", "HASTA/हस्त", "CHITRA/चित्रा", "SWATI/स्वाति", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूल", "PURVASHADA/पूर्वाषाढ़", "UTTARASHADA/उत्तराषाढ़", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/शुक्ल पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            name: "KRISHNA PAKSHA/कृष्ण पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]

                        }
                    ]
                },
                {
                    name: "KATIKA/कार्तिक",
                    nakshathira:
                        [
                            "ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशिरा", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/अश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वफाल्गुनी", "UTTARA PHALGUNI/उत्तराफाल्गुनी", "HASTA/हस्त", "CHITRA/चित्रा", "SWATI/स्वाति", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूल", "PURVASHADA/पूर्वाषाढ़", "UTTARASHADA/उत्तराषाढ़", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/शुक्ल पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            name: "KRISHNA PAKSHA/कृष्ण पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]

                        }
                    ]
                },
                {
                    name: "MARGASIRSA/मार्गशीर्ष",
                    nakshathira:
                        [
                            "ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशिरा", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/अश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वफाल्गुनी", "UTTARA PHALGUNI/उत्तराफाल्गुनी", "HASTA/हस्त", "CHITRA/चित्रा", "SWATI/स्वाति", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूल", "PURVASHADA/पूर्वाषाढ़", "UTTARASHADA/उत्तराषाढ़", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/शुक्ल पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            name: "KRISHNA PAKSHA/कृष्ण पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]

                        }
                    ]
                },

                {
                    name: "PAUSHA/पौष",
                    nakshathira:
                        [
                            "ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशिरा", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/अश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वफाल्गुनी", "UTTARA PHALGUNI/उत्तराफाल्गुनी", "HASTA/हस्त", "CHITRA/चित्रा", "SWATI/स्वाति", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूल", "PURVASHADA/पूर्वाषाढ़", "UTTARASHADA/उत्तराषाढ़", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/शुक्ल पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            name: "KRISHNA PAKSHA/कृष्ण पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]

                        }
                    ]
                },
                {
                    name: "MAGH/माघ",
                    nakshathira:
                        [
                            "ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशिरा", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/अश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वफाल्गुनी", "UTTARA PHALGUNI/उत्तराफाल्गुनी", "HASTA/हस्त", "CHITRA/चित्रा", "SWATI/स्वाति", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूल", "PURVASHADA/पूर्वाषाढ़", "UTTARASHADA/उत्तराषाढ़", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/शुक्ल पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            name: "KRISHNA PAKSHA/कृष्ण पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]

                        }
                    ]
                },
                {
                    name: "PHALGUNA/फाल्गुन",
                    nakshathira:
                        [
                            "ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशिरा", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/अश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वफाल्गुनी", "UTTARA PHALGUNI/उत्तराफाल्गुनी", "HASTA/हस्त", "CHITRA/चित्रा", "SWATI/स्वाति", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूल", "PURVASHADA/पूर्वाषाढ़", "UTTARASHADA/उत्तराषाढ़", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/शुक्ल पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            name: "KRISHNA PAKSHA/कृष्ण पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]

                        }
                    ]
                }
            ]
        },
        {
            name: "Gujarati",
            calenderCode: 6,
            month: [
                {
                    name: "CHAITRA/ચૈત્ર",
                    nakshathira: ["ASHWINI/અશ્વિની", "BHARANI/ભરણી", "KRITTIKA/કૃત્તિકા", "ROHINI/રોહિણી", "MRIGHASIRA/મૃગશીરા", "ARDRA/આર્દ્રા", "PUNARVASU/પુનર્વસુ", "PUSHYA/પુષ્ય", "ASHLESHA/અશ્લેષા", "MAGHA/મઘા", "PURVA PHALGUNI/પૂર્વ ફાલ્ગુની", "UTTARA PHALGUNI/ઉત્તર ફાલ્ગુની", "HASTA/હસ્ત", "CHITRA/ચિત્રા", "SWATI/સ્વાતિ", "VISHAKA/વિશાખા", "ANURADHA/અનુરાધા", "JYESTHA/જ્યેષ્ઠા", "MOOLA/મૂળ", "PURVASHADA/પૂર્વ શાડ", "UTTARASHADA/ઉત્તર શાડ", "SHRAVANA/શ્રાવણ", "DHANISHTA/ધનિષ્ઠા", "SHATABISHA/શતભિષા", "PURVABHADRA/પૂર્વભાદ્ર", "UTTARABHADRA/ઉત્તરભાદ્ર", "REVATI/રેવતી"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/શુક્લ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "PURNIMA/પૂર્ણિમા"],
                        },
                        {
                            name: "KRISHNA PAKSHA/કૃષ્ણ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "AMAVASYA/આમાસ્યા"]

                        }
                    ]




                },
                {
                    name: "VAISHAKHA/વૈશાખા",
                    nakshathira: ["ASHWINI/અશ્વિની", "BHARANI/ભરણી", "KRITTIKA/કૃત્તિકા", "ROHINI/રોહિણી", "MRIGHASIRA/મૃગશીરા", "ARDRA/આર્દ્રા", "PUNARVASU/પુનર્વસુ", "PUSHYA/પુષ્ય", "ASHLESHA/અશ્લેષા", "MAGHA/મઘા", "PURVA PHALGUNI/પૂર્વ ફાલ્ગુની", "UTTARA PHALGUNI/ઉત્તર ફાલ્ગુની", "HASTA/હસ્ત", "CHITRA/ચિત્રા", "SWATI/સ્વાતિ", "VISHAKA/વિશાખા", "ANURADHA/અનુરાધા", "JYESTHA/જ્યેષ્ઠા", "MOOLA/મૂળ", "PURVASHADA/પૂર્વ શાડ", "UTTARASHADA/ઉત્તર શાડ", "SHRAVANA/શ્રાવણ", "DHANISHTA/ધનિષ્ઠા", "SHATABISHA/શતભિષા", "PURVABHADRA/પૂર્વભાદ્ર", "UTTARABHADRA/ઉત્તરભાદ્ર", "REVATI/રેવતી"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/શુક્લ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "PURNIMA/પૂર્ણિમા"],
                        },
                        {
                            name: "KRISHNA PAKSHA/કૃષ્ણ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "AMAVASYA/આમાસ્યા"]

                        }
                    ]


                },
                {
                    name: "JETHA/જેઠા",
                    nakshathira: ["ASHWINI/અશ્વિની", "BHARANI/ભરણી", "KRITTIKA/કૃત્તિકા", "ROHINI/રોહિણી", "MRIGHASIRA/મૃગશીરા", "ARDRA/આર્દ્રા", "PUNARVASU/પુનર્વસુ", "PUSHYA/પુષ્ય", "ASHLESHA/અશ્લેષા", "MAGHA/મઘા", "PURVA PHALGUNI/પૂર્વ ફાલ્ગુની", "UTTARA PHALGUNI/ઉત્તર ફાલ્ગુની", "HASTA/હસ્ત", "CHITRA/ચિત્રા", "SWATI/સ્વાતિ", "VISHAKA/વિશાખા", "ANURADHA/અનુરાધા", "JYESTHA/જ્યેષ્ઠા", "MOOLA/મૂળ", "PURVASHADA/પૂર્વ શાડ", "UTTARASHADA/ઉત્તર શાડ", "SHRAVANA/શ્રાવણ", "DHANISHTA/ધનિષ્ઠા", "SHATABISHA/શતભિષા", "PURVABHADRA/પૂર્વભાદ્ર", "UTTARABHADRA/ઉત્તરભાદ્ર", "REVATI/રેવતી"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/શુક્લ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "PURNIMA/પૂર્ણિમા"],
                        },
                        {
                            name: "KRISHNA PAKSHA/કૃષ્ણ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "AMAVASYA/આમાસ્યા"]

                        }
                    ]


                },
                // {
                //     name: "SHRAVANA adhik/શ્રાવણ",
                //     nakshathira: ["ASHWINI/અશ્વિની", "BHARANI/ભરણી", "KRITTIKA/કૃત્તિકા", "ROHINI/રોહિણી", "MRIGHASIRA/મૃગશીરા", "ARDRA/આર્દ્રા", "PUNARVASU/પુનર્વસુ", "PUSHYA/પુષ્ય", "ASHLESHA/અશ્લેષા", "MAGHA/મઘા", "PURVA PHALGUNI/પૂર્વ ફાલ્ગુની", "UTTARA PHALGUNI/ઉત્તર ફાલ્ગુની", "HASTA/હસ્ત", "CHITRA/ચિત્રા", "SWATI/સ્વાતિ", "VISHAKA/વિશાખા", "ANURADHA/અનુરાધા", "JYESTHA/જ્યેષ્ઠા", "MOOLA/મૂળ", "PURVASHADA/પૂર્વ શાડ", "UTTARASHADA/ઉત્તર શાડ", "SHRAVANA/શ્રાવણ", "DHANISHTA/ધનિષ્ઠા", "SHATABISHA/શતભિષા", "PURVABHADRA/પૂર્વભાદ્ર", "UTTARABHADRA/ઉત્તરભાદ્ર", "REVATI/રેવતી"],
                //     Paksham: [
                //         {
                //             name: "SHUKLA PAKSHA/શુક્લ પક્ષ",
                //             tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "PURNIMA/પૂર્ણિમા"],
                //         },
                //         {
                //             name: "KRISHNA PAKSHA/કૃષ્ણ પક્ષ",
                //             tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "AMAVASYA/આમાસ્યા"]

                //         }
                //     ]


                // },
                {
                    name: "SHRAVANA/શ્રાવણ",
                    nakshathira: ["ASHWINI/અશ્વિની", "BHARANI/ભરણી", "KRITTIKA/કૃત્તિકા", "ROHINI/રોહિણી", "MRIGHASIRA/મૃગશીરા", "ARDRA/આર્દ્રા", "PUNARVASU/પુનર્વસુ", "PUSHYA/પુષ્ય", "ASHLESHA/અશ્લેષા", "MAGHA/મઘા", "PURVA PHALGUNI/પૂર્વ ફાલ્ગુની", "UTTARA PHALGUNI/ઉત્તર ફાલ્ગુની", "HASTA/હસ્ત", "CHITRA/ચિત્રા", "SWATI/સ્વાતિ", "VISHAKA/વિશાખા", "ANURADHA/અનુરાધા", "JYESTHA/જ્યેષ્ઠા", "MOOLA/મૂળ", "PURVASHADA/પૂર્વ શાડ", "UTTARASHADA/ઉત્તર શાડ", "SHRAVANA/શ્રાવણ", "DHANISHTA/ધનિષ્ઠા", "SHATABISHA/શતભિષા", "PURVABHADRA/પૂર્વભાદ્ર", "UTTARABHADRA/ઉત્તરભાદ્ર", "REVATI/રેવતી"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/શુક્લ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "PURNIMA/પૂર્ણિમા"],
                        },
                        {
                            name: "KRISHNA PAKSHA/કૃષ્ણ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "AMAVASYA/આમાસ્યા"]

                        }
                    ]


                },
                {
                    name: "BHADRAVO/ભાદરવો",
                    nakshathira: ["ASHWINI/અશ્વિની", "BHARANI/ભરણી", "KRITTIKA/કૃત્તિકા", "ROHINI/રોહિણી", "MRIGHASIRA/મૃગશીરા", "ARDRA/આર્દ્રા", "PUNARVASU/પુનર્વસુ", "PUSHYA/પુષ્ય", "ASHLESHA/અશ્લેષા", "MAGHA/મઘા", "PURVA PHALGUNI/પૂર્વ ફાલ્ગુની", "UTTARA PHALGUNI/ઉત્તર ફાલ્ગુની", "HASTA/હસ્ત", "CHITRA/ચિત્રા", "SWATI/સ્વાતિ", "VISHAKA/વિશાખા", "ANURADHA/અનુરાધા", "JYESTHA/જ્યેષ્ઠા", "MOOLA/મૂળ", "PURVASHADA/પૂર્વ શાડ", "UTTARASHADA/ઉત્તર શાડ", "SHRAVANA/શ્રાવણ", "DHANISHTA/ધનિષ્ઠા", "SHATABISHA/શતભિષા", "PURVABHADRA/પૂર્વભાદ્ર", "UTTARABHADRA/ઉત્તરભાદ્ર", "REVATI/રેવતી"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/શુક્લ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "PURNIMA/પૂર્ણિમા"],
                        },
                        {
                            name: "KRISHNA PAKSHA/કૃષ્ણ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "AMAVASYA/આમાસ્યા"]

                        }
                    ]


                },
                {
                    name: "ASO/આસો",
                    nakshathira: ["ASHWINI/અશ્વિની", "BHARANI/ભરણી", "KRITTIKA/કૃત્તિકા", "ROHINI/રોહિણી", "MRIGHASIRA/મૃગશીરા", "ARDRA/આર્દ્રા", "PUNARVASU/પુનર્વસુ", "PUSHYA/પુષ્ય", "ASHLESHA/અશ્લેષા", "MAGHA/મઘા", "PURVA PHALGUNI/પૂર્વ ફાલ્ગુની", "UTTARA PHALGUNI/ઉત્તર ફાલ્ગુની", "HASTA/હસ્ત", "CHITRA/ચિત્રા", "SWATI/સ્વાતિ", "VISHAKA/વિશાખા", "ANURADHA/અનુરાધા", "JYESTHA/જ્યેષ્ઠા", "MOOLA/મૂળ", "PURVASHADA/પૂર્વ શાડ", "UTTARASHADA/ઉત્તર શાડ", "SHRAVANA/શ્રાવણ", "DHANISHTA/ધનિષ્ઠા", "SHATABISHA/શતભિષા", "PURVABHADRA/પૂર્વભાદ્ર", "UTTARABHADRA/ઉત્તરભાદ્ર", "REVATI/રેવતી"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/શુક્લ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "PURNIMA/પૂર્ણિમા"],
                        },
                        {
                            name: "KRISHNA PAKSHA/કૃષ્ણ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "AMAVASYA/આમાસ્યા"]

                        }
                    ]


                },
                {
                    name: "KARKAT/કારતક",
                    nakshathira: ["ASHWINI/અશ્વિની", "BHARANI/ભરણી", "KRITTIKA/કૃત્તિકા", "ROHINI/રોહિણી", "MRIGHASIRA/મૃગશીરા", "ARDRA/આર્દ્રા", "PUNARVASU/પુનર્વસુ", "PUSHYA/પુષ્ય", "ASHLESHA/અશ્લેષા", "MAGHA/મઘા", "PURVA PHALGUNI/પૂર્વ ફાલ્ગુની", "UTTARA PHALGUNI/ઉત્તર ફાલ્ગુની", "HASTA/હસ્ત", "CHITRA/ચિત્રા", "SWATI/સ્વાતિ", "VISHAKA/વિશાખા", "ANURADHA/અનુરાધા", "JYESTHA/જ્યેષ્ઠા", "MOOLA/મૂળ", "PURVASHADA/પૂર્વ શાડ", "UTTARASHADA/ઉત્તર શાડ", "SHRAVANA/શ્રાવણ", "DHANISHTA/ધનિષ્ઠા", "SHATABISHA/શતભિષા", "PURVABHADRA/પૂર્વભાદ્ર", "UTTARABHADRA/ઉત્તરભાદ્ર", "REVATI/રેવતી"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/શુક્લ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "PURNIMA/પૂર્ણિમા"],
                        },
                        {
                            name: "KRISHNA PAKSHA/કૃષ્ણ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "AMAVASYA/આમાસ્યા"]

                        }
                    ]


                },
                {
                    name: "MAGSHAR/માગશર",
                    nakshathira: ["ASHWINI/અશ્વિની", "BHARANI/ભરણી", "KRITTIKA/કૃત્તિકા", "ROHINI/રોહિણી", "MRIGHASIRA/મૃગશીરા", "ARDRA/આર્દ્રા", "PUNARVASU/પુનર્વસુ", "PUSHYA/પુષ્ય", "ASHLESHA/અશ્લેષા", "MAGHA/મઘા", "PURVA PHALGUNI/પૂર્વ ફાલ્ગુની", "UTTARA PHALGUNI/ઉત્તર ફાલ્ગુની", "HASTA/હસ્ત", "CHITRA/ચિત્રા", "SWATI/સ્વાતિ", "VISHAKA/વિશાખા", "ANURADHA/અનુરાધા", "JYESTHA/જ્યેષ્ઠા", "MOOLA/મૂળ", "PURVASHADA/પૂર્વ શાડ", "UTTARASHADA/ઉત્તર શાડ", "SHRAVANA/શ્રાવણ", "DHANISHTA/ધનિષ્ઠા", "SHATABISHA/શતભિષા", "PURVABHADRA/પૂર્વભાદ્ર", "UTTARABHADRA/ઉત્તરભાદ્ર", "REVATI/રેવતી"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/શુક્લ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "PURNIMA/પૂર્ણિમા"],
                        },
                        {
                            name: "KRISHNA PAKSHA/કૃષ્ણ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "AMAVASYA/આમાસ્યા"]

                        }
                    ]


                },
                {
                    name: "POSH/પોષ",
                    nakshathira: ["ASHWINI/અશ્વિની", "BHARANI/ભરણી", "KRITTIKA/કૃત્તિકા", "ROHINI/રોહિણી", "MRIGHASIRA/મૃગશીરા", "ARDRA/આર્દ્રા", "PUNARVASU/પુનર્વસુ", "PUSHYA/પુષ્ય", "ASHLESHA/અશ્લેષા", "MAGHA/મઘા", "PURVA PHALGUNI/પૂર્વ ફાલ્ગુની", "UTTARA PHALGUNI/ઉત્તર ફાલ્ગુની", "HASTA/હસ્ત", "CHITRA/ચિત્રા", "SWATI/સ્વાતિ", "VISHAKA/વિશાખા", "ANURADHA/અનુરાધા", "JYESTHA/જ્યેષ્ઠા", "MOOLA/મૂળ", "PURVASHADA/પૂર્વ શાડ", "UTTARASHADA/ઉત્તર શાડ", "SHRAVANA/શ્રાવણ", "DHANISHTA/ધનિષ્ઠા", "SHATABISHA/શતભિષા", "PURVABHADRA/પૂર્વભાદ્ર", "UTTARABHADRA/ઉત્તરભાદ્ર", "REVATI/રેવતી"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/શુક્લ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "PURNIMA/પૂર્ણિમા"],
                        },
                        {
                            name: "KRISHNA PAKSHA/કૃષ્ણ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "AMAVASYA/આમાસ્યા"]

                        }
                    ]


                },
                {
                    name: "MAHA/મહા",
                    nakshathira: ["ASHWINI/અશ્વિની", "BHARANI/ભરણી", "KRITTIKA/કૃત્તિકા", "ROHINI/રોહિણી", "MRIGHASIRA/મૃગશીરા", "ARDRA/આર્દ્રા", "PUNARVASU/પુનર્વસુ", "PUSHYA/પુષ્ય", "ASHLESHA/અશ્લેષા", "MAGHA/મઘા", "PURVA PHALGUNI/પૂર્વ ફાલ્ગુની", "UTTARA PHALGUNI/ઉત્તર ફાલ્ગુની", "HASTA/હસ્ત", "CHITRA/ચિત્રા", "SWATI/સ્વાતિ", "VISHAKA/વિશાખા", "ANURADHA/અનુરાધા", "JYESTHA/જ્યેષ્ઠા", "MOOLA/મૂળ", "PURVASHADA/પૂર્વ શાડ", "UTTARASHADA/ઉત્તર શાડ", "SHRAVANA/શ્રાવણ", "DHANISHTA/ધનિષ્ઠા", "SHATABISHA/શતભિષા", "PURVABHADRA/પૂર્વભાદ્ર", "UTTARABHADRA/ઉત્તરભાદ્ર", "REVATI/રેવતી"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/શુક્લ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "PURNIMA/પૂર્ણિમા"],
                        },
                        {
                            name: "KRISHNA PAKSHA/કૃષ્ણ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "AMAVASYA/આમાસ્યા"]

                        }
                    ]


                },
                {
                    name: "PHAGUNA/ફાગુના",
                    nakshathira: ["ASHWINI/અશ્વિની", "BHARANI/ભરણી", "KRITTIKA/કૃત્તિકા", "ROHINI/રોહિણી", "MRIGHASIRA/મૃગશીરા", "ARDRA/આર્દ્રા", "PUNARVASU/પુનર્વસુ", "PUSHYA/પુષ્ય", "ASHLESHA/અશ્લેષા", "MAGHA/મઘા", "PURVA PHALGUNI/પૂર્વ ફાલ્ગુની", "UTTARA PHALGUNI/ઉત્તર ફાલ્ગુની", "HASTA/હસ્ત", "CHITRA/ચિત્રા", "SWATI/સ્વાતિ", "VISHAKA/વિશાખા", "ANURADHA/અનુરાધા", "JYESTHA/જ્યેષ્ઠા", "MOOLA/મૂળ", "PURVASHADA/પૂર્વ શાડ", "UTTARASHADA/ઉત્તર શાડ", "SHRAVANA/શ્રાવણ", "DHANISHTA/ધનિષ્ઠા", "SHATABISHA/શતભિષા", "PURVABHADRA/પૂર્વભાદ્ર", "UTTARABHADRA/ઉત્તરભાદ્ર", "REVATI/રેવતી"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/શુક્લ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "PURNIMA/પૂર્ણિમા"],
                        },
                        {
                            name: "KRISHNA PAKSHA/કૃષ્ણ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "AMAVASYA/આમાસ્યા"]

                        }
                    ]


                },

            ]
        },
        {
            name: "Bengali",
            calenderCode: 7,
            month: [
                {
                    name: "BOISHAKH/বৈশাখ",
                    nakshathira: ["ASHWINI/অশ্বিনী", "BHARANI/ভরণী", "KRITTIKA/কৃত্তিকা", "ROHINI/রোহিণী", "MRIGHASIRA/মৃগশিরা", "ARDRA/আর্দ্রা", "PUNARVASU/পুনর্বসু", "PUSHYA/পুষ্য", "ASHLESHA/অশ্লেষা", "MAGHA/মঘা", "PURVA PHALGUNI/পূর্ব ফাল্গুনী", "UTTARA PHALGUNI/উত্তর ফাল্গুনী", "HASTA/হস্ত", "CHITRA/চিত্রা", "SWATI/স্বাতী", "VISHAKA/বিশাখা", "ANURADHA/অনুরাধা", "JYESTHA/জ্যেষ্ঠা", "MOOLA/মূল", "PURVASHADA/পূর্বষাঢ়", "UTTARASHADA/উত্তরষাঢ়", "SHARAVAN/শ্রাবণ", "DHANISHTA/ধনিষ্ঠা", "SHATABISHA/শতভিষা", "PURVABHADRA/পূর্বভাদ্র", "UTTARABHADRA/উত্তরভাদ্র", "REVATI/রেবতী"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/শুক্ল পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "PURNIMA/পূর্ণিমা"],
                        },
                        {
                            name: "KRISHNA PAKSHA/কৃষ্ণ পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "AMAVASYA/অমাবস্যা"]

                        }
                    ]



                },
                {
                    name: "JOISHTHO/জ্যৈষ্ঠ",
                    nakshathira: ["ASHWINI/অশ্বিনী", "BHARANI/ভরণী", "KRITTIKA/কৃত্তিকা", "ROHINI/রোহিণী", "MRIGHASIRA/মৃগশিরা", "ARDRA/আর্দ্রা", "PUNARVASU/পুনর্বসু", "PUSHYA/পুষ্য", "ASHLESHA/অশ্লেষা", "MAGHA/মঘা", "PURVA PHALGUNI/পূর্ব ফাল্গুনী", "UTTARA PHALGUNI/উত্তর ফাল্গুনী", "HASTA/হস্ত", "CHITRA/চিত্রা", "SWATI/স্বাতী", "VISHAKA/বিশাখা", "ANURADHA/অনুরাধা", "JYESTHA/জ্যেষ্ঠা", "MOOLA/মূল", "PURVASHADA/পূর্বষাঢ়", "UTTARASHADA/উত্তরষাঢ়", "SHARAVAN/শ্রাবণ", "DHANISHTA/ধনিষ্ঠা", "SHATABISHA/শতভিষা", "PURVABHADRA/পূর্বভাদ্র", "UTTARABHADRA/উত্তরভাদ্র", "REVATI/রেবতী"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/শুক্ল পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "PURNIMA/পূর্ণিমা"],
                        },
                        {
                            name: "KRISHNA PAKSHA/কৃষ্ণ পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "AMAVASYA/অমাবস্যা"]

                        }
                    ]
                },
                {
                    name: "ASHARH/আষাঢ়",
                    nakshathira: ["ASHWINI/অশ্বিনী", "BHARANI/ভরণী", "KRITTIKA/কৃত্তিকা", "ROHINI/রোহিণী", "MRIGHASIRA/মৃগশিরা", "ARDRA/আর্দ্রা", "PUNARVASU/পুনর্বসু", "PUSHYA/পুষ্য", "ASHLESHA/অশ্লেষা", "MAGHA/মঘা", "PURVA PHALGUNI/পূর্ব ফাল্গুনী", "UTTARA PHALGUNI/উত্তর ফাল্গুনী", "HASTA/হস্ত", "CHITRA/চিত্রা", "SWATI/স্বাতী", "VISHAKA/বিশাখা", "ANURADHA/অনুরাধা", "JYESTHA/জ্যেষ্ঠা", "MOOLA/মূল", "PURVASHADA/পূর্বষাঢ়", "UTTARASHADA/উত্তরষাঢ়", "SHARAVAN/শ্রাবণ", "DHANISHTA/ধনিষ্ঠা", "SHATABISHA/শতভিষা", "PURVABHADRA/পূর্বভাদ্র", "UTTARABHADRA/উত্তরভাদ্র", "REVATI/রেবতী"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/শুক্ল পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "PURNIMA/পূর্ণিমা"],
                        },
                        {
                            name: "KRISHNA PAKSHA/কৃষ্ণ পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "AMAVASYA/অমাবস্যা"]

                        }
                    ]
                },
                {
                    name: "SHRABON/শ্রাবণ",
                    nakshathira: ["ASHWINI/অশ্বিনী", "BHARANI/ভরণী", "KRITTIKA/কৃত্তিকা", "ROHINI/রোহিণী", "MRIGHASIRA/মৃগশিরা", "ARDRA/আর্দ্রা", "PUNARVASU/পুনর্বসু", "PUSHYA/পুষ্য", "ASHLESHA/অশ্লেষা", "MAGHA/মঘা", "PURVA PHALGUNI/পূর্ব ফাল্গুনী", "UTTARA PHALGUNI/উত্তর ফাল্গুনী", "HASTA/হস্ত", "CHITRA/চিত্রা", "SWATI/স্বাতী", "VISHAKA/বিশাখা", "ANURADHA/অনুরাধা", "JYESTHA/জ্যেষ্ঠা", "MOOLA/মূল", "PURVASHADA/পূর্বষাঢ়", "UTTARASHADA/উত্তরষাঢ়", "SHARAVAN/শ্রাবণ", "DHANISHTA/ধনিষ্ঠা", "SHATABISHA/শতভিষা", "PURVABHADRA/পূর্বভাদ্র", "UTTARABHADRA/উত্তরভাদ্র", "REVATI/রেবতী"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/শুক্ল পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "PURNIMA/পূর্ণিমা"],
                        },
                        {
                            name: "KRISHNA PAKSHA/কৃষ্ণ পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "AMAVASYA/অমাবস্যা"]

                        }
                    ]
                },
                {
                    name: "BHADRO/ভাদ্র",
                    nakshathira: ["ASHWINI/অশ্বিনী", "BHARANI/ভরণী", "KRITTIKA/কৃত্তিকা", "ROHINI/রোহিণী", "MRIGHASIRA/মৃগশিরা", "ARDRA/আর্দ্রা", "PUNARVASU/পুনর্বসু", "PUSHYA/পুষ্য", "ASHLESHA/অশ্লেষা", "MAGHA/মঘা", "PURVA PHALGUNI/পূর্ব ফাল্গুনী", "UTTARA PHALGUNI/উত্তর ফাল্গুনী", "HASTA/হস্ত", "CHITRA/চিত্রা", "SWATI/স্বাতী", "VISHAKA/বিশাখা", "ANURADHA/অনুরাধা", "JYESTHA/জ্যেষ্ঠা", "MOOLA/মূল", "PURVASHADA/পূর্বষাঢ়", "UTTARASHADA/উত্তরষাঢ়", "SHARAVAN/শ্রাবণ", "DHANISHTA/ধনিষ্ঠা", "SHATABISHA/শতভিষা", "PURVABHADRA/পূর্বভাদ্র", "UTTARABHADRA/উত্তরভাদ্র", "REVATI/রেবতী"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/শুক্ল পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "PURNIMA/পূর্ণিমা"],
                        },
                        {
                            name: "KRISHNA PAKSHA/কৃষ্ণ পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "AMAVASYA/অমাবস্যা"]

                        }
                    ]
                },
                {
                    name: "ASHSHIN/আশ্বিন",
                    nakshathira: ["ASHWINI/অশ্বিনী", "BHARANI/ভরণী", "KRITTIKA/কৃত্তিকা", "ROHINI/রোহিণী", "MRIGHASIRA/মৃগশিরা", "ARDRA/আর্দ্রা", "PUNARVASU/পুনর্বসু", "PUSHYA/পুষ্য", "ASHLESHA/অশ্লেষা", "MAGHA/মঘা", "PURVA PHALGUNI/পূর্ব ফাল্গুনী", "UTTARA PHALGUNI/উত্তর ফাল্গুনী", "HASTA/হস্ত", "CHITRA/চিত্রা", "SWATI/স্বাতী", "VISHAKA/বিশাখা", "ANURADHA/অনুরাধা", "JYESTHA/জ্যেষ্ঠা", "MOOLA/মূল", "PURVASHADA/পূর্বষাঢ়", "UTTARASHADA/উত্তরষাঢ়", "SHARAVAN/শ্রাবণ", "DHANISHTA/ধনিষ্ঠা", "SHATABISHA/শতভিষা", "PURVABHADRA/পূর্বভাদ্র", "UTTARABHADRA/উত্তরভাদ্র", "REVATI/রেবতী"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/শুক্ল পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "PURNIMA/পূর্ণিমা"],
                        },
                        {
                            name: "KRISHNA PAKSHA/কৃষ্ণ পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "AMAVASYA/অমাবস্যা"]

                        }
                    ]
                },
                {
                    name: "KARTIK/কার্তিক",
                    nakshathira: ["ASHWINI/অশ্বিনী", "BHARANI/ভরণী", "KRITTIKA/কৃত্তিকা", "ROHINI/রোহিণী", "MRIGHASIRA/মৃগশিরা", "ARDRA/আর্দ্রা", "PUNARVASU/পুনর্বসু", "PUSHYA/পুষ্য", "ASHLESHA/অশ্লেষা", "MAGHA/মঘা", "PURVA PHALGUNI/পূর্ব ফাল্গুনী", "UTTARA PHALGUNI/উত্তর ফাল্গুনী", "HASTA/হস্ত", "CHITRA/চিত্রা", "SWATI/স্বাতী", "VISHAKA/বিশাখা", "ANURADHA/অনুরাধা", "JYESTHA/জ্যেষ্ঠা", "MOOLA/মূল", "PURVASHADA/পূর্বষাঢ়", "UTTARASHADA/উত্তরষাঢ়", "SHARAVAN/শ্রাবণ", "DHANISHTA/ধনিষ্ঠা", "SHATABISHA/শতভিষা", "PURVABHADRA/পূর্বভাদ্র", "UTTARABHADRA/উত্তরভাদ্র", "REVATI/রেবতী"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/শুক্ল পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "PURNIMA/পূর্ণিমা"],
                        },
                        {
                            name: "KRISHNA PAKSHA/কৃষ্ণ পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "AMAVASYA/অমাবস্যা"]

                        }
                    ]
                },
                {
                    name: "AGRAHAYAN/অগ্রহায়ণ",
                    nakshathira: ["ASHWINI/অশ্বিনী", "BHARANI/ভরণী", "KRITTIKA/কৃত্তিকা", "ROHINI/রোহিণী", "MRIGHASIRA/মৃগশিরা", "ARDRA/আর্দ্রা", "PUNARVASU/পুনর্বসু", "PUSHYA/পুষ্য", "ASHLESHA/অশ্লেষা", "MAGHA/মঘা", "PURVA PHALGUNI/পূর্ব ফাল্গুনী", "UTTARA PHALGUNI/উত্তর ফাল্গুনী", "HASTA/হস্ত", "CHITRA/চিত্রা", "SWATI/স্বাতী", "VISHAKA/বিশাখা", "ANURADHA/অনুরাধা", "JYESTHA/জ্যেষ্ঠা", "MOOLA/মূল", "PURVASHADA/পূর্বষাঢ়", "UTTARASHADA/উত্তরষাঢ়", "SHARAVAN/শ্রাবণ", "DHANISHTA/ধনিষ্ঠা", "SHATABISHA/শতভিষা", "PURVABHADRA/পূর্বভাদ্র", "UTTARABHADRA/উত্তরভাদ্র", "REVATI/রেবতী"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/শুক্ল পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "PURNIMA/পূর্ণিমা"],
                        },
                        {
                            name: "KRISHNA PAKSHA/কৃষ্ণ পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "AMAVASYA/অমাবস্যা"]

                        }
                    ]
                },
                {
                    name: "POUSH/পৌষ",
                    nakshathira: ["ASHWINI/অশ্বিনী", "BHARANI/ভরণী", "KRITTIKA/কৃত্তিকা", "ROHINI/রোহিণী", "MRIGHASIRA/মৃগশিরা", "ARDRA/আর্দ্রা", "PUNARVASU/পুনর্বসু", "PUSHYA/পুষ্য", "ASHLESHA/অশ্লেষা", "MAGHA/মঘা", "PURVA PHALGUNI/পূর্ব ফাল্গুনী", "UTTARA PHALGUNI/উত্তর ফাল্গুনী", "HASTA/হস্ত", "CHITRA/চিত্রা", "SWATI/স্বাতী", "VISHAKA/বিশাখা", "ANURADHA/অনুরাধা", "JYESTHA/জ্যেষ্ঠা", "MOOLA/মূল", "PURVASHADA/পূর্বষাঢ়", "UTTARASHADA/উত্তরষাঢ়", "SHARAVAN/শ্রাবণ", "DHANISHTA/ধনিষ্ঠা", "SHATABISHA/শতভিষা", "PURVABHADRA/পূর্বভাদ্র", "UTTARABHADRA/উত্তরভাদ্র", "REVATI/রেবতী"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/শুক্ল পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "PURNIMA/পূর্ণিমা"],
                        },
                        {
                            name: "KRISHNA PAKSHA/কৃষ্ণ পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "AMAVASYA/অমাবস্যা"]

                        }
                    ]
                },
                {
                    name: "MAGH/মাঘ",
                    nakshathira: ["ASHWINI/অশ্বিনী", "BHARANI/ভরণী", "KRITTIKA/কৃত্তিকা", "ROHINI/রোহিণী", "MRIGHASIRA/মৃগশিরা", "ARDRA/আর্দ্রা", "PUNARVASU/পুনর্বসু", "PUSHYA/পুষ্য", "ASHLESHA/অশ্লেষা", "MAGHA/মঘা", "PURVA PHALGUNI/পূর্ব ফাল্গুনী", "UTTARA PHALGUNI/উত্তর ফাল্গুনী", "HASTA/হস্ত", "CHITRA/চিত্রা", "SWATI/স্বাতী", "VISHAKA/বিশাখা", "ANURADHA/অনুরাধা", "JYESTHA/জ্যেষ্ঠা", "MOOLA/মূল", "PURVASHADA/পূর্বষাঢ়", "UTTARASHADA/উত্তরষাঢ়", "SHARAVAN/শ্রাবণ", "DHANISHTA/ধনিষ্ঠা", "SHATABISHA/শতভিষা", "PURVABHADRA/পূর্বভাদ্র", "UTTARABHADRA/উত্তরভাদ্র", "REVATI/রেবতী"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/শুক্ল পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "PURNIMA/পূর্ণিমা"],
                        },
                        {
                            name: "KRISHNA PAKSHA/কৃষ্ণ পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "AMAVASYA/অমাবস্যা"]

                        }
                    ]
                },
                {
                    name: "FALGUN/ফাল্গুন",
                    nakshathira: ["ASHWINI/অশ্বিনী", "BHARANI/ভরণী", "KRITTIKA/কৃত্তিকা", "ROHINI/রোহিণী", "MRIGHASIRA/মৃগশিরা", "ARDRA/আর্দ্রা", "PUNARVASU/পুনর্বসু", "PUSHYA/পুষ্য", "ASHLESHA/অশ্লেষা", "MAGHA/মঘা", "PURVA PHALGUNI/পূর্ব ফাল্গুনী", "UTTARA PHALGUNI/উত্তর ফাল্গুনী", "HASTA/হস্ত", "CHITRA/চিত্রা", "SWATI/স্বাতী", "VISHAKA/বিশাখা", "ANURADHA/অনুরাধা", "JYESTHA/জ্যেষ্ঠা", "MOOLA/মূল", "PURVASHADA/পূর্বষাঢ়", "UTTARASHADA/উত্তরষাঢ়", "SHARAVAN/শ্রাবণ", "DHANISHTA/ধনিষ্ঠা", "SHATABISHA/শতভিষা", "PURVABHADRA/পূর্বভাদ্র", "UTTARABHADRA/উত্তরভাদ্র", "REVATI/রেবতী"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/শুক্ল পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "PURNIMA/পূর্ণিমা"],
                        },
                        {
                            name: "KRISHNA PAKSHA/কৃষ্ণ পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "AMAVASYA/অমাবস্যা"]

                        }
                    ]
                },
                {
                    name: "CHAITRA/চৈত্র",
                    nakshathira: ["ASHWINI/অশ্বিনী", "BHARANI/ভরণী", "KRITTIKA/কৃত্তিকা", "ROHINI/রোহিণী", "MRIGHASIRA/মৃগশিরা", "ARDRA/আর্দ্রা", "PUNARVASU/পুনর্বসু", "PUSHYA/পুষ্য", "ASHLESHA/অশ্লেষা", "MAGHA/মঘা", "PURVA PHALGUNI/পূর্ব ফাল্গুনী", "UTTARA PHALGUNI/উত্তর ফাল্গুনী", "HASTA/হস্ত", "CHITRA/চিত্রা", "SWATI/স্বাতী", "VISHAKA/বিশাখা", "ANURADHA/অনুরাধা", "JYESTHA/জ্যেষ্ঠা", "MOOLA/মূল", "PURVASHADA/পূর্বষাঢ়", "UTTARASHADA/উত্তরষাঢ়", "SHARAVAN/শ্রাবণ", "DHANISHTA/ধনিষ্ঠা", "SHATABISHA/শতভিষা", "PURVABHADRA/পূর্বভাদ্র", "UTTARABHADRA/উত্তরভাদ্র", "REVATI/রেবতী"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/শুক্ল পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "PURNIMA/পূর্ণিমা"],
                        },
                        {
                            name: "KRISHNA PAKSHA/কৃষ্ণ পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "AMAVASYA/অমাবস্যা"]

                        }
                    ]
                },

            ]
        },
        {
            name: "Marathi",
            calenderCode: 8,
            month: [
                {
                    name: "CHAITRA/चैत्र",
                    nakshathira: ["ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशीर्ष", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/आश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वा फाल्गुनी", "UTTARA PHALGUNI/उत्तर फाल्गुनी", "HASTA/हस्ता", "CHITRA/चित्रा", "SWATI/स्वाती", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूळा", "PURVASHADA/पूर्वाषाढा", "UTTARASHADA/उत्तराषाढा", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वाभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            "name": "SHUKLA PAKSHA/शुक्ल पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            "name": "KRISHNA PAKSHA/कृष्ण पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]
                        }
                    ]
                },
                {
                    name: "VAISAKHA/वैशाख",
                    nakshathira: ["ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशीर्ष", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/आश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वा फाल्गुनी", "UTTARA PHALGUNI/उत्तर फाल्गुनी", "HASTA/हस्ता", "CHITRA/चित्रा", "SWATI/स्वाती", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूळा", "PURVASHADA/पूर्वाषाढा", "UTTARASHADA/उत्तराषाढा", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वाभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            "name": "SHUKLA PAKSHA/शुक्ल पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            "name": "KRISHNA PAKSHA/कृष्ण पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]
                        }
                    ]
                },
                {
                    name: "JYESTHA/ज्येष्ठा",
                    nakshathira: ["ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशीर्ष", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/आश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वा फाल्गुनी", "UTTARA PHALGUNI/उत्तर फाल्गुनी", "HASTA/हस्ता", "CHITRA/चित्रा", "SWATI/स्वाती", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूळा", "PURVASHADA/पूर्वाषाढा", "UTTARASHADA/उत्तराषाढा", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वाभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            "name": "SHUKLA PAKSHA/शुक्ल पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            "name": "KRISHNA PAKSHA/कृष्ण पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]
                        }
                    ]
                },
                {
                    name: "ASHADHA/आषाढ़",
                    nakshathira: ["ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशीर्ष", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/आश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वा फाल्गुनी", "UTTARA PHALGUNI/उत्तर फाल्गुनी", "HASTA/हस्ता", "CHITRA/चित्रा", "SWATI/स्वाती", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूळा", "PURVASHADA/पूर्वाषाढा", "UTTARASHADA/उत्तराषाढा", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वाभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            "name": "SHUKLA PAKSHA/शुक्ल पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            "name": "KRISHNA PAKSHA/कृष्ण पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]
                        }
                    ]
                },
                {
                    name: "SHRAVANA/श्रावण",
                    nakshathira: ["ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशीर्ष", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/आश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वा फाल्गुनी", "UTTARA PHALGUNI/उत्तर फाल्गुनी", "HASTA/हस्ता", "CHITRA/चित्रा", "SWATI/स्वाती", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूळा", "PURVASHADA/पूर्वाषाढा", "UTTARASHADA/उत्तराषाढा", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वाभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            "name": "SHUKLA PAKSHA/शुक्ल पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            "name": "KRISHNA PAKSHA/कृष्ण पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]
                        }
                    ]
                },
                {
                    name: "BHADRAPADA/भाद्रपद",
                    nakshathira: ["ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशीर्ष", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/आश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वा फाल्गुनी", "UTTARA PHALGUNI/उत्तर फाल्गुनी", "HASTA/हस्ता", "CHITRA/चित्रा", "SWATI/स्वाती", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूळा", "PURVASHADA/पूर्वाषाढा", "UTTARASHADA/उत्तराषाढा", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वाभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            "name": "SHUKLA PAKSHA/शुक्ल पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            "name": "KRISHNA PAKSHA/कृष्ण पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]
                        }
                    ]
                },
                {
                    name: "ASHVIN/अश्विन",
                    nakshathira: ["ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशीर्ष", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/आश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वा फाल्गुनी", "UTTARA PHALGUNI/उत्तर फाल्गुनी", "HASTA/हस्ता", "CHITRA/चित्रा", "SWATI/स्वाती", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूळा", "PURVASHADA/पूर्वाषाढा", "UTTARASHADA/उत्तराषाढा", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वाभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            "name": "SHUKLA PAKSHA/शुक्ल पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            "name": "KRISHNA PAKSHA/कृष्ण पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]
                        }
                    ]
                },
                {
                    name: "KATIKA/कार्तिक/चैत्र",
                    nakshathira: ["ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशीर्ष", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/आश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वा फाल्गुनी", "UTTARA PHALGUNI/उत्तर फाल्गुनी", "HASTA/हस्ता", "CHITRA/चित्रा", "SWATI/स्वाती", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूळा", "PURVASHADA/पूर्वाषाढा", "UTTARASHADA/उत्तराषाढा", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वाभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            "name": "SHUKLA PAKSHA/शुक्ल पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            "name": "KRISHNA PAKSHA/कृष्ण पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]
                        }
                    ]
                },
                {
                    name: "MARGASIRSA/मार्गशीर्ष",
                    nakshathira: ["ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशीर्ष", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/आश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वा फाल्गुनी", "UTTARA PHALGUNI/उत्तर फाल्गुनी", "HASTA/हस्ता", "CHITRA/चित्रा", "SWATI/स्वाती", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूळा", "PURVASHADA/पूर्वाषाढा", "UTTARASHADA/उत्तराषाढा", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वाभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            "name": "SHUKLA PAKSHA/शुक्ल पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            "name": "KRISHNA PAKSHA/कृष्ण पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]
                        }
                    ]
                },
                {
                    name: "PAUSHA/पौष",
                    nakshathira: ["ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशीर्ष", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/आश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वा फाल्गुनी", "UTTARA PHALGUNI/उत्तर फाल्गुनी", "HASTA/हस्ता", "CHITRA/चित्रा", "SWATI/स्वाती", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूळा", "PURVASHADA/पूर्वाषाढा", "UTTARASHADA/उत्तराषाढा", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वाभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            "name": "SHUKLA PAKSHA/शुक्ल पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            "name": "KRISHNA PAKSHA/कृष्ण पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]
                        }
                    ]
                },
                {
                    name: "MAGH/माघ",
                    nakshathira: ["ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशीर्ष", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/आश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वा फाल्गुनी", "UTTARA PHALGUNI/उत्तर फाल्गुनी", "HASTA/हस्ता", "CHITRA/चित्रा", "SWATI/स्वाती", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूळा", "PURVASHADA/पूर्वाषाढा", "UTTARASHADA/उत्तराषाढा", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वाभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            "name": "SHUKLA PAKSHA/शुक्ल पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            "name": "KRISHNA PAKSHA/कृष्ण पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]
                        }
                    ]
                },
                {
                    name: "PHALGUNA/फाल्गुन",
                    nakshathira: ["ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशीर्ष", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/आश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वा फाल्गुनी", "UTTARA PHALGUNI/उत्तर फाल्गुनी", "HASTA/हस्ता", "CHITRA/चित्रा", "SWATI/स्वाती", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूळा", "PURVASHADA/पूर्वाषाढा", "UTTARASHADA/उत्तराषाढा", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वाभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            "name": "SHUKLA PAKSHA/शुक्ल पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            "name": "KRISHNA PAKSHA/कृष्ण पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]
                        }
                    ]
                },
            ]
        }



    ]




    const handleCalender = (event) => {
        const calender = Calender.filter((cal, val) => {
            return cal.name == event.target.value
        });
        DynamicFunction({ ...dynamicState, [event.target.name]: event.target.value, calenderCode: calender[0].calenderCode })
        SetCalender(event.target.value)
        SetMonths(Calender.find(clr => clr.name === event.target.value).month)
    }

    const handleMonth = (event) => {
        DynamicFunction({ ...dynamicState, [event.target.name]: event.target.value })
        SetMonth(event.target.value)
        SetNakshathras(months.find(mth => mth.name === event.target.value).nakshathira)
        SetNakTithis(months.find(tth => tth.name === event.target.value).Paksham)

    }
    const handleNakTithi = (event) => {
        DynamicFunction({ ...dynamicState, [event.target.name]: event.target.value })
        SetNakTithi(event.target.value)
    }

    const handleNakshathra = (event) => {
        DynamicFunction({ ...dynamicState, [event.target.name]: event.target.value })
        SetNakshathra(event.target.value)
    }

    const handlePaksham = (event) => {
        DynamicFunction({ ...dynamicState, [event.target.name]: event.target.value })
        SetPaksham(event.target.value)
        SettithiName(nakTithis.find(pks => pks.name === event.target.value).tithiNames)
    }

    const handleTithi = (event) => {
        DynamicFunction({ ...dynamicState, [event.target.name]: event.target.value })
    }

    const handleInput = (event) => {

        DynamicFunction({ ...dynamicState, [event.target.name]: event.target.value })
    }

    const { userName, userEmail, userGender, userCalender, userMonth, userDateTime, nakshatraTithi, userNakshathra, userPaksham, userTithi } = dynamicState

    const signInHome = () => {

        // console.log(nakTithi);
        // console.log(dynamicState.nakshatraTithi);
        // console.log(dynamicState.userPaksham);
        // console.log(Pakshams);
        // console.log(tithiname);
        // console.log(dynamicState);

        const code = localStorage.getItem("code")



        setDynamicArray([...dynamicArray, { userName, userEmail, userGender, userCalender, userMonth, nakshatraTithi, userNakshathra, userPaksham, userTithi }])
        // context.globalFunction([...dynamicArray, dynamicState]);



        const URL = "https://backend.shubadinam.com/add/userprofile"

        axios.post(URL, { ...dynamicState, emailPass: JSON.parse(emailPass) })
            .then((response) => {
                const addUser = response.data
                let impId = addUser.insertedId;

                let secureId = addUser.secureId;

                const stringifyUserInfo = JSON.stringify(dynamicState.userEmail);
                localStorage.setItem("isAuthenticated", btoa(stringifyUserInfo));
                const stringifyForeignKey = JSON.stringify(dynamicState.userEmail);
                localStorage.setItem("fkey", btoa(stringifyForeignKey));
                localStorage.setItem("SD_Id", secureId);
                // console.log(addUser);
                addUserForm(impId)
            })
            .catch((error) => {
                // console.log(error);
            })


        const addUserForm = (impId) => {

            const sdId = localStorage.getItem("SD_Id");

            const URL = `https://backend.shubadinam.com/list/userprofile?userEmail=${dynamicState.userEmail}&userMonth=${dynamicState.userMonth}&nakshatraTithi=${dynamicState.nakshatraTithi}&userNakshathra=${dynamicState.userNakshathra}&userPaksham=${dynamicState.userPaksham}&userTithi=${dynamicState.userTithi}&userCalender=${dynamicState.userCalender}&impId=${impId}&secureId=${sdId}`;


            axios.get(URL)
                .then((response) => {
                    const getData = response.data
                    localStorage.setItem('myDataKey2', JSON.stringify(getData));

                    // const stringifyUserInfo = JSON.stringify(dynamicState.userEmail);
                    // localStorage.setItem("isAuthenticated", btoa(stringifyUserInfo));
                    // localStorage.setItem("email", dynamicState.userEmail);
                    // sessionStorage.removeItem("profileauth")
                    localStorage.removeItem("code")
                    localStorage.setItem('homeauth', 'true')
                    navigate("/dashboard_thankyou");

                    // setQueryResult(getData)
                    // console.log(getData)
                    context.globalFunction(getData)
                    localStorage.setItem('myDataKey2', JSON.stringify(getData));
                    window.location.reload();


                })
                .catch((error) => {
                    // console.log(error);
                })
        }

    }


    const stateSelectRef = useRef(null);
    const [autofilledState, setAutofilledState] = useState("");



    useEffect(() => {
        // console.log(dynamicArray)
    }, [dynamicArray]);

    const [isPremiumClicked, setPremiumClicked] = useState(false)

    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollY = window.scrollY || window.pageYOffset;
            const scrollPercent = (scrollY / window.innerHeight) * 100;

            if (scrollPercent > 10) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []); // Empty dependency array ensures that this effect runs only once

    const [isPremium, setPremium] = useState(true)


    const handleDateTime = (e) => {
        DynamicFunction({ ...dynamicState, [e.target.name]: e.target.value })
    }




    return (
        <div className="overall-container-profile">

            <ScrollToTop smooth />

            <div className="overall-percent-signup">
                <div className={isScrolled ? 'signup-title-scrolled' : "signup-title"}>
                    <div className="prof-logo">
                        <img style={{ width: "140px" }} src={require("../../images/New Shubadinam Logo/Shubadinam White.png")} width="100" height="30" onContextMenu={(e) => e.preventDefault()} />
                    </div>
                    <div className="prof-title">
                        <h1 className="letters-color-header">Ready To Connect With Your Cosmic Heritage</h1>
                    </div>
                </div>

                <div className="signup-content">

                    {
                        isPremiumClicked &&
                        // <>
                        <ProfilePremiumDialog />
                        // </>
                    }

                    <div className="signup-box-image">

                    </div>
                    <div className="signup-box">
                        <div className="title-profile">
                            <h2 className="letters-color-prf-head">Profile</h2>
                        </div>
                        <div className="title-name">
                            <div className="key-title letters-color">Name:</div>
                            <div className="value-input">
                                <input className="singup-input-box" type="text" name="userName" onChange={handleInput} />
                            </div>
                        </div>
                        <div className="title-email">
                            <div className="key-title letters-color" >Email:</div>
                            <div className="value-input">
                                <input className="singup-input-box" type="email" name="userEmail" placeholder="please enter user email" value={defaultEmail} />
                            </div>
                        </div>
                        <div className="title-gender">
                            <div className="key-title letters-color">Gender:</div>
                            <div className="value-input">
                                <select defaultValue="" className="singup-input-box" name="userGender" onChange={handleInput}>
                                    <option disabled value="" className="placeholder-select">Select Gender</option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                    <option value="others">Others</option>
                                </select>
                            </div>
                        </div>

                        <div className="title-gender">
                            <div className="key-title letters-color">Country:</div>
                            <div className="value-input">
                                <CountrySelect
                                    onChange={(e) => {
                                        setCountryid(e.id);
                                        // console.log(e);
                                        DynamicFunction(prevState => ({
                                            ...prevState,
                                            userCountry: e.name
                                        }));
                                    }}
                                    onTextChange={(e) => {
                                        DynamicFunction(prevState => ({
                                            ...prevState,
                                            userCountry: e.target.value
                                        }));
                                    }}
                                    name="userCountry"
                                    placeHolder="Select Country"
                                />
                            </div>
                        </div>
                        <div className="title-gender">
                            <div className="key-title letters-color">State:</div>
                            <div className="value-input">
                                <StateSelect
                                    countryid={countryid}
                                    onChange={(e) => {
                                        setstateid(e.id);
                                        // console.log(e);
                                        DynamicFunction(prevState => ({
                                            ...prevState,
                                            userState: e.name
                                        }));
                                    }}
                                    onTextChange={(e) => {
                                        DynamicFunction(prevState => ({
                                            ...prevState,
                                            userState: e.target.value
                                        }));
                                        // console.log(e.target.value);
                                    }}
                                    name="userState"
                                    placeHolder="Select State"
                                />

                            </div>
                        </div>
                        {/* <div className="title-gender">
                            <div className="key-title letters-color">City:</div>
                            <div className="value-input">
                                <CitySelect
                                    countryid={countryid}
                                    stateid={stateid}
                                    onChange={(e) => {
                                        console.log(e);
                                    }}
                                    placeHolder="Select City"
                                />
                            </div>
                        </div> */}




                        <div className="title-relation">
                            <div className="key-title letters-color">Relation:</div>
                            <div className="value-input">
                                <input className="singup-input-box" type="text" name="userRelation" value=" Myself" readOnly />
                            </div>
                        </div>

                        {

                            isPremium ?
                                <>
                                    <div className="title-name">
                                        <div className="key-title letters-color">Date & Time:</div>
                                        <div className="value-input">
                                            <input className="singup-input-box" type='datetime-local' onChange={(e) => handleDateTime(e)} placeholder="--Select Date & Time" name="userDateTime"></input>
                                        </div>
                                    </div>

                                </>
                                :

                                <>


                                    <div className="title-nakshatra-tithi">
                                        <div className="key-title letters-color">What do you celebrate:</div>
                                        <div className="value-input">
                                            <select defaultValue={""} className="singup-input-box" onChange={handleNakTithi} name="nakshatraTithi" >
                                                <option disabled value="">--Nakshatra/Tithi--</option>
                                                <option value="Nakshatra">Nakshatra</option>
                                                <option value="Tithi">Tithi</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="title-calender">
                                        <div className="key-title letters-color">Panchang:</div>
                                        <div className="value-input">
                                            <select defaultValue={""} className="singup-input-box" onChange={handleCalender} name="userCalender" >
                                                <option disabled value={""}>--Panchang--</option>
                                                {
                                                    Calender.map((clr, index) => (
                                                        <option key={index} value={clr.name} data-calendercode={clr.calenderCode}>{clr.name}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="title-month">
                                        <div className="key-title letters-color">Month:</div>
                                        <div className="value-input">
                                            <select defaultValue={""} className="singup-input-box" onChange={handleMonth} name="userMonth" >
                                                <option disabled value="">--Month--</option>
                                                {
                                                    months.map((mth, index) => (
                                                        <option key={index} value={mth.name}>{mth.name}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>


                                    {
                                        nakTithi && nakTithi === "Nakshatra" ?

                                            <div className="title-nakshathra">
                                                <div className="key-title letters-color">Nakshatra:</div>
                                                <div className="value-input">
                                                    <select defaultValue={""} className="singup-input-box" onChange={handleNakshathra} name="userNakshathra">
                                                        <option disabled value={""}>--Nakshatra--</option>
                                                        {
                                                            nakshathras.map((nakshathra, index) => (
                                                                <option key={index} value={nakshathra}>{nakshathra}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            :
                                            <div></div>
                                    }


                                    {
                                        nakTithi && nakTithi === "Tithi" ?
                                            <div className="nakandtithi">
                                                <div className="title-Paksham">
                                                    <div className="key-title letters-color">Paksha:</div>
                                                    <div className="value-input">
                                                        <select defaultValue={""} className="singup-input-box" onChange={handlePaksham} name="userPaksham">
                                                            <option disabled value={""}>--Paksha--</option>
                                                            {
                                                                nakTithis.map((Paksham, index) => (
                                                                    <option key={index} value={Paksham.name}>{Paksham.name}</option>
                                                                ))
                                                            }
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="title-Tithiname">
                                                    <div className="key-title letters-color">Tithi:</div>
                                                    <div className="value-input">
                                                        <select defaultValue={""} className="singup-input-box" onChange={handleTithi} name="userTithi">
                                                            <option disabled value={""}>--Tithi--</option>
                                                            {
                                                                tithiname.map((tithi, index) => (
                                                                    <option key={index} value={tithi}>{tithi}</option>
                                                                ))
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div></div>
                                    }

                                    <br />

                                    <span style={{ fontSize: '14px', color: '#666', fontStyle: 'italic' }}>
                                        (Note: Please fill out all the fields to continue.) *
                                    </span>


                                    <br />
                                    <label className="letters-color profile-buy-link">If you don't know the above details ,
                                        <ProfilePremiumDialog />
                                    </label>
                                    <br />


                                </>
                        }



                        <div className="title-signup-btn">
                            <button
                                // onClick={() => signInHome()}
                                className="signup-btn-style"
                                disabled={
                                    dynamicState.userName === "" ||
                                    dynamicState.userEmail === "" ||
                                    dynamicState.userGender === "" ||
                                    dynamicState.userCountry === "" ||
                                    dynamicState.userState === "" ||
                                    dynamicState.userRelation === "" ||
                                    dynamicState.userCalender === "" ||
                                    dynamicState.nakTithi === "" ||
                                    dynamicState.userNakshathra === "" && dynamicState.userTithi === ""
                                }

                            >Submit</button>
                        </div>


                    </div>


                </div>
                <br />
                {/* <div className="empty-container-footer-profile"> */}
                <FooterComponent></FooterComponent>
                {/* </div> */}
            </div>
        </div>
    )
}
export default PremiumProfile;
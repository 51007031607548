import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Confetti from 'react-confetti'

const SignupRedirection = () => {



    const navigate = useNavigate();



    useEffect(() => {

        const loadScript = async (snippet) => {
            try {

                const script = document.createElement('script');
                script.innerHTML = snippet;
                script.async = true; // Load script asynchronously
                document.body.appendChild(script);
                // console.log(document.scripts);

            } catch (error) {
                console.error('Error occurred while adding script:', error);
            }
        };

        const loadScripts = async () => {
            // Load existing event snippet
            loadScript(`gtag('event', 'conversion', { 'send_to': 'AW-16479234342/dKrPCK6B4qYZEKbS9LE9' });`);
            // Load new event conversion snippet
            loadScript(`gtag('event', 'conversion', { 'send_to': 'AW-11290519266/6OS0CLXk5KYZEOLN3ocq'});`);
        };

        loadScripts();
    }, []);





    const [count, setCount] = useState(0)
    const [showConfetti, setShowConfetti] = useState(true);

    useEffect(() => {

        const token = localStorage.getItem('token');
        const localId = localStorage.getItem('SD_Id');

        if (token) {
            const interval = setInterval(() => {

                if (count == 2) {
                    setShowConfetti(false) // Update count using the state updater function
                }
                if (count < 3) {
                    setCount(prevCount => prevCount + 1); // Update count using the state updater function
                } else {
                    clearInterval(interval); // Stop the interval when count reaches 3
                    sessionStorage.removeItem("profileauth")
                    navigate("/dashboard")
                }
            }, 1000);

            return () => clearInterval(interval); // Clean up the interval on component unmount


        }


    }, [count]); // Include count in the dependency array to ensure effect re-runs when count changes

    return (
        <div className='redirect-signup'>
            {showConfetti && <Confetti />}
            <h1>Thank You For Signing Up...</h1>
            <div className="slider">
                <div className="line"></div>
                <div className="break dot1"></div>
                <div className="break dot2"></div>
                <div className="break dot3"></div>
            </div>
            <p>Please wait. This page will redirect you to your Dashboard in {count} seconds ... </p>
        </div>
    );
};

export default SignupRedirection;

// src/CustomTheme.js
import { createTheme } from '@mui/material/styles';

// Define the theme with primary and secondary colors
const CustomTheme = createTheme({
    palette: {
        primary: {
            main: '#1b3e69', // Replace with your desired primary color
        },
        secondary: {
            main: '#4b769f', // Replace with your desired secondary color
        },
    },
});

export default CustomTheme;

import React, { useState, useEffect, useRef } from "react";
import { Link, NavLink, Navigate, useNavigate } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import FooterComponent from "../components/footer";
import TestimonialScreen from "./testimonials";
import Top10px from "./Top10px";
import LandingHeader from "../components/LandingHeader";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Box, Button, CardActionArea, CardActions } from '@mui/material';
import HomamEvents from "../components/HomamEvents";
import ShopComponent from "../Shopee/ShopComponent";
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/splide.min.css'; // Splide CSS

import web_banner1 from "../images/banners/web-banner1.jpg";
import web_banner2 from "../images/banners/web-banner2.jpg";
import web_banner3 from "../images/banners/web-banner3.jpg";

import ph_banner1 from "../images/banners/ph-banner1.png"
import ph_banner2 from "../images/banners/ph-banner2.png"
import ph_banner3 from "../images/banners/ph-banner3.jpg"
import axios from "axios";



const LoginScreen = () => {

    const navigate = useNavigate();


    const [dashsignOut, setSignOut] = useState(false)

    const [isHomeauth, setisHomeauth] = useState(false)


    useEffect(() => {

        const auth = localStorage.getItem("homeauth");
        if (auth) {
            setSignOut(true)
            setisHomeauth(true)
        }
    }, []);


    const blogsNakshatra = () => {
        navigate(`/blogs-nakshatra`)
    }

    const blogsTithi = () => {
        navigate(`/blogs-tithi`)
    }

    const [ismobile, setIsmobile] = useState(false)


    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 768) {
                setIsmobile(true);
            } else {
                setIsmobile(false);
            }
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // ip info
    useEffect(() => {
        const fetchIPData = async () => {
            try {
                // Replace with your ipinfo.io API token
                const ipInfoToken = 'b0ecbaf60e55e4'; // Get this from ipinfo.io/account/home

                // Fetch IP data
                const response = await axios.get(`https://ipinfo.io?token=${ipInfoToken}`);
                const { ip, city, region, country, loc } = response.data;

                // Prepare payload for the backend
                const payload = {
                    ip_address: ip,
                    city,
                    state: region,
                    country,
                    coordinates: loc, // Already in "latitude,longitude" format
                };

                // Send data to your backend
                await axios.post('https://backend.shubadinam.com/website-views', payload);
            } catch (error) {
                console.error('Error fetching or sending IP data:', error);
            }
        };

        // fetchIPData();
    }, []);


    const handleBanner = (page) => {
        if (page === "shop") {
            // Redirect to the external shop URL in a new tab
            window.open("https://shop.shubadinam.com/", "_blank");
        } else {
            // Navigate to a different page in your app
            navigate(`/${page}`);
        }
    };




    return (
        <Box>
            <HelmetProvider>
                <Helmet>
                    <title>Shubadinam</title>
                    <meta name="description" content="Shubadinam - Janma Nakshatra/Tithi Birthday Reminder Service." />
                </Helmet>
            </HelmetProvider>
            <ScrollToTop smooth />

            <Top10px />
            <LandingHeader />

            <Box sx={{ mt: "100px", p: { xs: 2, md: 4 } }}>

                {/* hero slider */}
                <Splide
                    options={{
                        type: 'loop',
                        autoplay: true,
                        interval: 5000, // 5 seconds per slide
                        arrows: false,  // Optional: Hide arrows
                        pagination: true, // Optional: Hide pagination
                        // padding: "80px", // Optional: You can still keep padding for other sides
                    }}
                >

                    {
                        ismobile &&
                        <>
                            {/* premium */}
                            <SplideSlide onClick={() => handleBanner("premium-checkout")}>

                                <Box
                                    sx={{
                                        // height: '50vh',
                                        position: 'relative',  // Ensure the video fits inside the container
                                        overflow: 'hidden',
                                        cursor: "pointer"  // Hide any overflowing content
                                    }}
                                >
                                    <img
                                        src={ph_banner1}
                                        alt="Background Image"
                                        style={{
                                            borderRadius: "20px",
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'contain',   // Ensures the image stays behind any content
                                        }}
                                    />

                                </Box>


                            </SplideSlide>
                            {/* shop */}
                            <SplideSlide onClick={() => handleBanner("shop")}>

                                <Box
                                    sx={{
                                        // height: '50vh',
                                        borderRadius: "20px",
                                        position: 'relative',  // Ensure the video fits inside the container
                                        overflow: 'hidden',    // Hide any overflowing content
                                    }}
                                >
                                    <img
                                        src={ph_banner2}
                                        alt="Background Image"
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'contain',   // Ensures the image stays behind any content
                                        }}
                                    />

                                </Box>
                            </SplideSlide>
                            {/* seva */}
                            <SplideSlide onClick={() => handleBanner("virtual-puja-seva")}>
                                <Box
                                    sx={{
                                        // height: '50vh',
                                        borderRadius: "20px",
                                        position: 'relative',  // Ensure the video fits inside the container
                                        overflow: 'hidden',    // Hide any overflowing content
                                    }}
                                >
                                    <img
                                        src={ph_banner3}
                                        alt="Background Image"
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'contain',   // Ensures the image stays behind any content
                                        }}
                                    />

                                </Box>
                            </SplideSlide>
                        </>
                    }
                    {
                        !ismobile &&

                        <>
                            {/* premium */}
                            <SplideSlide onClick={() => handleBanner("premium-checkout")}>
                                <Box
                                    sx={{
                                        width: "100%",
                                        height: 250,  // Set a height for the box
                                        backgroundImage: `url(${require("../images/banners/web-banner1.jpg")})`,
                                        backgroundSize: 'cover',  // Cover the entire box
                                        backgroundPosition: 'center', // Center the background image
                                        position: 'relative',
                                        borderRadius: 3,
                                        backgroundRepeat: "no-repeat",
                                        cursor: "pointer"
                                    }}
                                >
                                </Box>


                            </SplideSlide>
                            {/* shop */}
                            <SplideSlide onClick={() => handleBanner("shop")}>

                                <Box
                                    sx={{
                                        width: "100%",
                                        height: 250,  // Set a height for the box
                                        backgroundImage: `url(${require("../images/banners/web-banner2.jpg")})`,
                                        backgroundSize: 'cover',  // Cover the entire box
                                        backgroundPosition: 'center', // Center the background image
                                        position: 'relative',
                                        borderRadius: 3,
                                        backgroundRepeat: "no-repeat",
                                        cursor: "pointer"
                                    }}
                                >
                                </Box>

                            </SplideSlide>
                            {/* seva */}
                            <SplideSlide onClick={() => handleBanner("virtual-puja-seva")}>
                                <Box
                                    sx={{
                                        width: "100%",
                                        height: 250,  // Set a height for the box
                                        backgroundImage: `url(${require("../images/banners/web-banner3.jpg")})`,
                                        backgroundSize: 'cover',  // Cover the entire box
                                        backgroundPosition: 'center', // Center the background image
                                        position: 'relative',
                                        borderRadius: 3,
                                        backgroundRepeat: "no-repeat",
                                        cursor: "pointer"
                                    }}
                                >
                                </Box>


                            </SplideSlide>
                        </>

                    }
                </Splide>

                {/* hero text alone */}
                <Box
                    sx={{
                        mt: 5,
                        p: 4,
                        textAlign: 'center',
                        borderRadius: 3,
                        // backgroundColor: '#f1f5fa',
                        // boxShadow: '0 8px 20px rgba(0, 0, 0, 0.1)',
                        maxWidth: '700px',
                        margin: '0 auto',
                    }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 2 }}>
                        <Typography
                            variant={ismobile ? "h5" : "h3"}
                            sx={{
                                fontWeight: 'bold',
                                color: '#1b3e69',
                                textShadow: '1px 1px 2px rgba(0,0,0,0.1)',
                                mb: { xs: 1, md: 2 },
                            }}
                        >
                            Birthdays are Special
                        </Typography>
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', sm: 'row' },
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: 1,
                            mb: { xs: 1, md: 2 },
                        }}
                    >
                        <Typography
                            variant="h6"
                            color="primary"
                            sx={{
                                fontStyle: 'italic',
                                fontWeight: 400,
                                textAlign: { xs: 'center', sm: 'left' },
                            }}
                        >
                            "Make it Impactful..."
                        </Typography>
                        <Typography
                            variant="h6"
                            color="primary"
                            sx={{
                                fontStyle: 'italic',
                                fontWeight: 400,
                                textAlign: { xs: 'center', sm: 'left' },
                            }}
                        >
                            "Make it a Shubadinam!"
                        </Typography>
                    </Box>

                    <Typography
                        variant="subtitle1"
                        color="textSecondary"
                        sx={{
                            mb: 4,
                            lineHeight: 1.6,
                            maxWidth: '600px',
                            fontSize: '1rem',
                        }}
                    >
                        Get reminders to celebrate the Janma Nakshatra / Janma Tithi as per the Indian calendar and make every celebration memorable.
                    </Typography>

                    <Link to={isHomeauth ? "/dashboard" : "/pricing"} style={{ textDecoration: 'none' }}>
                        <Button
                            variant="contained"
                            sx={{
                                width: { xs: '100%', sm: 'auto' },
                                maxWidth: 220,
                                px: 3,
                                py: 1.5,
                                fontSize: { xs: '1.2rem', sm: '1rem' },
                                backgroundColor: '#15527b',
                                color: "white",
                                fontWeight: 'bold',
                                borderRadius: 2,
                                transition: 'transform 0.3s',
                                '&:hover': {
                                    border: 1,
                                    bgcolor: "white",
                                    color: "#1b3e69",
                                    transform: 'scale(1.05)',
                                    boxShadow: 5,
                                },
                            }}
                        >
                            {isHomeauth ? "Go to Dashboard" : "Sign Up Now"}
                        </Button>
                    </Link>
                </Box>

                {/* services */}
                <Box maxwidth="lg" sx={{ mt: 2, p: 2, }}>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        mb={2}
                        sx={{
                            backgroundColor: '#f3f4f6', // Subtle background color
                            padding: 2, // Add padding for spacing
                            borderRadius: '8px', // Rounded corners for a soft look
                        }}
                    >
                        <Typography
                            variant="h4"
                            sx={{
                                color: '#05195e',
                                fontFamily: 'Gill Sans, Gill Sans MT, Calibri, Trebuchet MS, sans-serif',
                                fontWeight: 900,
                                textTransform: 'capitalize',
                            }}
                        >
                            Services
                        </Typography>
                        <Box>
                            <NavLink
                                to="/more-services"
                                style={{
                                    textDecoration: 'none', // Remove underline
                                    color: '#ddddd', // Modern blue color
                                    fontWeight: 600, // Bold text
                                    padding: '8px 16px', // Add padding to make it look like a button
                                    borderRadius: '4px', // Rounded corners
                                    backgroundColor: '#ffffff', // Light blue background
                                    transition: 'background-color 0.3s ease, color 0.3s ease', // Smooth hover transition
                                }}
                                onMouseEnter={(e) => {
                                    e.target.style.backgroundColor = '#1b3e69'; // Darken background on hover
                                    e.target.style.color = '#ffffff'; // Change text to white on hover
                                }}
                                onMouseLeave={(e) => {
                                    e.target.style.backgroundColor = '#ffffff'; // Revert background
                                    e.target.style.color = '#0073e6'; // Revert text color
                                }}
                            >
                                View All
                            </NavLink>
                        </Box>
                    </Box>

                    <Box sx={{ mt: "30px" }}>
                        <HomamEvents />
                    </Box>
                </Box>

                {/* shop */}
                <Box maxwidth="lg" sx={{ mt: 2, p: 2, }}>

                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        mb={2}
                        sx={{
                            backgroundColor: '#f3f4f6', // Subtle background color
                            padding: 2, // Add padding for spacing
                            borderRadius: '8px', // Rounded corners for a soft look
                        }}
                    >
                        <Typography
                            variant="h4"
                            sx={{
                                color: '#05195e',
                                fontFamily: 'Gill Sans, Gill Sans MT, Calibri, Trebuchet MS, sans-serif',
                                fontWeight: 900,
                                textTransform: 'capitalize',
                            }}
                        >
                            Giftings
                        </Typography>

                        <Box>
                            <NavLink to='https://shop.shubadinam.com/collections/all' target='_blank'
                                style={{
                                    textDecoration: 'none', // Remove underline
                                    color: '#ddddd', // Modern blue color
                                    fontWeight: 600, // Bold text
                                    padding: '8px 16px', // Add padding to make it look like a button
                                    borderRadius: '4px', // Rounded corners
                                    backgroundColor: '#ffffff', // Light blue background
                                    transition: 'background-color 0.3s ease, color 0.3s ease', // Smooth hover transition
                                }}
                                onMouseEnter={(e) => {
                                    e.target.style.backgroundColor = '#1b3e69'; // Darken background on hover
                                    e.target.style.color = '#ffffff'; // Change text to white on hover
                                }}
                                onMouseLeave={(e) => {
                                    e.target.style.backgroundColor = '#ffffff'; // Revert background
                                    e.target.style.color = '#0073e6'; // Revert text color
                                }}
                            >
                                View All
                            </NavLink>
                        </Box>
                    </Box>
                    <Box>

                    </Box>
                    <Box>
                        <ShopComponent />
                    </Box>
                </Box>

                {/* blogs */}
                <Box maxwidth="lg" sx={{ mt: 2, p: 2, }}>
                    {/* Heading */}
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        mb={2}
                        sx={{
                            backgroundColor: '#f3f4f6', // Subtle background color
                            padding: 2, // Add padding for spacing
                            borderRadius: '8px', // Rounded corners for a soft look
                        }}
                    >
                        <Typography
                            variant="h4"
                            sx={{
                                color: '#05195e',
                                fontFamily: 'Gill Sans, Gill Sans MT, Calibri, Trebuchet MS, sans-serif',
                                fontWeight: 900,
                                textTransform: 'capitalize',
                            }}
                        >
                            Our Blogs
                        </Typography>
                        <Box>
                            <NavLink to='/Blogs'
                                style={{
                                    textDecoration: 'none', // Remove underline
                                    color: '#ddddd', // Modern blue color
                                    fontWeight: 600, // Bold text
                                    padding: '8px 16px', // Add padding to make it look like a button
                                    borderRadius: '4px', // Rounded corners
                                    backgroundColor: '#ffffff', // Light blue background
                                    transition: 'background-color 0.3s ease, color 0.3s ease', // Smooth hover transition
                                }}
                                onMouseEnter={(e) => {
                                    e.target.style.backgroundColor = '#1b3e69'; // Darken background on hover
                                    e.target.style.color = '#ffffff'; // Change text to white on hover
                                }}
                                onMouseLeave={(e) => {
                                    e.target.style.backgroundColor = '#ffffff'; // Revert background
                                    e.target.style.color = '#0073e6'; // Revert text color
                                }}
                            >
                                View All
                            </NavLink>
                        </Box>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: 5 }}>
                        {/* Nakshatra Blogs Card */}
                        <Card sx={{ maxWidth: 345, height: { xs: 'auto', md: 'auto' }, mt: 2 }} onClick={blogsNakshatra}>
                            <CardActionArea sx={{ border: "none !important" }}>
                                <CardMedia
                                    component="img"
                                    height="160"
                                    src={require("../images/boxesImages/food1.jpg")}
                                    alt="shubadinam image"
                                    onContextMenu={(e) => e.preventDefault()}
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                        Nakshatra Related Blogs
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Unlocking Destiny: Understanding Janma Nakshatra's Influence. Explore the cosmic blueprint shaping personalities with Janma Nakshatra.
                                    </Typography>
                                    <Typography variant="subtitle2" color="text.secondary">
                                        Click Read More to know more
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                            <CardActions sx={{ justifyContent: 'center' }}>
                                <Button size="small" sx={{ backgroundColor: "#1b3e69 !important", color: "white !important", textTransform: "none", padding: "5px 20px" }}>
                                    Read more
                                </Button>
                            </CardActions>
                        </Card>

                        {/* Tithi Blogs Card */}
                        <Card sx={{ maxWidth: 345, height: { xs: 'auto', md: 'auto' }, mt: 2 }} onClick={blogsTithi}>
                            <CardActionArea sx={{ border: "none !important" }}>
                                <CardMedia
                                    component="img"
                                    height="160"
                                    src={require("../images/boxesImages/deity2.png")}
                                    alt="shubadinam image"
                                    loading="lazy"
                                    onContextMenu={(e) => e.preventDefault()}
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                        Tithi Related Blogs
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Decoding Destiny: Unraveling Janma Tithi's Significance. Discover the profound impact of Janma Tithi on life's journey in just two lines.
                                    </Typography>
                                    <Typography variant="subtitle2" color="text.secondary">
                                        Click Read More to know more
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                            <CardActions sx={{ justifyContent: 'center' }}>
                                <Button size="small" sx={{ backgroundColor: "#1b3e69 !important", color: "white !important", textTransform: "none", padding: "5px 20px" }}>
                                    Read more
                                </Button>
                            </CardActions>
                        </Card>
                    </Box>
                </Box>

                {/* testimonial */}
                <Box maxwidth="lg" sx={{ mt: 2, textAlign: "center", p: 2, }}>
                    <Typography
                        variant="h4"
                        sx={{
                            p: 2,
                            bgcolor: "#f3f4f6",
                            color: '#05195e',
                            fontWeight: 900,
                            textTransform: 'capitalize',
                            fontFamily: 'Gill Sans, Gill Sans MT, Calibri, Trebuchet MS, sans-serif',
                        }}
                    >
                        What people say
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <TestimonialScreen />
                    </Box>
                </Box>

                {/* about */}
                <Box maxwidth="lg" sx={{ mt: 2, textAlign: "center", p: 2, }}>
                    {/* Title Section */}
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        mb={2}
                        sx={{
                            width: "100%",
                            backgroundColor: '#f3f4f6', // Subtle background color
                            padding: 2, // Add padding for spacing
                            borderRadius: '8px', // Rounded corners for a soft look
                        }}
                    >
                        <Typography
                            variant="h4"
                            sx={{
                                color: '#05195e',
                                fontFamily: 'Gill Sans, Gill Sans MT, Calibri, Trebuchet MS, sans-serif',
                                fontWeight: 900,
                                textTransform: 'capitalize',
                                textAlign: "center"
                            }}
                        >
                            About Us
                        </Typography>

                    </Box>

                    {/* Description Section */}
                    <Typography
                        variant="subtitle1"
                        sx={{
                            marginTop: 2,
                            lineHeight: 1.5,
                            color: 'text.primary', // You can adjust the text color here
                        }}
                    >
                        Shubadinam stands as a humble offering toward the noble cause of Retaining and Reclaiming the profound cultural legacy of Hinduism. Our mission is to illuminate the importance of celebrating Birth Stars, known as Janma Nakshatra / Janma Tithi. We firmly believe that{' '}
                        <a
                            href="/about-us"
                            style={{
                                textDecoration: 'underline',
                                color: 'primary.main', // You can change this to any color you prefer
                                '&:hover': {
                                    textDecoration: 'none',
                                },
                            }}
                        >
                            read more
                        </a>
                        .
                    </Typography>
                </Box>

            </Box>
            <FooterComponent />


        </Box>

    )
}
export default LoginScreen;
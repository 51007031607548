import React from "react";
import { useEffect } from "react";
import FooterComponent from "../components/footer";
import { NavLink } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles
import ScrollToTop from "react-scroll-to-top";
import LandingHeader from "../components/LandingHeader";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Box, Card, CardContent, Container, Grid, Paper, Typography, List, ListItem, ListItemText } from "@mui/material";


const tithiPreferences = [
    {
        name: "Pratipada",
        preferences: [
            "Preference for innovative and creative dishes.",
            "Enjoyment of spicy and flavorful foods.",
            "Appreciation for visually appealing presentations."
        ]
    },
    {
        name: "Dvitiya",
        preferences: [
            "Enjoyment of a variety of foods from different cuisines.",
            "Like for social dining and trying new recipes.",
            "Inclination towards comfort foods and traditional favorites."
        ]
    },
    {
        name: "Tritiya",
        preferences: [
            "Love for dishes that are visually appealing and creatively presented.",
            "Preference for foods that are both delicious and nutritious.",
            "Enjoyment of meals that include a variety of textures and flavors."
        ]
    },
    {
        name: "Chaturthi",
        preferences: [
            "Appreciation for home-cooked and traditional foods.",
            "Preference for meals that provide comfort and familiarity.",
            "Enjoyment of foods that are nourishing and easy to digest."
        ]
    },
    {
        name: "Panchami",
        preferences: [
            "Enjoyment of foods that symbolize growth and abundance.",
            "Appreciation for foods that are prepared with care and love.",
            "Preference for dishes that include a variety of flavors and ingredients."
        ]
    },
    {
        name: "Shashthi",
        preferences: [
            "Love for foods that are both nutritious and comforting.",
            "Preference for dishes that promote health and well-being.",
            "Enjoyment of home-cooked meals and family recipes."
        ]
    },
    {
        name: "Saptami",
        preferences: [
            "Appreciation for foods that provide energy and vitality.",
            "Love for meals that are balanced and nourishing.",
            "Enjoyment of dishes that include fresh and natural ingredients."
        ]
    },
    {
        name: "Ashtami",
        preferences: [
            "Preference for foods that are filling and substantial.",
            "Appreciation for rich and flavorful dishes.",
            "Enjoyment of indulgent and satisfying meals."
        ]
    },
    {
        name: "Navami",
        preferences: [
            "Love for foods that are prepared with care and attention to detail.",
            "Preference for meals that are both delicious and aesthetically pleasing.",
            "Appreciation for dishes that are shared in a joyful and communal setting."
        ]
    },
    {
        name: "Dashami",
        preferences: [
            "Enjoyment of foods that provide energy and stamina.",
            "Preference for meals that are fulfilling and satisfying.",
            "Love for dishes that align with a healthy and active lifestyle."
        ]
    },
    {
        name: "Ekadashi",
        preferences: [
            "Preference for light and easily digestible foods.",
            "Appreciation for vegetarian and plant-based options.",
            "Enjoyment of meals that support spiritual practices and self-purification."
        ]
    },
    {
        name: "Dvadashi",
        preferences: [
            "Love for foods that promote balance and harmony.",
            "Preference for dishes that include a variety of tastes.",
            "Appreciation for meals that bring together family and traditions."
        ]
    },
    {
        name: "Trayodashi",
        preferences: [
            "Enjoyment of foods that symbolize renewal and rejuvenation.",
            "Preference for light and detoxifying meals.",
            "Love for dishes that include cleansing and purifying ingredients."
        ]
    },
    {
        name: "Chaturdashi",
        preferences: [
            "Appreciation for foods that are prepared with mindfulness and intention.",
            "Preference for meals that promote self-care and introspection.",
            "Enjoyment of dishes that align with personal well-being."
        ]
    },
    {
        name: "Purnima",
        preferences: [
            "Love for celebratory and festive foods.",
            "Appreciation for foods that bring joy and fulfillment.",
            "Preference for meals that include a variety of flavors and textures."
        ]
    }
];




const BoxPage5 = () => {

    useEffect(() => {
        AOS.refresh();
        window.scrollTo(0, 0); // Scroll to the top when component mounts
    }, []);


    return (

        <HelmetProvider>
            <Helmet>
                <title>Tithi Foods</title>
                <meta name="description" content="Tithi Foods - Janma Nakshatra/Tithi Birthday Reminder Service." />
            </Helmet>
            <Container maxWidth="lg">
                <ScrollToTop smooth />
                <LandingHeader />

                <Box
                    mt={15}
                    sx={{
                        width: '100%',
                        height: '200px',
                        backgroundImage: `url(${require("../images/boxesImages/food2.jpg")})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        borderRadius: '8px'
                    }}
                    onContextMenu={(e) => e.preventDefault()} // Prevent right-click
                />



                <Grid container spacing={2} sx={{ mt: 2 }}>

                    <Grid item xs={12} md={8}>
                        <Box sx={{ fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" }}>

                            <Typography variant="h4" gutterBottom>Tithi Foods</Typography>
                            <Typography variant="body1" color="textSecondary" paragraph>
                                Food preferences can vary greatly among individuals, and they are influenced by factors such as culture, personal tastes, and dietary choices. While there may be some general trends associated with each Janma Tithi, it's important to remember that these preferences are not universal and should be taken as broad generalizations.
                            </Typography>
                            <Typography variant="h6" gutterBottom>Here's an overview of the food preferences that individuals born on each Janma Tithi might have according to Vedic astrology:</Typography>

                            <Box sx={{ padding: 2, backgroundColor: '#f5f5f5', maxWidth: '1200px', margin: 'auto' }}>

                                <Grid container spacing={1}>
                                    {tithiPreferences.map(({ name, preferences }) => (
                                        <Grid item xs={12} sm={6} md={6} key={name}>
                                            <Paper elevation={3} sx={{ borderRadius: 2, margin: 2 }}>
                                                <Card variant="outlined" sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                                                    <CardContent sx={{ flexGrow: 1, padding: 2 }}>
                                                        <Typography variant="h6" component="div" color="primary" sx={{ fontWeight: 'bold', marginBottom: 1.5 }}>
                                                            {name}
                                                        </Typography>
                                                        <Typography variant="body2" color="textSecondary" gutterBottom>
                                                            Preferences:
                                                        </Typography>
                                                        <ul style={{ paddingLeft: '20px', marginBottom: 0 }}>
                                                            {preferences.map((preference, index) => (
                                                                <li key={index}>
                                                                    <Typography variant="subtitle1" color="textPrimary" sx={{ marginBottom: 1 }}>
                                                                        {preference}
                                                                    </Typography>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </CardContent>
                                                </Card>
                                            </Paper>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Box>
                        </Box>

                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Card
                            sx={{
                                margin: '20px 0',
                                borderRadius: '10px',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                transition: '0.3s',
                                '&:hover': {
                                    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
                                },

                            }}
                        >
                            <CardContent>
                                <Typography variant="h6" gutterBottom>
                                    Related Searches
                                </Typography>
                                <List>
                                    {[
                                        { path: '/nakshatra-foods', label: 'Nakshatra Foods' },
                                        { path: '/nakshatra-characteristics', label: 'Nakshatra Characteristics' },
                                        { path: '/nakshatra-deities', label: 'Nakshatra Deities' },
                                        { path: '/nakshatra-likes-dislikes', label: 'Nakshatra Likes and Dislikes' },
                                        { path: '/tithi-foods', label: 'Tithi Foods' },
                                        { path: '/tithi-characteristics', label: 'Tithi Characteristics' },
                                        { path: '/tithi-deities', label: 'Tithi Deities' },
                                        { path: '/tithi-likes-dislikes', label: 'Tithi Likes and Dislikes' },
                                    ].map((item) => (
                                        <ListItem
                                            key={item.path}
                                            component={NavLink} // Make the ListItem act like a NavLink
                                            to={item.path} // Set the path for navigation
                                            sx={{
                                                padding: '10px 0',
                                                textDecoration: 'none', // Removes the underline from NavLink
                                                '&:hover': {
                                                    backgroundColor: '#f0f0f0',
                                                    cursor: 'pointer', // Changes the cursor on hover
                                                },
                                            }}
                                        >
                                            <ListItemText sx={{}} primary={item.label} />
                                        </ListItem>
                                    ))}
                                </List>

                            </CardContent>
                        </Card>
                    </Grid>

                </Grid>
            </Container>



            <FooterComponent />
        </HelmetProvider>

    )
}
export default BoxPage5;
import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {
    Container,
    Grid,
    Typography,
    Button,
    Box,
    useMediaQuery,
} from "@mui/material";
import ScrollToTop from "react-scroll-to-top";
import LandingHeader from "../components/LandingHeader";
import FooterComponent from "../components/footer";

const WhyUsPage = () => {
    const isLesser768 = useMediaQuery("(max-width:768px)");

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top when component mounts
    }, []);

    return (
        <HelmetProvider>
            <Helmet>
                <title>Why Us</title>
                <meta name="description" content="Why Us - Janma Nakshatra/Tithi Birthday Reminder Service." />
            </Helmet>
            <Box sx={{ bgcolor: "#f9f9f9", minHeight: "100vh" }}>
                <ScrollToTop smooth />
                <LandingHeader />
                <Container maxWidth="lg" sx={{ mt: 15, mb: 10 }}>
                    <Box sx={{ textAlign: "center", my: 4 }}>
                        <Typography variant="h4" gutterBottom>
                            Why {isLesser768 ? "Shubadinam" : "Us"}
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            Welcome to Shubadinam.com – where tradition meets technology, and your cosmic journey begins.
                        </Typography>
                    </Box>

                    <Grid container spacing={4}>
                        <Grid item xs={12} md={6}>
                            <Box
                                sx={{
                                    p: 4,
                                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                                    borderRadius: "10px",
                                    backgroundColor: "white",
                                }}
                            >
                                <img src={require("../images/why-us1.png")} alt="Why Us" style={{ width: '100%', borderRadius: '10px' }} />
                                <Typography variant="body1" paragraph>
                                    At Shubadinam, we're more than just a website. We're here to help you rediscover your roots and celebrate your {isLesser768 ? "Indian" : "Hindu"} identity. Imagine us as your guide on a journey to the ancient wisdom that's part of your heritage.
                                </Typography>
                                <Typography variant="h5" gutterBottom>
                                    Empowering You to Celebrate Your Identity:
                                </Typography>
                                <Typography variant="body1" paragraph>
                                    Shubadinam goes beyond dates, unveiling the magic of Birth Stars (Janma nakshatra)/Janma Tithi. It's like unlocking secrets of the stars on the day you were born.
                                </Typography>
                                <Typography variant="body1" paragraph>
                                    Our mission is to empower you, fostering a deep understanding and appreciation of your unique Janma Dinam. With our user-friendly Shubadinam finder, effortlessly receive notifications for your real Birthday and those of your friends and family.
                                </Typography>
                                {isLesser768 && (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        sx={{
                                            mt: 2,
                                            borderRadius: "6px",
                                            '&:hover': {
                                                backgroundColor: '#ffffff', // Set the hover background color to white
                                                color: '#000000', // Adjust text color for contrast on hover
                                            },
                                        }}
                                        component={NavLink}
                                        to="/pricing"
                                    >
                                        Register Now
                                    </Button>
                                )}
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Box
                                sx={{
                                    p: 4,
                                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                                    borderRadius: "10px",
                                    backgroundColor: "white",
                                }}
                            >
                                <Typography variant="h5" gutterBottom>
                                    Discover the Sacred Connection:
                                </Typography>
                                <Typography variant="body1" paragraph>
                                    At Shubadinam, we blend tradition seamlessly into your daily life, fostering a community of individuals who cherish their Hindu heritage. Recognizing and celebrating your Janma Dinam becomes a sacred connection with the divine.
                                </Typography>
                                <Typography variant="body1" paragraph>
                                    <NavLink to="/meanings-of-janma-nakshatra" style={{ textDecorationLine: "none" }}>
                                        (Click here to read more on 'Meanings of Janma Nakshatra')
                                    </NavLink>
                                    Celebrate your Janma Dinam for Good Health, Longer Life, Ample Wealth, Great fame, and spiritual Wisdom as you journey through the celestial influences shaping your life.
                                </Typography>
                                <Typography variant="body1" paragraph>
                                    Join us to celebrate your unique cosmic spark, learning about your roots.
                                </Typography>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    sx={{
                                        mt: 2,
                                        borderRadius: "6px",
                                        '&:hover': {
                                            backgroundColor: '#ffffff', // Set the hover background color to white
                                            color: '#000000', // Adjust text color for contrast on hover
                                        },
                                    }}
                                    component={NavLink}
                                    to="/pricing"
                                >
                                    Get Started
                                </Button>

                                <img src={require("../images/why-us2.png")} alt="Why Us" style={{ width: '100%', borderRadius: '10px', marginTop: '16px' }} />
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
                <FooterComponent />
            </Box>
        </HelmetProvider>
    );
};

export default WhyUsPage;

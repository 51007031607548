import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { NavLink, useNavigate } from 'react-router-dom';
import { useState } from 'react';

import {
    CitySelect,
    CountrySelect,
    StateSelect,
    LanguageSelect,
} from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";
import { Box } from '@mui/material';


const PremiumAlert = ({ routeNonpremium }) => {

    const sentMail = localStorage.getItem("fkey")   // sentMail :this is the mail of the user,sent while first login
    const defaultEmail = atob(sentMail).replace(/"/g, '').trim();

    const BootstrapDialog = styled(Dialog)(({ theme }) => ({
        '& .MuiDialogContent-root': {
            padding: theme.spacing(2),
        },
        '& .MuiDialogActions-root': {
            padding: theme.spacing(1),
        },
    }));


    const navigate = useNavigate();

    const [open, setOpen] = useState(true);

    const handleClose = () => {
        setOpen(false);
        routeNonpremium();
    };

    const gotoPricing = () => {
        navigate("/premium-checkout")
    }

    return (

        <>



            <Dialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                PaperProps={{
                    style: {
                        backgroundColor: '#f7f9fc',
                        borderRadius: '8px',
                        padding: '20px',
                        maxWidth: '600px',
                    },
                }}
            >
                <DialogTitle
                    sx={{
                        m: 0,
                        p: 2,
                        fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif",
                        fontWeight: 600,
                        color: "rgb(49, 159, 255)",
                        display: 'flex',
                        alignItems: 'center',
                    }}
                    id="customized-dialog-title"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="2em"
                        height="2em"
                        style={{ marginRight: "10px" }}
                        viewBox="0 0 24 24"
                    >
                        <path
                            fill="currentColor"
                            d="M5 16L3 5l5.5 5L12 4l3.5 6L21 5l-2 11zm14 3c0 .6-.4 1-1 1H6c-.6 0-1-.4-1-1v-1h14z"
                        ></path>
                    </svg>
                    BUY PREMIUM
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <Box>
                        <Typography gutterBottom variant="body1">
                            This is a Premium feature. Use this Nakshatra/Tithi finder assistance to get the details
                            as per English Date of Birth. Experience many more exclusive benefits and unlock the full
                            potential by upgrading to Premium.
                        </Typography>
                    </Box>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'space-between' }}>
                    <Button
                        variant="outlined"
                        onClick={handleClose}
                        sx={{
                            borderColor: "rgb(49, 159, 255)",
                            color: "rgb(49, 159, 255)",
                            "&:hover": {
                                backgroundColor: "rgba(49, 159, 255, 0.1)",
                            },
                        }}
                    >
                        Close
                    </Button>
                    <Button
                        className="premium-button"
                        onClick={() => gotoPricing()}
                    >
                        <svg viewBox="0 0 576 512" height="1em" className="logoIcon" style={{ marginRight: '8px' }}>
                            <path d="M309 106c11.4-7 19-19.7 19-34c0-22.1-17.9-40-40-40s-40 17.9-40 40c0 14.4 7.6 27 19 34L209.7 220.6c-9.1 18.2-32.7 23.4-48.6 10.7L72 160c5-6.7 8-15 8-24c0-22.1-17.9-40-40-40S0 113.9 0 136s17.9 40 40 40c.2 0 .5 0 .7 0L86.4 427.4c5.5 30.4 32 52.6 63 52.6H426.6c30.9 0 57.4-22.1 63-52.6L535.3 176c.2 0 .5 0 .7 0c22.1 0 40-17.9 40-40s-17.9-40-40-40s-40 17.9-40 40c0 9 3 17.3 8 24l-89.1 71.3c-15.9 12.7-39.5 7.5-48.6-10.7L309 106z"></path>
                        </svg>
                        GO PREMIUM
                    </Button>
                </DialogActions>
            </Dialog>


        </>
    );
};

export default PremiumAlert;
import React, { useState } from 'react';

const highorderComponent = (WrappedComponent) => {

    return (props) => {

        const [loading, setLoading] = useState(false); // State to manage loading
        const [error, setError] = useState(''); // State to manage errors

        const handleError = (message) => {
            setError(message);
            setLoading(false);
        };

        const handleLoading = (isLoading) => {
            setLoading(isLoading);
        };

        return (
            <WrappedComponent
                {...props}
                loading={loading}
                error={error}
                handleLoading={handleLoading}
                handleError={handleError}
            />
        );
    };
};

export default highorderComponent;

/* global ShopifyBuy */ // Inform ESLint that ShopifyBuy is a global variable

import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography, Grid, useMediaQuery } from '@mui/material';
import { Link, NavLink } from 'react-router-dom';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import { Button } from 'react-bootstrap';

const categories = [
    { name: 'Nicey', img: require("../images/shop/nicey.webp"), link: "https://shop.shubadinam.com/collections/nicey", description: "Thoughtful and memorable presents for every occasion." },
    { name: 'Kiddos', img: require("../images/shop/kiddos_shop.webp"), link: "https://shop.shubadinam.com/collections/kiddos", description: "Fun and educational gifts perfect for children." },
    { name: 'Utility', img: require("../images/shop/utility_shop.webp"), link: "https://shop.shubadinam.com/collections/utility", description: "Practical and useful items that everyone needs." },
    { name: 'Divine', img: require("../images/shop/nicey_shop.webp"), link: "https://shop.shubadinam.com/collections/divine", description: "Gifts that connect hearts to the divine." },
    { name: 'Gift Cards', img: require("../images/shop/giftcard_shop.webp"), link: "https://shop.shubadinam.com/collections/gift-cards", description: "Give the gift of choice so the recipient can choose what they desire." },

];

const ShopComponent = () => {
    const isSmallScreen = useMediaQuery('(max-width:600px)');
    const isMediumScreen = useMediaQuery('(max-width:900px)');
    const [products, setProducts] = useState([]); // State to hold fetched products

    useEffect(() => {
        // Load the Shopify Buy Button script
        const scriptURL = 'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js';

        const loadScript = () => {
            const script = document.createElement('script');
            script.async = true;
            script.src = scriptURL;
            script.onload = () => {
                fetchProducts(); // Fetch products after script is loaded
            };
            (document.head || document.body).appendChild(script);
        };

        const fetchProducts = async () => {
            const client = ShopifyBuy.buildClient({
                domain: '67cc47-90.myshopify.com',
                storefrontAccessToken: '184085a79bdc9a41b82921e03a10e1b0',
            });

            // Replace 'your-collection-id' with your actual collection ID
            const collectionId = 'gid://shopify/Collection/294958039092';

            try {
                const collection = await client.collection.fetchWithProducts(collectionId);

                const formattedProducts = collection.products.map((product) => ({
                    name: product.title,
                    img: product.images[0]?.src || 'https://via.placeholder.com/150',
                    link: product.onlineStoreUrl,
                }));
                // console.log(collection.products);
                setProducts(formattedProducts);
            } catch (error) {
                console.error("Error fetching products from collection:", error);
            }
        };


        // Load the script
        if (window.ShopifyBuy && window.ShopifyBuy.UI) {
            fetchProducts();
        } else {
            loadScript();
        }

        // Cleanup function
        return () => {
            const existingScript = document.querySelector(`script[src="${scriptURL}"]`);
            if (existingScript) {
                existingScript.remove();
            }
        };
    }, []);

    return (
        <Box sx={{ mt: 5, }}>
            <Typography variant='h6' color="text.secondary" align='center' sx={{ p: { xs: 0, md: "0px 100px" }, mb: 3 }}>On Shubadinam.com, we offer a wide range of gifts categorized to make your choices meaningful and easy!</Typography>
            <Splide
                options={{
                    type: 'loop', // Slider type
                    perPage: 5, // Number of slides per page
                    perMove: 1, // Number of slides to move on navigation
                    gap: '1rem', // Gap between slides
                    arrows: false,
                    pagination: true, // Disable pagination
                    breakpoints: {
                        640: { perPage: 2 }, // For mobile, show 1 slide
                        1024: { perPage: 2 }, // For tablet, show 2 slides
                    },
                }}
                sx={{ marginTop: '20px', }} // Margin at the top
            >
                {categories.map((category, index) => (
                    <SplideSlide key={index}>
                        <Link to={category.link} target='_blank' style={{ textDecoration: "none" }}>
                            <Box
                                sx={{
                                    cursor: "pointer",
                                    position: 'relative',
                                    display: "flex",
                                    justifyContent: "center",
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    padding: '10px',
                                    borderRadius: '12px',
                                    backgroundColor: "#f3f4f6",
                                    boxShadow: '0 2px 8px rgba(0,0,0,0.1)', // Optional: Add shadow
                                }}
                            >
                                <img
                                    src={category.img}
                                    alt={category.name}
                                    style={{
                                        width: '100px',
                                        height: '100px',
                                        margin: '0 auto',
                                        borderRadius: '50%',
                                    }}
                                />
                                <Typography variant="h6" sx={{ marginTop: '10px', color: '#020332' }}>
                                    {category.name}
                                </Typography>
                                <Typography variant="body2" color="text.secondary" align='center'>
                                    {category.description}
                                </Typography>
                            </Box>
                        </Link>

                    </SplideSlide>
                ))}
            </Splide>
            <Typography variant="h6" color="text.secondary" align='center' sx={{ mt: 1 }}>
                Explore these categories and make Janma Dinam special with the perfect gift!
            </Typography>
            {/* Product Carousel Row */}
            <Box sx={{ marginTop: '40px' }}>
                <Typography variant="h5" sx={{ marginBottom: '20px', textAlign: 'center', color: '#4b769f' }}>
                    Recommended to you
                </Typography>

                {
                    products ?

                        <Splide
                            options={{
                                type: 'none',
                                perPage: isSmallScreen ? 2 : isMediumScreen ? 2 : 5,
                                gap: '30px',
                                pagination: true,
                                arrows: true,
                            }}
                            style={{ height: "300px", width: "100%" }}
                            className='login-shop-splide'
                        >
                            {products.map((product, index) => (
                                <SplideSlide key={index}>
                                    <Box sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: "pointer", }}>
                                        <Box
                                            component={NavLink}
                                            to={product.link} // Navigate to the product link
                                            target="_blank" // Opens in a new tab
                                            rel="noopener noreferrer" // Security best practice for external links
                                            sx={{
                                                width: '150px',
                                                height: '150px',
                                                overflow: 'hidden',
                                                borderRadius: '10px',
                                                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                cursor: "pointer",
                                            }}
                                        >
                                            <img
                                                src={product.img}
                                                alt={product.name}
                                                style={{
                                                    width: '100%',
                                                    height: '100%',
                                                    objectFit: 'contain',
                                                }}
                                            />
                                        </Box>
                                        <Typography variant="body5" sx={{ marginTop: '10px', marginBottom: "10px" }}>
                                            {product.name}
                                        </Typography>

                                        <Button href={product.link} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', backgroundColor: '#0f548c' }}>
                                            Buy Now
                                        </Button>
                                    </Box>
                                </SplideSlide>

                            ))}
                        </Splide>
                        :


                        <Typography variant='h6' align='center'> Loading...</Typography>
                }
            </Box>
        </Box>
    );
};

export default ShopComponent;

import React, { useState, useEffect, useContext, useRef } from "react";
import SecondDataSharing from "../context/second-data-sharing";
import axios from "axios";
import { Link, NavLink, useNavigate } from "react-router-dom";

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import {
    CitySelect,
    CountrySelect,
    StateSelect,
    LanguageSelect,
} from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";
import ProfilePremiumDialog from "./ProfilePremiumDialog";
import SingupScreen from "../screens/signup";
import RelativesAdd from "./RelativesAdd";
import PremiumAlert from "../screens/Premium/PremiumAlert";
import Swal from "sweetalert2";
import { Nav } from "react-bootstrap";
import { Alert, Button, Stack } from "@mui/material";
import PersonAddIcon from '@mui/icons-material/PersonAdd'; // Import the icon

import {
    Grid,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem, Dialog, DialogTitle, DialogContent, DialogActions, IconButton

} from '@mui/material';
import { Info, PersonOffOutlined } from "@mui/icons-material";

const SignInComponent = ({ userData }) => {

    const [profilePending, setProfilePending] = useState(true);

    useEffect(() => {
        // Ensure userData exists before accessing profile_percent
        if (userData && Number(userData.profile_percent) !== 100) {
            setProfilePending(true);
        } else {
            setProfilePending(false);
        }
    }, [userData]);

    const defaultEmail = userData && userData.userEmail;

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [countryid, setCountryid] = useState(0);
    const [stateid, setstateid] = useState(0);
    const [cityid, setCityid] = useState(0);
    const formRef = useRef(null);
    const formRef2 = useRef(null);


    function CustomTabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }} >
                        <Box>{children}</Box>
                    </Box>
                )}
            </div>
        );
    }

    CustomTabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const [value, setValue] = useState(0);
    const [showNakshatra, setshowNakshatra] = useState(true);


    const handleChange = (event, newValue) => {
        setValue(newValue);
        if (newValue === 1) {
            setshowNakshatra(false)
        } else {
            setshowNakshatra(true)
        }
        if (formRef.current) {
            formRef.current.reset();
        }
        if (formRef2.current) {
            formRef2.current.reset();
        }
    };


    const handleEnglishCalendarClick = (value) => {

        setValue(value);
        if (value === 1) {
            setshowNakshatra(false)
        } else {
            setshowNakshatra(true)
        }
        if (formRef.current) {
            formRef.current.reset();
        }
        if (formRef2.current) {
            formRef2.current.reset();
        }
    };

    // const context = useContext(DataSharingContext);

    const context = useContext(SecondDataSharing);

    const navigate = useNavigate();

    const [queryResult, setQueryResult] = useState([]);

    const [dynamicArray, setDynamicArray] = useState([])

    const mailOfUser = localStorage.getItem("fkey"); // mailOfUser :this is the mail of the user,sent while first login

    const secureID = localStorage.getItem("SD_Id")


    const [dynamicState, DynamicFunction] = useState({
        userName: "",
        userEmail: defaultEmail,
        userGender: "",
        userRelation: "",
        userCalender: "",
        userMonth: "",
        nakshatraTithi: "",
        userNakshathra: "",
        userPaksham: "",
        userTithi: "",
        userCountry: "",
        userState: "",
        userCity: "",
        userDateTime: "",
        calenderCode: "",
        secureId: secureID,
    })

    const Calender2 = [
        {
            name: "Any"
        },
        {
            name: "Tamil",
            calenderCode: 1,
            month: [{
                name: "CHITHIRAI/சித்திரை",
                nakshathira: ["ASHWINI/அஸ்வினி", "BHARANI/பரணி", "KRITTIKA/கிருத்திகை", "ROHINI/ரோகிணி", "MRIGHASIRA/மிருகசிரம்", "ARDRA/திருவாதிரை", "PUNARVASU/புனர்பூசம்", "PUSHYA/பூசம்", "ASHLESHA/ஆயில்யம்", "MAGHA/மகம்", "PURVA PHALGUNI/பூரம்", "UTTARA PHALGUNI/உத்திரம்", "HASTA/ஹஸ்தம்", "CHITRA/சித்திரை", "SWATI/சுவாதி", "VISHAKA/விசாகம்", "ANURADHA/அனுஷம்", "JYESTHA/கேட்டை", "MOOLA/மூலம்", "PURVASHADA/பூராடம்", "UTTARASHADA/உத்திராடம்", "SHARAVAN/திருவோணம்", "DHANISHTA/அவிட்டம்", "SHATABISHA/சதயம்", "PURVABHADRA/பூரட்டாதி", "UTTARABHADRA/உத்திரட்டாதி", "REVATI/ரேவதி"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/சுக்ல பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "PURNIMA/பௌர்ணமி"],
                    },
                    {
                        name: "KRISHNA PAKSA/கிருஷ்ண பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "AMAVASYA/அமாவாசை"]

                    }
                ]


            },
            {
                name: "VAIGAASI/வைகாசி",
                nakshathira: ["ASHWINI/அஸ்வினி", "BHARANI/பரணி", "KRITTIKA/கிருத்திகை", "ROHINI/ரோகிணி", "MRIGHASIRA/மிருகசிரம்", "ARDRA/திருவாதிரை", "PUNARVASU/புனர்பூசம்", "PUSHYA/பூசம்", "ASHLESHA/ஆயில்யம்", "MAGHA/மகம்", "PURVA PHALGUNI/பூரம்", "UTTARA PHALGUNI/உத்திரம்", "HASTA/ஹஸ்தம்", "CHITRA/சித்திரை", "SWATI/சுவாதி", "VISHAKA/விசாகம்", "ANURADHA/அனுஷம்", "JYESTHA/கேட்டை", "MOOLA/மூலம்", "PURVASHADA/பூராடம்", "UTTARASHADA/உத்திராடம்", "SHARAVAN/திருவோணம்", "DHANISHTA/அவிட்டம்", "SHATABISHA/சதயம்", "PURVABHADRA/பூரட்டாதி", "UTTARABHADRA/உத்திரட்டாதி", "REVATI/ரேவதி"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/சுக்ல பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "PURNIMA/பௌர்ணமி"],
                    },
                    {
                        name: "KRISHNA PAKSA/கிருஷ்ண பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "AMAVASYA/அமாவாசை"]

                    }
                ]


            },
            {
                name: "AANI/ஆனி",
                nakshathira: ["ASHWINI/அஸ்வினி", "BHARANI/பரணி", "KRITTIKA/கிருத்திகை", "ROHINI/ரோகிணி", "MRIGHASIRA/மிருகசிரம்", "ARDRA/திருவாதிரை", "PUNARVASU/புனர்பூசம்", "PUSHYA/பூசம்", "ASHLESHA/ஆயில்யம்", "MAGHA/மகம்", "PURVA PHALGUNI/பூரம்", "UTTARA PHALGUNI/உத்திரம்", "HASTA/ஹஸ்தம்", "CHITRA/சித்திரை", "SWATI/சுவாதி", "VISHAKA/விசாகம்", "ANURADHA/அனுஷம்", "JYESTHA/கேட்டை", "MOOLA/மூலம்", "PURVASHADA/பூராடம்", "UTTARASHADA/உத்திராடம்", "SHARAVAN/திருவோணம்", "DHANISHTA/அவிட்டம்", "SHATABISHA/சதயம்", "PURVABHADRA/பூரட்டாதி", "UTTARABHADRA/உத்திரட்டாதி", "REVATI/ரேவதி"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/சுக்ல பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "PURNIMA/பௌர்ணமி"],
                    },
                    {
                        name: "KRISHNA PAKSA/கிருஷ்ண பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "AMAVASYA/அமாவாசை"]

                    }
                ]

            },
            {
                name: "AADI/ஆடி",
                nakshathira: ["ASHWINI/அஸ்வினி", "BHARANI/பரணி", "KRITTIKA/கிருத்திகை", "ROHINI/ரோகிணி", "MRIGHASIRA/மிருகசிரம்", "ARDRA/திருவாதிரை", "PUNARVASU/புனர்பூசம்", "PUSHYA/பூசம்", "ASHLESHA/ஆயில்யம்", "MAGHA/மகம்", "PURVA PHALGUNI/பூரம்", "UTTARA PHALGUNI/உத்திரம்", "HASTA/ஹஸ்தம்", "CHITRA/சித்திரை", "SWATI/சுவாதி", "VISHAKA/விசாகம்", "ANURADHA/அனுஷம்", "JYESTHA/கேட்டை", "MOOLA/மூலம்", "PURVASHADA/பூராடம்", "UTTARASHADA/உத்திராடம்", "SHARAVAN/திருவோணம்", "DHANISHTA/அவிட்டம்", "SHATABISHA/சதயம்", "PURVABHADRA/பூரட்டாதி", "UTTARABHADRA/உத்திரட்டாதி", "REVATI/ரேவதி"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/சுக்ல பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "PURNIMA/பௌர்ணமி"],
                    },
                    {
                        name: "KRISHNA PAKSA/கிருஷ்ண பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "AMAVASYA/அமாவாசை"]

                    }
                ]

            },
            {
                name: "AAVANI/ஆவணி",
                nakshathira: ["ASHWINI/அஸ்வினி", "BHARANI/பரணி", "KRITTIKA/கிருத்திகை", "ROHINI/ரோகிணி", "MRIGHASIRA/மிருகசிரம்", "ARDRA/திருவாதிரை", "PUNARVASU/புனர்பூசம்", "PUSHYA/பூசம்", "ASHLESHA/ஆயில்யம்", "MAGHA/மகம்", "PURVA PHALGUNI/பூரம்", "UTTARA PHALGUNI/உத்திரம்", "HASTA/ஹஸ்தம்", "CHITRA/சித்திரை", "SWATI/சுவாதி", "VISHAKA/விசாகம்", "ANURADHA/அனுஷம்", "JYESTHA/கேட்டை", "MOOLA/மூலம்", "PURVASHADA/பூராடம்", "UTTARASHADA/உத்திராடம்", "SHARAVAN/திருவோணம்", "DHANISHTA/அவிட்டம்", "SHATABISHA/சதயம்", "PURVABHADRA/பூரட்டாதி", "UTTARABHADRA/உத்திரட்டாதி", "REVATI/ரேவதி"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/சுக்ல பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "PURNIMA/பௌர்ணமி"],
                    },
                    {
                        name: "KRISHNA PAKSA/கிருஷ்ண பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "AMAVASYA/அமாவாசை"]

                    }
                ]

            },
            {
                name: "PURATTASI/புரட்டாசி",
                nakshathira: ["ASHWINI/அஸ்வினி", "BHARANI/பரணி", "KRITTIKA/கிருத்திகை", "ROHINI/ரோகிணி", "MRIGHASIRA/மிருகசிரம்", "ARDRA/திருவாதிரை", "PUNARVASU/புனர்பூசம்", "PUSHYA/பூசம்", "ASHLESHA/ஆயில்யம்", "MAGHA/மகம்", "PURVA PHALGUNI/பூரம்", "UTTARA PHALGUNI/உத்திரம்", "HASTA/ஹஸ்தம்", "CHITRA/சித்திரை", "SWATI/சுவாதி", "VISHAKA/விசாகம்", "ANURADHA/அனுஷம்", "JYESTHA/கேட்டை", "MOOLA/மூலம்", "PURVASHADA/பூராடம்", "UTTARASHADA/உத்திராடம்", "SHARAVAN/திருவோணம்", "DHANISHTA/அவிட்டம்", "SHATABISHA/சதயம்", "PURVABHADRA/பூரட்டாதி", "UTTARABHADRA/உத்திரட்டாதி", "REVATI/ரேவதி"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/சுக்ல பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "PURNIMA/பௌர்ணமி"],
                    },
                    {
                        name: "KRISHNA PAKSA/கிருஷ்ண பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "AMAVASYA/அமாவாசை"]

                    }
                ]

            },
            {
                name: "AYPPASI/ஐப்பசி",
                nakshathira: ["ASHWINI/அஸ்வினி", "BHARANI/பரணி", "KRITTIKA/கிருத்திகை", "ROHINI/ரோகிணி", "MRIGHASIRA/மிருகசிரம்", "ARDRA/திருவாதிரை", "PUNARVASU/புனர்பூசம்", "PUSHYA/பூசம்", "ASHLESHA/ஆயில்யம்", "MAGHA/மகம்", "PURVA PHALGUNI/பூரம்", "UTTARA PHALGUNI/உத்திரம்", "HASTA/ஹஸ்தம்", "CHITRA/சித்திரை", "SWATI/சுவாதி", "VISHAKA/விசாகம்", "ANURADHA/அனுஷம்", "JYESTHA/கேட்டை", "MOOLA/மூலம்", "PURVASHADA/பூராடம்", "UTTARASHADA/உத்திராடம்", "SHARAVAN/திருவோணம்", "DHANISHTA/அவிட்டம்", "SHATABISHA/சதயம்", "PURVABHADRA/பூரட்டாதி", "UTTARABHADRA/உத்திரட்டாதி", "REVATI/ரேவதி"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/சுக்ல பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "PURNIMA/பௌர்ணமி"],
                    },
                    {
                        name: "KRISHNA PAKSA/கிருஷ்ண பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "AMAVASYA/அமாவாசை"]

                    }
                ]

            },
            {
                name: "KARTHIGAI/கார்த்திகை",
                nakshathira: ["ASHWINI/அஸ்வினி", "BHARANI/பரணி", "KRITTIKA/கிருத்திகை", "ROHINI/ரோகிணி", "MRIGHASIRA/மிருகசிரம்", "ARDRA/திருவாதிரை", "PUNARVASU/புனர்பூசம்", "PUSHYA/பூசம்", "ASHLESHA/ஆயில்யம்", "MAGHA/மகம்", "PURVA PHALGUNI/பூரம்", "UTTARA PHALGUNI/உத்திரம்", "HASTA/ஹஸ்தம்", "CHITRA/சித்திரை", "SWATI/சுவாதி", "VISHAKA/விசாகம்", "ANURADHA/அனுஷம்", "JYESTHA/கேட்டை", "MOOLA/மூலம்", "PURVASHADA/பூராடம்", "UTTARASHADA/உத்திராடம்", "SHARAVAN/திருவோணம்", "DHANISHTA/அவிட்டம்", "SHATABISHA/சதயம்", "PURVABHADRA/பூரட்டாதி", "UTTARABHADRA/உத்திரட்டாதி", "REVATI/ரேவதி"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/சுக்ல பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "PURNIMA/பௌர்ணமி"],
                    },
                    {
                        name: "KRISHNA PAKSA/கிருஷ்ண பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "AMAVASYA/அமாவாசை"]

                    }
                ]

            },
            {
                name: "MARGAZHI/மார்கழி",
                nakshathira: ["ASHWINI/அஸ்வினி", "BHARANI/பரணி", "KRITTIKA/கிருத்திகை", "ROHINI/ரோகிணி", "MRIGHASIRA/மிருகசிரம்", "ARDRA/திருவாதிரை", "PUNARVASU/புனர்பூசம்", "PUSHYA/பூசம்", "ASHLESHA/ஆயில்யம்", "MAGHA/மகம்", "PURVA PHALGUNI/பூரம்", "UTTARA PHALGUNI/உத்திரம்", "HASTA/ஹஸ்தம்", "CHITRA/சித்திரை", "SWATI/சுவாதி", "VISHAKA/விசாகம்", "ANURADHA/அனுஷம்", "JYESTHA/கேட்டை", "MOOLA/மூலம்", "PURVASHADA/பூராடம்", "UTTARASHADA/உத்திராடம்", "SHARAVAN/திருவோணம்", "DHANISHTA/அவிட்டம்", "SHATABISHA/சதயம்", "PURVABHADRA/பூரட்டாதி", "UTTARABHADRA/உத்திரட்டாதி", "REVATI/ரேவதி"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/சுக்ல பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "PURNIMA/பௌர்ணமி"],
                    },
                    {
                        name: "KRISHNA PAKSA/கிருஷ்ண பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "AMAVASYA/அமாவாசை"]

                    }
                ]

            },
            {
                name: "THAI/தை",
                nakshathira: ["ASHWINI/அஸ்வினி", "BHARANI/பரணி", "KRITTIKA/கிருத்திகை", "ROHINI/ரோகிணி", "MRIGHASIRA/மிருகசிரம்", "ARDRA/திருவாதிரை", "PUNARVASU/புனர்பூசம்", "PUSHYA/பூசம்", "ASHLESHA/ஆயில்யம்", "MAGHA/மகம்", "PURVA PHALGUNI/பூரம்", "UTTARA PHALGUNI/உத்திரம்", "HASTA/ஹஸ்தம்", "CHITRA/சித்திரை", "SWATI/சுவாதி", "VISHAKA/விசாகம்", "ANURADHA/அனுஷம்", "JYESTHA/கேட்டை", "MOOLA/மூலம்", "PURVASHADA/பூராடம்", "UTTARASHADA/உத்திராடம்", "SHARAVAN/திருவோணம்", "DHANISHTA/அவிட்டம்", "SHATABISHA/சதயம்", "PURVABHADRA/பூரட்டாதி", "UTTARABHADRA/உத்திரட்டாதி", "REVATI/ரேவதி"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/சுக்ல பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "PURNIMA/பௌர்ணமி"],
                    },
                    {
                        name: "KRISHNA PAKSA/கிருஷ்ண பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "AMAVASYA/அமாவாசை"]

                    }
                ]

            },
            {
                name: "MAASI/மாசி",
                nakshathira: ["ASHWINI/அஸ்வினி", "BHARANI/பரணி", "KRITTIKA/கிருத்திகை", "ROHINI/ரோகிணி", "MRIGHASIRA/மிருகசிரம்", "ARDRA/திருவாதிரை", "PUNARVASU/புனர்பூசம்", "PUSHYA/பூசம்", "ASHLESHA/ஆயில்யம்", "MAGHA/மகம்", "PURVA PHALGUNI/பூரம்", "UTTARA PHALGUNI/உத்திரம்", "HASTA/ஹஸ்தம்", "CHITRA/சித்திரை", "SWATI/சுவாதி", "VISHAKA/விசாகம்", "ANURADHA/அனுஷம்", "JYESTHA/கேட்டை", "MOOLA/மூலம்", "PURVASHADA/பூராடம்", "UTTARASHADA/உத்திராடம்", "SHARAVAN/திருவோணம்", "DHANISHTA/அவிட்டம்", "SHATABISHA/சதயம்", "PURVABHADRA/பூரட்டாதி", "UTTARABHADRA/உத்திரட்டாதி", "REVATI/ரேவதி"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/சுக்ல பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "PURNIMA/பௌர்ணமி"],
                    },
                    {
                        name: "KRISHNA PAKSA/கிருஷ்ண பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "AMAVASYA/அமாவாசை"]

                    }
                ]

            },
            {
                name: "PANGUNI/பங்குனி",
                nakshathira: ["ASHWINI/அஸ்வினி", "BHARANI/பரணி", "KRITTIKA/கிருத்திகை", "ROHINI/ரோகிணி", "MRIGHASIRA/மிருகசிரம்", "ARDRA/திருவாதிரை", "PUNARVASU/புனர்பூசம்", "PUSHYA/பூசம்", "ASHLESHA/ஆயில்யம்", "MAGHA/மகம்", "PURVA PHALGUNI/பூரம்", "UTTARA PHALGUNI/உத்திரம்", "HASTA/ஹஸ்தம்", "CHITRA/சித்திரை", "SWATI/சுவாதி", "VISHAKA/விசாகம்", "ANURADHA/அனுஷம்", "JYESTHA/கேட்டை", "MOOLA/மூலம்", "PURVASHADA/பூராடம்", "UTTARASHADA/உத்திராடம்", "SHARAVAN/திருவோணம்", "DHANISHTA/அவிட்டம்", "SHATABISHA/சதயம்", "PURVABHADRA/பூரட்டாதி", "UTTARABHADRA/உத்திரட்டாதி", "REVATI/ரேவதி"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/சுக்ல பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "PURNIMA/பௌர்ணமி"],
                    },
                    {
                        name: "KRISHNA PAKSA/கிருஷ்ண பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "AMAVASYA/அமாவாசை"]

                    }
                ]

            }

            ]
        },
        {
            name: "Malayalam",
            calenderCode: 2,
            month: [
                {
                    name: "MEENAM/മീനം",
                    nakshathira: ["ASHWINI/അശ്വനി", "BHARANI/ഭരണി", "KRITTIKA/കൃത്തിക", "ROHINI/രോഹിണി", "MRIGHASIRA/മൃഗശിര", "ARDRA/അർദ്ധ", "PUNARVASU/പൂർണ്ണവാസു", "PUSHYA/പൂഷ്യ", "ASHLESHA/അശ്വതി", "MAGHA/മകം", "PURVA PHALGUNI/പുർവഫാൽഗുണി", "UTTARA PHALGUNI/ഉത്തരാഫാൽഗുണി", "HASTA/ഹസ്ത", "CHITRA/ചിത", "SWATI/സ്വാതി", "VISHAKA/വിശാഖം", "ANURADHA/അനുരാധ", "JYESTHA/തിരുവാതിര", "MOOLA/മൂലം", "PURVASHADA/പുർവാഷാഢ", "UTTARASHADA/ഉത്തരാഷാഢ", "SHARAVAN/ശ്രാവണം", "DHANISHTA/ധനിഷ്ഠ", "SHATABISHA/ചിത്തിര", "PURVABHADRA/പുർവാഭദ്ര", "UTTARABHADRA/ഉത്തരാഭദ്ര", "REVATI/രേവതി"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/വെളുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "PURNIMA/പൂർണ്ണിമ"],
                        },
                        {
                            name: "KRISHNA PAKSHA/കറുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "AMAVASYA/അമാവാസി"]

                        }
                    ]



                },
                {
                    name: "MEDAM/മെഡാം",
                    nakshathira: ["ASHWINI/അശ്വനി", "BHARANI/ഭരണി", "KRITTIKA/കൃത്തിക", "ROHINI/രോഹിണി", "MRIGHASIRA/മൃഗശിര", "ARDRA/അർദ്ധ", "PUNARVASU/പൂർണ്ണവാസു", "PUSHYA/പൂഷ്യ", "ASHLESHA/അശ്വതി", "MAGHA/മകം", "PURVA PHALGUNI/പുർവഫാൽഗുണി", "UTTARA PHALGUNI/ഉത്തരാഫാൽഗുണി", "HASTA/ഹസ്ത", "CHITRA/ചിത", "SWATI/സ്വാതി", "VISHAKA/വിശാഖം", "ANURADHA/അനുരാധ", "JYESTHA/തിരുവാതിര", "MOOLA/മൂലം", "PURVASHADA/പുർവാഷാഢ", "UTTARASHADA/ഉത്തരാഷാഢ", "SHARAVAN/ശ്രാവണം", "DHANISHTA/ധനിഷ്ഠ", "SHATABISHA/ചിത്തിര", "PURVABHADRA/പുർവാഭദ്ര", "UTTARABHADRA/ഉത്തരാഭദ്ര", "REVATI/രേവതി"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/വെളുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "PURNIMA/പൂർണ്ണിമ"],
                        },
                        {
                            name: "KRISHNA PAKSHA/കറുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "AMAVASYA/അമാവാസി"]

                        }
                    ]

                },
                {
                    name: "EDAVAM/എദാവം",
                    nakshathira: ["ASHWINI/അശ്വനി", "BHARANI/ഭരണി", "KRITTIKA/കൃത്തിക", "ROHINI/രോഹിണി", "MRIGHASIRA/മൃഗശിര", "ARDRA/അർദ്ധ", "PUNARVASU/പൂർണ്ണവാസു", "PUSHYA/പൂഷ്യ", "ASHLESHA/അശ്വതി", "MAGHA/മകം", "PURVA PHALGUNI/പുർവഫാൽഗുണി", "UTTARA PHALGUNI/ഉത്തരാഫാൽഗുണി", "HASTA/ഹസ്ത", "CHITRA/ചിത", "SWATI/സ്വാതി", "VISHAKA/വിശാഖം", "ANURADHA/അനുരാധ", "JYESTHA/തിരുവാതിര", "MOOLA/മൂലം", "PURVASHADA/പുർവാഷാഢ", "UTTARASHADA/ഉത്തരാഷാഢ", "SHARAVAN/ശ്രാവണം", "DHANISHTA/ധനിഷ്ഠ", "SHATABISHA/ചിത്തിര", "PURVABHADRA/പുർവാഭദ്ര", "UTTARABHADRA/ഉത്തരാഭദ്ര", "REVATI/രേവതി"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/വെളുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "PURNIMA/പൂർണ്ണിമ"],
                        },
                        {
                            name: "KRISHNA PAKSHA/കറുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "AMAVASYA/അമാവാസി"]

                        }
                    ]

                },
                {
                    name: "MITHUNAM/മിഥുനം",
                    nakshathira: ["ASHWINI/അശ്വനി", "BHARANI/ഭരണി", "KRITTIKA/കൃത്തിക", "ROHINI/രോഹിണി", "MRIGHASIRA/മൃഗശിര", "ARDRA/അർദ്ധ", "PUNARVASU/പൂർണ്ണവാസു", "PUSHYA/പൂഷ്യ", "ASHLESHA/അശ്വതി", "MAGHA/മകം", "PURVA PHALGUNI/പുർവഫാൽഗുണി", "UTTARA PHALGUNI/ഉത്തരാഫാൽഗുണി", "HASTA/ഹസ്ത", "CHITRA/ചിത", "SWATI/സ്വാതി", "VISHAKA/വിശാഖം", "ANURADHA/അനുരാധ", "JYESTHA/തിരുവാതിര", "MOOLA/മൂലം", "PURVASHADA/പുർവാഷാഢ", "UTTARASHADA/ഉത്തരാഷാഢ", "SHARAVAN/ശ്രാവണം", "DHANISHTA/ധനിഷ്ഠ", "SHATABISHA/ചിത്തിര", "PURVABHADRA/പുർവാഭദ്ര", "UTTARABHADRA/ഉത്തരാഭദ്ര", "REVATI/രേവതി"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/വെളുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "PURNIMA/പൂർണ്ണിമ"],
                        },
                        {
                            name: "KRISHNA PAKSHA/കറുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "AMAVASYA/അമാവാസി"]

                        }
                    ]

                },
                {
                    name: "KARKADAKAM/കാർകിഡകാം",
                    nakshathira: ["ASHWINI/അശ്വനി", "BHARANI/ഭരണി", "KRITTIKA/കൃത്തിക", "ROHINI/രോഹിണി", "MRIGHASIRA/മൃഗശിര", "ARDRA/അർദ്ധ", "PUNARVASU/പൂർണ്ണവാസു", "PUSHYA/പൂഷ്യ", "ASHLESHA/അശ്വതി", "MAGHA/മകം", "PURVA PHALGUNI/പുർവഫാൽഗുണി", "UTTARA PHALGUNI/ഉത്തരാഫാൽഗുണി", "HASTA/ഹസ്ത", "CHITRA/ചിത", "SWATI/സ്വാതി", "VISHAKA/വിശാഖം", "ANURADHA/അനുരാധ", "JYESTHA/തിരുവാതിര", "MOOLA/മൂലം", "PURVASHADA/പുർവാഷാഢ", "UTTARASHADA/ഉത്തരാഷാഢ", "SHARAVAN/ശ്രാവണം", "DHANISHTA/ധനിഷ്ഠ", "SHATABISHA/ചിത്തിര", "PURVABHADRA/പുർവാഭദ്ര", "UTTARABHADRA/ഉത്തരാഭദ്ര", "REVATI/രേവതി"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/വെളുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "PURNIMA/പൂർണ്ണിമ"],
                        },
                        {
                            name: "KRISHNA PAKSHA/കറുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "AMAVASYA/അമാവാസി"]

                        }
                    ]

                },
                {
                    name: "CHINGAM/ചിങ്കാം",
                    nakshathira: ["ASHWINI/അശ്വനി", "BHARANI/ഭരണി", "KRITTIKA/കൃത്തിക", "ROHINI/രോഹിണി", "MRIGHASIRA/മൃഗശിര", "ARDRA/അർദ്ധ", "PUNARVASU/പൂർണ്ണവാസു", "PUSHYA/പൂഷ്യ", "ASHLESHA/അശ്വതി", "MAGHA/മകം", "PURVA PHALGUNI/പുർവഫാൽഗുണി", "UTTARA PHALGUNI/ഉത്തരാഫാൽഗുണി", "HASTA/ഹസ്ത", "CHITRA/ചിത", "SWATI/സ്വാതി", "VISHAKA/വിശാഖം", "ANURADHA/അനുരാധ", "JYESTHA/തിരുവാതിര", "MOOLA/മൂലം", "PURVASHADA/പുർവാഷാഢ", "UTTARASHADA/ഉത്തരാഷാഢ", "SHARAVAN/ശ്രാവണം", "DHANISHTA/ധനിഷ്ഠ", "SHATABISHA/ചിത്തിര", "PURVABHADRA/പുർവാഭദ്ര", "UTTARABHADRA/ഉത്തരാഭദ്ര", "REVATI/രേവതി"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/വെളുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "PURNIMA/പൂർണ്ണിമ"],
                        },
                        {
                            name: "KRISHNA PAKSHA/കറുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "AMAVASYA/അമാവാസി"]

                        }
                    ]

                },
                {
                    name: "KANNI/കാനി",
                    nakshathira: ["ASHWINI/അശ്വനി", "BHARANI/ഭരണി", "KRITTIKA/കൃത്തിക", "ROHINI/രോഹിണി", "MRIGHASIRA/മൃഗശിര", "ARDRA/അർദ്ധ", "PUNARVASU/പൂർണ്ണവാസു", "PUSHYA/പൂഷ്യ", "ASHLESHA/അശ്വതി", "MAGHA/മകം", "PURVA PHALGUNI/പുർവഫാൽഗുണി", "UTTARA PHALGUNI/ഉത്തരാഫാൽഗുണി", "HASTA/ഹസ്ത", "CHITRA/ചിത", "SWATI/സ്വാതി", "VISHAKA/വിശാഖം", "ANURADHA/അനുരാധ", "JYESTHA/തിരുവാതിര", "MOOLA/മൂലം", "PURVASHADA/പുർവാഷാഢ", "UTTARASHADA/ഉത്തരാഷാഢ", "SHARAVAN/ശ്രാവണം", "DHANISHTA/ധനിഷ്ഠ", "SHATABISHA/ചിത്തിര", "PURVABHADRA/പുർവാഭദ്ര", "UTTARABHADRA/ഉത്തരാഭദ്ര", "REVATI/രേവതി"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/വെളുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "PURNIMA/പൂർണ്ണിമ"],
                        },
                        {
                            name: "KRISHNA PAKSHA/കറുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "AMAVASYA/അമാവാസി"]

                        }
                    ]

                },
                {
                    name: "THULAM/തുലാം",
                    nakshathira: ["ASHWINI/അശ്വനി", "BHARANI/ഭരണി", "KRITTIKA/കൃത്തിക", "ROHINI/രോഹിണി", "MRIGHASIRA/മൃഗശിര", "ARDRA/അർദ്ധ", "PUNARVASU/പൂർണ്ണവാസു", "PUSHYA/പൂഷ്യ", "ASHLESHA/അശ്വതി", "MAGHA/മകം", "PURVA PHALGUNI/പുർവഫാൽഗുണി", "UTTARA PHALGUNI/ഉത്തരാഫാൽഗുണി", "HASTA/ഹസ്ത", "CHITRA/ചിത", "SWATI/സ്വാതി", "VISHAKA/വിശാഖം", "ANURADHA/അനുരാധ", "JYESTHA/തിരുവാതിര", "MOOLA/മൂലം", "PURVASHADA/പുർവാഷാഢ", "UTTARASHADA/ഉത്തരാഷാഢ", "SHARAVAN/ശ്രാവണം", "DHANISHTA/ധനിഷ്ഠ", "SHATABISHA/ചിത്തിര", "PURVABHADRA/പുർവാഭദ്ര", "UTTARABHADRA/ഉത്തരാഭദ്ര", "REVATI/രേവതി"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/വെളുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "PURNIMA/പൂർണ്ണിമ"],
                        },
                        {
                            name: "KRISHNA PAKSHA/കറുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "AMAVASYA/അമാവാസി"]

                        }
                    ]

                },
                {
                    name: "VRISHCHIKAM/വ്രിഷിക്കാം",
                    nakshathira: ["ASHWINI/അശ്വനി", "BHARANI/ഭരണി", "KRITTIKA/കൃത്തിക", "ROHINI/രോഹിണി", "MRIGHASIRA/മൃഗശിര", "ARDRA/അർദ്ധ", "PUNARVASU/പൂർണ്ണവാസു", "PUSHYA/പൂഷ്യ", "ASHLESHA/അശ്വതി", "MAGHA/മകം", "PURVA PHALGUNI/പുർവഫാൽഗുണി", "UTTARA PHALGUNI/ഉത്തരാഫാൽഗുണി", "HASTA/ഹസ്ത", "CHITRA/ചിത", "SWATI/സ്വാതി", "VISHAKA/വിശാഖം", "ANURADHA/അനുരാധ", "JYESTHA/തിരുവാതിര", "MOOLA/മൂലം", "PURVASHADA/പുർവാഷാഢ", "UTTARASHADA/ഉത്തരാഷാഢ", "SHARAVAN/ശ്രാവണം", "DHANISHTA/ധനിഷ്ഠ", "SHATABISHA/ചിത്തിര", "PURVABHADRA/പുർവാഭദ്ര", "UTTARABHADRA/ഉത്തരാഭദ്ര", "REVATI/രേവതി"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/വെളുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "PURNIMA/പൂർണ്ണിമ"],
                        },
                        {
                            name: "KRISHNA PAKSHA/കറുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "AMAVASYA/അമാവാസി"]

                        }
                    ]

                },
                {
                    name: "DHANU/ഭാനു",
                    nakshathira: ["ASHWINI/അശ്വനി", "BHARANI/ഭരണി", "KRITTIKA/കൃത്തിക", "ROHINI/രോഹിണി", "MRIGHASIRA/മൃഗശിര", "ARDRA/അർദ്ധ", "PUNARVASU/പൂർണ്ണവാസു", "PUSHYA/പൂഷ്യ", "ASHLESHA/അശ്വതി", "MAGHA/മകം", "PURVA PHALGUNI/പുർവഫാൽഗുണി", "UTTARA PHALGUNI/ഉത്തരാഫാൽഗുണി", "HASTA/ഹസ്ത", "CHITRA/ചിത", "SWATI/സ്വാതി", "VISHAKA/വിശാഖം", "ANURADHA/അനുരാധ", "JYESTHA/തിരുവാതിര", "MOOLA/മൂലം", "PURVASHADA/പുർവാഷാഢ", "UTTARASHADA/ഉത്തരാഷാഢ", "SHARAVAN/ശ്രാവണം", "DHANISHTA/ധനിഷ്ഠ", "SHATABISHA/ചിത്തിര", "PURVABHADRA/പുർവാഭദ്ര", "UTTARABHADRA/ഉത്തരാഭദ്ര", "REVATI/രേവതി"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/വെളുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "PURNIMA/പൂർണ്ണിമ"],
                        },
                        {
                            name: "KRISHNA PAKSHA/കറുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "AMAVASYA/അമാവാസി"]

                        }
                    ]

                },
                {
                    name: "MAKARAM/മകരം",
                    nakshathira: ["ASHWINI/അശ്വനി", "BHARANI/ഭരണി", "KRITTIKA/കൃത്തിക", "ROHINI/രോഹിണി", "MRIGHASIRA/മൃഗശിര", "ARDRA/അർദ്ധ", "PUNARVASU/പൂർണ്ണവാസു", "PUSHYA/പൂഷ്യ", "ASHLESHA/അശ്വതി", "MAGHA/മകം", "PURVA PHALGUNI/പുർവഫാൽഗുണി", "UTTARA PHALGUNI/ഉത്തരാഫാൽഗുണി", "HASTA/ഹസ്ത", "CHITRA/ചിത", "SWATI/സ്വാതി", "VISHAKA/വിശാഖം", "ANURADHA/അനുരാധ", "JYESTHA/തിരുവാതിര", "MOOLA/മൂലം", "PURVASHADA/പുർവാഷാഢ", "UTTARASHADA/ഉത്തരാഷാഢ", "SHARAVAN/ശ്രാവണം", "DHANISHTA/ധനിഷ്ഠ", "SHATABISHA/ചിത്തിര", "PURVABHADRA/പുർവാഭദ്ര", "UTTARABHADRA/ഉത്തരാഭദ്ര", "REVATI/രേവതി"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/വെളുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "PURNIMA/പൂർണ്ണിമ"],
                        },
                        {
                            name: "KRISHNA PAKSHA/കറുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "AMAVASYA/അമാവാസി"]

                        }
                    ]

                },
                {
                    name: "KUMBHAM/കുംഹാം",
                    nakshathira: ["ASHWINI/അശ്വനി", "BHARANI/ഭരണി", "KRITTIKA/കൃത്തിക", "ROHINI/രോഹിണി", "MRIGHASIRA/മൃഗശിര", "ARDRA/അർദ്ധ", "PUNARVASU/പൂർണ്ണവാസു", "PUSHYA/പൂഷ്യ", "ASHLESHA/അശ്വതി", "MAGHA/മകം", "PURVA PHALGUNI/പുർവഫാൽഗുണി", "UTTARA PHALGUNI/ഉത്തരാഫാൽഗുണി", "HASTA/ഹസ്ത", "CHITRA/ചിത", "SWATI/സ്വാതി", "VISHAKA/വിശാഖം", "ANURADHA/അനുരാധ", "JYESTHA/തിരുവാതിര", "MOOLA/മൂലം", "PURVASHADA/പുർവാഷാഢ", "UTTARASHADA/ഉത്തരാഷാഢ", "SHARAVAN/ശ്രാവണം", "DHANISHTA/ധനിഷ്ഠ", "SHATABISHA/ചിത്തിര", "PURVABHADRA/പുർവാഭദ്ര", "UTTARABHADRA/ഉത്തരാഭദ്ര", "REVATI/രേവതി"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/വെളുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "PURNIMA/പൂർണ്ണിമ"],
                        },
                        {
                            name: "KRISHNA PAKSHA/കറുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "AMAVASYA/അമാവാസി"]

                        }
                    ]

                }
            ]
        },
        {
            name: "Telugu",
            calenderCode: 3,
            month: [
                {
                    name: "CHAITHRAMU/చైత్రము",
                    nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/శుక్ల పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి"],
                        },
                        {
                            name: "KRISHNA PAKSHA/కృష్ణ పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "AMAVASYA/అమావాస్య"]

                        }
                    ]
                },
                {
                    name: "VAISAAKHAMU/వైశాఖము",
                    nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/శుక్ల పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి"],
                        },
                        {
                            name: "KRISHNA PAKSHA/కృష్ణ పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "AMAVASYA/అమావాస్య"]

                        }
                    ]

                },
                {
                    name: "JYESTHAMU/జ్యేష్ఠము",
                    nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/శుక్ల పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి"],
                        },
                        {
                            name: "KRISHNA PAKSHA/కృష్ణ పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "AMAVASYA/అమావాస్య"]

                        }
                    ]

                },
                {
                    name: "ASHADHAMU/ఆషాఢము",
                    nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/శుక్ల పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి"],
                        },
                        {
                            name: "KRISHNA PAKSHA/కృష్ణ పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "AMAVASYA/అమావాస్య"]

                        }
                    ]

                },
                // {
                //     name: "Sravanamu (Adhik)/శ్రావణము",
                //     nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
                //     Paksham: [
                //         {
                //             name: "SHUKLA PAKSHA/శుక్ల పక్షం",
                //             tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి"],
                //         },
                //         {
                //             name: "KRISHNA PAKSHA/కృష్ణ పక్షం",
                //             tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "AMAVASYA/అమావాస్య"]

                //         }
                //     ]

                // },
                {
                    name: "SRAVANAMU/శ్రావణము",
                    nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/శుక్ల పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి"],
                        },
                        {
                            name: "KRISHNA PAKSHA/కృష్ణ పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "AMAVASYA/అమావాస్య"]

                        }
                    ]

                },
                {
                    name: "BHADHRAPADAMU/భాద్రపదము",
                    nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/శుక్ల పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి"],
                        },
                        {
                            name: "KRISHNA PAKSHA/కృష్ణ పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "AMAVASYA/అమావాస్య"]

                        }
                    ]

                },
                {
                    name: "AASVAYUJAMU/ఆశ్వయుజము",
                    nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/శుక్ల పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి"],
                        },
                        {
                            name: "KRISHNA PAKSHA/కృష్ణ పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "AMAVASYA/అమావాస్య"]

                        }
                    ]

                },
                {
                    name: "KARTHIKAMU/కార్తీకము",
                    nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/శుక్ల పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి"],
                        },
                        {
                            name: "KRISHNA PAKSHA/కృష్ణ పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "AMAVASYA/అమావాస్య"]

                        }
                    ]

                },
                {
                    name: "MAARGASEERSHAMU/మార్గశీర్షము",
                    nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/శుక్ల పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి"],
                        },
                        {
                            name: "KRISHNA PAKSHA/కృష్ణ పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "AMAVASYA/అమావాస్య"]

                        }
                    ]

                },
                {
                    name: "PUSHYAMU/పుష్యము",
                    nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/శుక్ల పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి"],
                        },
                        {
                            name: "KRISHNA PAKSHA/కృష్ణ పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "AMAVASYA/అమావాస్య"]

                        }
                    ]

                },
                {
                    name: "MAKHAMU/మఖము",
                    nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/శుక్ల పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి"],
                        },
                        {
                            name: "KRISHNA PAKSHA/కృష్ణ పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "AMAVASYA/అమావాస్య"]

                        }
                    ]
                },
                {
                    name: "PHALGUNAMU/ఫాల్గుణము",
                    nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/శుక్ల పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి"],
                        },
                        {
                            name: "KRISHNA PAKSHA/కృష్ణ పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "AMAVASYA/అమావాస్య"]

                        }
                    ]
                }
            ]
        },
        {
            name: "Kannada",
            calenderCode: 4,
            month: [{
                name: "CHAITRA/ಚೈತ್ರ",
                nakshathira: ["ASHWINI/ಅಶ್ವಿನಿ", "BHARANI/ಭರಣಿ", "KRITTIKA/ಕೃತ್ತಿಕಾ", "ROHINI/ರೋಹಿಣಿ", "MRIGHASIRA/ಮೃಘಾಸಿರ", "ARDRA/ಆರ್ದ್ರಾ", "PUNARVASU/ಪುನರ್ವಸು", "PUSHYA/ಪುಷ್ಯ", "ASHLESHA/ಆಶ್ಲೇಷಾ", "MAGHA/ಮಾಘ", "PURVA PHALGUNI/ಪೂರ್ವ ಫಲ್ಗುಣಿ", "UTTARA PHALGUNI/ಉತ್ತರ ಫಲ್ಗುಣಿ", "HASTA/ಹಸ್ತ", "CHITRA/ಚಿತ್ರಾ", "SWATI/ಸ್ವಾತಿ", "VISHAKA/ವಿಶಾಕ", "ANURADHA/ಅನುರಾಧಾ", "JYESTHA/ಜ್ಯೇಷ್ಠ", "MOOLA/ಮೂಲಾ", "PURVASHADA/ಪೂರ್ವಾಷಾಡ", "UTTARASHADA/ಉತ್ತರಾಷಾಢ", "SHARAVAN/ಶರವಣ", "DHANISHTA/ಧನಿಷ್ಠಾ", "SHATABISHA/ಶತಬಿಷಾ", "PURVABHADRA/ಪೂರ್ವಾಭದ್ರ", "UTTARABHADRA/ಉತ್ತರಾಭಾದ್ರ", "REVATI/ರೇವತಿ"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/ಶುಕ್ಲ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "PURNIMA/ಪೌರ್ಣಿಮಿ"],
                    },
                    {
                        name: "KRISHNA PAKSHA/ಕೃಷ್ಣ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "AMAVASYA/ಅಮಾವಾಸ್ಯೆ"]

                    }
                ]


            },
            {
                name: "VAISHAKHA/ವೈಶಾಖ",
                nakshathira: ["ASHWINI/ಅಶ್ವಿನಿ", "BHARANI/ಭರಣಿ", "KRITTIKA/ಕೃತ್ತಿಕಾ", "ROHINI/ರೋಹಿಣಿ", "MRIGHASIRA/ಮೃಘಾಸಿರ", "ARDRA/ಆರ್ದ್ರಾ", "PUNARVASU/ಪುನರ್ವಸು", "PUSHYA/ಪುಷ್ಯ", "ASHLESHA/ಆಶ್ಲೇಷಾ", "MAGHA/ಮಾಘ", "PURVA PHALGUNI/ಪೂರ್ವ ಫಲ್ಗುಣಿ", "UTTARA PHALGUNI/ಉತ್ತರ ಫಲ್ಗುಣಿ", "HASTA/ಹಸ್ತ", "CHITRA/ಚಿತ್ರಾ", "SWATI/ಸ್ವಾತಿ", "VISHAKA/ವಿಶಾಕ", "ANURADHA/ಅನುರಾಧಾ", "JYESTHA/ಜ್ಯೇಷ್ಠ", "MOOLA/ಮೂಲಾ", "PURVASHADA/ಪೂರ್ವಾಷಾಡ", "UTTARASHADA/ಉತ್ತರಾಷಾಢ", "SHARAVAN/ಶರವಣ", "DHANISHTA/ಧನಿಷ್ಠಾ", "SHATABISHA/ಶತಬಿಷಾ", "PURVABHADRA/ಪೂರ್ವಾಭದ್ರ", "UTTARABHADRA/ಉತ್ತರಾಭಾದ್ರ", "REVATI/ರೇವತಿ"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/ಶುಕ್ಲ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "PURNIMA/ಪೌರ್ಣಿಮಿ"],
                    },
                    {
                        name: "KRISHNA PAKSHA/ಕೃಷ್ಣ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "AMAVASYA/ಅಮಾವಾಸ್ಯೆ"]

                    }
                ]


            },
            {
                name: "JYESHTHA/ಜ್ಯೇಷ್ಠ",
                nakshathira: ["ASHWINI/ಅಶ್ವಿನಿ", "BHARANI/ಭರಣಿ", "KRITTIKA/ಕೃತ್ತಿಕಾ", "ROHINI/ರೋಹಿಣಿ", "MRIGHASIRA/ಮೃಘಾಸಿರ", "ARDRA/ಆರ್ದ್ರಾ", "PUNARVASU/ಪುನರ್ವಸು", "PUSHYA/ಪುಷ್ಯ", "ASHLESHA/ಆಶ್ಲೇಷಾ", "MAGHA/ಮಾಘ", "PURVA PHALGUNI/ಪೂರ್ವ ಫಲ್ಗುಣಿ", "UTTARA PHALGUNI/ಉತ್ತರ ಫಲ್ಗುಣಿ", "HASTA/ಹಸ್ತ", "CHITRA/ಚಿತ್ರಾ", "SWATI/ಸ್ವಾತಿ", "VISHAKA/ವಿಶಾಕ", "ANURADHA/ಅನುರಾಧಾ", "JYESTHA/ಜ್ಯೇಷ್ಠ", "MOOLA/ಮೂಲಾ", "PURVASHADA/ಪೂರ್ವಾಷಾಡ", "UTTARASHADA/ಉತ್ತರಾಷಾಢ", "SHARAVAN/ಶರವಣ", "DHANISHTA/ಧನಿಷ್ಠಾ", "SHATABISHA/ಶತಬಿಷಾ", "PURVABHADRA/ಪೂರ್ವಾಭದ್ರ", "UTTARABHADRA/ಉತ್ತರಾಭಾದ್ರ", "REVATI/ರೇವತಿ"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/ಶುಕ್ಲ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "PURNIMA/ಪೌರ್ಣಿಮಿ"],
                    },
                    {
                        name: "KRISHNA PAKSHA/ಕೃಷ್ಣ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "AMAVASYA/ಅಮಾವಾಸ್ಯೆ"]

                    }
                ]


            },
            {
                name: "ASHADHA/ಆಷಾಢ",
                nakshathira: ["ASHWINI/ಅಶ್ವಿನಿ", "BHARANI/ಭರಣಿ", "KRITTIKA/ಕೃತ್ತಿಕಾ", "ROHINI/ರೋಹಿಣಿ", "MRIGHASIRA/ಮೃಘಾಸಿರ", "ARDRA/ಆರ್ದ್ರಾ", "PUNARVASU/ಪುನರ್ವಸು", "PUSHYA/ಪುಷ್ಯ", "ASHLESHA/ಆಶ್ಲೇಷಾ", "MAGHA/ಮಾಘ", "PURVA PHALGUNI/ಪೂರ್ವ ಫಲ್ಗುಣಿ", "UTTARA PHALGUNI/ಉತ್ತರ ಫಲ್ಗುಣಿ", "HASTA/ಹಸ್ತ", "CHITRA/ಚಿತ್ರಾ", "SWATI/ಸ್ವಾತಿ", "VISHAKA/ವಿಶಾಕ", "ANURADHA/ಅನುರಾಧಾ", "JYESTHA/ಜ್ಯೇಷ್ಠ", "MOOLA/ಮೂಲಾ", "PURVASHADA/ಪೂರ್ವಾಷಾಡ", "UTTARASHADA/ಉತ್ತರಾಷಾಢ", "SHARAVAN/ಶರವಣ", "DHANISHTA/ಧನಿಷ್ಠಾ", "SHATABISHA/ಶತಬಿಷಾ", "PURVABHADRA/ಪೂರ್ವಾಭದ್ರ", "UTTARABHADRA/ಉತ್ತರಾಭಾದ್ರ", "REVATI/ರೇವತಿ"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/ಶುಕ್ಲ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "PURNIMA/ಪೌರ್ಣಿಮಿ"],
                    },
                    {
                        name: "KRISHNA PAKSHA/ಕೃಷ್ಣ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "AMAVASYA/ಅಮಾವಾಸ್ಯೆ"]

                    }
                ]


            },
            {
                name: "SAHRAVANA/ಶ್ರಾವಣ",
                nakshathira: ["ASHWINI/ಅಶ್ವಿನಿ", "BHARANI/ಭರಣಿ", "KRITTIKA/ಕೃತ್ತಿಕಾ", "ROHINI/ರೋಹಿಣಿ", "MRIGHASIRA/ಮೃಘಾಸಿರ", "ARDRA/ಆರ್ದ್ರಾ", "PUNARVASU/ಪುನರ್ವಸು", "PUSHYA/ಪುಷ್ಯ", "ASHLESHA/ಆಶ್ಲೇಷಾ", "MAGHA/ಮಾಘ", "PURVA PHALGUNI/ಪೂರ್ವ ಫಲ್ಗುಣಿ", "UTTARA PHALGUNI/ಉತ್ತರ ಫಲ್ಗುಣಿ", "HASTA/ಹಸ್ತ", "CHITRA/ಚಿತ್ರಾ", "SWATI/ಸ್ವಾತಿ", "VISHAKA/ವಿಶಾಕ", "ANURADHA/ಅನುರಾಧಾ", "JYESTHA/ಜ್ಯೇಷ್ಠ", "MOOLA/ಮೂಲಾ", "PURVASHADA/ಪೂರ್ವಾಷಾಡ", "UTTARASHADA/ಉತ್ತರಾಷಾಢ", "SHARAVAN/ಶರವಣ", "DHANISHTA/ಧನಿಷ್ಠಾ", "SHATABISHA/ಶತಬಿಷಾ", "PURVABHADRA/ಪೂರ್ವಾಭದ್ರ", "UTTARABHADRA/ಉತ್ತರಾಭಾದ್ರ", "REVATI/ರೇವತಿ"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/ಶುಕ್ಲ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "PURNIMA/ಪೌರ್ಣಿಮಿ"],
                    },
                    {
                        name: "KRISHNA PAKSHA/ಕೃಷ್ಣ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "AMAVASYA/ಅಮಾವಾಸ್ಯೆ"]

                    }
                ]


            },
            {
                name: "BHADRAPADA/ಭಾದ್ರಪದ",
                nakshathira: ["ASHWINI/ಅಶ್ವಿನಿ", "BHARANI/ಭರಣಿ", "KRITTIKA/ಕೃತ್ತಿಕಾ", "ROHINI/ರೋಹಿಣಿ", "MRIGHASIRA/ಮೃಘಾಸಿರ", "ARDRA/ಆರ್ದ್ರಾ", "PUNARVASU/ಪುನರ್ವಸು", "PUSHYA/ಪುಷ್ಯ", "ASHLESHA/ಆಶ್ಲೇಷಾ", "MAGHA/ಮಾಘ", "PURVA PHALGUNI/ಪೂರ್ವ ಫಲ್ಗುಣಿ", "UTTARA PHALGUNI/ಉತ್ತರ ಫಲ್ಗುಣಿ", "HASTA/ಹಸ್ತ", "CHITRA/ಚಿತ್ರಾ", "SWATI/ಸ್ವಾತಿ", "VISHAKA/ವಿಶಾಕ", "ANURADHA/ಅನುರಾಧಾ", "JYESTHA/ಜ್ಯೇಷ್ಠ", "MOOLA/ಮೂಲಾ", "PURVASHADA/ಪೂರ್ವಾಷಾಡ", "UTTARASHADA/ಉತ್ತರಾಷಾಢ", "SHARAVAN/ಶರವಣ", "DHANISHTA/ಧನಿಷ್ಠಾ", "SHATABISHA/ಶತಬಿಷಾ", "PURVABHADRA/ಪೂರ್ವಾಭದ್ರ", "UTTARABHADRA/ಉತ್ತರಾಭಾದ್ರ", "REVATI/ರೇವತಿ"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/ಶುಕ್ಲ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "PURNIMA/ಪೌರ್ಣಿಮಿ"],
                    },
                    {
                        name: "KRISHNA PAKSHA/ಕೃಷ್ಣ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "AMAVASYA/ಅಮಾವಾಸ್ಯೆ"]

                    }
                ]


            },
            {
                name: "ASHWEJA/ಆಶ್ವೇಜ",
                nakshathira: ["ASHWINI/ಅಶ್ವಿನಿ", "BHARANI/ಭರಣಿ", "KRITTIKA/ಕೃತ್ತಿಕಾ", "ROHINI/ರೋಹಿಣಿ", "MRIGHASIRA/ಮೃಘಾಸಿರ", "ARDRA/ಆರ್ದ್ರಾ", "PUNARVASU/ಪುನರ್ವಸು", "PUSHYA/ಪುಷ್ಯ", "ASHLESHA/ಆಶ್ಲೇಷಾ", "MAGHA/ಮಾಘ", "PURVA PHALGUNI/ಪೂರ್ವ ಫಲ್ಗುಣಿ", "UTTARA PHALGUNI/ಉತ್ತರ ಫಲ್ಗುಣಿ", "HASTA/ಹಸ್ತ", "CHITRA/ಚಿತ್ರಾ", "SWATI/ಸ್ವಾತಿ", "VISHAKA/ವಿಶಾಕ", "ANURADHA/ಅನುರಾಧಾ", "JYESTHA/ಜ್ಯೇಷ್ಠ", "MOOLA/ಮೂಲಾ", "PURVASHADA/ಪೂರ್ವಾಷಾಡ", "UTTARASHADA/ಉತ್ತರಾಷಾಢ", "SHARAVAN/ಶರವಣ", "DHANISHTA/ಧನಿಷ್ಠಾ", "SHATABISHA/ಶತಬಿಷಾ", "PURVABHADRA/ಪೂರ್ವಾಭದ್ರ", "UTTARABHADRA/ಉತ್ತರಾಭಾದ್ರ", "REVATI/ರೇವತಿ"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/ಶುಕ್ಲ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "PURNIMA/ಪೌರ್ಣಿಮಿ"],
                    },
                    {
                        name: "KRISHNA PAKSHA/ಕೃಷ್ಣ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "AMAVASYA/ಅಮಾವಾಸ್ಯೆ"]

                    }
                ]


            },
            {
                name: "KARTIKA/ಕಾರ್ತೀಕ",
                nakshathira: ["ASHWINI/ಅಶ್ವಿನಿ", "BHARANI/ಭರಣಿ", "KRITTIKA/ಕೃತ್ತಿಕಾ", "ROHINI/ರೋಹಿಣಿ", "MRIGHASIRA/ಮೃಘಾಸಿರ", "ARDRA/ಆರ್ದ್ರಾ", "PUNARVASU/ಪುನರ್ವಸು", "PUSHYA/ಪುಷ್ಯ", "ASHLESHA/ಆಶ್ಲೇಷಾ", "MAGHA/ಮಾಘ", "PURVA PHALGUNI/ಪೂರ್ವ ಫಲ್ಗುಣಿ", "UTTARA PHALGUNI/ಉತ್ತರ ಫಲ್ಗುಣಿ", "HASTA/ಹಸ್ತ", "CHITRA/ಚಿತ್ರಾ", "SWATI/ಸ್ವಾತಿ", "VISHAKA/ವಿಶಾಕ", "ANURADHA/ಅನುರಾಧಾ", "JYESTHA/ಜ್ಯೇಷ್ಠ", "MOOLA/ಮೂಲಾ", "PURVASHADA/ಪೂರ್ವಾಷಾಡ", "UTTARASHADA/ಉತ್ತರಾಷಾಢ", "SHARAVAN/ಶರವಣ", "DHANISHTA/ಧನಿಷ್ಠಾ", "SHATABISHA/ಶತಬಿಷಾ", "PURVABHADRA/ಪೂರ್ವಾಭದ್ರ", "UTTARABHADRA/ಉತ್ತರಾಭಾದ್ರ", "REVATI/ರೇವತಿ"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/ಶುಕ್ಲ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "PURNIMA/ಪೌರ್ಣಿಮಿ"],
                    },
                    {
                        name: "KRISHNA PAKSHA/ಕೃಷ್ಣ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "AMAVASYA/ಅಮಾವಾಸ್ಯೆ"]

                    }
                ]


            },
            {
                name: "MARGASHIRSHA/ಮಾರ್ಗಶೀರ್ಷ",
                nakshathira: ["ASHWINI/ಅಶ್ವಿನಿ", "BHARANI/ಭರಣಿ", "KRITTIKA/ಕೃತ್ತಿಕಾ", "ROHINI/ರೋಹಿಣಿ", "MRIGHASIRA/ಮೃಘಾಸಿರ", "ARDRA/ಆರ್ದ್ರಾ", "PUNARVASU/ಪುನರ್ವಸು", "PUSHYA/ಪುಷ್ಯ", "ASHLESHA/ಆಶ್ಲೇಷಾ", "MAGHA/ಮಾಘ", "PURVA PHALGUNI/ಪೂರ್ವ ಫಲ್ಗುಣಿ", "UTTARA PHALGUNI/ಉತ್ತರ ಫಲ್ಗುಣಿ", "HASTA/ಹಸ್ತ", "CHITRA/ಚಿತ್ರಾ", "SWATI/ಸ್ವಾತಿ", "VISHAKA/ವಿಶಾಕ", "ANURADHA/ಅನುರಾಧಾ", "JYESTHA/ಜ್ಯೇಷ್ಠ", "MOOLA/ಮೂಲಾ", "PURVASHADA/ಪೂರ್ವಾಷಾಡ", "UTTARASHADA/ಉತ್ತರಾಷಾಢ", "SHARAVAN/ಶರವಣ", "DHANISHTA/ಧನಿಷ್ಠಾ", "SHATABISHA/ಶತಬಿಷಾ", "PURVABHADRA/ಪೂರ್ವಾಭದ್ರ", "UTTARABHADRA/ಉತ್ತರಾಭಾದ್ರ", "REVATI/ರೇವತಿ"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/ಶುಕ್ಲ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "PURNIMA/ಪೌರ್ಣಿಮಿ"],
                    },
                    {
                        name: "KRISHNA PAKSHA/ಕೃಷ್ಣ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "AMAVASYA/ಅಮಾವಾಸ್ಯೆ"]

                    }
                ]


            },
            {
                name: "PAUSHA/ಪೌಷಾ",
                nakshathira: ["ASHWINI/ಅಶ್ವಿನಿ", "BHARANI/ಭರಣಿ", "KRITTIKA/ಕೃತ್ತಿಕಾ", "ROHINI/ರೋಹಿಣಿ", "MRIGHASIRA/ಮೃಘಾಸಿರ", "ARDRA/ಆರ್ದ್ರಾ", "PUNARVASU/ಪುನರ್ವಸು", "PUSHYA/ಪುಷ್ಯ", "ASHLESHA/ಆಶ್ಲೇಷಾ", "MAGHA/ಮಾಘ", "PURVA PHALGUNI/ಪೂರ್ವ ಫಲ್ಗುಣಿ", "UTTARA PHALGUNI/ಉತ್ತರ ಫಲ್ಗುಣಿ", "HASTA/ಹಸ್ತ", "CHITRA/ಚಿತ್ರಾ", "SWATI/ಸ್ವಾತಿ", "VISHAKA/ವಿಶಾಕ", "ANURADHA/ಅನುರಾಧಾ", "JYESTHA/ಜ್ಯೇಷ್ಠ", "MOOLA/ಮೂಲಾ", "PURVASHADA/ಪೂರ್ವಾಷಾಡ", "UTTARASHADA/ಉತ್ತರಾಷಾಢ", "SHARAVAN/ಶರವಣ", "DHANISHTA/ಧನಿಷ್ಠಾ", "SHATABISHA/ಶತಬಿಷಾ", "PURVABHADRA/ಪೂರ್ವಾಭದ್ರ", "UTTARABHADRA/ಉತ್ತರಾಭಾದ್ರ", "REVATI/ರೇವತಿ"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/ಶುಕ್ಲ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "PURNIMA/ಪೌರ್ಣಿಮಿ"],
                    },
                    {
                        name: "KRISHNA PAKSHA/ಕೃಷ್ಣ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "AMAVASYA/ಅಮಾವಾಸ್ಯೆ"]

                    }
                ]


            },
            {
                name: "MAGHA/ಮಾಘ",
                nakshathira: ["ASHWINI/ಅಶ್ವಿನಿ", "BHARANI/ಭರಣಿ", "KRITTIKA/ಕೃತ್ತಿಕಾ", "ROHINI/ರೋಹಿಣಿ", "MRIGHASIRA/ಮೃಘಾಸಿರ", "ARDRA/ಆರ್ದ್ರಾ", "PUNARVASU/ಪುನರ್ವಸು", "PUSHYA/ಪುಷ್ಯ", "ASHLESHA/ಆಶ್ಲೇಷಾ", "MAGHA/ಮಾಘ", "PURVA PHALGUNI/ಪೂರ್ವ ಫಲ್ಗುಣಿ", "UTTARA PHALGUNI/ಉತ್ತರ ಫಲ್ಗುಣಿ", "HASTA/ಹಸ್ತ", "CHITRA/ಚಿತ್ರಾ", "SWATI/ಸ್ವಾತಿ", "VISHAKA/ವಿಶಾಕ", "ANURADHA/ಅನುರಾಧಾ", "JYESTHA/ಜ್ಯೇಷ್ಠ", "MOOLA/ಮೂಲಾ", "PURVASHADA/ಪೂರ್ವಾಷಾಡ", "UTTARASHADA/ಉತ್ತರಾಷಾಢ", "SHARAVAN/ಶರವಣ", "DHANISHTA/ಧನಿಷ್ಠಾ", "SHATABISHA/ಶತಬಿಷಾ", "PURVABHADRA/ಪೂರ್ವಾಭದ್ರ", "UTTARABHADRA/ಉತ್ತರಾಭಾದ್ರ", "REVATI/ರೇವತಿ"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/ಶುಕ್ಲ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "PURNIMA/ಪೌರ್ಣಿಮಿ"],
                    },
                    {
                        name: "KRISHNA PAKSHA/ಕೃಷ್ಣ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "AMAVASYA/ಅಮಾವಾಸ್ಯೆ"]

                    }
                ]


            },
            {
                name: "PHALGUNA/ಫಲ್ಗುಣ",
                nakshathira: ["ASHWINI/ಅಶ್ವಿನಿ", "BHARANI/ಭರಣಿ", "KRITTIKA/ಕೃತ್ತಿಕಾ", "ROHINI/ರೋಹಿಣಿ", "MRIGHASIRA/ಮೃಘಾಸಿರ", "ARDRA/ಆರ್ದ್ರಾ", "PUNARVASU/ಪುನರ್ವಸು", "PUSHYA/ಪುಷ್ಯ", "ASHLESHA/ಆಶ್ಲೇಷಾ", "MAGHA/ಮಾಘ", "PURVA PHALGUNI/ಪೂರ್ವ ಫಲ್ಗುಣಿ", "UTTARA PHALGUNI/ಉತ್ತರ ಫಲ್ಗುಣಿ", "HASTA/ಹಸ್ತ", "CHITRA/ಚಿತ್ರಾ", "SWATI/ಸ್ವಾತಿ", "VISHAKA/ವಿಶಾಕ", "ANURADHA/ಅನುರಾಧಾ", "JYESTHA/ಜ್ಯೇಷ್ಠ", "MOOLA/ಮೂಲಾ", "PURVASHADA/ಪೂರ್ವಾಷಾಡ", "UTTARASHADA/ಉತ್ತರಾಷಾಢ", "SHARAVAN/ಶರವಣ", "DHANISHTA/ಧನಿಷ್ಠಾ", "SHATABISHA/ಶತಬಿಷಾ", "PURVABHADRA/ಪೂರ್ವಾಭದ್ರ", "UTTARABHADRA/ಉತ್ತರಾಭಾದ್ರ", "REVATI/ರೇವತಿ"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/ಶುಕ್ಲ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "PURNIMA/ಪೌರ್ಣಿಮಿ"],
                    },
                    {
                        name: "KRISHNA PAKSHA/ಕೃಷ್ಣ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "AMAVASYA/ಅಮಾವಾಸ್ಯೆ"]

                    }
                ]


            },
            ]
        },
        {
            name: "Hindi",
            calenderCode: 5,
            month: [
                {
                    name: "CHAITRA/चैत्र",
                    nakshathira:
                        [
                            "ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशिरा", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/अश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वफाल्गुनी", "UTTARA PHALGUNI/उत्तराफाल्गुनी", "HASTA/हस्त", "CHITRA/चित्रा", "SWATI/स्वाति", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूल", "PURVASHADA/पूर्वाषाढ़", "UTTARASHADA/उत्तराषाढ़", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/शुक्ल पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            name: "KRISHNA PAKSHA/कृष्ण पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]

                        }
                    ]
                },
                {
                    name: "VAISAKHA/वैशाख",
                    nakshathira:
                        [
                            "ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशिरा", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/अश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वफाल्गुनी", "UTTARA PHALGUNI/उत्तराफाल्गुनी", "HASTA/हस्त", "CHITRA/चित्रा", "SWATI/स्वाति", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूल", "PURVASHADA/पूर्वाषाढ़", "UTTARASHADA/उत्तराषाढ़", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/शुक्ल पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            name: "KRISHNA PAKSHA/कृष्ण पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]

                        }
                    ]
                },
                {
                    name: "ASHADHA/आषाढ़",
                    nakshathira:
                        [
                            "ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशिरा", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/अश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वफाल्गुनी", "UTTARA PHALGUNI/उत्तराफाल्गुनी", "HASTA/हस्त", "CHITRA/चित्रा", "SWATI/स्वाति", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूल", "PURVASHADA/पूर्वाषाढ़", "UTTARASHADA/उत्तराषाढ़", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/शुक्ल पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            name: "KRISHNA PAKSHA/कृष्ण पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]

                        }
                    ]
                },
                {
                    name: "JYESTHA/ज्येष्ठा",
                    nakshathira:
                        [
                            "ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशिरा", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/अश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वफाल्गुनी", "UTTARA PHALGUNI/उत्तराफाल्गुनी", "HASTA/हस्त", "CHITRA/चित्रा", "SWATI/स्वाति", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूल", "PURVASHADA/पूर्वाषाढ़", "UTTARASHADA/उत्तराषाढ़", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/शुक्ल पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            name: "KRISHNA PAKSHA/कृष्ण पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]

                        }
                    ]
                },
                // {
                //     name: "SHRAVANA(Adhik)/श्रावण",
                //     nakshathira:
                //         [
                //             "ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशिरा", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/अश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वफाल्गुनी", "UTTARA PHALGUNI/उत्तराफाल्गुनी", "HASTA/हस्त", "CHITRA/चित्रा", "SWATI/स्वाति", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूल", "PURVASHADA/पूर्वाषाढ़", "UTTARASHADA/उत्तराषाढ़", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                //     Paksham: [
                //         {
                //             name: "SHUKLA PAKSHA/शुक्ल पक्ष",
                //             tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                //         },
                //         {
                //             name: "KRISHNA PAKSHA/कृष्ण पक्ष",
                //             tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]

                //         }
                //     ]
                // },
                {
                    name: "SHRAVANA/श्रावण",
                    nakshathira:
                        [
                            "ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशिरा", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/अश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वफाल्गुनी", "UTTARA PHALGUNI/उत्तराफाल्गुनी", "HASTA/हस्त", "CHITRA/चित्रा", "SWATI/स्वाति", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूल", "PURVASHADA/पूर्वाषाढ़", "UTTARASHADA/उत्तराषाढ़", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/शुक्ल पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            name: "KRISHNA PAKSHA/कृष्ण पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]

                        }
                    ]
                },
                {
                    name: "BHADRAPADA/भाद्रपद",
                    nakshathira:
                        [
                            "ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशिरा", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/अश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वफाल्गुनी", "UTTARA PHALGUNI/उत्तराफाल्गुनी", "HASTA/हस्त", "CHITRA/चित्रा", "SWATI/स्वाति", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूल", "PURVASHADA/पूर्वाषाढ़", "UTTARASHADA/उत्तराषाढ़", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/शुक्ल पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            name: "KRISHNA PAKSHA/कृष्ण पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]

                        }
                    ]
                },
                {
                    name: "ASHVIN/अश्विन",
                    nakshathira:
                        [
                            "ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशिरा", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/अश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वफाल्गुनी", "UTTARA PHALGUNI/उत्तराफाल्गुनी", "HASTA/हस्त", "CHITRA/चित्रा", "SWATI/स्वाति", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूल", "PURVASHADA/पूर्वाषाढ़", "UTTARASHADA/उत्तराषाढ़", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/शुक्ल पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            name: "KRISHNA PAKSHA/कृष्ण पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]

                        }
                    ]
                },
                {
                    name: "KATIKA/कार्तिक",
                    nakshathira:
                        [
                            "ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशिरा", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/अश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वफाल्गुनी", "UTTARA PHALGUNI/उत्तराफाल्गुनी", "HASTA/हस्त", "CHITRA/चित्रा", "SWATI/स्वाति", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूल", "PURVASHADA/पूर्वाषाढ़", "UTTARASHADA/उत्तराषाढ़", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/शुक्ल पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            name: "KRISHNA PAKSHA/कृष्ण पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]

                        }
                    ]
                },
                {
                    name: "MARGASIRSA/मार्गशीर्ष",
                    nakshathira:
                        [
                            "ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशिरा", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/अश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वफाल्गुनी", "UTTARA PHALGUNI/उत्तराफाल्गुनी", "HASTA/हस्त", "CHITRA/चित्रा", "SWATI/स्वाति", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूल", "PURVASHADA/पूर्वाषाढ़", "UTTARASHADA/उत्तराषाढ़", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/शुक्ल पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            name: "KRISHNA PAKSHA/कृष्ण पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]

                        }
                    ]
                },
                {
                    name: "PAUSHA/पौष",
                    nakshathira:
                        [
                            "ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशिरा", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/अश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वफाल्गुनी", "UTTARA PHALGUNI/उत्तराफाल्गुनी", "HASTA/हस्त", "CHITRA/चित्रा", "SWATI/स्वाति", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूल", "PURVASHADA/पूर्वाषाढ़", "UTTARASHADA/उत्तराषाढ़", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/शुक्ल पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            name: "KRISHNA PAKSHA/कृष्ण पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]

                        }
                    ]
                },
                {
                    name: "MAGH/माघ",
                    nakshathira:
                        [
                            "ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशिरा", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/अश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वफाल्गुनी", "UTTARA PHALGUNI/उत्तराफाल्गुनी", "HASTA/हस्त", "CHITRA/चित्रा", "SWATI/स्वाति", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूल", "PURVASHADA/पूर्वाषाढ़", "UTTARASHADA/उत्तराषाढ़", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/शुक्ल पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            name: "KRISHNA PAKSHA/कृष्ण पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]

                        }
                    ]
                },
                {
                    name: "PHALGUNA/फाल्गुन",
                    nakshathira:
                        [
                            "ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशिरा", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/अश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वफाल्गुनी", "UTTARA PHALGUNI/उत्तराफाल्गुनी", "HASTA/हस्त", "CHITRA/चित्रा", "SWATI/स्वाति", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूल", "PURVASHADA/पूर्वाषाढ़", "UTTARASHADA/उत्तराषाढ़", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/शुक्ल पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            name: "KRISHNA PAKSHA/कृष्ण पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]

                        }
                    ]
                }
            ]
        },
        {
            name: "Gujarati",
            calenderCode: 6,
            month: [
                {
                    name: "CHAITRA/ચૈત્ર",
                    nakshathira: ["ASHWINI/અશ્વિની", "BHARANI/ભરણી", "KRITTIKA/કૃત્તિકા", "ROHINI/રોહિણી", "MRIGHASIRA/મૃગશીરા", "ARDRA/આર્દ્રા", "PUNARVASU/પુનર્વસુ", "PUSHYA/પુષ્ય", "ASHLESHA/અશ્લેષા", "MAGHA/મઘા", "PURVA PHALGUNI/પૂર્વ ફાલ્ગુની", "UTTARA PHALGUNI/ઉત્તર ફાલ્ગુની", "HASTA/હસ્ત", "CHITRA/ચિત્રા", "SWATI/સ્વાતિ", "VISHAKA/વિશાખા", "ANURADHA/અનુરાધા", "JYESTHA/જ્યેષ્ઠા", "MOOLA/મૂળ", "PURVASHADA/પૂર્વ શાડ", "UTTARASHADA/ઉત્તર શાડ", "SHRAVANA/શ્રાવણ", "DHANISHTA/ધનિષ્ઠા", "SHATABISHA/શતભિષા", "PURVABHADRA/પૂર્વભાદ્ર", "UTTARABHADRA/ઉત્તરભાદ્ર", "REVATI/રેવતી"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/શુક્લ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "PURNIMA/પૂર્ણિમા"],
                        },
                        {
                            name: "KRISHNA PAKSHA/કૃષ્ણ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "AMAVASYA/આમાસ્યા"]

                        }
                    ]




                },
                {
                    name: "VAISHAKHA/વૈશાખા",
                    nakshathira: ["ASHWINI/અશ્વિની", "BHARANI/ભરણી", "KRITTIKA/કૃત્તિકા", "ROHINI/રોહિણી", "MRIGHASIRA/મૃગશીરા", "ARDRA/આર્દ્રા", "PUNARVASU/પુનર્વસુ", "PUSHYA/પુષ્ય", "ASHLESHA/અશ્લેષા", "MAGHA/મઘા", "PURVA PHALGUNI/પૂર્વ ફાલ્ગુની", "UTTARA PHALGUNI/ઉત્તર ફાલ્ગુની", "HASTA/હસ્ત", "CHITRA/ચિત્રા", "SWATI/સ્વાતિ", "VISHAKA/વિશાખા", "ANURADHA/અનુરાધા", "JYESTHA/જ્યેષ્ઠા", "MOOLA/મૂળ", "PURVASHADA/પૂર્વ શાડ", "UTTARASHADA/ઉત્તર શાડ", "SHRAVANA/શ્રાવણ", "DHANISHTA/ધનિષ્ઠા", "SHATABISHA/શતભિષા", "PURVABHADRA/પૂર્વભાદ્ર", "UTTARABHADRA/ઉત્તરભાદ્ર", "REVATI/રેવતી"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/શુક્લ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "PURNIMA/પૂર્ણિમા"],
                        },
                        {
                            name: "KRISHNA PAKSHA/કૃષ્ણ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "AMAVASYA/આમાસ્યા"]

                        }
                    ]


                },
                {
                    name: "JETHA/જેઠા",
                    nakshathira: ["ASHWINI/અશ્વિની", "BHARANI/ભરણી", "KRITTIKA/કૃત્તિકા", "ROHINI/રોહિણી", "MRIGHASIRA/મૃગશીરા", "ARDRA/આર્દ્રા", "PUNARVASU/પુનર્વસુ", "PUSHYA/પુષ્ય", "ASHLESHA/અશ્લેષા", "MAGHA/મઘા", "PURVA PHALGUNI/પૂર્વ ફાલ્ગુની", "UTTARA PHALGUNI/ઉત્તર ફાલ્ગુની", "HASTA/હસ્ત", "CHITRA/ચિત્રા", "SWATI/સ્વાતિ", "VISHAKA/વિશાખા", "ANURADHA/અનુરાધા", "JYESTHA/જ્યેષ્ઠા", "MOOLA/મૂળ", "PURVASHADA/પૂર્વ શાડ", "UTTARASHADA/ઉત્તર શાડ", "SHRAVANA/શ્રાવણ", "DHANISHTA/ધનિષ્ઠા", "SHATABISHA/શતભિષા", "PURVABHADRA/પૂર્વભાદ્ર", "UTTARABHADRA/ઉત્તરભાદ્ર", "REVATI/રેવતી"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/શુક્લ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "PURNIMA/પૂર્ણિમા"],
                        },
                        {
                            name: "KRISHNA PAKSHA/કૃષ્ણ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "AMAVASYA/આમાસ્યા"]

                        }
                    ]


                },
                // {
                //     name: "SHRAVANA adhik/શ્રાવણ",
                //     nakshathira: ["ASHWINI/અશ્વિની", "BHARANI/ભરણી", "KRITTIKA/કૃત્તિકા", "ROHINI/રોહિણી", "MRIGHASIRA/મૃગશીરા", "ARDRA/આર્દ્રા", "PUNARVASU/પુનર્વસુ", "PUSHYA/પુષ્ય", "ASHLESHA/અશ્લેષા", "MAGHA/મઘા", "PURVA PHALGUNI/પૂર્વ ફાલ્ગુની", "UTTARA PHALGUNI/ઉત્તર ફાલ્ગુની", "HASTA/હસ્ત", "CHITRA/ચિત્રા", "SWATI/સ્વાતિ", "VISHAKA/વિશાખા", "ANURADHA/અનુરાધા", "JYESTHA/જ્યેષ્ઠા", "MOOLA/મૂળ", "PURVASHADA/પૂર્વ શાડ", "UTTARASHADA/ઉત્તર શાડ", "SHRAVANA/શ્રાવણ", "DHANISHTA/ધનિષ્ઠા", "SHATABISHA/શતભિષા", "PURVABHADRA/પૂર્વભાદ્ર", "UTTARABHADRA/ઉત્તરભાદ્ર", "REVATI/રેવતી"],
                //     Paksham: [
                //         {
                //             name: "SHUKLA PAKSHA/શુક્લ પક્ષ",
                //             tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "PURNIMA/પૂર્ણિમા"],
                //         },
                //         {
                //             name: "KRISHNA PAKSHA/કૃષ્ણ પક્ષ",
                //             tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "AMAVASYA/આમાસ્યા"]

                //         }
                //     ]


                // },
                {
                    name: "SHRAVANA/શ્રાવણ",
                    nakshathira: ["ASHWINI/અશ્વિની", "BHARANI/ભરણી", "KRITTIKA/કૃત્તિકા", "ROHINI/રોહિણી", "MRIGHASIRA/મૃગશીરા", "ARDRA/આર્દ્રા", "PUNARVASU/પુનર્વસુ", "PUSHYA/પુષ્ય", "ASHLESHA/અશ્લેષા", "MAGHA/મઘા", "PURVA PHALGUNI/પૂર્વ ફાલ્ગુની", "UTTARA PHALGUNI/ઉત્તર ફાલ્ગુની", "HASTA/હસ્ત", "CHITRA/ચિત્રા", "SWATI/સ્વાતિ", "VISHAKA/વિશાખા", "ANURADHA/અનુરાધા", "JYESTHA/જ્યેષ્ઠા", "MOOLA/મૂળ", "PURVASHADA/પૂર્વ શાડ", "UTTARASHADA/ઉત્તર શાડ", "SHRAVANA/શ્રાવણ", "DHANISHTA/ધનિષ્ઠા", "SHATABISHA/શતભિષા", "PURVABHADRA/પૂર્વભાદ્ર", "UTTARABHADRA/ઉત્તરભાદ્ર", "REVATI/રેવતી"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/શુક્લ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "PURNIMA/પૂર્ણિમા"],
                        },
                        {
                            name: "KRISHNA PAKSHA/કૃષ્ણ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "AMAVASYA/આમાસ્યા"]

                        }
                    ]


                },
                {
                    name: "BHADRAVO/ભાદરવો",
                    nakshathira: ["ASHWINI/અશ્વિની", "BHARANI/ભરણી", "KRITTIKA/કૃત્તિકા", "ROHINI/રોહિણી", "MRIGHASIRA/મૃગશીરા", "ARDRA/આર્દ્રા", "PUNARVASU/પુનર્વસુ", "PUSHYA/પુષ્ય", "ASHLESHA/અશ્લેષા", "MAGHA/મઘા", "PURVA PHALGUNI/પૂર્વ ફાલ્ગુની", "UTTARA PHALGUNI/ઉત્તર ફાલ્ગુની", "HASTA/હસ્ત", "CHITRA/ચિત્રા", "SWATI/સ્વાતિ", "VISHAKA/વિશાખા", "ANURADHA/અનુરાધા", "JYESTHA/જ્યેષ્ઠા", "MOOLA/મૂળ", "PURVASHADA/પૂર્વ શાડ", "UTTARASHADA/ઉત્તર શાડ", "SHRAVANA/શ્રાવણ", "DHANISHTA/ધનિષ્ઠા", "SHATABISHA/શતભિષા", "PURVABHADRA/પૂર્વભાદ્ર", "UTTARABHADRA/ઉત્તરભાદ્ર", "REVATI/રેવતી"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/શુક્લ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "PURNIMA/પૂર્ણિમા"],
                        },
                        {
                            name: "KRISHNA PAKSHA/કૃષ્ણ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "AMAVASYA/આમાસ્યા"]

                        }
                    ]


                },
                {
                    name: "ASO/આસો",
                    nakshathira: ["ASHWINI/અશ્વિની", "BHARANI/ભરણી", "KRITTIKA/કૃત્તિકા", "ROHINI/રોહિણી", "MRIGHASIRA/મૃગશીરા", "ARDRA/આર્દ્રા", "PUNARVASU/પુનર્વસુ", "PUSHYA/પુષ્ય", "ASHLESHA/અશ્લેષા", "MAGHA/મઘા", "PURVA PHALGUNI/પૂર્વ ફાલ્ગુની", "UTTARA PHALGUNI/ઉત્તર ફાલ્ગુની", "HASTA/હસ્ત", "CHITRA/ચિત્રા", "SWATI/સ્વાતિ", "VISHAKA/વિશાખા", "ANURADHA/અનુરાધા", "JYESTHA/જ્યેષ્ઠા", "MOOLA/મૂળ", "PURVASHADA/પૂર્વ શાડ", "UTTARASHADA/ઉત્તર શાડ", "SHRAVANA/શ્રાવણ", "DHANISHTA/ધનિષ્ઠા", "SHATABISHA/શતભિષા", "PURVABHADRA/પૂર્વભાદ્ર", "UTTARABHADRA/ઉત્તરભાદ્ર", "REVATI/રેવતી"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/શુક્લ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "PURNIMA/પૂર્ણિમા"],
                        },
                        {
                            name: "KRISHNA PAKSHA/કૃષ્ણ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "AMAVASYA/આમાસ્યા"]

                        }
                    ]


                },
                {
                    name: "KARKAT/કારતક",
                    nakshathira: ["ASHWINI/અશ્વિની", "BHARANI/ભરણી", "KRITTIKA/કૃત્તિકા", "ROHINI/રોહિણી", "MRIGHASIRA/મૃગશીરા", "ARDRA/આર્દ્રા", "PUNARVASU/પુનર્વસુ", "PUSHYA/પુષ્ય", "ASHLESHA/અશ્લેષા", "MAGHA/મઘા", "PURVA PHALGUNI/પૂર્વ ફાલ્ગુની", "UTTARA PHALGUNI/ઉત્તર ફાલ્ગુની", "HASTA/હસ્ત", "CHITRA/ચિત્રા", "SWATI/સ્વાતિ", "VISHAKA/વિશાખા", "ANURADHA/અનુરાધા", "JYESTHA/જ્યેષ્ઠા", "MOOLA/મૂળ", "PURVASHADA/પૂર્વ શાડ", "UTTARASHADA/ઉત્તર શાડ", "SHRAVANA/શ્રાવણ", "DHANISHTA/ધનિષ્ઠા", "SHATABISHA/શતભિષા", "PURVABHADRA/પૂર્વભાદ્ર", "UTTARABHADRA/ઉત્તરભાદ્ર", "REVATI/રેવતી"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/શુક્લ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "PURNIMA/પૂર્ણિમા"],
                        },
                        {
                            name: "KRISHNA PAKSHA/કૃષ્ણ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "AMAVASYA/આમાસ્યા"]

                        }
                    ]


                },
                {
                    name: "MAGSHAR/માગશર",
                    nakshathira: ["ASHWINI/અશ્વિની", "BHARANI/ભરણી", "KRITTIKA/કૃત્તિકા", "ROHINI/રોહિણી", "MRIGHASIRA/મૃગશીરા", "ARDRA/આર્દ્રા", "PUNARVASU/પુનર્વસુ", "PUSHYA/પુષ્ય", "ASHLESHA/અશ્લેષા", "MAGHA/મઘા", "PURVA PHALGUNI/પૂર્વ ફાલ્ગુની", "UTTARA PHALGUNI/ઉત્તર ફાલ્ગુની", "HASTA/હસ્ત", "CHITRA/ચિત્રા", "SWATI/સ્વાતિ", "VISHAKA/વિશાખા", "ANURADHA/અનુરાધા", "JYESTHA/જ્યેષ્ઠા", "MOOLA/મૂળ", "PURVASHADA/પૂર્વ શાડ", "UTTARASHADA/ઉત્તર શાડ", "SHRAVANA/શ્રાવણ", "DHANISHTA/ધનિષ્ઠા", "SHATABISHA/શતભિષા", "PURVABHADRA/પૂર્વભાદ્ર", "UTTARABHADRA/ઉત્તરભાદ્ર", "REVATI/રેવતી"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/શુક્લ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "PURNIMA/પૂર્ણિમા"],
                        },
                        {
                            name: "KRISHNA PAKSHA/કૃષ્ણ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "AMAVASYA/આમાસ્યા"]

                        }
                    ]


                },
                {
                    name: "POSH/પોષ",
                    nakshathira: ["ASHWINI/અશ્વિની", "BHARANI/ભરણી", "KRITTIKA/કૃત્તિકા", "ROHINI/રોહિણી", "MRIGHASIRA/મૃગશીરા", "ARDRA/આર્દ્રા", "PUNARVASU/પુનર્વસુ", "PUSHYA/પુષ્ય", "ASHLESHA/અશ્લેષા", "MAGHA/મઘા", "PURVA PHALGUNI/પૂર્વ ફાલ્ગુની", "UTTARA PHALGUNI/ઉત્તર ફાલ્ગુની", "HASTA/હસ્ત", "CHITRA/ચિત્રા", "SWATI/સ્વાતિ", "VISHAKA/વિશાખા", "ANURADHA/અનુરાધા", "JYESTHA/જ્યેષ્ઠા", "MOOLA/મૂળ", "PURVASHADA/પૂર્વ શાડ", "UTTARASHADA/ઉત્તર શાડ", "SHRAVANA/શ્રાવણ", "DHANISHTA/ધનિષ્ઠા", "SHATABISHA/શતભિષા", "PURVABHADRA/પૂર્વભાદ્ર", "UTTARABHADRA/ઉત્તરભાદ્ર", "REVATI/રેવતી"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/શુક્લ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "PURNIMA/પૂર્ણિમા"],
                        },
                        {
                            name: "KRISHNA PAKSHA/કૃષ્ણ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "AMAVASYA/આમાસ્યા"]

                        }
                    ]


                },
                {
                    name: "MAHA/મહા",
                    nakshathira: ["ASHWINI/અશ્વિની", "BHARANI/ભરણી", "KRITTIKA/કૃત્તિકા", "ROHINI/રોહિણી", "MRIGHASIRA/મૃગશીરા", "ARDRA/આર્દ્રા", "PUNARVASU/પુનર્વસુ", "PUSHYA/પુષ્ય", "ASHLESHA/અશ્લેષા", "MAGHA/મઘા", "PURVA PHALGUNI/પૂર્વ ફાલ્ગુની", "UTTARA PHALGUNI/ઉત્તર ફાલ્ગુની", "HASTA/હસ્ત", "CHITRA/ચિત્રા", "SWATI/સ્વાતિ", "VISHAKA/વિશાખા", "ANURADHA/અનુરાધા", "JYESTHA/જ્યેષ્ઠા", "MOOLA/મૂળ", "PURVASHADA/પૂર્વ શાડ", "UTTARASHADA/ઉત્તર શાડ", "SHRAVANA/શ્રાવણ", "DHANISHTA/ધનિષ્ઠા", "SHATABISHA/શતભિષા", "PURVABHADRA/પૂર્વભાદ્ર", "UTTARABHADRA/ઉત્તરભાદ્ર", "REVATI/રેવતી"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/શુક્લ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "PURNIMA/પૂર્ણિમા"],
                        },
                        {
                            name: "KRISHNA PAKSHA/કૃષ્ણ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "AMAVASYA/આમાસ્યા"]

                        }
                    ]


                },
                {
                    name: "PHAGUNA/ફાગુના",
                    nakshathira: ["ASHWINI/અશ્વિની", "BHARANI/ભરણી", "KRITTIKA/કૃત્તિકા", "ROHINI/રોહિણી", "MRIGHASIRA/મૃગશીરા", "ARDRA/આર્દ્રા", "PUNARVASU/પુનર્વસુ", "PUSHYA/પુષ્ય", "ASHLESHA/અશ્લેષા", "MAGHA/મઘા", "PURVA PHALGUNI/પૂર્વ ફાલ્ગુની", "UTTARA PHALGUNI/ઉત્તર ફાલ્ગુની", "HASTA/હસ્ત", "CHITRA/ચિત્રા", "SWATI/સ્વાતિ", "VISHAKA/વિશાખા", "ANURADHA/અનુરાધા", "JYESTHA/જ્યેષ્ઠા", "MOOLA/મૂળ", "PURVASHADA/પૂર્વ શાડ", "UTTARASHADA/ઉત્તર શાડ", "SHRAVANA/શ્રાવણ", "DHANISHTA/ધનિષ્ઠા", "SHATABISHA/શતભિષા", "PURVABHADRA/પૂર્વભાદ્ર", "UTTARABHADRA/ઉત્તરભાદ્ર", "REVATI/રેવતી"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/શુક્લ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "PURNIMA/પૂર્ણિમા"],
                        },
                        {
                            name: "KRISHNA PAKSHA/કૃષ્ણ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "AMAVASYA/આમાસ્યા"]

                        }
                    ]


                },

            ]
        },
        {
            name: "Bengali",
            calenderCode: 7,
            month: [
                {
                    name: "BOISHAKH/বৈশাখ",
                    nakshathira: ["ASHWINI/অশ্বিনী", "BHARANI/ভরণী", "KRITTIKA/কৃত্তিকা", "ROHINI/রোহিণী", "MRIGHASIRA/মৃগশিরা", "ARDRA/আর্দ্রা", "PUNARVASU/পুনর্বসু", "PUSHYA/পুষ্য", "ASHLESHA/অশ্লেষা", "MAGHA/মঘা", "PURVA PHALGUNI/পূর্ব ফাল্গুনী", "UTTARA PHALGUNI/উত্তর ফাল্গুনী", "HASTA/হস্ত", "CHITRA/চিত্রা", "SWATI/স্বাতী", "VISHAKA/বিশাখা", "ANURADHA/অনুরাধা", "JYESTHA/জ্যেষ্ঠা", "MOOLA/মূল", "PURVASHADA/পূর্বষাঢ়", "UTTARASHADA/উত্তরষাঢ়", "SHARAVAN/শ্রাবণ", "DHANISHTA/ধনিষ্ঠা", "SHATABISHA/শতভিষা", "PURVABHADRA/পূর্বভাদ্র", "UTTARABHADRA/উত্তরভাদ্র", "REVATI/রেবতী"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/শুক্ল পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "PURNIMA/পূর্ণিমা"],
                        },
                        {
                            name: "KRISHNA PAKSHA/কৃষ্ণ পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "AMAVASYA/অমাবস্যা"]

                        }
                    ]



                },
                {
                    name: "JOISHTHO/জ্যৈষ্ঠ",
                    nakshathira: ["ASHWINI/অশ্বিনী", "BHARANI/ভরণী", "KRITTIKA/কৃত্তিকা", "ROHINI/রোহিণী", "MRIGHASIRA/মৃগশিরা", "ARDRA/আর্দ্রা", "PUNARVASU/পুনর্বসু", "PUSHYA/পুষ্য", "ASHLESHA/অশ্লেষা", "MAGHA/মঘা", "PURVA PHALGUNI/পূর্ব ফাল্গুনী", "UTTARA PHALGUNI/উত্তর ফাল্গুনী", "HASTA/হস্ত", "CHITRA/চিত্রা", "SWATI/স্বাতী", "VISHAKA/বিশাখা", "ANURADHA/অনুরাধা", "JYESTHA/জ্যেষ্ঠা", "MOOLA/মূল", "PURVASHADA/পূর্বষাঢ়", "UTTARASHADA/উত্তরষাঢ়", "SHARAVAN/শ্রাবণ", "DHANISHTA/ধনিষ্ঠা", "SHATABISHA/শতভিষা", "PURVABHADRA/পূর্বভাদ্র", "UTTARABHADRA/উত্তরভাদ্র", "REVATI/রেবতী"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/শুক্ল পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "PURNIMA/পূর্ণিমা"],
                        },
                        {
                            name: "KRISHNA PAKSHA/কৃষ্ণ পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "AMAVASYA/অমাবস্যা"]

                        }
                    ]
                },
                {
                    name: "ASHARH/আষাঢ়",
                    nakshathira: ["ASHWINI/অশ্বিনী", "BHARANI/ভরণী", "KRITTIKA/কৃত্তিকা", "ROHINI/রোহিণী", "MRIGHASIRA/মৃগশিরা", "ARDRA/আর্দ্রা", "PUNARVASU/পুনর্বসু", "PUSHYA/পুষ্য", "ASHLESHA/অশ্লেষা", "MAGHA/মঘা", "PURVA PHALGUNI/পূর্ব ফাল্গুনী", "UTTARA PHALGUNI/উত্তর ফাল্গুনী", "HASTA/হস্ত", "CHITRA/চিত্রা", "SWATI/স্বাতী", "VISHAKA/বিশাখা", "ANURADHA/অনুরাধা", "JYESTHA/জ্যেষ্ঠা", "MOOLA/মূল", "PURVASHADA/পূর্বষাঢ়", "UTTARASHADA/উত্তরষাঢ়", "SHARAVAN/শ্রাবণ", "DHANISHTA/ধনিষ্ঠা", "SHATABISHA/শতভিষা", "PURVABHADRA/পূর্বভাদ্র", "UTTARABHADRA/উত্তরভাদ্র", "REVATI/রেবতী"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/শুক্ল পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "PURNIMA/পূর্ণিমা"],
                        },
                        {
                            name: "KRISHNA PAKSHA/কৃষ্ণ পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "AMAVASYA/অমাবস্যা"]

                        }
                    ]
                },
                {
                    name: "SHRABON/শ্রাবণ",
                    nakshathira: ["ASHWINI/অশ্বিনী", "BHARANI/ভরণী", "KRITTIKA/কৃত্তিকা", "ROHINI/রোহিণী", "MRIGHASIRA/মৃগশিরা", "ARDRA/আর্দ্রা", "PUNARVASU/পুনর্বসু", "PUSHYA/পুষ্য", "ASHLESHA/অশ্লেষা", "MAGHA/মঘা", "PURVA PHALGUNI/পূর্ব ফাল্গুনী", "UTTARA PHALGUNI/উত্তর ফাল্গুনী", "HASTA/হস্ত", "CHITRA/চিত্রা", "SWATI/স্বাতী", "VISHAKA/বিশাখা", "ANURADHA/অনুরাধা", "JYESTHA/জ্যেষ্ঠা", "MOOLA/মূল", "PURVASHADA/পূর্বষাঢ়", "UTTARASHADA/উত্তরষাঢ়", "SHARAVAN/শ্রাবণ", "DHANISHTA/ধনিষ্ঠা", "SHATABISHA/শতভিষা", "PURVABHADRA/পূর্বভাদ্র", "UTTARABHADRA/উত্তরভাদ্র", "REVATI/রেবতী"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/শুক্ল পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "PURNIMA/পূর্ণিমা"],
                        },
                        {
                            name: "KRISHNA PAKSHA/কৃষ্ণ পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "AMAVASYA/অমাবস্যা"]

                        }
                    ]
                },
                {
                    name: "BHADRO/ভাদ্র",
                    nakshathira: ["ASHWINI/অশ্বিনী", "BHARANI/ভরণী", "KRITTIKA/কৃত্তিকা", "ROHINI/রোহিণী", "MRIGHASIRA/মৃগশিরা", "ARDRA/আর্দ্রা", "PUNARVASU/পুনর্বসু", "PUSHYA/পুষ্য", "ASHLESHA/অশ্লেষা", "MAGHA/মঘা", "PURVA PHALGUNI/পূর্ব ফাল্গুনী", "UTTARA PHALGUNI/উত্তর ফাল্গুনী", "HASTA/হস্ত", "CHITRA/চিত্রা", "SWATI/স্বাতী", "VISHAKA/বিশাখা", "ANURADHA/অনুরাধা", "JYESTHA/জ্যেষ্ঠা", "MOOLA/মূল", "PURVASHADA/পূর্বষাঢ়", "UTTARASHADA/উত্তরষাঢ়", "SHARAVAN/শ্রাবণ", "DHANISHTA/ধনিষ্ঠা", "SHATABISHA/শতভিষা", "PURVABHADRA/পূর্বভাদ্র", "UTTARABHADRA/উত্তরভাদ্র", "REVATI/রেবতী"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/শুক্ল পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "PURNIMA/পূর্ণিমা"],
                        },
                        {
                            name: "KRISHNA PAKSHA/কৃষ্ণ পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "AMAVASYA/অমাবস্যা"]

                        }
                    ]
                },
                {
                    name: "ASHSHIN/আশ্বিন",
                    nakshathira: ["ASHWINI/অশ্বিনী", "BHARANI/ভরণী", "KRITTIKA/কৃত্তিকা", "ROHINI/রোহিণী", "MRIGHASIRA/মৃগশিরা", "ARDRA/আর্দ্রা", "PUNARVASU/পুনর্বসু", "PUSHYA/পুষ্য", "ASHLESHA/অশ্লেষা", "MAGHA/মঘা", "PURVA PHALGUNI/পূর্ব ফাল্গুনী", "UTTARA PHALGUNI/উত্তর ফাল্গুনী", "HASTA/হস্ত", "CHITRA/চিত্রা", "SWATI/স্বাতী", "VISHAKA/বিশাখা", "ANURADHA/অনুরাধা", "JYESTHA/জ্যেষ্ঠা", "MOOLA/মূল", "PURVASHADA/পূর্বষাঢ়", "UTTARASHADA/উত্তরষাঢ়", "SHARAVAN/শ্রাবণ", "DHANISHTA/ধনিষ্ঠা", "SHATABISHA/শতভিষা", "PURVABHADRA/পূর্বভাদ্র", "UTTARABHADRA/উত্তরভাদ্র", "REVATI/রেবতী"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/শুক্ল পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "PURNIMA/পূর্ণিমা"],
                        },
                        {
                            name: "KRISHNA PAKSHA/কৃষ্ণ পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "AMAVASYA/অমাবস্যা"]

                        }
                    ]
                },
                {
                    name: "KARTIK/কার্তিক",
                    nakshathira: ["ASHWINI/অশ্বিনী", "BHARANI/ভরণী", "KRITTIKA/কৃত্তিকা", "ROHINI/রোহিণী", "MRIGHASIRA/মৃগশিরা", "ARDRA/আর্দ্রা", "PUNARVASU/পুনর্বসু", "PUSHYA/পুষ্য", "ASHLESHA/অশ্লেষা", "MAGHA/মঘা", "PURVA PHALGUNI/পূর্ব ফাল্গুনী", "UTTARA PHALGUNI/উত্তর ফাল্গুনী", "HASTA/হস্ত", "CHITRA/চিত্রা", "SWATI/স্বাতী", "VISHAKA/বিশাখা", "ANURADHA/অনুরাধা", "JYESTHA/জ্যেষ্ঠা", "MOOLA/মূল", "PURVASHADA/পূর্বষাঢ়", "UTTARASHADA/উত্তরষাঢ়", "SHARAVAN/শ্রাবণ", "DHANISHTA/ধনিষ্ঠা", "SHATABISHA/শতভিষা", "PURVABHADRA/পূর্বভাদ্র", "UTTARABHADRA/উত্তরভাদ্র", "REVATI/রেবতী"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/শুক্ল পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "PURNIMA/পূর্ণিমা"],
                        },
                        {
                            name: "KRISHNA PAKSHA/কৃষ্ণ পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "AMAVASYA/অমাবস্যা"]

                        }
                    ]
                },
                {
                    name: "AGRAHAYAN/অগ্রহায়ণ",
                    nakshathira: ["ASHWINI/অশ্বিনী", "BHARANI/ভরণী", "KRITTIKA/কৃত্তিকা", "ROHINI/রোহিণী", "MRIGHASIRA/মৃগশিরা", "ARDRA/আর্দ্রা", "PUNARVASU/পুনর্বসু", "PUSHYA/পুষ্য", "ASHLESHA/অশ্লেষা", "MAGHA/মঘা", "PURVA PHALGUNI/পূর্ব ফাল্গুনী", "UTTARA PHALGUNI/উত্তর ফাল্গুনী", "HASTA/হস্ত", "CHITRA/চিত্রা", "SWATI/স্বাতী", "VISHAKA/বিশাখা", "ANURADHA/অনুরাধা", "JYESTHA/জ্যেষ্ঠা", "MOOLA/মূল", "PURVASHADA/পূর্বষাঢ়", "UTTARASHADA/উত্তরষাঢ়", "SHARAVAN/শ্রাবণ", "DHANISHTA/ধনিষ্ঠা", "SHATABISHA/শতভিষা", "PURVABHADRA/পূর্বভাদ্র", "UTTARABHADRA/উত্তরভাদ্র", "REVATI/রেবতী"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/শুক্ল পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "PURNIMA/পূর্ণিমা"],
                        },
                        {
                            name: "KRISHNA PAKSHA/কৃষ্ণ পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "AMAVASYA/অমাবস্যা"]

                        }
                    ]
                },
                {
                    name: "POUSH/পৌষ",
                    nakshathira: ["ASHWINI/অশ্বিনী", "BHARANI/ভরণী", "KRITTIKA/কৃত্তিকা", "ROHINI/রোহিণী", "MRIGHASIRA/মৃগশিরা", "ARDRA/আর্দ্রা", "PUNARVASU/পুনর্বসু", "PUSHYA/পুষ্য", "ASHLESHA/অশ্লেষা", "MAGHA/মঘা", "PURVA PHALGUNI/পূর্ব ফাল্গুনী", "UTTARA PHALGUNI/উত্তর ফাল্গুনী", "HASTA/হস্ত", "CHITRA/চিত্রা", "SWATI/স্বাতী", "VISHAKA/বিশাখা", "ANURADHA/অনুরাধা", "JYESTHA/জ্যেষ্ঠা", "MOOLA/মূল", "PURVASHADA/পূর্বষাঢ়", "UTTARASHADA/উত্তরষাঢ়", "SHARAVAN/শ্রাবণ", "DHANISHTA/ধনিষ্ঠা", "SHATABISHA/শতভিষা", "PURVABHADRA/পূর্বভাদ্র", "UTTARABHADRA/উত্তরভাদ্র", "REVATI/রেবতী"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/শুক্ল পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "PURNIMA/পূর্ণিমা"],
                        },
                        {
                            name: "KRISHNA PAKSHA/কৃষ্ণ পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "AMAVASYA/অমাবস্যা"]

                        }
                    ]
                },
                {
                    name: "MAGH/মাঘ",
                    nakshathira: ["ASHWINI/অশ্বিনী", "BHARANI/ভরণী", "KRITTIKA/কৃত্তিকা", "ROHINI/রোহিণী", "MRIGHASIRA/মৃগশিরা", "ARDRA/আর্দ্রা", "PUNARVASU/পুনর্বসু", "PUSHYA/পুষ্য", "ASHLESHA/অশ্লেষা", "MAGHA/মঘা", "PURVA PHALGUNI/পূর্ব ফাল্গুনী", "UTTARA PHALGUNI/উত্তর ফাল্গুনী", "HASTA/হস্ত", "CHITRA/চিত্রা", "SWATI/স্বাতী", "VISHAKA/বিশাখা", "ANURADHA/অনুরাধা", "JYESTHA/জ্যেষ্ঠা", "MOOLA/মূল", "PURVASHADA/পূর্বষাঢ়", "UTTARASHADA/উত্তরষাঢ়", "SHARAVAN/শ্রাবণ", "DHANISHTA/ধনিষ্ঠা", "SHATABISHA/শতভিষা", "PURVABHADRA/পূর্বভাদ্র", "UTTARABHADRA/উত্তরভাদ্র", "REVATI/রেবতী"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/শুক্ল পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "PURNIMA/পূর্ণিমা"],
                        },
                        {
                            name: "KRISHNA PAKSHA/কৃষ্ণ পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "AMAVASYA/অমাবস্যা"]

                        }
                    ]
                },
                {
                    name: "FALGUN/ফাল্গুন",
                    nakshathira: ["ASHWINI/অশ্বিনী", "BHARANI/ভরণী", "KRITTIKA/কৃত্তিকা", "ROHINI/রোহিণী", "MRIGHASIRA/মৃগশিরা", "ARDRA/আর্দ্রা", "PUNARVASU/পুনর্বসু", "PUSHYA/পুষ্য", "ASHLESHA/অশ্লেষা", "MAGHA/মঘা", "PURVA PHALGUNI/পূর্ব ফাল্গুনী", "UTTARA PHALGUNI/উত্তর ফাল্গুনী", "HASTA/হস্ত", "CHITRA/চিত্রা", "SWATI/স্বাতী", "VISHAKA/বিশাখা", "ANURADHA/অনুরাধা", "JYESTHA/জ্যেষ্ঠা", "MOOLA/মূল", "PURVASHADA/পূর্বষাঢ়", "UTTARASHADA/উত্তরষাঢ়", "SHARAVAN/শ্রাবণ", "DHANISHTA/ধনিষ্ঠা", "SHATABISHA/শতভিষা", "PURVABHADRA/পূর্বভাদ্র", "UTTARABHADRA/উত্তরভাদ্র", "REVATI/রেবতী"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/শুক্ল পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "PURNIMA/পূর্ণিমা"],
                        },
                        {
                            name: "KRISHNA PAKSHA/কৃষ্ণ পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "AMAVASYA/অমাবস্যা"]

                        }
                    ]
                },
                {
                    name: "CHAITRA/চৈত্র",
                    nakshathira: ["ASHWINI/অশ্বিনী", "BHARANI/ভরণী", "KRITTIKA/কৃত্তিকা", "ROHINI/রোহিণী", "MRIGHASIRA/মৃগশিরা", "ARDRA/আর্দ্রা", "PUNARVASU/পুনর্বসু", "PUSHYA/পুষ্য", "ASHLESHA/অশ্লেষা", "MAGHA/মঘা", "PURVA PHALGUNI/পূর্ব ফাল্গুনী", "UTTARA PHALGUNI/উত্তর ফাল্গুনী", "HASTA/হস্ত", "CHITRA/চিত্রা", "SWATI/স্বাতী", "VISHAKA/বিশাখা", "ANURADHA/অনুরাধা", "JYESTHA/জ্যেষ্ঠা", "MOOLA/মূল", "PURVASHADA/পূর্বষাঢ়", "UTTARASHADA/উত্তরষাঢ়", "SHARAVAN/শ্রাবণ", "DHANISHTA/ধনিষ্ঠা", "SHATABISHA/শতভিষা", "PURVABHADRA/পূর্বভাদ্র", "UTTARABHADRA/উত্তরভাদ্র", "REVATI/রেবতী"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/শুক্ল পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "PURNIMA/পূর্ণিমা"],
                        },
                        {
                            name: "KRISHNA PAKSHA/কৃষ্ণ পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "AMAVASYA/অমাবস্যা"]

                        }
                    ]
                },

            ]
        },
        {
            name: "Marathi",
            calenderCode: 8,
            month: [
                {
                    name: "CHAITRA/चैत्र",
                    nakshathira: ["ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशीर्ष", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/आश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वा फाल्गुनी", "UTTARA PHALGUNI/उत्तर फाल्गुनी", "HASTA/हस्ता", "CHITRA/चित्रा", "SWATI/स्वाती", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूळा", "PURVASHADA/पूर्वाषाढा", "UTTARASHADA/उत्तराषाढा", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वाभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            "name": "SHUKLA PAKSHA/शुक्ल पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            "name": "KRISHNA PAKSHA/कृष्ण पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]
                        }
                    ]
                },
                {
                    name: "VAISAKHA/वैशाख",
                    nakshathira: ["ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशीर्ष", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/आश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वा फाल्गुनी", "UTTARA PHALGUNI/उत्तर फाल्गुनी", "HASTA/हस्ता", "CHITRA/चित्रा", "SWATI/स्वाती", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूळा", "PURVASHADA/पूर्वाषाढा", "UTTARASHADA/उत्तराषाढा", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वाभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            "name": "SHUKLA PAKSHA/शुक्ल पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            "name": "KRISHNA PAKSHA/कृष्ण पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]
                        }
                    ]
                },
                {
                    name: "JYESTHA/ज्येष्ठा",
                    nakshathira: ["ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशीर्ष", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/आश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वा फाल्गुनी", "UTTARA PHALGUNI/उत्तर फाल्गुनी", "HASTA/हस्ता", "CHITRA/चित्रा", "SWATI/स्वाती", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूळा", "PURVASHADA/पूर्वाषाढा", "UTTARASHADA/उत्तराषाढा", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वाभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            "name": "SHUKLA PAKSHA/शुक्ल पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            "name": "KRISHNA PAKSHA/कृष्ण पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]
                        }
                    ]
                },
                {
                    name: "ASHADHA/आषाढ़",
                    nakshathira: ["ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशीर्ष", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/आश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वा फाल्गुनी", "UTTARA PHALGUNI/उत्तर फाल्गुनी", "HASTA/हस्ता", "CHITRA/चित्रा", "SWATI/स्वाती", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूळा", "PURVASHADA/पूर्वाषाढा", "UTTARASHADA/उत्तराषाढा", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वाभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            "name": "SHUKLA PAKSHA/शुक्ल पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            "name": "KRISHNA PAKSHA/कृष्ण पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]
                        }
                    ]
                },
                {
                    name: "SHRAVANA/श्रावण",
                    nakshathira: ["ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशीर्ष", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/आश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वा फाल्गुनी", "UTTARA PHALGUNI/उत्तर फाल्गुनी", "HASTA/हस्ता", "CHITRA/चित्रा", "SWATI/स्वाती", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूळा", "PURVASHADA/पूर्वाषाढा", "UTTARASHADA/उत्तराषाढा", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वाभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            "name": "SHUKLA PAKSHA/शुक्ल पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            "name": "KRISHNA PAKSHA/कृष्ण पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]
                        }
                    ]
                },
                {
                    name: "BHADRAPADA/भाद्रपद",
                    nakshathira: ["ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशीर्ष", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/आश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वा फाल्गुनी", "UTTARA PHALGUNI/उत्तर फाल्गुनी", "HASTA/हस्ता", "CHITRA/चित्रा", "SWATI/स्वाती", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूळा", "PURVASHADA/पूर्वाषाढा", "UTTARASHADA/उत्तराषाढा", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वाभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            "name": "SHUKLA PAKSHA/शुक्ल पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            "name": "KRISHNA PAKSHA/कृष्ण पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]
                        }
                    ]
                },
                {
                    name: "ASHVIN/अश्विन",
                    nakshathira: ["ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशीर्ष", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/आश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वा फाल्गुनी", "UTTARA PHALGUNI/उत्तर फाल्गुनी", "HASTA/हस्ता", "CHITRA/चित्रा", "SWATI/स्वाती", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूळा", "PURVASHADA/पूर्वाषाढा", "UTTARASHADA/उत्तराषाढा", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वाभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            "name": "SHUKLA PAKSHA/शुक्ल पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            "name": "KRISHNA PAKSHA/कृष्ण पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]
                        }
                    ]
                },
                {
                    name: "KATIKA/कार्तिक/चैत्र",
                    nakshathira: ["ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशीर्ष", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/आश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वा फाल्गुनी", "UTTARA PHALGUNI/उत्तर फाल्गुनी", "HASTA/हस्ता", "CHITRA/चित्रा", "SWATI/स्वाती", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूळा", "PURVASHADA/पूर्वाषाढा", "UTTARASHADA/उत्तराषाढा", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वाभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            "name": "SHUKLA PAKSHA/शुक्ल पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            "name": "KRISHNA PAKSHA/कृष्ण पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]
                        }
                    ]
                },
                {
                    name: "MARGASIRSA/मार्गशीर्ष",
                    nakshathira: ["ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशीर्ष", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/आश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वा फाल्गुनी", "UTTARA PHALGUNI/उत्तर फाल्गुनी", "HASTA/हस्ता", "CHITRA/चित्रा", "SWATI/स्वाती", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूळा", "PURVASHADA/पूर्वाषाढा", "UTTARASHADA/उत्तराषाढा", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वाभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            "name": "SHUKLA PAKSHA/शुक्ल पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            "name": "KRISHNA PAKSHA/कृष्ण पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]
                        }
                    ]
                },
                {
                    name: "PAUSHA/पौष",
                    nakshathira: ["ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशीर्ष", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/आश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वा फाल्गुनी", "UTTARA PHALGUNI/उत्तर फाल्गुनी", "HASTA/हस्ता", "CHITRA/चित्रा", "SWATI/स्वाती", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूळा", "PURVASHADA/पूर्वाषाढा", "UTTARASHADA/उत्तराषाढा", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वाभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            "name": "SHUKLA PAKSHA/शुक्ल पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            "name": "KRISHNA PAKSHA/कृष्ण पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]
                        }
                    ]
                },
                {
                    name: "MAGH/माघ",
                    nakshathira: ["ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशीर्ष", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/आश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वा फाल्गुनी", "UTTARA PHALGUNI/उत्तर फाल्गुनी", "HASTA/हस्ता", "CHITRA/चित्रा", "SWATI/स्वाती", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूळा", "PURVASHADA/पूर्वाषाढा", "UTTARASHADA/उत्तराषाढा", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वाभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            "name": "SHUKLA PAKSHA/शुक्ल पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            "name": "KRISHNA PAKSHA/कृष्ण पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]
                        }
                    ]
                },
                {
                    name: "PHALGUNA/फाल्गुन",
                    nakshathira: ["ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशीर्ष", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/आश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वा फाल्गुनी", "UTTARA PHALGUNI/उत्तर फाल्गुनी", "HASTA/हस्ता", "CHITRA/चित्रा", "SWATI/स्वाती", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूळा", "PURVASHADA/पूर्वाषाढा", "UTTARASHADA/उत्तराषाढा", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वाभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            "name": "SHUKLA PAKSHA/शुक्ल पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            "name": "KRISHNA PAKSHA/कृष्ण पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]
                        }
                    ]
                },
            ]
        }



    ]

    const Calender = [
        {
            name: "Tamil",
            calenderCode: 1,
            month: [{
                name: "CHITHIRAI/சித்திரை",
                nakshathira: ["ASHWINI/அஸ்வினி", "BHARANI/பரணி", "KRITTIKA/கிருத்திகை", "ROHINI/ரோகிணி", "MRIGHASIRA/மிருகசிரம்", "ARDRA/திருவாதிரை", "PUNARVASU/புனர்பூசம்", "PUSHYA/பூசம்", "ASHLESHA/ஆயில்யம்", "MAGHA/மகம்", "PURVA PHALGUNI/பூரம்", "UTTARA PHALGUNI/உத்திரம்", "HASTA/ஹஸ்தம்", "CHITRA/சித்திரை", "SWATI/சுவாதி", "VISHAKA/விசாகம்", "ANURADHA/அனுஷம்", "JYESTHA/கேட்டை", "MOOLA/மூலம்", "PURVASHADA/பூராடம்", "UTTARASHADA/உத்திராடம்", "SHARAVAN/திருவோணம்", "DHANISHTA/அவிட்டம்", "SHATABISHA/சதயம்", "PURVABHADRA/பூரட்டாதி", "UTTARABHADRA/உத்திரட்டாதி", "REVATI/ரேவதி"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/சுக்ல பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "PURNIMA/பௌர்ணமி"],
                    },
                    {
                        name: "KRISHNA PAKSA/கிருஷ்ண பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "AMAVASYA/அமாவாசை"]

                    }
                ]


            },
            {
                name: "VAIGAASI/வைகாசி",
                nakshathira: ["ASHWINI/அஸ்வினி", "BHARANI/பரணி", "KRITTIKA/கிருத்திகை", "ROHINI/ரோகிணி", "MRIGHASIRA/மிருகசிரம்", "ARDRA/திருவாதிரை", "PUNARVASU/புனர்பூசம்", "PUSHYA/பூசம்", "ASHLESHA/ஆயில்யம்", "MAGHA/மகம்", "PURVA PHALGUNI/பூரம்", "UTTARA PHALGUNI/உத்திரம்", "HASTA/ஹஸ்தம்", "CHITRA/சித்திரை", "SWATI/சுவாதி", "VISHAKA/விசாகம்", "ANURADHA/அனுஷம்", "JYESTHA/கேட்டை", "MOOLA/மூலம்", "PURVASHADA/பூராடம்", "UTTARASHADA/உத்திராடம்", "SHARAVAN/திருவோணம்", "DHANISHTA/அவிட்டம்", "SHATABISHA/சதயம்", "PURVABHADRA/பூரட்டாதி", "UTTARABHADRA/உத்திரட்டாதி", "REVATI/ரேவதி"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/சுக்ல பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "PURNIMA/பௌர்ணமி"],
                    },
                    {
                        name: "KRISHNA PAKSA/கிருஷ்ண பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "AMAVASYA/அமாவாசை"]

                    }
                ]


            },
            {
                name: "AANI/ஆனி",
                nakshathira: ["ASHWINI/அஸ்வினி", "BHARANI/பரணி", "KRITTIKA/கிருத்திகை", "ROHINI/ரோகிணி", "MRIGHASIRA/மிருகசிரம்", "ARDRA/திருவாதிரை", "PUNARVASU/புனர்பூசம்", "PUSHYA/பூசம்", "ASHLESHA/ஆயில்யம்", "MAGHA/மகம்", "PURVA PHALGUNI/பூரம்", "UTTARA PHALGUNI/உத்திரம்", "HASTA/ஹஸ்தம்", "CHITRA/சித்திரை", "SWATI/சுவாதி", "VISHAKA/விசாகம்", "ANURADHA/அனுஷம்", "JYESTHA/கேட்டை", "MOOLA/மூலம்", "PURVASHADA/பூராடம்", "UTTARASHADA/உத்திராடம்", "SHARAVAN/திருவோணம்", "DHANISHTA/அவிட்டம்", "SHATABISHA/சதயம்", "PURVABHADRA/பூரட்டாதி", "UTTARABHADRA/உத்திரட்டாதி", "REVATI/ரேவதி"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/சுக்ல பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "PURNIMA/பௌர்ணமி"],
                    },
                    {
                        name: "KRISHNA PAKSA/கிருஷ்ண பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "AMAVASYA/அமாவாசை"]

                    }
                ]

            },
            {
                name: "AADI/ஆடி",
                nakshathira: ["ASHWINI/அஸ்வினி", "BHARANI/பரணி", "KRITTIKA/கிருத்திகை", "ROHINI/ரோகிணி", "MRIGHASIRA/மிருகசிரம்", "ARDRA/திருவாதிரை", "PUNARVASU/புனர்பூசம்", "PUSHYA/பூசம்", "ASHLESHA/ஆயில்யம்", "MAGHA/மகம்", "PURVA PHALGUNI/பூரம்", "UTTARA PHALGUNI/உத்திரம்", "HASTA/ஹஸ்தம்", "CHITRA/சித்திரை", "SWATI/சுவாதி", "VISHAKA/விசாகம்", "ANURADHA/அனுஷம்", "JYESTHA/கேட்டை", "MOOLA/மூலம்", "PURVASHADA/பூராடம்", "UTTARASHADA/உத்திராடம்", "SHARAVAN/திருவோணம்", "DHANISHTA/அவிட்டம்", "SHATABISHA/சதயம்", "PURVABHADRA/பூரட்டாதி", "UTTARABHADRA/உத்திரட்டாதி", "REVATI/ரேவதி"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/சுக்ல பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "PURNIMA/பௌர்ணமி"],
                    },
                    {
                        name: "KRISHNA PAKSA/கிருஷ்ண பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "AMAVASYA/அமாவாசை"]

                    }
                ]

            },
            {
                name: "AAVANI/ஆவணி",
                nakshathira: ["ASHWINI/அஸ்வினி", "BHARANI/பரணி", "KRITTIKA/கிருத்திகை", "ROHINI/ரோகிணி", "MRIGHASIRA/மிருகசிரம்", "ARDRA/திருவாதிரை", "PUNARVASU/புனர்பூசம்", "PUSHYA/பூசம்", "ASHLESHA/ஆயில்யம்", "MAGHA/மகம்", "PURVA PHALGUNI/பூரம்", "UTTARA PHALGUNI/உத்திரம்", "HASTA/ஹஸ்தம்", "CHITRA/சித்திரை", "SWATI/சுவாதி", "VISHAKA/விசாகம்", "ANURADHA/அனுஷம்", "JYESTHA/கேட்டை", "MOOLA/மூலம்", "PURVASHADA/பூராடம்", "UTTARASHADA/உத்திராடம்", "SHARAVAN/திருவோணம்", "DHANISHTA/அவிட்டம்", "SHATABISHA/சதயம்", "PURVABHADRA/பூரட்டாதி", "UTTARABHADRA/உத்திரட்டாதி", "REVATI/ரேவதி"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/சுக்ல பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "PURNIMA/பௌர்ணமி"],
                    },
                    {
                        name: "KRISHNA PAKSA/கிருஷ்ண பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "AMAVASYA/அமாவாசை"]

                    }
                ]

            },
            {
                name: "PURATTASI/புரட்டாசி",
                nakshathira: ["ASHWINI/அஸ்வினி", "BHARANI/பரணி", "KRITTIKA/கிருத்திகை", "ROHINI/ரோகிணி", "MRIGHASIRA/மிருகசிரம்", "ARDRA/திருவாதிரை", "PUNARVASU/புனர்பூசம்", "PUSHYA/பூசம்", "ASHLESHA/ஆயில்யம்", "MAGHA/மகம்", "PURVA PHALGUNI/பூரம்", "UTTARA PHALGUNI/உத்திரம்", "HASTA/ஹஸ்தம்", "CHITRA/சித்திரை", "SWATI/சுவாதி", "VISHAKA/விசாகம்", "ANURADHA/அனுஷம்", "JYESTHA/கேட்டை", "MOOLA/மூலம்", "PURVASHADA/பூராடம்", "UTTARASHADA/உத்திராடம்", "SHARAVAN/திருவோணம்", "DHANISHTA/அவிட்டம்", "SHATABISHA/சதயம்", "PURVABHADRA/பூரட்டாதி", "UTTARABHADRA/உத்திரட்டாதி", "REVATI/ரேவதி"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/சுக்ல பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "PURNIMA/பௌர்ணமி"],
                    },
                    {
                        name: "KRISHNA PAKSA/கிருஷ்ண பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "AMAVASYA/அமாவாசை"]

                    }
                ]

            },
            {
                name: "AYPPASI/ஐப்பசி",
                nakshathira: ["ASHWINI/அஸ்வினி", "BHARANI/பரணி", "KRITTIKA/கிருத்திகை", "ROHINI/ரோகிணி", "MRIGHASIRA/மிருகசிரம்", "ARDRA/திருவாதிரை", "PUNARVASU/புனர்பூசம்", "PUSHYA/பூசம்", "ASHLESHA/ஆயில்யம்", "MAGHA/மகம்", "PURVA PHALGUNI/பூரம்", "UTTARA PHALGUNI/உத்திரம்", "HASTA/ஹஸ்தம்", "CHITRA/சித்திரை", "SWATI/சுவாதி", "VISHAKA/விசாகம்", "ANURADHA/அனுஷம்", "JYESTHA/கேட்டை", "MOOLA/மூலம்", "PURVASHADA/பூராடம்", "UTTARASHADA/உத்திராடம்", "SHARAVAN/திருவோணம்", "DHANISHTA/அவிட்டம்", "SHATABISHA/சதயம்", "PURVABHADRA/பூரட்டாதி", "UTTARABHADRA/உத்திரட்டாதி", "REVATI/ரேவதி"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/சுக்ல பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "PURNIMA/பௌர்ணமி"],
                    },
                    {
                        name: "KRISHNA PAKSA/கிருஷ்ண பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "AMAVASYA/அமாவாசை"]

                    }
                ]

            },
            {
                name: "KARTHIGAI/கார்த்திகை",
                nakshathira: ["ASHWINI/அஸ்வினி", "BHARANI/பரணி", "KRITTIKA/கிருத்திகை", "ROHINI/ரோகிணி", "MRIGHASIRA/மிருகசிரம்", "ARDRA/திருவாதிரை", "PUNARVASU/புனர்பூசம்", "PUSHYA/பூசம்", "ASHLESHA/ஆயில்யம்", "MAGHA/மகம்", "PURVA PHALGUNI/பூரம்", "UTTARA PHALGUNI/உத்திரம்", "HASTA/ஹஸ்தம்", "CHITRA/சித்திரை", "SWATI/சுவாதி", "VISHAKA/விசாகம்", "ANURADHA/அனுஷம்", "JYESTHA/கேட்டை", "MOOLA/மூலம்", "PURVASHADA/பூராடம்", "UTTARASHADA/உத்திராடம்", "SHARAVAN/திருவோணம்", "DHANISHTA/அவிட்டம்", "SHATABISHA/சதயம்", "PURVABHADRA/பூரட்டாதி", "UTTARABHADRA/உத்திரட்டாதி", "REVATI/ரேவதி"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/சுக்ல பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "PURNIMA/பௌர்ணமி"],
                    },
                    {
                        name: "KRISHNA PAKSA/கிருஷ்ண பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "AMAVASYA/அமாவாசை"]

                    }
                ]

            },
            {
                name: "MARGAZHI/மார்கழி",
                nakshathira: ["ASHWINI/அஸ்வினி", "BHARANI/பரணி", "KRITTIKA/கிருத்திகை", "ROHINI/ரோகிணி", "MRIGHASIRA/மிருகசிரம்", "ARDRA/திருவாதிரை", "PUNARVASU/புனர்பூசம்", "PUSHYA/பூசம்", "ASHLESHA/ஆயில்யம்", "MAGHA/மகம்", "PURVA PHALGUNI/பூரம்", "UTTARA PHALGUNI/உத்திரம்", "HASTA/ஹஸ்தம்", "CHITRA/சித்திரை", "SWATI/சுவாதி", "VISHAKA/விசாகம்", "ANURADHA/அனுஷம்", "JYESTHA/கேட்டை", "MOOLA/மூலம்", "PURVASHADA/பூராடம்", "UTTARASHADA/உத்திராடம்", "SHARAVAN/திருவோணம்", "DHANISHTA/அவிட்டம்", "SHATABISHA/சதயம்", "PURVABHADRA/பூரட்டாதி", "UTTARABHADRA/உத்திரட்டாதி", "REVATI/ரேவதி"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/சுக்ல பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "PURNIMA/பௌர்ணமி"],
                    },
                    {
                        name: "KRISHNA PAKSA/கிருஷ்ண பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "AMAVASYA/அமாவாசை"]

                    }
                ]

            },
            {
                name: "THAI/தை",
                nakshathira: ["ASHWINI/அஸ்வினி", "BHARANI/பரணி", "KRITTIKA/கிருத்திகை", "ROHINI/ரோகிணி", "MRIGHASIRA/மிருகசிரம்", "ARDRA/திருவாதிரை", "PUNARVASU/புனர்பூசம்", "PUSHYA/பூசம்", "ASHLESHA/ஆயில்யம்", "MAGHA/மகம்", "PURVA PHALGUNI/பூரம்", "UTTARA PHALGUNI/உத்திரம்", "HASTA/ஹஸ்தம்", "CHITRA/சித்திரை", "SWATI/சுவாதி", "VISHAKA/விசாகம்", "ANURADHA/அனுஷம்", "JYESTHA/கேட்டை", "MOOLA/மூலம்", "PURVASHADA/பூராடம்", "UTTARASHADA/உத்திராடம்", "SHARAVAN/திருவோணம்", "DHANISHTA/அவிட்டம்", "SHATABISHA/சதயம்", "PURVABHADRA/பூரட்டாதி", "UTTARABHADRA/உத்திரட்டாதி", "REVATI/ரேவதி"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/சுக்ல பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "PURNIMA/பௌர்ணமி"],
                    },
                    {
                        name: "KRISHNA PAKSA/கிருஷ்ண பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "AMAVASYA/அமாவாசை"]

                    }
                ]

            },
            {
                name: "MAASI/மாசி",
                nakshathira: ["ASHWINI/அஸ்வினி", "BHARANI/பரணி", "KRITTIKA/கிருத்திகை", "ROHINI/ரோகிணி", "MRIGHASIRA/மிருகசிரம்", "ARDRA/திருவாதிரை", "PUNARVASU/புனர்பூசம்", "PUSHYA/பூசம்", "ASHLESHA/ஆயில்யம்", "MAGHA/மகம்", "PURVA PHALGUNI/பூரம்", "UTTARA PHALGUNI/உத்திரம்", "HASTA/ஹஸ்தம்", "CHITRA/சித்திரை", "SWATI/சுவாதி", "VISHAKA/விசாகம்", "ANURADHA/அனுஷம்", "JYESTHA/கேட்டை", "MOOLA/மூலம்", "PURVASHADA/பூராடம்", "UTTARASHADA/உத்திராடம்", "SHARAVAN/திருவோணம்", "DHANISHTA/அவிட்டம்", "SHATABISHA/சதயம்", "PURVABHADRA/பூரட்டாதி", "UTTARABHADRA/உத்திரட்டாதி", "REVATI/ரேவதி"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/சுக்ல பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "PURNIMA/பௌர்ணமி"],
                    },
                    {
                        name: "KRISHNA PAKSA/கிருஷ்ண பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "AMAVASYA/அமாவாசை"]

                    }
                ]

            },
            {
                name: "PANGUNI/பங்குனி",
                nakshathira: ["ASHWINI/அஸ்வினி", "BHARANI/பரணி", "KRITTIKA/கிருத்திகை", "ROHINI/ரோகிணி", "MRIGHASIRA/மிருகசிரம்", "ARDRA/திருவாதிரை", "PUNARVASU/புனர்பூசம்", "PUSHYA/பூசம்", "ASHLESHA/ஆயில்யம்", "MAGHA/மகம்", "PURVA PHALGUNI/பூரம்", "UTTARA PHALGUNI/உத்திரம்", "HASTA/ஹஸ்தம்", "CHITRA/சித்திரை", "SWATI/சுவாதி", "VISHAKA/விசாகம்", "ANURADHA/அனுஷம்", "JYESTHA/கேட்டை", "MOOLA/மூலம்", "PURVASHADA/பூராடம்", "UTTARASHADA/உத்திராடம்", "SHARAVAN/திருவோணம்", "DHANISHTA/அவிட்டம்", "SHATABISHA/சதயம்", "PURVABHADRA/பூரட்டாதி", "UTTARABHADRA/உத்திரட்டாதி", "REVATI/ரேவதி"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/சுக்ல பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "PURNIMA/பௌர்ணமி"],
                    },
                    {
                        name: "KRISHNA PAKSA/கிருஷ்ண பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "AMAVASYA/அமாவாசை"]

                    }
                ]

            }

            ]
        },
        {
            name: "Malayalam",
            calenderCode: 2,
            month: [
                {
                    name: "MEENAM/മീനം",
                    nakshathira: ["ASHWINI/അശ്വനി", "BHARANI/ഭരണി", "KRITTIKA/കൃത്തിക", "ROHINI/രോഹിണി", "MRIGHASIRA/മൃഗശിര", "ARDRA/അർദ്ധ", "PUNARVASU/പൂർണ്ണവാസു", "PUSHYA/പൂഷ്യ", "ASHLESHA/അശ്വതി", "MAGHA/മകം", "PURVA PHALGUNI/പുർവഫാൽഗുണി", "UTTARA PHALGUNI/ഉത്തരാഫാൽഗുണി", "HASTA/ഹസ്ത", "CHITRA/ചിത", "SWATI/സ്വാതി", "VISHAKA/വിശാഖം", "ANURADHA/അനുരാധ", "JYESTHA/തിരുവാതിര", "MOOLA/മൂലം", "PURVASHADA/പുർവാഷാഢ", "UTTARASHADA/ഉത്തരാഷാഢ", "SHARAVAN/ശ്രാവണം", "DHANISHTA/ധനിഷ്ഠ", "SHATABISHA/ചിത്തിര", "PURVABHADRA/പുർവാഭദ്ര", "UTTARABHADRA/ഉത്തരാഭദ്ര", "REVATI/രേവതി"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/വെളുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "PURNIMA/പൂർണ്ണിമ"],
                        },
                        {
                            name: "KRISHNA PAKSHA/കറുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "AMAVASYA/അമാവാസി"]

                        }
                    ]



                },
                {
                    name: "MEDAM/മെഡാം",
                    nakshathira: ["ASHWINI/അശ്വനി", "BHARANI/ഭരണി", "KRITTIKA/കൃത്തിക", "ROHINI/രോഹിണി", "MRIGHASIRA/മൃഗശിര", "ARDRA/അർദ്ധ", "PUNARVASU/പൂർണ്ണവാസു", "PUSHYA/പൂഷ്യ", "ASHLESHA/അശ്വതി", "MAGHA/മകം", "PURVA PHALGUNI/പുർവഫാൽഗുണി", "UTTARA PHALGUNI/ഉത്തരാഫാൽഗുണി", "HASTA/ഹസ്ത", "CHITRA/ചിത", "SWATI/സ്വാതി", "VISHAKA/വിശാഖം", "ANURADHA/അനുരാധ", "JYESTHA/തിരുവാതിര", "MOOLA/മൂലം", "PURVASHADA/പുർവാഷാഢ", "UTTARASHADA/ഉത്തരാഷാഢ", "SHARAVAN/ശ്രാവണം", "DHANISHTA/ധനിഷ്ഠ", "SHATABISHA/ചിത്തിര", "PURVABHADRA/പുർവാഭദ്ര", "UTTARABHADRA/ഉത്തരാഭദ്ര", "REVATI/രേവതി"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/വെളുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "PURNIMA/പൂർണ്ണിമ"],
                        },
                        {
                            name: "KRISHNA PAKSHA/കറുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "AMAVASYA/അമാവാസി"]

                        }
                    ]

                },
                {
                    name: "EDAVAM/എദാവം",
                    nakshathira: ["ASHWINI/അശ്വനി", "BHARANI/ഭരണി", "KRITTIKA/കൃത്തിക", "ROHINI/രോഹിണി", "MRIGHASIRA/മൃഗശിര", "ARDRA/അർദ്ധ", "PUNARVASU/പൂർണ്ണവാസു", "PUSHYA/പൂഷ്യ", "ASHLESHA/അശ്വതി", "MAGHA/മകം", "PURVA PHALGUNI/പുർവഫാൽഗുണി", "UTTARA PHALGUNI/ഉത്തരാഫാൽഗുണി", "HASTA/ഹസ്ത", "CHITRA/ചിത", "SWATI/സ്വാതി", "VISHAKA/വിശാഖം", "ANURADHA/അനുരാധ", "JYESTHA/തിരുവാതിര", "MOOLA/മൂലം", "PURVASHADA/പുർവാഷാഢ", "UTTARASHADA/ഉത്തരാഷാഢ", "SHARAVAN/ശ്രാവണം", "DHANISHTA/ധനിഷ്ഠ", "SHATABISHA/ചിത്തിര", "PURVABHADRA/പുർവാഭദ്ര", "UTTARABHADRA/ഉത്തരാഭദ്ര", "REVATI/രേവതി"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/വെളുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "PURNIMA/പൂർണ്ണിമ"],
                        },
                        {
                            name: "KRISHNA PAKSHA/കറുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "AMAVASYA/അമാവാസി"]

                        }
                    ]

                },
                {
                    name: "MITHUNAM/മിഥുനം",
                    nakshathira: ["ASHWINI/അശ്വനി", "BHARANI/ഭരണി", "KRITTIKA/കൃത്തിക", "ROHINI/രോഹിണി", "MRIGHASIRA/മൃഗശിര", "ARDRA/അർദ്ധ", "PUNARVASU/പൂർണ്ണവാസു", "PUSHYA/പൂഷ്യ", "ASHLESHA/അശ്വതി", "MAGHA/മകം", "PURVA PHALGUNI/പുർവഫാൽഗുണി", "UTTARA PHALGUNI/ഉത്തരാഫാൽഗുണി", "HASTA/ഹസ്ത", "CHITRA/ചിത", "SWATI/സ്വാതി", "VISHAKA/വിശാഖം", "ANURADHA/അനുരാധ", "JYESTHA/തിരുവാതിര", "MOOLA/മൂലം", "PURVASHADA/പുർവാഷാഢ", "UTTARASHADA/ഉത്തരാഷാഢ", "SHARAVAN/ശ്രാവണം", "DHANISHTA/ധനിഷ്ഠ", "SHATABISHA/ചിത്തിര", "PURVABHADRA/പുർവാഭദ്ര", "UTTARABHADRA/ഉത്തരാഭദ്ര", "REVATI/രേവതി"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/വെളുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "PURNIMA/പൂർണ്ണിമ"],
                        },
                        {
                            name: "KRISHNA PAKSHA/കറുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "AMAVASYA/അമാവാസി"]

                        }
                    ]

                },
                {
                    name: "KARKADAKAM/കാർകിഡകാം",
                    nakshathira: ["ASHWINI/അശ്വനി", "BHARANI/ഭരണി", "KRITTIKA/കൃത്തിക", "ROHINI/രോഹിണി", "MRIGHASIRA/മൃഗശിര", "ARDRA/അർദ്ധ", "PUNARVASU/പൂർണ്ണവാസു", "PUSHYA/പൂഷ്യ", "ASHLESHA/അശ്വതി", "MAGHA/മകം", "PURVA PHALGUNI/പുർവഫാൽഗുണി", "UTTARA PHALGUNI/ഉത്തരാഫാൽഗുണി", "HASTA/ഹസ്ത", "CHITRA/ചിത", "SWATI/സ്വാതി", "VISHAKA/വിശാഖം", "ANURADHA/അനുരാധ", "JYESTHA/തിരുവാതിര", "MOOLA/മൂലം", "PURVASHADA/പുർവാഷാഢ", "UTTARASHADA/ഉത്തരാഷാഢ", "SHARAVAN/ശ്രാവണം", "DHANISHTA/ധനിഷ്ഠ", "SHATABISHA/ചിത്തിര", "PURVABHADRA/പുർവാഭദ്ര", "UTTARABHADRA/ഉത്തരാഭദ്ര", "REVATI/രേവതി"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/വെളുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "PURNIMA/പൂർണ്ണിമ"],
                        },
                        {
                            name: "KRISHNA PAKSHA/കറുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "AMAVASYA/അമാവാസി"]

                        }
                    ]

                },
                {
                    name: "CHINGAM/ചിങ്കാം",
                    nakshathira: ["ASHWINI/അശ്വനി", "BHARANI/ഭരണി", "KRITTIKA/കൃത്തിക", "ROHINI/രോഹിണി", "MRIGHASIRA/മൃഗശിര", "ARDRA/അർദ്ധ", "PUNARVASU/പൂർണ്ണവാസു", "PUSHYA/പൂഷ്യ", "ASHLESHA/അശ്വതി", "MAGHA/മകം", "PURVA PHALGUNI/പുർവഫാൽഗുണി", "UTTARA PHALGUNI/ഉത്തരാഫാൽഗുണി", "HASTA/ഹസ്ത", "CHITRA/ചിത", "SWATI/സ്വാതി", "VISHAKA/വിശാഖം", "ANURADHA/അനുരാധ", "JYESTHA/തിരുവാതിര", "MOOLA/മൂലം", "PURVASHADA/പുർവാഷാഢ", "UTTARASHADA/ഉത്തരാഷാഢ", "SHARAVAN/ശ്രാവണം", "DHANISHTA/ധനിഷ്ഠ", "SHATABISHA/ചിത്തിര", "PURVABHADRA/പുർവാഭദ്ര", "UTTARABHADRA/ഉത്തരാഭദ്ര", "REVATI/രേവതി"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/വെളുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "PURNIMA/പൂർണ്ണിമ"],
                        },
                        {
                            name: "KRISHNA PAKSHA/കറുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "AMAVASYA/അമാവാസി"]

                        }
                    ]

                },
                {
                    name: "KANNI/കാനി",
                    nakshathira: ["ASHWINI/അശ്വനി", "BHARANI/ഭരണി", "KRITTIKA/കൃത്തിക", "ROHINI/രോഹിണി", "MRIGHASIRA/മൃഗശിര", "ARDRA/അർദ്ധ", "PUNARVASU/പൂർണ്ണവാസു", "PUSHYA/പൂഷ്യ", "ASHLESHA/അശ്വതി", "MAGHA/മകം", "PURVA PHALGUNI/പുർവഫാൽഗുണി", "UTTARA PHALGUNI/ഉത്തരാഫാൽഗുണി", "HASTA/ഹസ്ത", "CHITRA/ചിത", "SWATI/സ്വാതി", "VISHAKA/വിശാഖം", "ANURADHA/അനുരാധ", "JYESTHA/തിരുവാതിര", "MOOLA/മൂലം", "PURVASHADA/പുർവാഷാഢ", "UTTARASHADA/ഉത്തരാഷാഢ", "SHARAVAN/ശ്രാവണം", "DHANISHTA/ധനിഷ്ഠ", "SHATABISHA/ചിത്തിര", "PURVABHADRA/പുർവാഭദ്ര", "UTTARABHADRA/ഉത്തരാഭദ്ര", "REVATI/രേവതി"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/വെളുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "PURNIMA/പൂർണ്ണിമ"],
                        },
                        {
                            name: "KRISHNA PAKSHA/കറുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "AMAVASYA/അമാവാസി"]

                        }
                    ]

                },
                {
                    name: "THULAM/തുലാം",
                    nakshathira: ["ASHWINI/അശ്വനി", "BHARANI/ഭരണി", "KRITTIKA/കൃത്തിക", "ROHINI/രോഹിണി", "MRIGHASIRA/മൃഗശിര", "ARDRA/അർദ്ധ", "PUNARVASU/പൂർണ്ണവാസു", "PUSHYA/പൂഷ്യ", "ASHLESHA/അശ്വതി", "MAGHA/മകം", "PURVA PHALGUNI/പുർവഫാൽഗുണി", "UTTARA PHALGUNI/ഉത്തരാഫാൽഗുണി", "HASTA/ഹസ്ത", "CHITRA/ചിത", "SWATI/സ്വാതി", "VISHAKA/വിശാഖം", "ANURADHA/അനുരാധ", "JYESTHA/തിരുവാതിര", "MOOLA/മൂലം", "PURVASHADA/പുർവാഷാഢ", "UTTARASHADA/ഉത്തരാഷാഢ", "SHARAVAN/ശ്രാവണം", "DHANISHTA/ധനിഷ്ഠ", "SHATABISHA/ചിത്തിര", "PURVABHADRA/പുർവാഭദ്ര", "UTTARABHADRA/ഉത്തരാഭദ്ര", "REVATI/രേവതി"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/വെളുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "PURNIMA/പൂർണ്ണിമ"],
                        },
                        {
                            name: "KRISHNA PAKSHA/കറുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "AMAVASYA/അമാവാസി"]

                        }
                    ]

                },
                {
                    name: "VRISHCHIKAM/വ്രിഷിക്കാം",
                    nakshathira: ["ASHWINI/അശ്വനി", "BHARANI/ഭരണി", "KRITTIKA/കൃത്തിക", "ROHINI/രോഹിണി", "MRIGHASIRA/മൃഗശിര", "ARDRA/അർദ്ധ", "PUNARVASU/പൂർണ്ണവാസു", "PUSHYA/പൂഷ്യ", "ASHLESHA/അശ്വതി", "MAGHA/മകം", "PURVA PHALGUNI/പുർവഫാൽഗുണി", "UTTARA PHALGUNI/ഉത്തരാഫാൽഗുണി", "HASTA/ഹസ്ത", "CHITRA/ചിത", "SWATI/സ്വാതി", "VISHAKA/വിശാഖം", "ANURADHA/അനുരാധ", "JYESTHA/തിരുവാതിര", "MOOLA/മൂലം", "PURVASHADA/പുർവാഷാഢ", "UTTARASHADA/ഉത്തരാഷാഢ", "SHARAVAN/ശ്രാവണം", "DHANISHTA/ധനിഷ്ഠ", "SHATABISHA/ചിത്തിര", "PURVABHADRA/പുർവാഭദ്ര", "UTTARABHADRA/ഉത്തരാഭദ്ര", "REVATI/രേവതി"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/വെളുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "PURNIMA/പൂർണ്ണിമ"],
                        },
                        {
                            name: "KRISHNA PAKSHA/കറുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "AMAVASYA/അമാവാസി"]

                        }
                    ]

                },
                {
                    name: "DHANU/ഭാനു",
                    nakshathira: ["ASHWINI/അശ്വനി", "BHARANI/ഭരണി", "KRITTIKA/കൃത്തിക", "ROHINI/രോഹിണി", "MRIGHASIRA/മൃഗശിര", "ARDRA/അർദ്ധ", "PUNARVASU/പൂർണ്ണവാസു", "PUSHYA/പൂഷ്യ", "ASHLESHA/അശ്വതി", "MAGHA/മകം", "PURVA PHALGUNI/പുർവഫാൽഗുണി", "UTTARA PHALGUNI/ഉത്തരാഫാൽഗുണി", "HASTA/ഹസ്ത", "CHITRA/ചിത", "SWATI/സ്വാതി", "VISHAKA/വിശാഖം", "ANURADHA/അനുരാധ", "JYESTHA/തിരുവാതിര", "MOOLA/മൂലം", "PURVASHADA/പുർവാഷാഢ", "UTTARASHADA/ഉത്തരാഷാഢ", "SHARAVAN/ശ്രാവണം", "DHANISHTA/ധനിഷ്ഠ", "SHATABISHA/ചിത്തിര", "PURVABHADRA/പുർവാഭദ്ര", "UTTARABHADRA/ഉത്തരാഭദ്ര", "REVATI/രേവതി"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/വെളുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "PURNIMA/പൂർണ്ണിമ"],
                        },
                        {
                            name: "KRISHNA PAKSHA/കറുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "AMAVASYA/അമാവാസി"]

                        }
                    ]

                },
                {
                    name: "MAKARAM/മകരം",
                    nakshathira: ["ASHWINI/അശ്വനി", "BHARANI/ഭരണി", "KRITTIKA/കൃത്തിക", "ROHINI/രോഹിണി", "MRIGHASIRA/മൃഗശിര", "ARDRA/അർദ്ധ", "PUNARVASU/പൂർണ്ണവാസു", "PUSHYA/പൂഷ്യ", "ASHLESHA/അശ്വതി", "MAGHA/മകം", "PURVA PHALGUNI/പുർവഫാൽഗുണി", "UTTARA PHALGUNI/ഉത്തരാഫാൽഗുണി", "HASTA/ഹസ്ത", "CHITRA/ചിത", "SWATI/സ്വാതി", "VISHAKA/വിശാഖം", "ANURADHA/അനുരാധ", "JYESTHA/തിരുവാതിര", "MOOLA/മൂലം", "PURVASHADA/പുർവാഷാഢ", "UTTARASHADA/ഉത്തരാഷാഢ", "SHARAVAN/ശ്രാവണം", "DHANISHTA/ധനിഷ്ഠ", "SHATABISHA/ചിത്തിര", "PURVABHADRA/പുർവാഭദ്ര", "UTTARABHADRA/ഉത്തരാഭദ്ര", "REVATI/രേവതി"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/വെളുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "PURNIMA/പൂർണ്ണിമ"],
                        },
                        {
                            name: "KRISHNA PAKSHA/കറുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "AMAVASYA/അമാവാസി"]

                        }
                    ]

                },
                {
                    name: "KUMBHAM/കുംഹാം",
                    nakshathira: ["ASHWINI/അശ്വനി", "BHARANI/ഭരണി", "KRITTIKA/കൃത്തിക", "ROHINI/രോഹിണി", "MRIGHASIRA/മൃഗശിര", "ARDRA/അർദ്ധ", "PUNARVASU/പൂർണ്ണവാസു", "PUSHYA/പൂഷ്യ", "ASHLESHA/അശ്വതി", "MAGHA/മകം", "PURVA PHALGUNI/പുർവഫാൽഗുണി", "UTTARA PHALGUNI/ഉത്തരാഫാൽഗുണി", "HASTA/ഹസ്ത", "CHITRA/ചിത", "SWATI/സ്വാതി", "VISHAKA/വിശാഖം", "ANURADHA/അനുരാധ", "JYESTHA/തിരുവാതിര", "MOOLA/മൂലം", "PURVASHADA/പുർവാഷാഢ", "UTTARASHADA/ഉത്തരാഷാഢ", "SHARAVAN/ശ്രാവണം", "DHANISHTA/ധനിഷ്ഠ", "SHATABISHA/ചിത്തിര", "PURVABHADRA/പുർവാഭദ്ര", "UTTARABHADRA/ഉത്തരാഭദ്ര", "REVATI/രേവതി"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/വെളുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "PURNIMA/പൂർണ്ണിമ"],
                        },
                        {
                            name: "KRISHNA PAKSHA/കറുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "AMAVASYA/അമാവാസി"]

                        }
                    ]

                }
            ]
        },
        {
            name: "Telugu",
            calenderCode: 3,
            month: [
                {
                    name: "CHAITHRAMU/చైత్రము",
                    nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/శుక్ల పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి"],
                        },
                        {
                            name: "KRISHNA PAKSHA/కృష్ణ పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "AMAVASYA/అమావాస్య"]

                        }
                    ]
                },
                {
                    name: "VAISAAKHAMU/వైశాఖము",
                    nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/శుక్ల పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి"],
                        },
                        {
                            name: "KRISHNA PAKSHA/కృష్ణ పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "AMAVASYA/అమావాస్య"]

                        }
                    ]

                },
                {
                    name: "JYESTHAMU/జ్యేష్ఠము",
                    nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/శుక్ల పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి"],
                        },
                        {
                            name: "KRISHNA PAKSHA/కృష్ణ పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "AMAVASYA/అమావాస్య"]

                        }
                    ]

                },
                {
                    name: "ASHADHAMU/ఆషాఢము",
                    nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/శుక్ల పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి"],
                        },
                        {
                            name: "KRISHNA PAKSHA/కృష్ణ పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "AMAVASYA/అమావాస్య"]

                        }
                    ]

                },
                // {
                //     name: "Sravanamu (Adhik)/శ్రావణము",
                //     nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
                //     Paksham: [
                //         {
                //             name: "SHUKLA PAKSHA/శుక్ల పక్షం",
                //             tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి"],
                //         },
                //         {
                //             name: "KRISHNA PAKSHA/కృష్ణ పక్షం",
                //             tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "AMAVASYA/అమావాస్య"]

                //         }
                //     ]

                // },
                {
                    name: "SRAVANAMU/శ్రావణము",
                    nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/శుక్ల పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి"],
                        },
                        {
                            name: "KRISHNA PAKSHA/కృష్ణ పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "AMAVASYA/అమావాస్య"]

                        }
                    ]

                },
                {
                    name: "BHADHRAPADAMU/భాద్రపదము",
                    nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/శుక్ల పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి"],
                        },
                        {
                            name: "KRISHNA PAKSHA/కృష్ణ పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "AMAVASYA/అమావాస్య"]

                        }
                    ]

                },
                {
                    name: "AASVAYUJAMU/ఆశ్వయుజము",
                    nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/శుక్ల పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి"],
                        },
                        {
                            name: "KRISHNA PAKSHA/కృష్ణ పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "AMAVASYA/అమావాస్య"]

                        }
                    ]

                },
                {
                    name: "KARTHIKAMU/కార్తీకము",
                    nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/శుక్ల పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి"],
                        },
                        {
                            name: "KRISHNA PAKSHA/కృష్ణ పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "AMAVASYA/అమావాస్య"]

                        }
                    ]

                },
                {
                    name: "MAARGASEERSHAMU/మార్గశీర్షము",
                    nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/శుక్ల పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి"],
                        },
                        {
                            name: "KRISHNA PAKSHA/కృష్ణ పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "AMAVASYA/అమావాస్య"]

                        }
                    ]

                },
                {
                    name: "PUSHYAMU/పుష్యము",
                    nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/శుక్ల పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి"],
                        },
                        {
                            name: "KRISHNA PAKSHA/కృష్ణ పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "AMAVASYA/అమావాస్య"]

                        }
                    ]

                },
                {
                    name: "MAKHAMU/మఖము",
                    nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/శుక్ల పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి"],
                        },
                        {
                            name: "KRISHNA PAKSHA/కృష్ణ పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "AMAVASYA/అమావాస్య"]

                        }
                    ]
                },
                {
                    name: "PHALGUNAMU/ఫాల్గుణము",
                    nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/శుక్ల పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి"],
                        },
                        {
                            name: "KRISHNA PAKSHA/కృష్ణ పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "AMAVASYA/అమావాస్య"]

                        }
                    ]
                }
            ]
        },
        {
            name: "Kannada",
            calenderCode: 4,
            month: [{
                name: "CHAITRA/ಚೈತ್ರ",
                nakshathira: ["ASHWINI/ಅಶ್ವಿನಿ", "BHARANI/ಭರಣಿ", "KRITTIKA/ಕೃತ್ತಿಕಾ", "ROHINI/ರೋಹಿಣಿ", "MRIGHASIRA/ಮೃಘಾಸಿರ", "ARDRA/ಆರ್ದ್ರಾ", "PUNARVASU/ಪುನರ್ವಸು", "PUSHYA/ಪುಷ್ಯ", "ASHLESHA/ಆಶ್ಲೇಷಾ", "MAGHA/ಮಾಘ", "PURVA PHALGUNI/ಪೂರ್ವ ಫಲ್ಗುಣಿ", "UTTARA PHALGUNI/ಉತ್ತರ ಫಲ್ಗುಣಿ", "HASTA/ಹಸ್ತ", "CHITRA/ಚಿತ್ರಾ", "SWATI/ಸ್ವಾತಿ", "VISHAKA/ವಿಶಾಕ", "ANURADHA/ಅನುರಾಧಾ", "JYESTHA/ಜ್ಯೇಷ್ಠ", "MOOLA/ಮೂಲಾ", "PURVASHADA/ಪೂರ್ವಾಷಾಡ", "UTTARASHADA/ಉತ್ತರಾಷಾಢ", "SHARAVAN/ಶರವಣ", "DHANISHTA/ಧನಿಷ್ಠಾ", "SHATABISHA/ಶತಬಿಷಾ", "PURVABHADRA/ಪೂರ್ವಾಭದ್ರ", "UTTARABHADRA/ಉತ್ತರಾಭಾದ್ರ", "REVATI/ರೇವತಿ"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/ಶುಕ್ಲ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "PURNIMA/ಪೌರ್ಣಿಮಿ"],
                    },
                    {
                        name: "KRISHNA PAKSHA/ಕೃಷ್ಣ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "AMAVASYA/ಅಮಾವಾಸ್ಯೆ"]

                    }
                ]


            },
            {
                name: "VAISHAKHA/ವೈಶಾಖ",
                nakshathira: ["ASHWINI/ಅಶ್ವಿನಿ", "BHARANI/ಭರಣಿ", "KRITTIKA/ಕೃತ್ತಿಕಾ", "ROHINI/ರೋಹಿಣಿ", "MRIGHASIRA/ಮೃಘಾಸಿರ", "ARDRA/ಆರ್ದ್ರಾ", "PUNARVASU/ಪುನರ್ವಸು", "PUSHYA/ಪುಷ್ಯ", "ASHLESHA/ಆಶ್ಲೇಷಾ", "MAGHA/ಮಾಘ", "PURVA PHALGUNI/ಪೂರ್ವ ಫಲ್ಗುಣಿ", "UTTARA PHALGUNI/ಉತ್ತರ ಫಲ್ಗುಣಿ", "HASTA/ಹಸ್ತ", "CHITRA/ಚಿತ್ರಾ", "SWATI/ಸ್ವಾತಿ", "VISHAKA/ವಿಶಾಕ", "ANURADHA/ಅನುರಾಧಾ", "JYESTHA/ಜ್ಯೇಷ್ಠ", "MOOLA/ಮೂಲಾ", "PURVASHADA/ಪೂರ್ವಾಷಾಡ", "UTTARASHADA/ಉತ್ತರಾಷಾಢ", "SHARAVAN/ಶರವಣ", "DHANISHTA/ಧನಿಷ್ಠಾ", "SHATABISHA/ಶತಬಿಷಾ", "PURVABHADRA/ಪೂರ್ವಾಭದ್ರ", "UTTARABHADRA/ಉತ್ತರಾಭಾದ್ರ", "REVATI/ರೇವತಿ"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/ಶುಕ್ಲ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "PURNIMA/ಪೌರ್ಣಿಮಿ"],
                    },
                    {
                        name: "KRISHNA PAKSHA/ಕೃಷ್ಣ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "AMAVASYA/ಅಮಾವಾಸ್ಯೆ"]

                    }
                ]


            },
            {
                name: "JYESHTHA/ಜ್ಯೇಷ್ಠ",
                nakshathira: ["ASHWINI/ಅಶ್ವಿನಿ", "BHARANI/ಭರಣಿ", "KRITTIKA/ಕೃತ್ತಿಕಾ", "ROHINI/ರೋಹಿಣಿ", "MRIGHASIRA/ಮೃಘಾಸಿರ", "ARDRA/ಆರ್ದ್ರಾ", "PUNARVASU/ಪುನರ್ವಸು", "PUSHYA/ಪುಷ್ಯ", "ASHLESHA/ಆಶ್ಲೇಷಾ", "MAGHA/ಮಾಘ", "PURVA PHALGUNI/ಪೂರ್ವ ಫಲ್ಗುಣಿ", "UTTARA PHALGUNI/ಉತ್ತರ ಫಲ್ಗುಣಿ", "HASTA/ಹಸ್ತ", "CHITRA/ಚಿತ್ರಾ", "SWATI/ಸ್ವಾತಿ", "VISHAKA/ವಿಶಾಕ", "ANURADHA/ಅನುರಾಧಾ", "JYESTHA/ಜ್ಯೇಷ್ಠ", "MOOLA/ಮೂಲಾ", "PURVASHADA/ಪೂರ್ವಾಷಾಡ", "UTTARASHADA/ಉತ್ತರಾಷಾಢ", "SHARAVAN/ಶರವಣ", "DHANISHTA/ಧನಿಷ್ಠಾ", "SHATABISHA/ಶತಬಿಷಾ", "PURVABHADRA/ಪೂರ್ವಾಭದ್ರ", "UTTARABHADRA/ಉತ್ತರಾಭಾದ್ರ", "REVATI/ರೇವತಿ"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/ಶುಕ್ಲ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "PURNIMA/ಪೌರ್ಣಿಮಿ"],
                    },
                    {
                        name: "KRISHNA PAKSHA/ಕೃಷ್ಣ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "AMAVASYA/ಅಮಾವಾಸ್ಯೆ"]

                    }
                ]


            },
            {
                name: "ASHADHA/ಆಷಾಢ",
                nakshathira: ["ASHWINI/ಅಶ್ವಿನಿ", "BHARANI/ಭರಣಿ", "KRITTIKA/ಕೃತ್ತಿಕಾ", "ROHINI/ರೋಹಿಣಿ", "MRIGHASIRA/ಮೃಘಾಸಿರ", "ARDRA/ಆರ್ದ್ರಾ", "PUNARVASU/ಪುನರ್ವಸು", "PUSHYA/ಪುಷ್ಯ", "ASHLESHA/ಆಶ್ಲೇಷಾ", "MAGHA/ಮಾಘ", "PURVA PHALGUNI/ಪೂರ್ವ ಫಲ್ಗುಣಿ", "UTTARA PHALGUNI/ಉತ್ತರ ಫಲ್ಗುಣಿ", "HASTA/ಹಸ್ತ", "CHITRA/ಚಿತ್ರಾ", "SWATI/ಸ್ವಾತಿ", "VISHAKA/ವಿಶಾಕ", "ANURADHA/ಅನುರಾಧಾ", "JYESTHA/ಜ್ಯೇಷ್ಠ", "MOOLA/ಮೂಲಾ", "PURVASHADA/ಪೂರ್ವಾಷಾಡ", "UTTARASHADA/ಉತ್ತರಾಷಾಢ", "SHARAVAN/ಶರವಣ", "DHANISHTA/ಧನಿಷ್ಠಾ", "SHATABISHA/ಶತಬಿಷಾ", "PURVABHADRA/ಪೂರ್ವಾಭದ್ರ", "UTTARABHADRA/ಉತ್ತರಾಭಾದ್ರ", "REVATI/ರೇವತಿ"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/ಶುಕ್ಲ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "PURNIMA/ಪೌರ್ಣಿಮಿ"],
                    },
                    {
                        name: "KRISHNA PAKSHA/ಕೃಷ್ಣ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "AMAVASYA/ಅಮಾವಾಸ್ಯೆ"]

                    }
                ]


            },
            {
                name: "SAHRAVANA/ಶ್ರಾವಣ",
                nakshathira: ["ASHWINI/ಅಶ್ವಿನಿ", "BHARANI/ಭರಣಿ", "KRITTIKA/ಕೃತ್ತಿಕಾ", "ROHINI/ರೋಹಿಣಿ", "MRIGHASIRA/ಮೃಘಾಸಿರ", "ARDRA/ಆರ್ದ್ರಾ", "PUNARVASU/ಪುನರ್ವಸು", "PUSHYA/ಪುಷ್ಯ", "ASHLESHA/ಆಶ್ಲೇಷಾ", "MAGHA/ಮಾಘ", "PURVA PHALGUNI/ಪೂರ್ವ ಫಲ್ಗುಣಿ", "UTTARA PHALGUNI/ಉತ್ತರ ಫಲ್ಗುಣಿ", "HASTA/ಹಸ್ತ", "CHITRA/ಚಿತ್ರಾ", "SWATI/ಸ್ವಾತಿ", "VISHAKA/ವಿಶಾಕ", "ANURADHA/ಅನುರಾಧಾ", "JYESTHA/ಜ್ಯೇಷ್ಠ", "MOOLA/ಮೂಲಾ", "PURVASHADA/ಪೂರ್ವಾಷಾಡ", "UTTARASHADA/ಉತ್ತರಾಷಾಢ", "SHARAVAN/ಶರವಣ", "DHANISHTA/ಧನಿಷ್ಠಾ", "SHATABISHA/ಶತಬಿಷಾ", "PURVABHADRA/ಪೂರ್ವಾಭದ್ರ", "UTTARABHADRA/ಉತ್ತರಾಭಾದ್ರ", "REVATI/ರೇವತಿ"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/ಶುಕ್ಲ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "PURNIMA/ಪೌರ್ಣಿಮಿ"],
                    },
                    {
                        name: "KRISHNA PAKSHA/ಕೃಷ್ಣ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "AMAVASYA/ಅಮಾವಾಸ್ಯೆ"]

                    }
                ]


            },
            {
                name: "BHADRAPADA/ಭಾದ್ರಪದ",
                nakshathira: ["ASHWINI/ಅಶ್ವಿನಿ", "BHARANI/ಭರಣಿ", "KRITTIKA/ಕೃತ್ತಿಕಾ", "ROHINI/ರೋಹಿಣಿ", "MRIGHASIRA/ಮೃಘಾಸಿರ", "ARDRA/ಆರ್ದ್ರಾ", "PUNARVASU/ಪುನರ್ವಸು", "PUSHYA/ಪುಷ್ಯ", "ASHLESHA/ಆಶ್ಲೇಷಾ", "MAGHA/ಮಾಘ", "PURVA PHALGUNI/ಪೂರ್ವ ಫಲ್ಗುಣಿ", "UTTARA PHALGUNI/ಉತ್ತರ ಫಲ್ಗುಣಿ", "HASTA/ಹಸ್ತ", "CHITRA/ಚಿತ್ರಾ", "SWATI/ಸ್ವಾತಿ", "VISHAKA/ವಿಶಾಕ", "ANURADHA/ಅನುರಾಧಾ", "JYESTHA/ಜ್ಯೇಷ್ಠ", "MOOLA/ಮೂಲಾ", "PURVASHADA/ಪೂರ್ವಾಷಾಡ", "UTTARASHADA/ಉತ್ತರಾಷಾಢ", "SHARAVAN/ಶರವಣ", "DHANISHTA/ಧನಿಷ್ಠಾ", "SHATABISHA/ಶತಬಿಷಾ", "PURVABHADRA/ಪೂರ್ವಾಭದ್ರ", "UTTARABHADRA/ಉತ್ತರಾಭಾದ್ರ", "REVATI/ರೇವತಿ"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/ಶುಕ್ಲ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "PURNIMA/ಪೌರ್ಣಿಮಿ"],
                    },
                    {
                        name: "KRISHNA PAKSHA/ಕೃಷ್ಣ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "AMAVASYA/ಅಮಾವಾಸ್ಯೆ"]

                    }
                ]


            },
            {
                name: "ASHWEJA/ಆಶ್ವೇಜ",
                nakshathira: ["ASHWINI/ಅಶ್ವಿನಿ", "BHARANI/ಭರಣಿ", "KRITTIKA/ಕೃತ್ತಿಕಾ", "ROHINI/ರೋಹಿಣಿ", "MRIGHASIRA/ಮೃಘಾಸಿರ", "ARDRA/ಆರ್ದ್ರಾ", "PUNARVASU/ಪುನರ್ವಸು", "PUSHYA/ಪುಷ್ಯ", "ASHLESHA/ಆಶ್ಲೇಷಾ", "MAGHA/ಮಾಘ", "PURVA PHALGUNI/ಪೂರ್ವ ಫಲ್ಗುಣಿ", "UTTARA PHALGUNI/ಉತ್ತರ ಫಲ್ಗುಣಿ", "HASTA/ಹಸ್ತ", "CHITRA/ಚಿತ್ರಾ", "SWATI/ಸ್ವಾತಿ", "VISHAKA/ವಿಶಾಕ", "ANURADHA/ಅನುರಾಧಾ", "JYESTHA/ಜ್ಯೇಷ್ಠ", "MOOLA/ಮೂಲಾ", "PURVASHADA/ಪೂರ್ವಾಷಾಡ", "UTTARASHADA/ಉತ್ತರಾಷಾಢ", "SHARAVAN/ಶರವಣ", "DHANISHTA/ಧನಿಷ್ಠಾ", "SHATABISHA/ಶತಬಿಷಾ", "PURVABHADRA/ಪೂರ್ವಾಭದ್ರ", "UTTARABHADRA/ಉತ್ತರಾಭಾದ್ರ", "REVATI/ರೇವತಿ"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/ಶುಕ್ಲ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "PURNIMA/ಪೌರ್ಣಿಮಿ"],
                    },
                    {
                        name: "KRISHNA PAKSHA/ಕೃಷ್ಣ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "AMAVASYA/ಅಮಾವಾಸ್ಯೆ"]

                    }
                ]


            },
            {
                name: "KARTIKA/ಕಾರ್ತೀಕ",
                nakshathira: ["ASHWINI/ಅಶ್ವಿನಿ", "BHARANI/ಭರಣಿ", "KRITTIKA/ಕೃತ್ತಿಕಾ", "ROHINI/ರೋಹಿಣಿ", "MRIGHASIRA/ಮೃಘಾಸಿರ", "ARDRA/ಆರ್ದ್ರಾ", "PUNARVASU/ಪುನರ್ವಸು", "PUSHYA/ಪುಷ್ಯ", "ASHLESHA/ಆಶ್ಲೇಷಾ", "MAGHA/ಮಾಘ", "PURVA PHALGUNI/ಪೂರ್ವ ಫಲ್ಗುಣಿ", "UTTARA PHALGUNI/ಉತ್ತರ ಫಲ್ಗುಣಿ", "HASTA/ಹಸ್ತ", "CHITRA/ಚಿತ್ರಾ", "SWATI/ಸ್ವಾತಿ", "VISHAKA/ವಿಶಾಕ", "ANURADHA/ಅನುರಾಧಾ", "JYESTHA/ಜ್ಯೇಷ್ಠ", "MOOLA/ಮೂಲಾ", "PURVASHADA/ಪೂರ್ವಾಷಾಡ", "UTTARASHADA/ಉತ್ತರಾಷಾಢ", "SHARAVAN/ಶರವಣ", "DHANISHTA/ಧನಿಷ್ಠಾ", "SHATABISHA/ಶತಬಿಷಾ", "PURVABHADRA/ಪೂರ್ವಾಭದ್ರ", "UTTARABHADRA/ಉತ್ತರಾಭಾದ್ರ", "REVATI/ರೇವತಿ"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/ಶುಕ್ಲ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "PURNIMA/ಪೌರ್ಣಿಮಿ"],
                    },
                    {
                        name: "KRISHNA PAKSHA/ಕೃಷ್ಣ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "AMAVASYA/ಅಮಾವಾಸ್ಯೆ"]

                    }
                ]


            },
            {
                name: "MARGASHIRSHA/ಮಾರ್ಗಶೀರ್ಷ",
                nakshathira: ["ASHWINI/ಅಶ್ವಿನಿ", "BHARANI/ಭರಣಿ", "KRITTIKA/ಕೃತ್ತಿಕಾ", "ROHINI/ರೋಹಿಣಿ", "MRIGHASIRA/ಮೃಘಾಸಿರ", "ARDRA/ಆರ್ದ್ರಾ", "PUNARVASU/ಪುನರ್ವಸು", "PUSHYA/ಪುಷ್ಯ", "ASHLESHA/ಆಶ್ಲೇಷಾ", "MAGHA/ಮಾಘ", "PURVA PHALGUNI/ಪೂರ್ವ ಫಲ್ಗುಣಿ", "UTTARA PHALGUNI/ಉತ್ತರ ಫಲ್ಗುಣಿ", "HASTA/ಹಸ್ತ", "CHITRA/ಚಿತ್ರಾ", "SWATI/ಸ್ವಾತಿ", "VISHAKA/ವಿಶಾಕ", "ANURADHA/ಅನುರಾಧಾ", "JYESTHA/ಜ್ಯೇಷ್ಠ", "MOOLA/ಮೂಲಾ", "PURVASHADA/ಪೂರ್ವಾಷಾಡ", "UTTARASHADA/ಉತ್ತರಾಷಾಢ", "SHARAVAN/ಶರವಣ", "DHANISHTA/ಧನಿಷ್ಠಾ", "SHATABISHA/ಶತಬಿಷಾ", "PURVABHADRA/ಪೂರ್ವಾಭದ್ರ", "UTTARABHADRA/ಉತ್ತರಾಭಾದ್ರ", "REVATI/ರೇವತಿ"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/ಶುಕ್ಲ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "PURNIMA/ಪೌರ್ಣಿಮಿ"],
                    },
                    {
                        name: "KRISHNA PAKSHA/ಕೃಷ್ಣ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "AMAVASYA/ಅಮಾವಾಸ್ಯೆ"]

                    }
                ]


            },
            {
                name: "PAUSHA/ಪೌಷಾ",
                nakshathira: ["ASHWINI/ಅಶ್ವಿನಿ", "BHARANI/ಭರಣಿ", "KRITTIKA/ಕೃತ್ತಿಕಾ", "ROHINI/ರೋಹಿಣಿ", "MRIGHASIRA/ಮೃಘಾಸಿರ", "ARDRA/ಆರ್ದ್ರಾ", "PUNARVASU/ಪುನರ್ವಸು", "PUSHYA/ಪುಷ್ಯ", "ASHLESHA/ಆಶ್ಲೇಷಾ", "MAGHA/ಮಾಘ", "PURVA PHALGUNI/ಪೂರ್ವ ಫಲ್ಗುಣಿ", "UTTARA PHALGUNI/ಉತ್ತರ ಫಲ್ಗುಣಿ", "HASTA/ಹಸ್ತ", "CHITRA/ಚಿತ್ರಾ", "SWATI/ಸ್ವಾತಿ", "VISHAKA/ವಿಶಾಕ", "ANURADHA/ಅನುರಾಧಾ", "JYESTHA/ಜ್ಯೇಷ್ಠ", "MOOLA/ಮೂಲಾ", "PURVASHADA/ಪೂರ್ವಾಷಾಡ", "UTTARASHADA/ಉತ್ತರಾಷಾಢ", "SHARAVAN/ಶರವಣ", "DHANISHTA/ಧನಿಷ್ಠಾ", "SHATABISHA/ಶತಬಿಷಾ", "PURVABHADRA/ಪೂರ್ವಾಭದ್ರ", "UTTARABHADRA/ಉತ್ತರಾಭಾದ್ರ", "REVATI/ರೇವತಿ"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/ಶುಕ್ಲ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "PURNIMA/ಪೌರ್ಣಿಮಿ"],
                    },
                    {
                        name: "KRISHNA PAKSHA/ಕೃಷ್ಣ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "AMAVASYA/ಅಮಾವಾಸ್ಯೆ"]

                    }
                ]


            },
            {
                name: "MAGHA/ಮಾಘ",
                nakshathira: ["ASHWINI/ಅಶ್ವಿನಿ", "BHARANI/ಭರಣಿ", "KRITTIKA/ಕೃತ್ತಿಕಾ", "ROHINI/ರೋಹಿಣಿ", "MRIGHASIRA/ಮೃಘಾಸಿರ", "ARDRA/ಆರ್ದ್ರಾ", "PUNARVASU/ಪುನರ್ವಸು", "PUSHYA/ಪುಷ್ಯ", "ASHLESHA/ಆಶ್ಲೇಷಾ", "MAGHA/ಮಾಘ", "PURVA PHALGUNI/ಪೂರ್ವ ಫಲ್ಗುಣಿ", "UTTARA PHALGUNI/ಉತ್ತರ ಫಲ್ಗುಣಿ", "HASTA/ಹಸ್ತ", "CHITRA/ಚಿತ್ರಾ", "SWATI/ಸ್ವಾತಿ", "VISHAKA/ವಿಶಾಕ", "ANURADHA/ಅನುರಾಧಾ", "JYESTHA/ಜ್ಯೇಷ್ಠ", "MOOLA/ಮೂಲಾ", "PURVASHADA/ಪೂರ್ವಾಷಾಡ", "UTTARASHADA/ಉತ್ತರಾಷಾಢ", "SHARAVAN/ಶರವಣ", "DHANISHTA/ಧನಿಷ್ಠಾ", "SHATABISHA/ಶತಬಿಷಾ", "PURVABHADRA/ಪೂರ್ವಾಭದ್ರ", "UTTARABHADRA/ಉತ್ತರಾಭಾದ್ರ", "REVATI/ರೇವತಿ"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/ಶುಕ್ಲ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "PURNIMA/ಪೌರ್ಣಿಮಿ"],
                    },
                    {
                        name: "KRISHNA PAKSHA/ಕೃಷ್ಣ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "AMAVASYA/ಅಮಾವಾಸ್ಯೆ"]

                    }
                ]


            },
            {
                name: "PHALGUNA/ಫಲ್ಗುಣ",
                nakshathira: ["ASHWINI/ಅಶ್ವಿನಿ", "BHARANI/ಭರಣಿ", "KRITTIKA/ಕೃತ್ತಿಕಾ", "ROHINI/ರೋಹಿಣಿ", "MRIGHASIRA/ಮೃಘಾಸಿರ", "ARDRA/ಆರ್ದ್ರಾ", "PUNARVASU/ಪುನರ್ವಸು", "PUSHYA/ಪುಷ್ಯ", "ASHLESHA/ಆಶ್ಲೇಷಾ", "MAGHA/ಮಾಘ", "PURVA PHALGUNI/ಪೂರ್ವ ಫಲ್ಗುಣಿ", "UTTARA PHALGUNI/ಉತ್ತರ ಫಲ್ಗುಣಿ", "HASTA/ಹಸ್ತ", "CHITRA/ಚಿತ್ರಾ", "SWATI/ಸ್ವಾತಿ", "VISHAKA/ವಿಶಾಕ", "ANURADHA/ಅನುರಾಧಾ", "JYESTHA/ಜ್ಯೇಷ್ಠ", "MOOLA/ಮೂಲಾ", "PURVASHADA/ಪೂರ್ವಾಷಾಡ", "UTTARASHADA/ಉತ್ತರಾಷಾಢ", "SHARAVAN/ಶರವಣ", "DHANISHTA/ಧನಿಷ್ಠಾ", "SHATABISHA/ಶತಬಿಷಾ", "PURVABHADRA/ಪೂರ್ವಾಭದ್ರ", "UTTARABHADRA/ಉತ್ತರಾಭಾದ್ರ", "REVATI/ರೇವತಿ"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/ಶುಕ್ಲ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "PURNIMA/ಪೌರ್ಣಿಮಿ"],
                    },
                    {
                        name: "KRISHNA PAKSHA/ಕೃಷ್ಣ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "AMAVASYA/ಅಮಾವಾಸ್ಯೆ"]

                    }
                ]


            },
            ]
        },
        {
            name: "Hindi",
            calenderCode: 5,
            month: [
                {
                    name: "CHAITRA/चैत्र",
                    nakshathira:
                        [
                            "ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशिरा", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/अश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वफाल्गुनी", "UTTARA PHALGUNI/उत्तराफाल्गुनी", "HASTA/हस्त", "CHITRA/चित्रा", "SWATI/स्वाति", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूल", "PURVASHADA/पूर्वाषाढ़", "UTTARASHADA/उत्तराषाढ़", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/शुक्ल पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            name: "KRISHNA PAKSHA/कृष्ण पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]

                        }
                    ]
                },
                {
                    name: "VAISAKHA/वैशाख",
                    nakshathira:
                        [
                            "ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशिरा", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/अश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वफाल्गुनी", "UTTARA PHALGUNI/उत्तराफाल्गुनी", "HASTA/हस्त", "CHITRA/चित्रा", "SWATI/स्वाति", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूल", "PURVASHADA/पूर्वाषाढ़", "UTTARASHADA/उत्तराषाढ़", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/शुक्ल पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            name: "KRISHNA PAKSHA/कृष्ण पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]

                        }
                    ]
                },
                {
                    name: "ASHADHA/आषाढ़",
                    nakshathira:
                        [
                            "ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशिरा", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/अश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वफाल्गुनी", "UTTARA PHALGUNI/उत्तराफाल्गुनी", "HASTA/हस्त", "CHITRA/चित्रा", "SWATI/स्वाति", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूल", "PURVASHADA/पूर्वाषाढ़", "UTTARASHADA/उत्तराषाढ़", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/शुक्ल पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            name: "KRISHNA PAKSHA/कृष्ण पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]

                        }
                    ]
                },
                {
                    name: "JYESTHA/ज्येष्ठा",
                    nakshathira:
                        [
                            "ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशिरा", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/अश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वफाल्गुनी", "UTTARA PHALGUNI/उत्तराफाल्गुनी", "HASTA/हस्त", "CHITRA/चित्रा", "SWATI/स्वाति", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूल", "PURVASHADA/पूर्वाषाढ़", "UTTARASHADA/उत्तराषाढ़", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/शुक्ल पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            name: "KRISHNA PAKSHA/कृष्ण पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]

                        }
                    ]
                },
                // {
                //     name: "SHRAVANA(Adhik)/श्रावण",
                //     nakshathira:
                //         [
                //             "ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशिरा", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/अश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वफाल्गुनी", "UTTARA PHALGUNI/उत्तराफाल्गुनी", "HASTA/हस्त", "CHITRA/चित्रा", "SWATI/स्वाति", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूल", "PURVASHADA/पूर्वाषाढ़", "UTTARASHADA/उत्तराषाढ़", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                //     Paksham: [
                //         {
                //             name: "SHUKLA PAKSHA/शुक्ल पक्ष",
                //             tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                //         },
                //         {
                //             name: "KRISHNA PAKSHA/कृष्ण पक्ष",
                //             tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]

                //         }
                //     ]
                // },
                {
                    name: "SHRAVANA/श्रावण",
                    nakshathira:
                        [
                            "ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशिरा", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/अश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वफाल्गुनी", "UTTARA PHALGUNI/उत्तराफाल्गुनी", "HASTA/हस्त", "CHITRA/चित्रा", "SWATI/स्वाति", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूल", "PURVASHADA/पूर्वाषाढ़", "UTTARASHADA/उत्तराषाढ़", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/शुक्ल पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            name: "KRISHNA PAKSHA/कृष्ण पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]

                        }
                    ]
                },
                {
                    name: "BHADRAPADA/भाद्रपद",
                    nakshathira:
                        [
                            "ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशिरा", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/अश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वफाल्गुनी", "UTTARA PHALGUNI/उत्तराफाल्गुनी", "HASTA/हस्त", "CHITRA/चित्रा", "SWATI/स्वाति", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूल", "PURVASHADA/पूर्वाषाढ़", "UTTARASHADA/उत्तराषाढ़", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/शुक्ल पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            name: "KRISHNA PAKSHA/कृष्ण पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]

                        }
                    ]
                },
                {
                    name: "ASHVIN/अश्विन",
                    nakshathira:
                        [
                            "ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशिरा", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/अश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वफाल्गुनी", "UTTARA PHALGUNI/उत्तराफाल्गुनी", "HASTA/हस्त", "CHITRA/चित्रा", "SWATI/स्वाति", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूल", "PURVASHADA/पूर्वाषाढ़", "UTTARASHADA/उत्तराषाढ़", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/शुक्ल पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            name: "KRISHNA PAKSHA/कृष्ण पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]

                        }
                    ]
                },
                {
                    name: "KATIKA/कार्तिक",
                    nakshathira:
                        [
                            "ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशिरा", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/अश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वफाल्गुनी", "UTTARA PHALGUNI/उत्तराफाल्गुनी", "HASTA/हस्त", "CHITRA/चित्रा", "SWATI/स्वाति", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूल", "PURVASHADA/पूर्वाषाढ़", "UTTARASHADA/उत्तराषाढ़", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/शुक्ल पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            name: "KRISHNA PAKSHA/कृष्ण पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]

                        }
                    ]
                },
                {
                    name: "MARGASIRSA/मार्गशीर्ष",
                    nakshathira:
                        [
                            "ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशिरा", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/अश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वफाल्गुनी", "UTTARA PHALGUNI/उत्तराफाल्गुनी", "HASTA/हस्त", "CHITRA/चित्रा", "SWATI/स्वाति", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूल", "PURVASHADA/पूर्वाषाढ़", "UTTARASHADA/उत्तराषाढ़", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/शुक्ल पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            name: "KRISHNA PAKSHA/कृष्ण पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]

                        }
                    ]
                },
                {
                    name: "PAUSHA/पौष",
                    nakshathira:
                        [
                            "ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशिरा", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/अश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वफाल्गुनी", "UTTARA PHALGUNI/उत्तराफाल्गुनी", "HASTA/हस्त", "CHITRA/चित्रा", "SWATI/स्वाति", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूल", "PURVASHADA/पूर्वाषाढ़", "UTTARASHADA/उत्तराषाढ़", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/शुक्ल पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            name: "KRISHNA PAKSHA/कृष्ण पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]

                        }
                    ]
                },
                {
                    name: "MAGH/माघ",
                    nakshathira:
                        [
                            "ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशिरा", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/अश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वफाल्गुनी", "UTTARA PHALGUNI/उत्तराफाल्गुनी", "HASTA/हस्त", "CHITRA/चित्रा", "SWATI/स्वाति", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूल", "PURVASHADA/पूर्वाषाढ़", "UTTARASHADA/उत्तराषाढ़", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/शुक्ल पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            name: "KRISHNA PAKSHA/कृष्ण पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]

                        }
                    ]
                },
                {
                    name: "PHALGUNA/फाल्गुन",
                    nakshathira:
                        [
                            "ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशिरा", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/अश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वफाल्गुनी", "UTTARA PHALGUNI/उत्तराफाल्गुनी", "HASTA/हस्त", "CHITRA/चित्रा", "SWATI/स्वाति", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूल", "PURVASHADA/पूर्वाषाढ़", "UTTARASHADA/उत्तराषाढ़", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/शुक्ल पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            name: "KRISHNA PAKSHA/कृष्ण पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]

                        }
                    ]
                }
            ]
        },
        {
            name: "Gujarati",
            calenderCode: 6,
            month: [
                {
                    name: "CHAITRA/ચૈત્ર",
                    nakshathira: ["ASHWINI/અશ્વિની", "BHARANI/ભરણી", "KRITTIKA/કૃત્તિકા", "ROHINI/રોહિણી", "MRIGHASIRA/મૃગશીરા", "ARDRA/આર્દ્રા", "PUNARVASU/પુનર્વસુ", "PUSHYA/પુષ્ય", "ASHLESHA/અશ્લેષા", "MAGHA/મઘા", "PURVA PHALGUNI/પૂર્વ ફાલ્ગુની", "UTTARA PHALGUNI/ઉત્તર ફાલ્ગુની", "HASTA/હસ્ત", "CHITRA/ચિત્રા", "SWATI/સ્વાતિ", "VISHAKA/વિશાખા", "ANURADHA/અનુરાધા", "JYESTHA/જ્યેષ્ઠા", "MOOLA/મૂળ", "PURVASHADA/પૂર્વ શાડ", "UTTARASHADA/ઉત્તર શાડ", "SHRAVANA/શ્રાવણ", "DHANISHTA/ધનિષ્ઠા", "SHATABISHA/શતભિષા", "PURVABHADRA/પૂર્વભાદ્ર", "UTTARABHADRA/ઉત્તરભાદ્ર", "REVATI/રેવતી"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/શુક્લ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "PURNIMA/પૂર્ણિમા"],
                        },
                        {
                            name: "KRISHNA PAKSHA/કૃષ્ણ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "AMAVASYA/આમાસ્યા"]

                        }
                    ]




                },
                {
                    name: "VAISHAKHA/વૈશાખા",
                    nakshathira: ["ASHWINI/અશ્વિની", "BHARANI/ભરણી", "KRITTIKA/કૃત્તિકા", "ROHINI/રોહિણી", "MRIGHASIRA/મૃગશીરા", "ARDRA/આર્દ્રા", "PUNARVASU/પુનર્વસુ", "PUSHYA/પુષ્ય", "ASHLESHA/અશ્લેષા", "MAGHA/મઘા", "PURVA PHALGUNI/પૂર્વ ફાલ્ગુની", "UTTARA PHALGUNI/ઉત્તર ફાલ્ગુની", "HASTA/હસ્ત", "CHITRA/ચિત્રા", "SWATI/સ્વાતિ", "VISHAKA/વિશાખા", "ANURADHA/અનુરાધા", "JYESTHA/જ્યેષ્ઠા", "MOOLA/મૂળ", "PURVASHADA/પૂર્વ શાડ", "UTTARASHADA/ઉત્તર શાડ", "SHRAVANA/શ્રાવણ", "DHANISHTA/ધનિષ્ઠા", "SHATABISHA/શતભિષા", "PURVABHADRA/પૂર્વભાદ્ર", "UTTARABHADRA/ઉત્તરભાદ્ર", "REVATI/રેવતી"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/શુક્લ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "PURNIMA/પૂર્ણિમા"],
                        },
                        {
                            name: "KRISHNA PAKSHA/કૃષ્ણ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "AMAVASYA/આમાસ્યા"]

                        }
                    ]


                },
                {
                    name: "JETHA/જેઠા",
                    nakshathira: ["ASHWINI/અશ્વિની", "BHARANI/ભરણી", "KRITTIKA/કૃત્તિકા", "ROHINI/રોહિણી", "MRIGHASIRA/મૃગશીરા", "ARDRA/આર્દ્રા", "PUNARVASU/પુનર્વસુ", "PUSHYA/પુષ્ય", "ASHLESHA/અશ્લેષા", "MAGHA/મઘા", "PURVA PHALGUNI/પૂર્વ ફાલ્ગુની", "UTTARA PHALGUNI/ઉત્તર ફાલ્ગુની", "HASTA/હસ્ત", "CHITRA/ચિત્રા", "SWATI/સ્વાતિ", "VISHAKA/વિશાખા", "ANURADHA/અનુરાધા", "JYESTHA/જ્યેષ્ઠા", "MOOLA/મૂળ", "PURVASHADA/પૂર્વ શાડ", "UTTARASHADA/ઉત્તર શાડ", "SHRAVANA/શ્રાવણ", "DHANISHTA/ધનિષ્ઠા", "SHATABISHA/શતભિષા", "PURVABHADRA/પૂર્વભાદ્ર", "UTTARABHADRA/ઉત્તરભાદ્ર", "REVATI/રેવતી"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/શુક્લ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "PURNIMA/પૂર્ણિમા"],
                        },
                        {
                            name: "KRISHNA PAKSHA/કૃષ્ણ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "AMAVASYA/આમાસ્યા"]

                        }
                    ]


                },
                // {
                //     name: "SHRAVANA adhik/શ્રાવણ",
                //     nakshathira: ["ASHWINI/અશ્વિની", "BHARANI/ભરણી", "KRITTIKA/કૃત્તિકા", "ROHINI/રોહિણી", "MRIGHASIRA/મૃગશીરા", "ARDRA/આર્દ્રા", "PUNARVASU/પુનર્વસુ", "PUSHYA/પુષ્ય", "ASHLESHA/અશ્લેષા", "MAGHA/મઘા", "PURVA PHALGUNI/પૂર્વ ફાલ્ગુની", "UTTARA PHALGUNI/ઉત્તર ફાલ્ગુની", "HASTA/હસ્ત", "CHITRA/ચિત્રા", "SWATI/સ્વાતિ", "VISHAKA/વિશાખા", "ANURADHA/અનુરાધા", "JYESTHA/જ્યેષ્ઠા", "MOOLA/મૂળ", "PURVASHADA/પૂર્વ શાડ", "UTTARASHADA/ઉત્તર શાડ", "SHRAVANA/શ્રાવણ", "DHANISHTA/ધનિષ્ઠા", "SHATABISHA/શતભિષા", "PURVABHADRA/પૂર્વભાદ્ર", "UTTARABHADRA/ઉત્તરભાદ્ર", "REVATI/રેવતી"],
                //     Paksham: [
                //         {
                //             name: "SHUKLA PAKSHA/શુક્લ પક્ષ",
                //             tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "PURNIMA/પૂર્ણિમા"],
                //         },
                //         {
                //             name: "KRISHNA PAKSHA/કૃષ્ણ પક્ષ",
                //             tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "AMAVASYA/આમાસ્યા"]

                //         }
                //     ]


                // },
                {
                    name: "SHRAVANA/શ્રાવણ",
                    nakshathira: ["ASHWINI/અશ્વિની", "BHARANI/ભરણી", "KRITTIKA/કૃત્તિકા", "ROHINI/રોહિણી", "MRIGHASIRA/મૃગશીરા", "ARDRA/આર્દ્રા", "PUNARVASU/પુનર્વસુ", "PUSHYA/પુષ્ય", "ASHLESHA/અશ્લેષા", "MAGHA/મઘા", "PURVA PHALGUNI/પૂર્વ ફાલ્ગુની", "UTTARA PHALGUNI/ઉત્તર ફાલ્ગુની", "HASTA/હસ્ત", "CHITRA/ચિત્રા", "SWATI/સ્વાતિ", "VISHAKA/વિશાખા", "ANURADHA/અનુરાધા", "JYESTHA/જ્યેષ્ઠા", "MOOLA/મૂળ", "PURVASHADA/પૂર્વ શાડ", "UTTARASHADA/ઉત્તર શાડ", "SHRAVANA/શ્રાવણ", "DHANISHTA/ધનિષ્ઠા", "SHATABISHA/શતભિષા", "PURVABHADRA/પૂર્વભાદ્ર", "UTTARABHADRA/ઉત્તરભાદ્ર", "REVATI/રેવતી"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/શુક્લ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "PURNIMA/પૂર્ણિમા"],
                        },
                        {
                            name: "KRISHNA PAKSHA/કૃષ્ણ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "AMAVASYA/આમાસ્યા"]

                        }
                    ]


                },
                {
                    name: "BHADRAVO/ભાદરવો",
                    nakshathira: ["ASHWINI/અશ્વિની", "BHARANI/ભરણી", "KRITTIKA/કૃત્તિકા", "ROHINI/રોહિણી", "MRIGHASIRA/મૃગશીરા", "ARDRA/આર્દ્રા", "PUNARVASU/પુનર્વસુ", "PUSHYA/પુષ્ય", "ASHLESHA/અશ્લેષા", "MAGHA/મઘા", "PURVA PHALGUNI/પૂર્વ ફાલ્ગુની", "UTTARA PHALGUNI/ઉત્તર ફાલ્ગુની", "HASTA/હસ્ત", "CHITRA/ચિત્રા", "SWATI/સ્વાતિ", "VISHAKA/વિશાખા", "ANURADHA/અનુરાધા", "JYESTHA/જ્યેષ્ઠા", "MOOLA/મૂળ", "PURVASHADA/પૂર્વ શાડ", "UTTARASHADA/ઉત્તર શાડ", "SHRAVANA/શ્રાવણ", "DHANISHTA/ધનિષ્ઠા", "SHATABISHA/શતભિષા", "PURVABHADRA/પૂર્વભાદ્ર", "UTTARABHADRA/ઉત્તરભાદ્ર", "REVATI/રેવતી"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/શુક્લ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "PURNIMA/પૂર્ણિમા"],
                        },
                        {
                            name: "KRISHNA PAKSHA/કૃષ્ણ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "AMAVASYA/આમાસ્યા"]

                        }
                    ]


                },
                {
                    name: "ASO/આસો",
                    nakshathira: ["ASHWINI/અશ્વિની", "BHARANI/ભરણી", "KRITTIKA/કૃત્તિકા", "ROHINI/રોહિણી", "MRIGHASIRA/મૃગશીરા", "ARDRA/આર્દ્રા", "PUNARVASU/પુનર્વસુ", "PUSHYA/પુષ્ય", "ASHLESHA/અશ્લેષા", "MAGHA/મઘા", "PURVA PHALGUNI/પૂર્વ ફાલ્ગુની", "UTTARA PHALGUNI/ઉત્તર ફાલ્ગુની", "HASTA/હસ્ત", "CHITRA/ચિત્રા", "SWATI/સ્વાતિ", "VISHAKA/વિશાખા", "ANURADHA/અનુરાધા", "JYESTHA/જ્યેષ્ઠા", "MOOLA/મૂળ", "PURVASHADA/પૂર્વ શાડ", "UTTARASHADA/ઉત્તર શાડ", "SHRAVANA/શ્રાવણ", "DHANISHTA/ધનિષ્ઠા", "SHATABISHA/શતભિષા", "PURVABHADRA/પૂર્વભાદ્ર", "UTTARABHADRA/ઉત્તરભાદ્ર", "REVATI/રેવતી"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/શુક્લ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "PURNIMA/પૂર્ણિમા"],
                        },
                        {
                            name: "KRISHNA PAKSHA/કૃષ્ણ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "AMAVASYA/આમાસ્યા"]

                        }
                    ]


                },
                {
                    name: "KARKAT/કારતક",
                    nakshathira: ["ASHWINI/અશ્વિની", "BHARANI/ભરણી", "KRITTIKA/કૃત્તિકા", "ROHINI/રોહિણી", "MRIGHASIRA/મૃગશીરા", "ARDRA/આર્દ્રા", "PUNARVASU/પુનર્વસુ", "PUSHYA/પુષ્ય", "ASHLESHA/અશ્લેષા", "MAGHA/મઘા", "PURVA PHALGUNI/પૂર્વ ફાલ્ગુની", "UTTARA PHALGUNI/ઉત્તર ફાલ્ગુની", "HASTA/હસ્ત", "CHITRA/ચિત્રા", "SWATI/સ્વાતિ", "VISHAKA/વિશાખા", "ANURADHA/અનુરાધા", "JYESTHA/જ્યેષ્ઠા", "MOOLA/મૂળ", "PURVASHADA/પૂર્વ શાડ", "UTTARASHADA/ઉત્તર શાડ", "SHRAVANA/શ્રાવણ", "DHANISHTA/ધનિષ્ઠા", "SHATABISHA/શતભિષા", "PURVABHADRA/પૂર્વભાદ્ર", "UTTARABHADRA/ઉત્તરભાદ્ર", "REVATI/રેવતી"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/શુક્લ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "PURNIMA/પૂર્ણિમા"],
                        },
                        {
                            name: "KRISHNA PAKSHA/કૃષ્ણ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "AMAVASYA/આમાસ્યા"]

                        }
                    ]


                },
                {
                    name: "MAGSHAR/માગશર",
                    nakshathira: ["ASHWINI/અશ્વિની", "BHARANI/ભરણી", "KRITTIKA/કૃત્તિકા", "ROHINI/રોહિણી", "MRIGHASIRA/મૃગશીરા", "ARDRA/આર્દ્રા", "PUNARVASU/પુનર્વસુ", "PUSHYA/પુષ્ય", "ASHLESHA/અશ્લેષા", "MAGHA/મઘા", "PURVA PHALGUNI/પૂર્વ ફાલ્ગુની", "UTTARA PHALGUNI/ઉત્તર ફાલ્ગુની", "HASTA/હસ્ત", "CHITRA/ચિત્રા", "SWATI/સ્વાતિ", "VISHAKA/વિશાખા", "ANURADHA/અનુરાધા", "JYESTHA/જ્યેષ્ઠા", "MOOLA/મૂળ", "PURVASHADA/પૂર્વ શાડ", "UTTARASHADA/ઉત્તર શાડ", "SHRAVANA/શ્રાવણ", "DHANISHTA/ધનિષ્ઠા", "SHATABISHA/શતભિષા", "PURVABHADRA/પૂર્વભાદ્ર", "UTTARABHADRA/ઉત્તરભાદ્ર", "REVATI/રેવતી"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/શુક્લ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "PURNIMA/પૂર્ણિમા"],
                        },
                        {
                            name: "KRISHNA PAKSHA/કૃષ્ણ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "AMAVASYA/આમાસ્યા"]

                        }
                    ]


                },
                {
                    name: "POSH/પોષ",
                    nakshathira: ["ASHWINI/અશ્વિની", "BHARANI/ભરણી", "KRITTIKA/કૃત્તિકા", "ROHINI/રોહિણી", "MRIGHASIRA/મૃગશીરા", "ARDRA/આર્દ્રા", "PUNARVASU/પુનર્વસુ", "PUSHYA/પુષ્ય", "ASHLESHA/અશ્લેષા", "MAGHA/મઘા", "PURVA PHALGUNI/પૂર્વ ફાલ્ગુની", "UTTARA PHALGUNI/ઉત્તર ફાલ્ગુની", "HASTA/હસ્ત", "CHITRA/ચિત્રા", "SWATI/સ્વાતિ", "VISHAKA/વિશાખા", "ANURADHA/અનુરાધા", "JYESTHA/જ્યેષ્ઠા", "MOOLA/મૂળ", "PURVASHADA/પૂર્વ શાડ", "UTTARASHADA/ઉત્તર શાડ", "SHRAVANA/શ્રાવણ", "DHANISHTA/ધનિષ્ઠા", "SHATABISHA/શતભિષા", "PURVABHADRA/પૂર્વભાદ્ર", "UTTARABHADRA/ઉત્તરભાદ્ર", "REVATI/રેવતી"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/શુક્લ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "PURNIMA/પૂર્ણિમા"],
                        },
                        {
                            name: "KRISHNA PAKSHA/કૃષ્ણ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "AMAVASYA/આમાસ્યા"]

                        }
                    ]


                },
                {
                    name: "MAHA/મહા",
                    nakshathira: ["ASHWINI/અશ્વિની", "BHARANI/ભરણી", "KRITTIKA/કૃત્તિકા", "ROHINI/રોહિણી", "MRIGHASIRA/મૃગશીરા", "ARDRA/આર્દ્રા", "PUNARVASU/પુનર્વસુ", "PUSHYA/પુષ્ય", "ASHLESHA/અશ્લેષા", "MAGHA/મઘા", "PURVA PHALGUNI/પૂર્વ ફાલ્ગુની", "UTTARA PHALGUNI/ઉત્તર ફાલ્ગુની", "HASTA/હસ્ત", "CHITRA/ચિત્રા", "SWATI/સ્વાતિ", "VISHAKA/વિશાખા", "ANURADHA/અનુરાધા", "JYESTHA/જ્યેષ્ઠા", "MOOLA/મૂળ", "PURVASHADA/પૂર્વ શાડ", "UTTARASHADA/ઉત્તર શાડ", "SHRAVANA/શ્રાવણ", "DHANISHTA/ધનિષ્ઠા", "SHATABISHA/શતભિષા", "PURVABHADRA/પૂર્વભાદ્ર", "UTTARABHADRA/ઉત્તરભાદ્ર", "REVATI/રેવતી"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/શુક્લ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "PURNIMA/પૂર્ણિમા"],
                        },
                        {
                            name: "KRISHNA PAKSHA/કૃષ્ણ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "AMAVASYA/આમાસ્યા"]

                        }
                    ]


                },
                {
                    name: "PHAGUNA/ફાગુના",
                    nakshathira: ["ASHWINI/અશ્વિની", "BHARANI/ભરણી", "KRITTIKA/કૃત્તિકા", "ROHINI/રોહિણી", "MRIGHASIRA/મૃગશીરા", "ARDRA/આર્દ્રા", "PUNARVASU/પુનર્વસુ", "PUSHYA/પુષ્ય", "ASHLESHA/અશ્લેષા", "MAGHA/મઘા", "PURVA PHALGUNI/પૂર્વ ફાલ્ગુની", "UTTARA PHALGUNI/ઉત્તર ફાલ્ગુની", "HASTA/હસ્ત", "CHITRA/ચિત્રા", "SWATI/સ્વાતિ", "VISHAKA/વિશાખા", "ANURADHA/અનુરાધા", "JYESTHA/જ્યેષ્ઠા", "MOOLA/મૂળ", "PURVASHADA/પૂર્વ શાડ", "UTTARASHADA/ઉત્તર શાડ", "SHRAVANA/શ્રાવણ", "DHANISHTA/ધનિષ્ઠા", "SHATABISHA/શતભિષા", "PURVABHADRA/પૂર્વભાદ્ર", "UTTARABHADRA/ઉત્તરભાદ્ર", "REVATI/રેવતી"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/શુક્લ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "PURNIMA/પૂર્ણિમા"],
                        },
                        {
                            name: "KRISHNA PAKSHA/કૃષ્ણ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "AMAVASYA/આમાસ્યા"]

                        }
                    ]


                },

            ]
        },
        {
            name: "Bengali",
            calenderCode: 7,
            month: [
                {
                    name: "BOISHAKH/বৈশাখ",
                    nakshathira: ["ASHWINI/অশ্বিনী", "BHARANI/ভরণী", "KRITTIKA/কৃত্তিকা", "ROHINI/রোহিণী", "MRIGHASIRA/মৃগশিরা", "ARDRA/আর্দ্রা", "PUNARVASU/পুনর্বসু", "PUSHYA/পুষ্য", "ASHLESHA/অশ্লেষা", "MAGHA/মঘা", "PURVA PHALGUNI/পূর্ব ফাল্গুনী", "UTTARA PHALGUNI/উত্তর ফাল্গুনী", "HASTA/হস্ত", "CHITRA/চিত্রা", "SWATI/স্বাতী", "VISHAKA/বিশাখা", "ANURADHA/অনুরাধা", "JYESTHA/জ্যেষ্ঠা", "MOOLA/মূল", "PURVASHADA/পূর্বষাঢ়", "UTTARASHADA/উত্তরষাঢ়", "SHARAVAN/শ্রাবণ", "DHANISHTA/ধনিষ্ঠা", "SHATABISHA/শতভিষা", "PURVABHADRA/পূর্বভাদ্র", "UTTARABHADRA/উত্তরভাদ্র", "REVATI/রেবতী"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/শুক্ল পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "PURNIMA/পূর্ণিমা"],
                        },
                        {
                            name: "KRISHNA PAKSHA/কৃষ্ণ পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "AMAVASYA/অমাবস্যা"]

                        }
                    ]



                },
                {
                    name: "JOISHTHO/জ্যৈষ্ঠ",
                    nakshathira: ["ASHWINI/অশ্বিনী", "BHARANI/ভরণী", "KRITTIKA/কৃত্তিকা", "ROHINI/রোহিণী", "MRIGHASIRA/মৃগশিরা", "ARDRA/আর্দ্রা", "PUNARVASU/পুনর্বসু", "PUSHYA/পুষ্য", "ASHLESHA/অশ্লেষা", "MAGHA/মঘা", "PURVA PHALGUNI/পূর্ব ফাল্গুনী", "UTTARA PHALGUNI/উত্তর ফাল্গুনী", "HASTA/হস্ত", "CHITRA/চিত্রা", "SWATI/স্বাতী", "VISHAKA/বিশাখা", "ANURADHA/অনুরাধা", "JYESTHA/জ্যেষ্ঠা", "MOOLA/মূল", "PURVASHADA/পূর্বষাঢ়", "UTTARASHADA/উত্তরষাঢ়", "SHARAVAN/শ্রাবণ", "DHANISHTA/ধনিষ্ঠা", "SHATABISHA/শতভিষা", "PURVABHADRA/পূর্বভাদ্র", "UTTARABHADRA/উত্তরভাদ্র", "REVATI/রেবতী"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/শুক্ল পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "PURNIMA/পূর্ণিমা"],
                        },
                        {
                            name: "KRISHNA PAKSHA/কৃষ্ণ পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "AMAVASYA/অমাবস্যা"]

                        }
                    ]
                },
                {
                    name: "ASHARH/আষাঢ়",
                    nakshathira: ["ASHWINI/অশ্বিনী", "BHARANI/ভরণী", "KRITTIKA/কৃত্তিকা", "ROHINI/রোহিণী", "MRIGHASIRA/মৃগশিরা", "ARDRA/আর্দ্রা", "PUNARVASU/পুনর্বসু", "PUSHYA/পুষ্য", "ASHLESHA/অশ্লেষা", "MAGHA/মঘা", "PURVA PHALGUNI/পূর্ব ফাল্গুনী", "UTTARA PHALGUNI/উত্তর ফাল্গুনী", "HASTA/হস্ত", "CHITRA/চিত্রা", "SWATI/স্বাতী", "VISHAKA/বিশাখা", "ANURADHA/অনুরাধা", "JYESTHA/জ্যেষ্ঠা", "MOOLA/মূল", "PURVASHADA/পূর্বষাঢ়", "UTTARASHADA/উত্তরষাঢ়", "SHARAVAN/শ্রাবণ", "DHANISHTA/ধনিষ্ঠা", "SHATABISHA/শতভিষা", "PURVABHADRA/পূর্বভাদ্র", "UTTARABHADRA/উত্তরভাদ্র", "REVATI/রেবতী"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/শুক্ল পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "PURNIMA/পূর্ণিমা"],
                        },
                        {
                            name: "KRISHNA PAKSHA/কৃষ্ণ পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "AMAVASYA/অমাবস্যা"]

                        }
                    ]
                },
                {
                    name: "SHRABON/শ্রাবণ",
                    nakshathira: ["ASHWINI/অশ্বিনী", "BHARANI/ভরণী", "KRITTIKA/কৃত্তিকা", "ROHINI/রোহিণী", "MRIGHASIRA/মৃগশিরা", "ARDRA/আর্দ্রা", "PUNARVASU/পুনর্বসু", "PUSHYA/পুষ্য", "ASHLESHA/অশ্লেষা", "MAGHA/মঘা", "PURVA PHALGUNI/পূর্ব ফাল্গুনী", "UTTARA PHALGUNI/উত্তর ফাল্গুনী", "HASTA/হস্ত", "CHITRA/চিত্রা", "SWATI/স্বাতী", "VISHAKA/বিশাখা", "ANURADHA/অনুরাধা", "JYESTHA/জ্যেষ্ঠা", "MOOLA/মূল", "PURVASHADA/পূর্বষাঢ়", "UTTARASHADA/উত্তরষাঢ়", "SHARAVAN/শ্রাবণ", "DHANISHTA/ধনিষ্ঠা", "SHATABISHA/শতভিষা", "PURVABHADRA/পূর্বভাদ্র", "UTTARABHADRA/উত্তরভাদ্র", "REVATI/রেবতী"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/শুক্ল পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "PURNIMA/পূর্ণিমা"],
                        },
                        {
                            name: "KRISHNA PAKSHA/কৃষ্ণ পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "AMAVASYA/অমাবস্যা"]

                        }
                    ]
                },
                {
                    name: "BHADRO/ভাদ্র",
                    nakshathira: ["ASHWINI/অশ্বিনী", "BHARANI/ভরণী", "KRITTIKA/কৃত্তিকা", "ROHINI/রোহিণী", "MRIGHASIRA/মৃগশিরা", "ARDRA/আর্দ্রা", "PUNARVASU/পুনর্বসু", "PUSHYA/পুষ্য", "ASHLESHA/অশ্লেষা", "MAGHA/মঘা", "PURVA PHALGUNI/পূর্ব ফাল্গুনী", "UTTARA PHALGUNI/উত্তর ফাল্গুনী", "HASTA/হস্ত", "CHITRA/চিত্রা", "SWATI/স্বাতী", "VISHAKA/বিশাখা", "ANURADHA/অনুরাধা", "JYESTHA/জ্যেষ্ঠা", "MOOLA/মূল", "PURVASHADA/পূর্বষাঢ়", "UTTARASHADA/উত্তরষাঢ়", "SHARAVAN/শ্রাবণ", "DHANISHTA/ধনিষ্ঠা", "SHATABISHA/শতভিষা", "PURVABHADRA/পূর্বভাদ্র", "UTTARABHADRA/উত্তরভাদ্র", "REVATI/রেবতী"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/শুক্ল পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "PURNIMA/পূর্ণিমা"],
                        },
                        {
                            name: "KRISHNA PAKSHA/কৃষ্ণ পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "AMAVASYA/অমাবস্যা"]

                        }
                    ]
                },
                {
                    name: "ASHSHIN/আশ্বিন",
                    nakshathira: ["ASHWINI/অশ্বিনী", "BHARANI/ভরণী", "KRITTIKA/কৃত্তিকা", "ROHINI/রোহিণী", "MRIGHASIRA/মৃগশিরা", "ARDRA/আর্দ্রা", "PUNARVASU/পুনর্বসু", "PUSHYA/পুষ্য", "ASHLESHA/অশ্লেষা", "MAGHA/মঘা", "PURVA PHALGUNI/পূর্ব ফাল্গুনী", "UTTARA PHALGUNI/উত্তর ফাল্গুনী", "HASTA/হস্ত", "CHITRA/চিত্রা", "SWATI/স্বাতী", "VISHAKA/বিশাখা", "ANURADHA/অনুরাধা", "JYESTHA/জ্যেষ্ঠা", "MOOLA/মূল", "PURVASHADA/পূর্বষাঢ়", "UTTARASHADA/উত্তরষাঢ়", "SHARAVAN/শ্রাবণ", "DHANISHTA/ধনিষ্ঠা", "SHATABISHA/শতভিষা", "PURVABHADRA/পূর্বভাদ্র", "UTTARABHADRA/উত্তরভাদ্র", "REVATI/রেবতী"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/শুক্ল পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "PURNIMA/পূর্ণিমা"],
                        },
                        {
                            name: "KRISHNA PAKSHA/কৃষ্ণ পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "AMAVASYA/অমাবস্যা"]

                        }
                    ]
                },
                {
                    name: "KARTIK/কার্তিক",
                    nakshathira: ["ASHWINI/অশ্বিনী", "BHARANI/ভরণী", "KRITTIKA/কৃত্তিকা", "ROHINI/রোহিণী", "MRIGHASIRA/মৃগশিরা", "ARDRA/আর্দ্রা", "PUNARVASU/পুনর্বসু", "PUSHYA/পুষ্য", "ASHLESHA/অশ্লেষা", "MAGHA/মঘা", "PURVA PHALGUNI/পূর্ব ফাল্গুনী", "UTTARA PHALGUNI/উত্তর ফাল্গুনী", "HASTA/হস্ত", "CHITRA/চিত্রা", "SWATI/স্বাতী", "VISHAKA/বিশাখা", "ANURADHA/অনুরাধা", "JYESTHA/জ্যেষ্ঠা", "MOOLA/মূল", "PURVASHADA/পূর্বষাঢ়", "UTTARASHADA/উত্তরষাঢ়", "SHARAVAN/শ্রাবণ", "DHANISHTA/ধনিষ্ঠা", "SHATABISHA/শতভিষা", "PURVABHADRA/পূর্বভাদ্র", "UTTARABHADRA/উত্তরভাদ্র", "REVATI/রেবতী"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/শুক্ল পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "PURNIMA/পূর্ণিমা"],
                        },
                        {
                            name: "KRISHNA PAKSHA/কৃষ্ণ পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "AMAVASYA/অমাবস্যা"]

                        }
                    ]
                },
                {
                    name: "AGRAHAYAN/অগ্রহায়ণ",
                    nakshathira: ["ASHWINI/অশ্বিনী", "BHARANI/ভরণী", "KRITTIKA/কৃত্তিকা", "ROHINI/রোহিণী", "MRIGHASIRA/মৃগশিরা", "ARDRA/আর্দ্রা", "PUNARVASU/পুনর্বসু", "PUSHYA/পুষ্য", "ASHLESHA/অশ্লেষা", "MAGHA/মঘা", "PURVA PHALGUNI/পূর্ব ফাল্গুনী", "UTTARA PHALGUNI/উত্তর ফাল্গুনী", "HASTA/হস্ত", "CHITRA/চিত্রা", "SWATI/স্বাতী", "VISHAKA/বিশাখা", "ANURADHA/অনুরাধা", "JYESTHA/জ্যেষ্ঠা", "MOOLA/মূল", "PURVASHADA/পূর্বষাঢ়", "UTTARASHADA/উত্তরষাঢ়", "SHARAVAN/শ্রাবণ", "DHANISHTA/ধনিষ্ঠা", "SHATABISHA/শতভিষা", "PURVABHADRA/পূর্বভাদ্র", "UTTARABHADRA/উত্তরভাদ্র", "REVATI/রেবতী"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/শুক্ল পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "PURNIMA/পূর্ণিমা"],
                        },
                        {
                            name: "KRISHNA PAKSHA/কৃষ্ণ পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "AMAVASYA/অমাবস্যা"]

                        }
                    ]
                },
                {
                    name: "POUSH/পৌষ",
                    nakshathira: ["ASHWINI/অশ্বিনী", "BHARANI/ভরণী", "KRITTIKA/কৃত্তিকা", "ROHINI/রোহিণী", "MRIGHASIRA/মৃগশিরা", "ARDRA/আর্দ্রা", "PUNARVASU/পুনর্বসু", "PUSHYA/পুষ্য", "ASHLESHA/অশ্লেষা", "MAGHA/মঘা", "PURVA PHALGUNI/পূর্ব ফাল্গুনী", "UTTARA PHALGUNI/উত্তর ফাল্গুনী", "HASTA/হস্ত", "CHITRA/চিত্রা", "SWATI/স্বাতী", "VISHAKA/বিশাখা", "ANURADHA/অনুরাধা", "JYESTHA/জ্যেষ্ঠা", "MOOLA/মূল", "PURVASHADA/পূর্বষাঢ়", "UTTARASHADA/উত্তরষাঢ়", "SHARAVAN/শ্রাবণ", "DHANISHTA/ধনিষ্ঠা", "SHATABISHA/শতভিষা", "PURVABHADRA/পূর্বভাদ্র", "UTTARABHADRA/উত্তরভাদ্র", "REVATI/রেবতী"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/শুক্ল পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "PURNIMA/পূর্ণিমা"],
                        },
                        {
                            name: "KRISHNA PAKSHA/কৃষ্ণ পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "AMAVASYA/অমাবস্যা"]

                        }
                    ]
                },
                {
                    name: "MAGH/মাঘ",
                    nakshathira: ["ASHWINI/অশ্বিনী", "BHARANI/ভরণী", "KRITTIKA/কৃত্তিকা", "ROHINI/রোহিণী", "MRIGHASIRA/মৃগশিরা", "ARDRA/আর্দ্রা", "PUNARVASU/পুনর্বসু", "PUSHYA/পুষ্য", "ASHLESHA/অশ্লেষা", "MAGHA/মঘা", "PURVA PHALGUNI/পূর্ব ফাল্গুনী", "UTTARA PHALGUNI/উত্তর ফাল্গুনী", "HASTA/হস্ত", "CHITRA/চিত্রা", "SWATI/স্বাতী", "VISHAKA/বিশাখা", "ANURADHA/অনুরাধা", "JYESTHA/জ্যেষ্ঠা", "MOOLA/মূল", "PURVASHADA/পূর্বষাঢ়", "UTTARASHADA/উত্তরষাঢ়", "SHARAVAN/শ্রাবণ", "DHANISHTA/ধনিষ্ঠা", "SHATABISHA/শতভিষা", "PURVABHADRA/পূর্বভাদ্র", "UTTARABHADRA/উত্তরভাদ্র", "REVATI/রেবতী"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/শুক্ল পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "PURNIMA/পূর্ণিমা"],
                        },
                        {
                            name: "KRISHNA PAKSHA/কৃষ্ণ পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "AMAVASYA/অমাবস্যা"]

                        }
                    ]
                },
                {
                    name: "FALGUN/ফাল্গুন",
                    nakshathira: ["ASHWINI/অশ্বিনী", "BHARANI/ভরণী", "KRITTIKA/কৃত্তিকা", "ROHINI/রোহিণী", "MRIGHASIRA/মৃগশিরা", "ARDRA/আর্দ্রা", "PUNARVASU/পুনর্বসু", "PUSHYA/পুষ্য", "ASHLESHA/অশ্লেষা", "MAGHA/মঘা", "PURVA PHALGUNI/পূর্ব ফাল্গুনী", "UTTARA PHALGUNI/উত্তর ফাল্গুনী", "HASTA/হস্ত", "CHITRA/চিত্রা", "SWATI/স্বাতী", "VISHAKA/বিশাখা", "ANURADHA/অনুরাধা", "JYESTHA/জ্যেষ্ঠা", "MOOLA/মূল", "PURVASHADA/পূর্বষাঢ়", "UTTARASHADA/উত্তরষাঢ়", "SHARAVAN/শ্রাবণ", "DHANISHTA/ধনিষ্ঠা", "SHATABISHA/শতভিষা", "PURVABHADRA/পূর্বভাদ্র", "UTTARABHADRA/উত্তরভাদ্র", "REVATI/রেবতী"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/শুক্ল পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "PURNIMA/পূর্ণিমা"],
                        },
                        {
                            name: "KRISHNA PAKSHA/কৃষ্ণ পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "AMAVASYA/অমাবস্যা"]

                        }
                    ]
                },
                {
                    name: "CHAITRA/চৈত্র",
                    nakshathira: ["ASHWINI/অশ্বিনী", "BHARANI/ভরণী", "KRITTIKA/কৃত্তিকা", "ROHINI/রোহিণী", "MRIGHASIRA/মৃগশিরা", "ARDRA/আর্দ্রা", "PUNARVASU/পুনর্বসু", "PUSHYA/পুষ্য", "ASHLESHA/অশ্লেষা", "MAGHA/মঘা", "PURVA PHALGUNI/পূর্ব ফাল্গুনী", "UTTARA PHALGUNI/উত্তর ফাল্গুনী", "HASTA/হস্ত", "CHITRA/চিত্রা", "SWATI/স্বাতী", "VISHAKA/বিশাখা", "ANURADHA/অনুরাধা", "JYESTHA/জ্যেষ্ঠা", "MOOLA/মূল", "PURVASHADA/পূর্বষাঢ়", "UTTARASHADA/উত্তরষাঢ়", "SHARAVAN/শ্রাবণ", "DHANISHTA/ধনিষ্ঠা", "SHATABISHA/শতভিষা", "PURVABHADRA/পূর্বভাদ্র", "UTTARABHADRA/উত্তরভাদ্র", "REVATI/রেবতী"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/শুক্ল পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "PURNIMA/পূর্ণিমা"],
                        },
                        {
                            name: "KRISHNA PAKSHA/কৃষ্ণ পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "AMAVASYA/অমাবস্যা"]

                        }
                    ]
                },

            ]
        },
        {
            name: "Marathi",
            calenderCode: 8,
            month: [
                {
                    name: "CHAITRA/चैत्र",
                    nakshathira: ["ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशीर्ष", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/आश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वा फाल्गुनी", "UTTARA PHALGUNI/उत्तर फाल्गुनी", "HASTA/हस्ता", "CHITRA/चित्रा", "SWATI/स्वाती", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूळा", "PURVASHADA/पूर्वाषाढा", "UTTARASHADA/उत्तराषाढा", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वाभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            "name": "SHUKLA PAKSHA/शुक्ल पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            "name": "KRISHNA PAKSHA/कृष्ण पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]
                        }
                    ]
                },
                {
                    name: "VAISAKHA/वैशाख",
                    nakshathira: ["ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशीर्ष", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/आश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वा फाल्गुनी", "UTTARA PHALGUNI/उत्तर फाल्गुनी", "HASTA/हस्ता", "CHITRA/चित्रा", "SWATI/स्वाती", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूळा", "PURVASHADA/पूर्वाषाढा", "UTTARASHADA/उत्तराषाढा", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वाभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            "name": "SHUKLA PAKSHA/शुक्ल पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            "name": "KRISHNA PAKSHA/कृष्ण पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]
                        }
                    ]
                },
                {
                    name: "JYESTHA/ज्येष्ठा",
                    nakshathira: ["ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशीर्ष", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/आश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वा फाल्गुनी", "UTTARA PHALGUNI/उत्तर फाल्गुनी", "HASTA/हस्ता", "CHITRA/चित्रा", "SWATI/स्वाती", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूळा", "PURVASHADA/पूर्वाषाढा", "UTTARASHADA/उत्तराषाढा", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वाभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            "name": "SHUKLA PAKSHA/शुक्ल पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            "name": "KRISHNA PAKSHA/कृष्ण पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]
                        }
                    ]
                },
                {
                    name: "ASHADHA/आषाढ़",
                    nakshathira: ["ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशीर्ष", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/आश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वा फाल्गुनी", "UTTARA PHALGUNI/उत्तर फाल्गुनी", "HASTA/हस्ता", "CHITRA/चित्रा", "SWATI/स्वाती", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूळा", "PURVASHADA/पूर्वाषाढा", "UTTARASHADA/उत्तराषाढा", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वाभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            "name": "SHUKLA PAKSHA/शुक्ल पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            "name": "KRISHNA PAKSHA/कृष्ण पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]
                        }
                    ]
                },
                {
                    name: "SHRAVANA/श्रावण",
                    nakshathira: ["ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशीर्ष", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/आश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वा फाल्गुनी", "UTTARA PHALGUNI/उत्तर फाल्गुनी", "HASTA/हस्ता", "CHITRA/चित्रा", "SWATI/स्वाती", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूळा", "PURVASHADA/पूर्वाषाढा", "UTTARASHADA/उत्तराषाढा", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वाभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            "name": "SHUKLA PAKSHA/शुक्ल पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            "name": "KRISHNA PAKSHA/कृष्ण पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]
                        }
                    ]
                },
                {
                    name: "BHADRAPADA/भाद्रपद",
                    nakshathira: ["ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशीर्ष", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/आश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वा फाल्गुनी", "UTTARA PHALGUNI/उत्तर फाल्गुनी", "HASTA/हस्ता", "CHITRA/चित्रा", "SWATI/स्वाती", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूळा", "PURVASHADA/पूर्वाषाढा", "UTTARASHADA/उत्तराषाढा", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वाभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            "name": "SHUKLA PAKSHA/शुक्ल पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            "name": "KRISHNA PAKSHA/कृष्ण पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]
                        }
                    ]
                },
                {
                    name: "ASHVIN/अश्विन",
                    nakshathira: ["ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशीर्ष", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/आश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वा फाल्गुनी", "UTTARA PHALGUNI/उत्तर फाल्गुनी", "HASTA/हस्ता", "CHITRA/चित्रा", "SWATI/स्वाती", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूळा", "PURVASHADA/पूर्वाषाढा", "UTTARASHADA/उत्तराषाढा", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वाभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            "name": "SHUKLA PAKSHA/शुक्ल पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            "name": "KRISHNA PAKSHA/कृष्ण पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]
                        }
                    ]
                },
                {
                    name: "KATIKA/कार्तिक/चैत्र",
                    nakshathira: ["ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशीर्ष", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/आश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वा फाल्गुनी", "UTTARA PHALGUNI/उत्तर फाल्गुनी", "HASTA/हस्ता", "CHITRA/चित्रा", "SWATI/स्वाती", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूळा", "PURVASHADA/पूर्वाषाढा", "UTTARASHADA/उत्तराषाढा", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वाभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            "name": "SHUKLA PAKSHA/शुक्ल पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            "name": "KRISHNA PAKSHA/कृष्ण पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]
                        }
                    ]
                },
                {
                    name: "MARGASIRSA/मार्गशीर्ष",
                    nakshathira: ["ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशीर्ष", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/आश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वा फाल्गुनी", "UTTARA PHALGUNI/उत्तर फाल्गुनी", "HASTA/हस्ता", "CHITRA/चित्रा", "SWATI/स्वाती", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूळा", "PURVASHADA/पूर्वाषाढा", "UTTARASHADA/उत्तराषाढा", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वाभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            "name": "SHUKLA PAKSHA/शुक्ल पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            "name": "KRISHNA PAKSHA/कृष्ण पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]
                        }
                    ]
                },
                {
                    name: "PAUSHA/पौष",
                    nakshathira: ["ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशीर्ष", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/आश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वा फाल्गुनी", "UTTARA PHALGUNI/उत्तर फाल्गुनी", "HASTA/हस्ता", "CHITRA/चित्रा", "SWATI/स्वाती", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूळा", "PURVASHADA/पूर्वाषाढा", "UTTARASHADA/उत्तराषाढा", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वाभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            "name": "SHUKLA PAKSHA/शुक्ल पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            "name": "KRISHNA PAKSHA/कृष्ण पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]
                        }
                    ]
                },
                {
                    name: "MAGH/माघ",
                    nakshathira: ["ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशीर्ष", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/आश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वा फाल्गुनी", "UTTARA PHALGUNI/उत्तर फाल्गुनी", "HASTA/हस्ता", "CHITRA/चित्रा", "SWATI/स्वाती", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूळा", "PURVASHADA/पूर्वाषाढा", "UTTARASHADA/उत्तराषाढा", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वाभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            "name": "SHUKLA PAKSHA/शुक्ल पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            "name": "KRISHNA PAKSHA/कृष्ण पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]
                        }
                    ]
                },
                {
                    name: "PHALGUNA/फाल्गुन",
                    nakshathira: ["ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशीर्ष", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/आश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वा फाल्गुनी", "UTTARA PHALGUNI/उत्तर फाल्गुनी", "HASTA/हस्ता", "CHITRA/चित्रा", "SWATI/स्वाती", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूळा", "PURVASHADA/पूर्वाषाढा", "UTTARASHADA/उत्तराषाढा", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वाभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            "name": "SHUKLA PAKSHA/शुक्ल पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            "name": "KRISHNA PAKSHA/कृष्ण पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]
                        }
                    ]
                },
            ]
        }



    ]

    const [calender, SetCalender] = useState("")
    const [month, SetMonth] = useState("")
    const [nakshathra, SetNakshathra] = useState("")
    const [nakTithi, SetNakTithi] = useState()
    const [Pakshams, SetPaksham] = useState([])
    const [tithiname, SettithiName] = useState([])

    const [months, SetMonths] = useState([])
    const [nakshathras, SetNakshathras] = useState([])
    const [nakTithis, SetNakTithis] = useState([])

    const handleCalender = (event) => {

        if (event.target.value === "Any") {
            DynamicFunction({ ...dynamicState, [event.target.name]: event.target.value });
            return;
        }


        const calender = Calender.filter((cal, val) => {
            return cal.name == event.target.value
        });
        DynamicFunction({ ...dynamicState, [event.target.name]: event.target.value, calenderCode: calender[0].calenderCode })
        SetCalender(event.target.value)
        SetMonths(Calender.find(clr => clr.name === event.target.value).month)
    }

    const handleMonth = (event) => {
        DynamicFunction({ ...dynamicState, [event.target.name]: event.target.value })
        SetMonth(event.target.value)
        SetNakshathras(months.find(mth => mth.name === event.target.value).nakshathira)
        SetNakTithis(months.find(tth => tth.name === event.target.value).Paksham)
    }

    const handleNakTithi = (event) => {
        DynamicFunction({ ...dynamicState, [event.target.name]: event.target.value })
        SetNakTithi(event.target.value)
    }

    const handleNakshathra = (event) => {
        DynamicFunction({ ...dynamicState, [event.target.name]: event.target.value })
        SetNakshathra(event.target.value)
    }

    const handlePaksham = (event) => {
        DynamicFunction({ ...dynamicState, [event.target.name]: event.target.value })
        SetPaksham(event.target.value)
        SettithiName(nakTithis.find(pks => pks.name === event.target.value).tithiNames)
    }

    const handleTithi = (event) => {
        DynamicFunction({ ...dynamicState, [event.target.name]: event.target.value })
    }

    const handleInput = (event) => {
        // console.log(event.target.value);
        DynamicFunction({ ...dynamicState, [event.target.name]: event.target.value })
    }

    const handleDateTime = (e) => {
        DynamicFunction({ ...dynamicState, [e.target.name]: e.target.value })
    }

    const { userName, userEmail, userGender, userRelation, userCalender, userMonth, userNakshathra } = dynamicState



    const handleSubmit = (event) => {
        event.preventDefault();
        signInHome();
    };

    const [showDatePicker, setDatePicker] = useState(false)

    useEffect(() => {
        const checkPremiumStatus = async () => {
            try {
                // Get the secure ID from localStorage (either "premiumVerified" or "SD_Id")
                const secureId = localStorage.getItem("premiumVerified") || localStorage.getItem("SD_Id");

                if (!secureId) {
                    return; // Exit if there's no secureId
                }

                const url = `https://backend.shubadinam.com/premiumcheck/signinpopup?secureId=${secureId}`;

                // Await the response from the API
                const response = await axios.get(url);

                // Set datePicker to true if API call is successful
                setDatePicker(true);
            } catch (error) {
                // If any error occurs, disable the date picker
                setDatePicker(false);

                if (error.response) {
                    // Handle specific HTTP error
                    if (error.response.status === 403) {
                        Swal.fire({
                            text: 'Something unexpected happened! Please login again.',
                            icon: "info",
                            showConfirmButton: true,
                        });
                        // Clear localStorage and sessionStorage on 403 error
                        localStorage.clear();
                        sessionStorage.clear();
                    } else {
                        // console.error("Error response:", error.response);
                    }
                } else {
                    // Handle network errors or other unexpected errors
                    // console.error("Error:", error);
                }
            }
        };

        // Call the function to check premium status
        checkPremiumStatus();
    }, []);


    const signInHome = () => {

        setDynamicArray([...dynamicArray, { userName, userEmail, userGender, userRelation, userCalender, userMonth, userNakshathra }])
        // context.globalFunction([...dynamicArray, dynamicState]);

        const URL = "https://backend.shubadinam.com/add"

        axios.post(URL, dynamicState)
            .then((response) => {
                // console.log(response);
                const addUser = response.data
                let impId = addUser.insertedId
                // console.log(impId)
                // console.log(dynamicState);
                addUserForm(impId)
            })
            .catch((error) => {
                // console.log(error);
            })




        const addUserForm = (impId) => {


            const URL = `https://backend.shubadinam.com/list?userEmail=${dynamicState.userEmail}&userCalender=${dynamicState.userCalender}&userMonth=${dynamicState.userMonth}&nakshatraTithi=${dynamicState.nakshatraTithi}&userNakshathra=${dynamicState.userNakshathra}&userPaksham=${dynamicState.userPaksham}&userTithi=${dynamicState.userTithi}&impId=${impId}&userDateTime=${dynamicState.userDateTime}`;


            axios.get(URL)
                .then((response) => {
                    const getData = response.data
                    // console.log(getData);
                    // setQueryResult(getData)
                    context.globalFunctionTwo(getData)
                    localStorage.setItem('myDataKey', JSON.stringify(getData));
                    window.location.reload();




                })
                .catch((error) => {
                    // console.log(error);
                })
        }
    }

    const [premiumDialog, setPremiumDialog] = useState(false); // State to control dialog visibility

    // Function to close the dialog
    const handlePremiumDialogClose = () => {
        setPremiumDialog(false);
    };

    const buttonStyles = {
        fontSize: "12px !important", // Adjust font size for larger screens
        width: 'fit-content',
        marginRight: "16px",
        padding: "5px 10px",
        color: "#ffffff",
        fontWeight: "bold",
        background: "linear-gradient(45deg, #8A2BE2, #FF69B4)", // Purple to rose gradient
        boxShadow: "0 4px 12px rgba(138, 43, 226, 0.4)", // Subtle purple shadow
        transition: "transform 0.2s ease, box-shadow 0.2s ease",
        borderRadius: "8px",
        '&:hover': {
            transform: 'scale(1.05)',
            boxShadow: '0 4px 15px rgba(0, 0, 0, 0.25)', // Hover effect
        },
        '&:active': {
            transform: 'scale(1)', // Active state for clicking
            boxShadow: '0 2px 10px rgba(0, 0, 0, 0.15)', // Active shadow
        },
    };

    const PremiumButton = ({ handleClick }) => {
        return (
            <Box sx={{ mt: 1, }}>
                <Button
                    variant="contained"
                    color="info"
                    style={{
                        fontSize: "0.7rem", // Adjust font size for larger screens
                        width: 'fit-content',
                        marginRight: "16px",
                        padding: "5px 10px",
                        color: "#ffffff",
                        fontWeight: "bold",
                        background: "linear-gradient(45deg, #8A2BE2, #FF69B4)", // Purple to rose gradient
                        boxShadow: "0 4px 12px rgba(138, 43, 226, 0.4)", // Subtle purple shadow
                        transition: "transform 0.2s ease, box-shadow 0.2s ease",
                        borderRadius: "8px",
                    }}
                    onMouseEnter={(e) => {
                        e.currentTarget.style.transform = "scale(1.05)";
                        e.currentTarget.style.boxShadow = "0 4px 15px rgba(0, 0, 0, 0.25)";
                    }}
                    onMouseLeave={(e) => {
                        e.currentTarget.style.transform = "scale(1)";
                        e.currentTarget.style.boxShadow = "0 2px 10px rgba(0, 0, 0, 0.15)";
                    }}
                    onClick={() => handleClick()} // Trigger the handleClick function when clicked
                >
                    Get Premium
                </Button>
            </Box>
        );
    };

    const navtoPricing = () => {
        handlePremiumDialogClose();
        window.location.href = "/premium-checkout";
    }





    return (
        <>
            <Button
                type="button"
                variant="outlined"
                sx={{ textTransform: "none" }} // Remove the uppercase transformation
                startIcon={<PersonAddIcon />} // Add the icon before the text
                onClick={handleClickOpen} // Open the modal
            >
                Add Person
            </Button>

            <Dialog open={open} onClose={handleClose} PaperProps={{
                sx: {
                    margin: 0, // Remove the margin
                    width: '100%', // Optional: Set full width (or adjust as needed)
                    maxWidth: '300', // Prevent max-width from being applied
                },
            }}>

                {
                    profilePending ?
                        <>
                            <DialogTitle >
                                <Typography variant="h5" color="primary" sx={{ fontWeight: "bold" }} gutterBottom>Complete Profile</Typography>
                            </DialogTitle>
                            <DialogContent sx={{ display: "flex", flexDirection: "row", justifyContent: "start" }}>

                                <IconButton size="small">
                                    <Info color="warning" />
                                </IconButton>
                                <Typography>
                                    Complete your profile to add the Janmadinam of your loved ones and get reminders!
                                </Typography>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose} color="primary" variant="outlined">
                                    Cancel
                                </Button>
                                <Button
                                    onClick={() => navigate("/profile")}
                                    variant="contained"
                                    color="primary"
                                >
                                    Complete Profile
                                </Button>
                            </DialogActions>
                        </>
                        :
                        <>
                            <DialogTitle>
                                <Typography variant="h6" sx={{ color: "textPrimary", my: 2 }}>Add Person Details</Typography>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', }}>
                                    <Button
                                        variant="contained" // Apply different styling based on active state
                                        sx={{ width: "fit-content", fontSize: "12px !important", textTransform: 'none', padding: "5px 10px", mr: 2 }}
                                        onClick={() => handleChange(null, 0)} // Set value to 0 for Indian Calendar
                                    >
                                        Find By Indian Calendar
                                    </Button>
                                    <Button
                                        variant={value === 1 ? "contained" : "outlined"} // Apply different styling based on active state
                                        sx={buttonStyles}
                                        onClick={() => handleChange(null, 1)} // Set value to 1 for English Calendar
                                    >
                                        Find By English Calendar
                                    </Button>
                                </Box>
                            </DialogTitle>
                            <DialogContent >
                                {
                                    showNakshatra ?

                                        <>

                                            <form onSubmit={handleSubmit} ref={formRef}>
                                                <Typography variant="subtitle1" color="primary" align="center" gutterBottom>
                                                    Fill Janma Nakshatra/ Tithi details to get reminders.
                                                </Typography>

                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} sm={6}>
                                                        <TextField
                                                            fullWidth
                                                            size="small"
                                                            label="Name"
                                                            name="userName"
                                                            onChange={handleInput}
                                                            variant="outlined"
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12} sm={6} className="hidden">
                                                        <TextField
                                                            fullWidth
                                                            type="email"
                                                            label="Email"
                                                            name="userEmail"
                                                            value={defaultEmail}
                                                            inputProps={{ readOnly: true }}
                                                            variant="outlined"
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12} sm={6}>
                                                        <FormControl fullWidth variant="outlined">
                                                            <InputLabel>Gender</InputLabel>
                                                            <Select
                                                                defaultValue=""
                                                                name="userGender"
                                                                onChange={handleInput}
                                                                label="Gender"
                                                                sx={{ textIndent: "15px" }}
                                                            >
                                                                <MenuItem value="" disabled>
                                                                    Select your Gender
                                                                </MenuItem>
                                                                <MenuItem value="male">Male</MenuItem>
                                                                <MenuItem value="female">Female</MenuItem>
                                                                <MenuItem value="others">Others</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>

                                                    <Grid item xs={12} sm={6}>
                                                        <FormControl fullWidth variant="outlined">
                                                            <InputLabel>Relation</InputLabel>
                                                            <Select
                                                                defaultValue=""
                                                                name="userRelation"
                                                                onChange={handleInput}
                                                                label="Relation"
                                                                sx={{ textIndent: "15px" }}
                                                            >
                                                                <MenuItem value="" disabled>
                                                                    Select Relation
                                                                </MenuItem>
                                                                <MenuItem value="Grand Parent">Grand Parent</MenuItem>
                                                                <MenuItem value="Grand Children">Grand Children</MenuItem>
                                                                <MenuItem value="Parent">Parent</MenuItem>
                                                                <MenuItem value="Friend">Friend</MenuItem>
                                                                <MenuItem value="Relative">Relative</MenuItem>
                                                                <MenuItem value="Spouse">Spouse</MenuItem>
                                                                <MenuItem value="Sibling">Sibling</MenuItem>
                                                                <MenuItem value="Children">Children</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>

                                                    <Grid item xs={12} sm={6}>
                                                        <FormControl fullWidth variant="outlined">
                                                            <InputLabel>What do they celebrate</InputLabel>
                                                            <Select
                                                                defaultValue=""
                                                                name="nakshatraTithi"
                                                                onChange={handleNakTithi}
                                                                label="What do they celebrate"
                                                                sx={{ textIndent: "15px" }}
                                                            >
                                                                <MenuItem value="" disabled>
                                                                    --Nakshatra/Tithi--
                                                                </MenuItem>
                                                                <MenuItem value="Nakshatra">Nakshatra</MenuItem>
                                                                <MenuItem value="Tithi">Tithi</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>

                                                    <Grid item xs={12} sm={6}>
                                                        <FormControl fullWidth variant="outlined">
                                                            <InputLabel>Panchang</InputLabel>
                                                            <Select
                                                                defaultValue=""
                                                                name="userCalender"
                                                                onChange={handleCalender}
                                                                label="Panchang"
                                                                sx={{ textIndent: "15px" }}
                                                            >
                                                                <MenuItem value="" disabled>
                                                                    --Panchang--
                                                                </MenuItem>
                                                                {Calender.map((clr, index) => (
                                                                    <MenuItem key={index} value={clr.name}>
                                                                        {clr.name}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>

                                                    <Grid item xs={12} sm={6}>
                                                        <FormControl fullWidth variant="outlined">
                                                            <InputLabel>Month</InputLabel>
                                                            <Select
                                                                defaultValue=""
                                                                name="userMonth"
                                                                onChange={handleMonth}
                                                                label="Month"
                                                                sx={{ textIndent: "15px" }}
                                                            >
                                                                <MenuItem value="" disabled>
                                                                    --Month--
                                                                </MenuItem>
                                                                {months.map((mth, index) => (
                                                                    <MenuItem key={index} value={mth.name}>
                                                                        {mth.name}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>

                                                    {nakTithi === "Nakshatra" && (
                                                        <Grid item xs={12} sm={6}>
                                                            <FormControl fullWidth variant="outlined">
                                                                <InputLabel>Nakshatra</InputLabel>
                                                                <Select
                                                                    defaultValue=""
                                                                    name="userNakshathra"
                                                                    onChange={handleNakshathra}
                                                                    label="Nakshatra"
                                                                    sx={{ textIndent: "15px" }}
                                                                >
                                                                    <MenuItem value="" disabled>
                                                                        --Nakshatra--
                                                                    </MenuItem>
                                                                    {nakshathras.map((nakshathra, index) => (
                                                                        <MenuItem key={index} value={nakshathra}>
                                                                            {nakshathra}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                        </Grid>
                                                    )}

                                                    {nakTithi === "Tithi" && (
                                                        <>
                                                            <Grid item xs={12} sm={6}>
                                                                <FormControl fullWidth variant="outlined">
                                                                    <InputLabel>Paksha</InputLabel>
                                                                    <Select
                                                                        defaultValue=""
                                                                        name="userPaksham"
                                                                        onChange={handlePaksham}
                                                                        label="Paksha"
                                                                    >
                                                                        <MenuItem value="" disabled>
                                                                            --Paksha--
                                                                        </MenuItem>
                                                                        {nakTithis.map((Paksham, index) => (
                                                                            <MenuItem key={index} value={Paksham.name}>
                                                                                {Paksham.name}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>
                                                            </Grid>

                                                            <Grid item xs={12} sm={6}>
                                                                <FormControl fullWidth variant="outlined">
                                                                    <InputLabel>Tithi</InputLabel>
                                                                    <Select
                                                                        defaultValue=""
                                                                        name="userTithi"
                                                                        onChange={handleTithi}
                                                                        label="Tithi"
                                                                    >
                                                                        <MenuItem value="" disabled>
                                                                            --Tithi--
                                                                        </MenuItem>
                                                                        {tithiname.map((tithi, index) => (
                                                                            <MenuItem key={index} value={tithi}>
                                                                                {tithi}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>
                                                            </Grid>
                                                        </>
                                                    )}
                                                </Grid>
                                            </form>

                                            <Typography
                                                variant="body1"
                                                align="center"
                                                color="textPrimary"
                                                sx={{ p: 2, mt: 2 }} // Optional margin for spacing
                                            >
                                                If you don’t know the above details, you can use{' '}
                                                <strong>
                                                    <Link
                                                        component="button" // Makes it behave like a button
                                                        onClick={() => handleEnglishCalendarClick(1)}
                                                        style={{ cursor: 'pointer', color: '#1976d2' }} // Customize the link color
                                                    >
                                                        English Date of Birth
                                                    </Link>
                                                </strong>{' '}
                                                to find the Janma Dinam details.
                                            </Typography>


                                            <Box textAlign="center">


                                                <Typography
                                                    variant="body2"
                                                    sx={{
                                                        fontSize: '14px',
                                                        color: '#666666c4',
                                                        fontStyle: 'italic',
                                                    }}
                                                    textAlign="center"
                                                >
                                                    (Note: Please fill out all fields before submit.) *
                                                </Typography>
                                            </Box>
                                        </>
                                        :

                                        <>


                                            {
                                                showDatePicker ?

                                                    <>
                                                        <form onSubmit={handleSubmit} ref={formRef2}>
                                                            <Typography variant="subtitle1" color="primary" align="center" gutterBottom>
                                                                Use this Nakshatra/Tithi finder Assistance to get the details as per English Date of Birth.
                                                            </Typography>
                                                            <Grid container spacing={2}>

                                                                {/* Name Input */}
                                                                <Grid item xs={12} sm={6}>
                                                                    <TextField
                                                                        size="small"
                                                                        fullWidth
                                                                        label="Name"
                                                                        name="userName"
                                                                        onChange={handleInput}
                                                                        variant="outlined"
                                                                    />
                                                                </Grid>

                                                                {/* Email Input (hidden field) */}
                                                                <Grid item xs={12} sm={6} className="hidden">
                                                                    <TextField
                                                                        fullWidth
                                                                        type="email"
                                                                        label="Email"
                                                                        name="userEmail"
                                                                        value={defaultEmail}
                                                                        inputProps={{ readOnly: true }}
                                                                        variant="outlined"
                                                                    />
                                                                </Grid>

                                                                {/* Gender Select */}
                                                                <Grid item xs={12} sm={6}>
                                                                    <FormControl fullWidth variant="outlined">
                                                                        <InputLabel>Gender</InputLabel>
                                                                        <Select
                                                                            defaultValue=""
                                                                            name="userGender"
                                                                            onChange={handleInput}
                                                                            label="Gender"
                                                                            sx={{ textIndent: "15px" }}
                                                                        >
                                                                            <MenuItem value="" disabled>
                                                                                Select your Gender
                                                                            </MenuItem>
                                                                            <MenuItem value="male">Male</MenuItem>
                                                                            <MenuItem value="female">Female</MenuItem>
                                                                            <MenuItem value="others">Others</MenuItem>
                                                                        </Select>
                                                                    </FormControl>
                                                                </Grid>

                                                                {/* Relation Select */}
                                                                <Grid item xs={12} sm={6}>
                                                                    <FormControl fullWidth variant="outlined">
                                                                        <InputLabel>Relation</InputLabel>
                                                                        <Select
                                                                            defaultValue=""
                                                                            name="userRelation"
                                                                            onChange={handleInput}
                                                                            label="Relation"
                                                                            sx={{ textIndent: "15px" }}
                                                                        >
                                                                            <MenuItem value="" disabled>
                                                                                Select Relation
                                                                            </MenuItem>
                                                                            <MenuItem value="Grand Parent">Grand Parent</MenuItem>
                                                                            <MenuItem value="Grand Children">Grand Children</MenuItem>
                                                                            <MenuItem value="Parent">Parent</MenuItem>
                                                                            <MenuItem value="Friend">Friend</MenuItem>
                                                                            <MenuItem value="Relative">Relative</MenuItem>
                                                                            <MenuItem value="Spouse">Spouse</MenuItem>
                                                                            <MenuItem value="Sibling">Sibling</MenuItem>
                                                                            <MenuItem value="Children">Children</MenuItem>
                                                                        </Select>
                                                                    </FormControl>
                                                                </Grid>

                                                                {/* Nakshatra/Tithi Select */}
                                                                <Grid item xs={12} sm={6}>
                                                                    <FormControl fullWidth variant="outlined">
                                                                        <InputLabel>Nakshatra/Tithi</InputLabel>
                                                                        <Select
                                                                            defaultValue=""
                                                                            name="nakshatraTithi"
                                                                            onChange={handleNakTithi}
                                                                            label="Nakshatra/Tithi"
                                                                            sx={{ textIndent: "15px" }}
                                                                        >
                                                                            <MenuItem value="" disabled>
                                                                                --Nakshatra/Tithi--
                                                                            </MenuItem>
                                                                            <MenuItem value="Nakshatra">Nakshatra</MenuItem>
                                                                            <MenuItem value="Tithi">Tithi</MenuItem>
                                                                        </Select>
                                                                    </FormControl>
                                                                </Grid>

                                                                {/* Panchang Select */}
                                                                <Grid item xs={12} sm={6}>
                                                                    <FormControl fullWidth variant="outlined">
                                                                        <InputLabel>Panchang</InputLabel>
                                                                        <Select
                                                                            defaultValue=""
                                                                            name="userCalender"
                                                                            onChange={handleCalender}
                                                                            label="Panchang"
                                                                            sx={{ textIndent: "15px" }}
                                                                        >
                                                                            <MenuItem value="" disabled>
                                                                                --Panchang--
                                                                            </MenuItem>
                                                                            {Calender2.map((clr, index) => (
                                                                                <MenuItem key={index} value={clr.name} data-calendercode={clr.calenderCode}>
                                                                                    {clr.name}
                                                                                </MenuItem>
                                                                            ))}
                                                                        </Select>
                                                                    </FormControl>
                                                                </Grid>

                                                                {/* Country Select */}
                                                                <Grid item xs={12} sm={6}>
                                                                    <CountrySelect
                                                                        onChange={(e) => {
                                                                            setCountryid(e.id);
                                                                            handleInput({ target: { name: 'userCountry', value: e.name } });
                                                                        }}
                                                                        name="userCountry"
                                                                        placeHolder="Select Country"
                                                                        sx={{
                                                                            width: '100%',               // Full width for the select box
                                                                            borderRadius: '4px',          // Rounded corners
                                                                            '& .MuiOutlinedInput-root': { // Target the input field
                                                                                padding: '8px',
                                                                                fontSize: '14px',
                                                                                borderColor: '#4b769f !important',     // Custom border color
                                                                                '&:hover fieldset': {
                                                                                    borderColor: '#4b769f',   // Border color on hover
                                                                                },
                                                                            },
                                                                            '& .MuiInputLabel-root': {    // Label custom styling
                                                                                fontSize: '14px',
                                                                                color: '#4b769f',           // Custom color for label
                                                                            },
                                                                            '& .MuiSelect-icon': {        // Icon customization
                                                                                color: '#4b769f',
                                                                            },
                                                                            '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                                                borderColor: '#4b769f',     // Focus border color
                                                                            },
                                                                        }}
                                                                    />
                                                                </Grid>


                                                                {/* State Select */}
                                                                <Grid item xs={12} sm={6}>
                                                                    <StateSelect
                                                                        countryid={countryid}
                                                                        onChange={(e) => {
                                                                            setstateid(e.id);
                                                                            handleInput({ target: { name: 'userState', value: e.name } });
                                                                        }}
                                                                        name="userState"
                                                                        placeHolder="Select State"
                                                                    />
                                                                </Grid>

                                                                {/* City Select */}
                                                                <Grid item xs={12} sm={6}>
                                                                    <CitySelect
                                                                        countryid={countryid}
                                                                        stateid={stateid}
                                                                        onChange={(e) => {
                                                                            setCityid(e.id);
                                                                            handleInput({ target: { name: 'userCity', value: e.name } });
                                                                        }}
                                                                        name="userCity"
                                                                        placeHolder="Select City"
                                                                    />
                                                                </Grid>

                                                                {/* Birth Date & Time */}
                                                                <Grid item xs={12} sm={6}>
                                                                    <TextField
                                                                        fullWidth
                                                                        size="small"
                                                                        label="Birth Date & Time"
                                                                        type="datetime-local"
                                                                        name="userDateTime"
                                                                        onChange={handleDateTime}
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                        variant="outlined"
                                                                    />
                                                                </Grid>

                                                            </Grid>
                                                        </form>

                                                        <br />

                                                        <span style={{ fontSize: '14px', color: '#666', fontStyle: 'italic' }}>
                                                            (Note: Please fill out all fields before submit.) *
                                                        </span>

                                                        {/* <div className="title-signup-btn">
                                                <button onClick={() => signInHome()} className="signup-btn-style" data-bs-dismiss="modal"
                                                    disabled={
                                                        dynamicState.userName === "" ||
                                                        dynamicState.userEmail === "" ||
                                                        dynamicState.userGender === "" ||
                                                        dynamicState.userRelation === "" ||
                                                        dynamicState.userCalender === "" ||
                                                        dynamicState.nakTithi === "" ||
                                                        dynamicState.userDateTime === ""
                                                    }
                                                >Submit</button>
                                            </div> */}
                                                    </>

                                                    :




                                                    <>

                                                        <Stack spacing={2} sx={{ width: '100%', padding: 2 }}>
                                                            {/* Enhanced Material-UI Alert with text first and button second */}
                                                            <Alert
                                                                severity="info"
                                                                sx={{
                                                                    borderRadius: '8px', // Rounded corners
                                                                    // backgroundColor: '#f5f7fb', // Light background
                                                                    backgroundColor: "#e7efff",
                                                                    padding: '16px', // Add some padding
                                                                    boxShadow: 3, // Slight shadow for depth
                                                                    display: 'flex',
                                                                    flexDirection: 'column', // Stack the elements vertically
                                                                    alignItems: 'flex-start',
                                                                }}
                                                            >
                                                                {/* Displaying text first */}
                                                                <Typography variant="body1" sx={{ fontSize: '16px', lineHeight: 1.5 }}>
                                                                    This is a Premium feature. Use this Nakshatra/Tithi finder Assistance to get the details as per English Date of Birth. Experience many more exclusive benefits & Unlock the Full potential by upgrading to Premium.
                                                                </Typography>

                                                                {/* Displaying the button after the text */}
                                                                <PremiumButton handleClick={navtoPricing} />
                                                            </Alert>
                                                        </Stack>

                                                        <form >
                                                            <Grid container spacing={3} sx={{ mt: 1 }}>
                                                                {/* Name */}
                                                                <Grid item xs={12} sm={6}>
                                                                    <TextField
                                                                        size="small"
                                                                        label="Name"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        name="userName"
                                                                        onChange={handleInput}
                                                                        disabled
                                                                    />
                                                                </Grid>

                                                                {/* Email */}
                                                                <Grid item xs={12} sm={6}>
                                                                    <TextField
                                                                        size="small"
                                                                        label="Email"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        type="email"
                                                                        name="userEmail"
                                                                        disabled
                                                                        onChange={handleInput}
                                                                        readOnly
                                                                    />
                                                                </Grid>

                                                                {/* Gender */}
                                                                <Grid item xs={12} sm={6}>
                                                                    <FormControl fullWidth variant="outlined">
                                                                        <InputLabel>Gender</InputLabel>
                                                                        <Select

                                                                            size="small"
                                                                            label="Gender"
                                                                            name="userGender"
                                                                            onChange={handleInput}
                                                                            displayEmpty
                                                                            disabled
                                                                            sx={{ textIndent: 15 }}
                                                                        >
                                                                            <MenuItem value="" disabled>Select Gender</MenuItem>
                                                                            <MenuItem value="male">Male</MenuItem>
                                                                            <MenuItem value="female">Female</MenuItem>
                                                                            <MenuItem value="others">Others</MenuItem>
                                                                        </Select>
                                                                    </FormControl>
                                                                </Grid>

                                                                {/* Relation */}
                                                                <Grid item xs={12} sm={6}>
                                                                    <FormControl fullWidth variant="outlined">
                                                                        <InputLabel>Relation</InputLabel>
                                                                        <Select
                                                                            size="small"
                                                                            label="Relation"
                                                                            name="userRelation"
                                                                            onChange={handleInput}
                                                                            displayEmpty
                                                                            disabled
                                                                        >
                                                                            <MenuItem value="" disabled>Select Relation</MenuItem>
                                                                            <MenuItem value="Grand Parent">Grand Parent</MenuItem>
                                                                            <MenuItem value="Grand Children">Grand Children</MenuItem>
                                                                            <MenuItem value="Parent">Parent</MenuItem>
                                                                            <MenuItem value="Friend">Friend</MenuItem>
                                                                            <MenuItem value="Relative">Relative</MenuItem>
                                                                            <MenuItem value="Spouse">Spouse</MenuItem>
                                                                            <MenuItem value="Sibling">Sibling</MenuItem>
                                                                            <MenuItem value="Children">Children</MenuItem>
                                                                        </Select>
                                                                    </FormControl>
                                                                </Grid>

                                                                {/* Nakshatra/Tithi */}
                                                                <Grid item xs={12} sm={6}>
                                                                    <FormControl fullWidth variant="outlined">
                                                                        <InputLabel>What do they celebrate</InputLabel>
                                                                        <Select
                                                                            size="small"
                                                                            sx={{ textIndent: 15 }}
                                                                            label="What do they celebrate"
                                                                            name="nakshatraTithi"
                                                                            onChange={handleInput}
                                                                            // displayEmpty
                                                                            disabled
                                                                        >
                                                                            <MenuItem value="" disabled>--Nakshatra/Tithi--</MenuItem>
                                                                            <MenuItem value="Nakshatra">Nakshatra</MenuItem>
                                                                            <MenuItem value="Tithi">Tithi</MenuItem>
                                                                        </Select>
                                                                    </FormControl>
                                                                </Grid>

                                                                {/* Panchang */}
                                                                <Grid item xs={12} sm={6}>
                                                                    <FormControl fullWidth variant="outlined">
                                                                        <InputLabel>Panchang</InputLabel>
                                                                        <Select
                                                                            size="small"
                                                                            label="Panchang"
                                                                            name="userCalender"
                                                                            onChange={handleInput}
                                                                            displayEmpty
                                                                            disabled
                                                                            sx={{ textIndent: 15 }}
                                                                        >
                                                                            <MenuItem value="" disabled>--Panchang--</MenuItem>
                                                                            {/* Assuming `Calender2` is an array with data */}
                                                                            {Calender2.map((clr, index) => (
                                                                                <MenuItem key={index} value={clr.name}>
                                                                                    {clr.name}
                                                                                </MenuItem>
                                                                            ))}
                                                                        </Select>
                                                                    </FormControl>
                                                                </Grid>

                                                                {/* Country */}
                                                                <Grid item xs={12} sm={6}>
                                                                    <FormControl fullWidth variant="outlined">
                                                                        <InputLabel>Country</InputLabel>
                                                                        <Select
                                                                            size="small"
                                                                            label="Country"
                                                                            name="userGender"
                                                                            onChange={handleInput}
                                                                            displayEmpty
                                                                            disabled
                                                                        >
                                                                            <MenuItem value="" disabled>Select Gender</MenuItem>
                                                                            <MenuItem value="male">Male</MenuItem>
                                                                            <MenuItem value="female">Female</MenuItem>
                                                                            <MenuItem value="others">Others</MenuItem>
                                                                        </Select>
                                                                    </FormControl>
                                                                </Grid>

                                                                {/* State */}
                                                                <Grid item xs={12} sm={6}>
                                                                    <FormControl fullWidth variant="outlined">
                                                                        <InputLabel>State</InputLabel>
                                                                        <Select
                                                                            size="small"
                                                                            label="State"
                                                                            name="userGender"
                                                                            onChange={handleInput}
                                                                            displayEmpty
                                                                            disabled
                                                                        >
                                                                            <MenuItem value="" disabled>Select Gender</MenuItem>
                                                                            <MenuItem value="male">Male</MenuItem>
                                                                            <MenuItem value="female">Female</MenuItem>
                                                                            <MenuItem value="others">Others</MenuItem>
                                                                        </Select>
                                                                    </FormControl>
                                                                </Grid>

                                                                {/* City */}
                                                                <Grid item xs={12} sm={6}>
                                                                    <FormControl fullWidth variant="outlined">
                                                                        <InputLabel>City</InputLabel>
                                                                        <Select
                                                                            size="small"
                                                                            label="City"
                                                                            name="userGender"
                                                                            onChange={handleInput}
                                                                            displayEmpty
                                                                            disabled
                                                                        >
                                                                            <MenuItem value="" disabled>Select Gender</MenuItem>
                                                                            <MenuItem value="male">Male</MenuItem>
                                                                            <MenuItem value="female">Female</MenuItem>
                                                                            <MenuItem value="others">Others</MenuItem>
                                                                        </Select>
                                                                    </FormControl>
                                                                </Grid>

                                                                {/* Birth Date & Time */}
                                                                <Grid item xs={12} sm={6}>
                                                                    <TextField
                                                                        size="small"
                                                                        label="Birth Date & Time"
                                                                        type="datetime-local"
                                                                        fullWidth
                                                                        name="userDateTime"
                                                                        onChange={handleDateTime}
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                        disabled
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </form>


                                                        <Typography
                                                            variant="body2"
                                                            color="textSecondary"
                                                            sx={{
                                                                p: 2,
                                                                fontStyle: 'italic'
                                                            }}
                                                        >
                                                            (Note: Please fill out all fields before submit.) *
                                                        </Typography>

                                                        <Stack direction="row" justifyContent="end">
                                                            <Button sx={buttonStyles} onClick={() => navtoPricing()}>Buy Premium</Button>
                                                            {/* <Button variant="contained" sx={{ width: "fit-content", padding: "3px 10px", textTransform: "none" }} onClick={() => handleClose}>Close</Button> */}
                                                        </Stack>
                                                    </>


                                            }
                                        </>
                                }
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose} color="primary">
                                    Cancel
                                </Button>
                                <Button
                                    onClick={() => signInHome()}
                                    variant="contained"
                                    color="primary" // You can customize the color
                                    disabled={
                                        dynamicState.userName === "" ||
                                        dynamicState.userEmail === "" ||
                                        dynamicState.userGender === "" ||
                                        dynamicState.userRelation === "" ||
                                        dynamicState.userCalender === "" ||
                                        (dynamicState.nakTithi === "" && dynamicState.userNakshathra === "" && dynamicState.userTithi === "")
                                    }
                                    sx={{
                                        borderRadius: 2, // Rounded corners
                                        padding: '10px 30px', // Custom padding
                                        fontSize: '16px', // Font size
                                    }}
                                >
                                    Submit
                                </Button>
                            </DialogActions>
                        </>
                }
            </Dialog>



        </>
    )
}
export default SignInComponent;
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const PremiumProtected = ({ children }) => {
    const [userData, setUserData] = useState(null);
    const [status, setStatus] = useState("");

    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem("token");

        if (!token) {
            // Swal.fire({
            //     icon: "warning",
            //     title: "Access Denied",
            //     text: "Kindly login again."
            // });
            setStatus("new user")
            // navigate("/");
            return;
        }

        const fetchProtectedData = async () => {
            try {
                const response = await fetch("https://backend.shubadinam.com/protected-route", {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                const data = await response.json();

                if (!response.ok) {
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: data.message
                    });
                }

                setUserData(data.user);
                setStatus("user")
            } catch (error) {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: error.message
                });
                setStatus("new user")
            }
        };

        fetchProtectedData();
    }, [navigate]);

    return React.cloneElement(children, { userData, status });
};

export default PremiumProtected;

import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from "axios";
import { Box } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from "react-router-dom";
import PricingPoints from "./PricingPoints";


const PremiumPurchaseAlert = ({ handlePremiumButton }) => {

    const Navigate = useNavigate();

    const [userType, setuserType] = useState("");


    useEffect(() => {

        const localId3 = localStorage.getItem("SD_Id");

        if (localId3) {
            const url = `https://backend.shubadinam.com/check/ispremium?localId=${localId3}`;
            axios
                .get(url)
                .then((response) => {
                    // console.log(response.data);
                    if (response.data.message === "non-premium") {
                        setuserType("non-premium")
                        const isNonPremium = sessionStorage.getItem("nonpre")

                        if (!isNonPremium) {
                            setOpen(true)
                            sessionStorage.setItem('nonpre', 'true');
                            return;
                        }
                    } else {
                        setuserType("premium")
                        sessionStorage.removeItem("nonpre")
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    }, [])

    const [open, setOpen] = useState(false);
    const [scroll, setScroll] = useState('paper');
    const descriptionElementRef = useRef(null);

    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    const handleClose = () => {
        setOpen(false);
        handlePremiumButton(userType);
    };


    const gotoPricing = () => {
        Navigate("/premium-checkout");
    };

    const [isGreater768, setGreater768] = useState(false);

    useEffect(() => {

        const handleResize = () => {
            setGreater768(window.innerWidth > 768);
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <React.Fragment>

            <Dialog
                open={open}
                onClose={handleClose}
                scroll={scroll}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    {/* <DialogTitle id="scroll-dialog-title">Don't miss out the benefits</DialogTitle> */}
                    {
                        isGreater768 ?
                            <DialogTitle sx={{ width: "500px" }}>
                                <h3 style={{ textAlign: "center", fontWeight: "600", color: "#319fff", fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" }}> Don't miss out these benefits of Premium Membership
                                </h3>
                            </DialogTitle>
                            :
                            <DialogTitle sx={{ width: "300px" }}>
                                <h3 style={{ textAlign: "start", fontWeight: "600", color: "#319fff", fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" }}> Don't miss out these benefits of Premium Membership
                                </h3>
                            </DialogTitle>
                    }


                    <CloseIcon sx={{ color: "black" }} onClick={handleClose} />
                </Box>

                <DialogContent dividers={scroll === 'paper'}>
                    <DialogContentText
                        id="scroll-dialog-description"
                        ref={descriptionElementRef}
                        tabIndex={-1}
                    >
                        <PricingPoints />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <div className="pc-left-1" onClick={() => gotoPricing()}>
                        <button style={{ alignSelf: "end" }}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 24">
                                <path d="m18 0 8 12 10-8-4 20H4L0 4l10 8 8-12z"></path>
                            </svg>
                            Unlock Premium
                        </button>
                    </div>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default PremiumPurchaseAlert;


import React from 'react';
import HeaderComponent from '../components/header';
import FooterComponent from '../components/footer';
import { useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import ScrollToTop from 'react-scroll-to-top';
import LandingHeader from '../components/LandingHeader';

const ShippingDelivery = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top when component mounts
    }, []);
    return (
        <div>
            <ScrollToTop smooth />
            <div className="overall-container">
                <HelmetProvider>
                    <Helmet>
                        <title>Shipping and Delivery </title>
                        <meta name="description" content="Shipping and Delivery  - Janma Nakshatra/Tithi Birthday Reminder Service." />
                    </Helmet>
                </HelmetProvider>
                <div className="overall-percent">
                    <LandingHeader />
                    <div className="center-container-whyus">


                        <h2 style={{
                            fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif"
                        }}>Shipping and Delivery</h2>
                        <ol>
                            <br />
                            {/* <h2 className="letters-color-subhead">Why Shubadinam.com?</h2> */}
                            <p className="letters-color-subhead1" >Thank you for shopping with Shubadinam ,
                            </p>


                            <p className="letters-color-subhead1" >Currently there is no shipment.
                            </p>


                        </ol>






                    </div>
                    <div className="empty-container-footer">
                        <FooterComponent></FooterComponent>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ShippingDelivery;
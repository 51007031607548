import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import HeaderComponent from "../components/header";
import FooterComponent from "../components/footer";
import SecondDataSharing from "../context/second-data-sharing";
import Button from '@mui/material/Button';

import "bootstrap/dist/css/bootstrap.min.css";

import ScrollToTop from "react-scroll-to-top";
import Top10px from "./Top10px";
import LandingHeader from "../components/LandingHeader";
import { isDisabled } from "@testing-library/user-event/dist/utils";
import axios from "axios";
import { Box, Typography } from "@mui/material";
import TestimonialScreen from "./testimonials";

import { Helmet, HelmetProvider } from 'react-helmet-async';
import { RFC_2822 } from "moment";


const Pricing = () => {
    const [data, setData] = useState(null);
    const [daysLeft, setDaysLeft] = useState(0);

    const tableTwoData = useContext(SecondDataSharing);
    // console.log(tableTwoData);

    const Navigate = useNavigate();

    const signOut = () => {
        setSignOut(false);
        localStorage.removeItem("isAuthenticated");
        localStorage.removeItem("fkey");
        localStorage.removeItem("homeauth")
        Navigate("/");
    };

    const logoBtn = () => {
        Navigate("/");
    };

    // MENU BAR

    const [isOpenMenu, setOpenMenu] = useState(false);

    const [dashsignOut, setSignOut] = useState(false);

    const [selectedPack, setSelectedPack] = useState('SD FREE'); // State to track the selected pack

    const handleSelectPack = (pack) => {
        setSelectedPack(pack);
    };

    const buttonStyleFree = {
        border: selectedPack === 'SD FREE' ? '2px solid black' : 'none',
        color: selectedPack === 'SD FREE' ? '#ffcc75' : '#fff', // Golden color if selected, black otherwise
        // Add other common styles here
    };

    const buttonStylePremium = {
        border: selectedPack === 'SD PREMIUM' ? '2px solid black' : 'none',
        color: selectedPack === 'SD PREMIUM' ? '#ffcc75' : '#fff', // Golden color if selected, black otherwise
    };

    const handleButtonClick = () => {

        let rupees;
        // Access the selectedPack value here
        // console.log('Selected Pack:', selectedPack);

        if (selectedPack === "SD PREMIUM") {
            Navigate("/checkout");
        }

    };

    const [isGreater768, setGreater768] = useState(false);
    const auth = localStorage.getItem("isAuthenticated");
    const [isFreeUser, setFreeUser] = useState(false);

    const [timeLeft, setTimeLeft] = useState("")


    useEffect(() => {

        window.scroll(0, 0);

        const secureId = localStorage.getItem("SD_Id");

        if (secureId) {
            const url = `https://backend.shubadinam.com/getpremium?secureId=${secureId}`

            axios.get(url)
                .then((response) => {
                    // console.log(response.data)
                    setTimeLeft(response.data.timeLimit)
                    calculateDaysLeft(response.data.timeLimit)
                    // setFreeUser(false)
                })
                .catch((error) => {
                    console.log(error)
                    // alert("Something unexpected happen.Please try again after sometime !")
                })

        }

        const isFreeRegister = sessionStorage.getItem("isFree")

        if (auth || isFreeRegister) {
            setFreeUser(true)
        }


        const handleResize = () => {
            setGreater768(window.innerWidth > 768);
        };

        // Initial setup
        handleResize();

        // Listen for window resize events
        window.addEventListener('resize', handleResize);

        // Clean up the event listener when the component is unmounted
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const calculateDaysLeft = (purchaseDate) => {
        const purchaseDateTime = new Date(purchaseDate);
        const currentDate = new Date();
        const endDate = new Date(purchaseDateTime);
        endDate.setDate(purchaseDateTime.getDate() + 365); // Adding 365 days

        const timeDifference = endDate - currentDate;
        const daysLeft = Math.ceil(timeDifference / (1000 * 60 * 60 * 24)); // Convert milliseconds to days
        setDaysLeft(daysLeft);
    };

    const gotoCheckout = (reg) => {
        if (reg) {
            Navigate("/register")
            return
        }
        Navigate("/premium-checkout")
    }





    return (
        <div className="overall-container-home">
            <HelmetProvider>
                <Helmet>
                    <title>Pricing</title>
                    <meta name="description" content="Pricing - Janma Nakshatra/Tithi Birthday Reminder Service." />
                </Helmet>
            </HelmetProvider>
            <div className="overall-percent-home">
                <ScrollToTop smooth />

                <div className="container-header-profile">
                    <LandingHeader />
                </div>

                <Top10px />

                <br />



                <section className="pricing-overall">



                    {
                        isGreater768 ?
                            <div className="pricing-container">
                                <h2 style={{ textAlign: "start", fontWeight: "600", color: "#319fff", fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" }}> What You Get?
                                </h2>
                                <Typography variant="body1">
                                    Shubadinam helps you to find out your Birthday based on <strong>Indian Calendar / Panchang</strong>. Join Free or Choose <strong>Premium Membership</strong> for <strong>Exclusive Benefits</strong>
                                </Typography>
                                <br />
                                <div class="pricing-table-wrapper">
                                    <table className="pricing-table">
                                        <thead className="pricing-table-header">
                                            <tr >
                                                <th>
                                                    <div>
                                                        Register Now
                                                        <div class="svg-wrapper">
                                                            <svg viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm1 17v-4h-8v-2h8v-4l6 5-6 5z" /></svg>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th>
                                                    <div class="heading">Basic</div>
                                                    <div class="info">
                                                        <div class="amount">Free / <label>&#8377;</label> 0<span>Yearly</span></div>
                                                        <Button sx={{ width: 100, alignSelf: "center" }} type="button" disabled={isFreeUser ? true : false} onClick={() => gotoCheckout("reg")}>
                                                            {isFreeUser ? "In-Use" : "Sign Up"}
                                                        </Button>


                                                    </div>
                                                </th>
                                                <th >
                                                    <div class="heading" >Premium</div>
                                                    <div class="info">
                                                        <div class="popular">Popular</div>
                                                        <div class="amount"><label>&#8377;</label>299 <span>Yearly</span></div>
                                                        {daysLeft > 0 ? (
                                                            <>
                                                                <br />
                                                                (Premium Purchased)
                                                                <p style={{ color: "red" }}>Only {daysLeft} days to renewal.</p>

                                                            </>
                                                        ) : (

                                                            <Button sx={{ width: 100, alignSelf: "center" }} type="button" onClick={() => gotoCheckout()}>Sign Up</Button>

                                                        )}
                                                    </div>
                                                </th>

                                            </tr>
                                        </thead>
                                        <tbody>

                                            {/* indian calender */}
                                            <tr >
                                                <td >Find Nakshatra / Janma Tithi
                                                    <p>{"(As per Indian calender)"}</p></td>
                                                <td className="tick-center">
                                                    <svg class="starter" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z" /></svg>
                                                </td>
                                                <td className="premium-background tick-center">
                                                    <svg class="essential" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z" /></svg>
                                                </td>

                                            </tr>
                                            {/* english date */}
                                            <tr >
                                                <td >Find Nakshatra / Janma Tithi
                                                    <p>{"(As per English Date of Birth)"}</p></td>
                                                <td className="tick-center">
                                                    <svg class="not-included" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.151 17.943l-4.143-4.102-4.117 4.159-1.833-1.833 4.104-4.157-4.162-4.119 1.833-1.833 4.155 4.102 4.106-4.16 1.849 1.849-4.1 4.141 4.157 4.104-1.849 1.849z" /></svg>
                                                </td>
                                                <td className="premium-background tick-center">
                                                    <svg class="essential" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z" /></svg>
                                                </td>

                                            </tr>
                                            {/* add family */}
                                            <tr>
                                                <td>Add family and friends
                                                    (Unlimited Entries)</td>
                                                <td className="tick-center">
                                                    <svg class="starter" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z" /></svg>
                                                </td>
                                                <td className="tick-center">
                                                    <svg class="essential" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z" /></svg>
                                                </td>
                                            </tr>
                                            {/* virtual seva */}
                                            <tr style={{ backgroundColor: "#ccffdf99" }}>
                                                <td  >  👑 One Free Virtual Puja Seva Slot</td>
                                                <td className="tick-center">
                                                    <svg class="not-included" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.151 17.943l-4.143-4.102-4.117 4.159-1.833-1.833 4.104-4.157-4.162-4.119 1.833-1.833 4.155 4.102 4.106-4.16 1.849 1.849-4.1 4.141 4.157 4.104-1.849 1.849z" /></svg>
                                                </td>
                                                <td className="premium-background tick-center">
                                                    <svg class="essential" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z" /></svg>
                                                </td>

                                            </tr>
                                            {/* merchandise */}
                                            <tr style={{ backgroundColor: "#ccffdf99" }}>
                                                <td  > 👑 Free Delivery For Merchandise<p>{"(India only)"}</p>
                                                </td>
                                                <td className="tick-center">
                                                    <svg class="not-included" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.151 17.943l-4.143-4.102-4.117 4.159-1.833-1.833 4.104-4.157-4.162-4.119 1.833-1.833 4.155 4.102 4.106-4.16 1.849 1.849-4.1 4.141 4.157 4.104-1.849 1.849z" /></svg>
                                                </td>
                                                <td className="premium-background tick-center">
                                                    <svg class="essential" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z" /></svg>
                                                </td>

                                            </tr>
                                            {/* multiple */}
                                            <tr >
                                                <td >Multiple Advance reminders to plan your celebration</td>
                                                <td className="tick-center">
                                                    <svg class="not-included" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.151 17.943l-4.143-4.102-4.117 4.159-1.833-1.833 4.104-4.157-4.162-4.119 1.833-1.833 4.155 4.102 4.106-4.16 1.849 1.849-4.1 4.141 4.157 4.104-1.849 1.849z" /></svg>
                                                </td>
                                                <td className="premium-background tick-center">
                                                    <svg class="essential" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z" /></svg>
                                                </td>
                                            </tr>
                                            {/* reminder */}
                                            <tr >
                                                <td >Email Reminder<p>{"(India and Global)"}</p></td>
                                                <td className="tick-center">
                                                    <svg class="starter" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z" /></svg>
                                                </td>
                                                <td className="premium-background tick-center">
                                                    <svg class="essential" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z" /></svg>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td >SMS Reminder <p>{"(India only)"}</p></td>
                                                <td className="tick-center">
                                                    <svg class="not-included" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.151 17.943l-4.143-4.102-4.117 4.159-1.833-1.833 4.104-4.157-4.162-4.119 1.833-1.833 4.155 4.102 4.106-4.16 1.849 1.849-4.1 4.141 4.157 4.104-1.849 1.849z" /></svg>
                                                </td>
                                                <td className="premium-background tick-center">
                                                    <svg class="essential" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z" /></svg>
                                                </td>

                                            </tr>
                                            <tr >
                                                <td  >
                                                    WhatsApp<p>{"(India and Global)"}</p>
                                                </td>
                                                <td className="tick-center">
                                                    <svg class="not-included" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.151 17.943l-4.143-4.102-4.117 4.159-1.833-1.833 4.104-4.157-4.162-4.119 1.833-1.833 4.155 4.102 4.106-4.16 1.849 1.849-4.1 4.141 4.157 4.104-1.849 1.849z" /></svg>
                                                </td>
                                                <td className="premium-background tick-center">
                                                    <svg class="essential" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z" /></svg>
                                                </td>

                                            </tr>
                                            {/* ads */}
                                            <tr >
                                                <td >Ads Free </td>
                                                <td className="tick-center">
                                                    <svg class="not-included" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.151 17.943l-4.143-4.102-4.117 4.159-1.833-1.833 4.104-4.157-4.162-4.119 1.833-1.833 4.155 4.102 4.106-4.16 1.849 1.849-4.1 4.141 4.157 4.104-1.849 1.849z" /></svg>
                                                </td>
                                                <td className="premium-background tick-center">
                                                    <svg class="essential" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z" /></svg>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td></td>
                                                <th>
                                                    <div class="info">
                                                        <div class="amount">Free / <label>&#8377;</label> 0 <span>Yearly</span></div>
                                                        <Button sx={{ width: 100, alignSelf: "center" }} type="button" disabled={isFreeUser ? true : false} onClick={() => gotoCheckout("reg")}>
                                                            {isFreeUser ? "In-Use" : "Sign Up"}
                                                        </Button>


                                                    </div>
                                                </th>
                                                <th >
                                                    <div class="info">
                                                        <div class="popular">Popular</div>
                                                        <div class="amount"><label>&#8377;</label>299 <span>Yearly</span></div>
                                                        {daysLeft > 0 ? (
                                                            <>
                                                                <br />
                                                                (Premium Purchased)
                                                                <p style={{ color: "red" }}>Only {daysLeft} days to renewal.</p>
                                                                {/* <br /> */}



                                                            </>
                                                        ) : (

                                                            <Button sx={{ width: 100, alignSelf: "center" }} type="button" onClick={() => gotoCheckout()}>Sign Up</Button>

                                                        )}
                                                    </div>
                                                </th>

                                            </tr>

                                        </tbody>
                                    </table>
                                </div>





                            </div>
                            :
                            <div className="pricing-container">
                                <br />
                                <h2 style={{ textAlign: "start", fontWeight: "600", color: "#319fff", fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" }}> What You Get?
                                </h2>
                                <Typography variant="body1">
                                    Shubadinam helps you to find out your Birthday based on <strong>Indian Calendar / Panchang</strong>. Join Free or Choose <strong>Premium Membership</strong> for <strong>Exclusive Benefits</strong>
                                </Typography>

                                <br />
                                <div class="pricing-table-wrapper">
                                    <table className="pricing-table">
                                        <thead className="pricing-table-header">
                                            <tr >
                                                <th className="pricing-table-col1" >
                                                    <div style={{ fontSize: "smaller" }}>
                                                        Register Now
                                                        <div class="svg-wrapper">
                                                            <svg viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm1 17v-4h-8v-2h8v-4l6 5-6 5z" /></svg>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th style={{ borderLeft: "1px solid #ddd" }}>
                                                    <div class="heading">Basic</div>
                                                    <div class="info">

                                                        <div class="amount">
                                                            <div class="free">Free /</div>
                                                            <label>&#8377;</label>0
                                                            <span>Yearly</span>
                                                        </div>


                                                    </div>
                                                </th>
                                                <th style={{ borderLeft: "1px solid #ddd" }}>
                                                    <div class="heading" >Premium</div>
                                                    <div class="info">
                                                        <div class="popular">Popular</div>
                                                        <div class="amount"><label>&#8377;</label>299 <span>Yearly</span></div>
                                                    </div>
                                                </th>

                                            </tr>
                                        </thead>
                                        <tbody>

                                            {/* indian calender */}
                                            <tr >
                                                <td className="pricing-table-col1">Find Nakshatra / Janma Tithi
                                                    <p>{"(As per Indian Calender)"}</p></td>
                                                <td>
                                                    <svg class="starter" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z" /></svg>
                                                </td>
                                                <td className="premium-background">
                                                    <svg class="essential" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z" /></svg>
                                                </td>

                                            </tr>
                                            {/* english date */}
                                            <tr >
                                                <td className="pricing-table-col1">Find Nakshatra / Janma Tithi
                                                    <p>{"(As per English Date of Birth)"}</p></td>
                                                <td>
                                                    <svg class="not-included" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.151 17.943l-4.143-4.102-4.117 4.159-1.833-1.833 4.104-4.157-4.162-4.119 1.833-1.833 4.155 4.102 4.106-4.16 1.849 1.849-4.1 4.141 4.157 4.104-1.849 1.849z" /></svg>
                                                </td>
                                                <td className="premium-background">
                                                    <svg class="essential" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z" /></svg>
                                                </td>

                                            </tr>
                                            {/* add families */}
                                            <tr>
                                                <td className="pricing-table-col1" >Add family and friends
                                                    (Unlimited Entries)</td>
                                                <td>
                                                    <svg class="starter" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z" /></svg>
                                                </td>
                                                <td className="bg-premium premium-background">
                                                    <svg class="essential" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z" /></svg>
                                                </td>
                                            </tr>
                                            {/* virtual seva */}
                                            <tr style={{ backgroundColor: "#ccffdf99" }}>
                                                <td className="pricing-table-col1"> 👑 One Free Virtual Puja Seva Slot

                                                </td>
                                                <td>
                                                    <svg class="not-included" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.151 17.943l-4.143-4.102-4.117 4.159-1.833-1.833 4.104-4.157-4.162-4.119 1.833-1.833 4.155 4.102 4.106-4.16 1.849 1.849-4.1 4.141 4.157 4.104-1.849 1.849z" /></svg>
                                                </td>
                                                <td >
                                                    <svg class="essential" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z" /></svg>
                                                </td>

                                            </tr>
                                            {/* merchandise */}
                                            <tr style={{ backgroundColor: "#ccffdf99" }}>
                                                <td className="pricing-table-col1">👑 Free Delivery For Merchandise<p>{"(India only)"}</p></td>
                                                <td>
                                                    <svg class="not-included" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.151 17.943l-4.143-4.102-4.117 4.159-1.833-1.833 4.104-4.157-4.162-4.119 1.833-1.833 4.155 4.102 4.106-4.16 1.849 1.849-4.1 4.141 4.157 4.104-1.849 1.849z" /></svg>
                                                </td>
                                                <td >
                                                    <svg class="essential" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z" /></svg>
                                                </td>

                                            </tr>
                                            {/* multiple */}
                                            <tr >
                                                <td className="pricing-table-col1" style={{ padding: "90px !important" }}>Multiple Advance reminders to plan your celebration </td>
                                                <td>
                                                    <svg class="not-included" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.151 17.943l-4.143-4.102-4.117 4.159-1.833-1.833 4.104-4.157-4.162-4.119 1.833-1.833 4.155 4.102 4.106-4.16 1.849 1.849-4.1 4.141 4.157 4.104-1.849 1.849z" /></svg>
                                                </td>
                                                <td className="premium-background">
                                                    <svg class="essential" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z" /></svg>
                                                </td>
                                            </tr>
                                            {/* reminder */}
                                            <tr >
                                                <td className="pricing-table-col1">Email Reminder<p>{"(India and Global)"}</p></td>
                                                <td>
                                                    <svg class="starter" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z" /></svg>
                                                </td>
                                                <td className="premium-background">
                                                    <svg class="essential" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z" /></svg>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td className="pricing-table-col1">SMS Reminder <p>{"(India only)"}</p></td>
                                                <td>
                                                    <svg class="not-included" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.151 17.943l-4.143-4.102-4.117 4.159-1.833-1.833 4.104-4.157-4.162-4.119 1.833-1.833 4.155 4.102 4.106-4.16 1.849 1.849-4.1 4.141 4.157 4.104-1.849 1.849z" /></svg>
                                                </td>
                                                <td className="premium-background">
                                                    <svg class="essential" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z" /></svg>
                                                </td>

                                            </tr>
                                            <tr >
                                                <td className="pricing-table-col1">
                                                    WhatsApp<p>{"(India and Global)"}</p>
                                                </td>
                                                <td>
                                                    <svg class="not-included" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.151 17.943l-4.143-4.102-4.117 4.159-1.833-1.833 4.104-4.157-4.162-4.119 1.833-1.833 4.155 4.102 4.106-4.16 1.849 1.849-4.1 4.141 4.157 4.104-1.849 1.849z" /></svg>
                                                </td>
                                                <td className="premium-background">
                                                    <svg class="essential" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z" /></svg>
                                                </td>

                                            </tr>

                                            {/* ads */}
                                            <tr >
                                                <td className="pricing-table-col1" >Ads Free </td>
                                                <td>
                                                    <svg class="not-included" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.151 17.943l-4.143-4.102-4.117 4.159-1.833-1.833 4.104-4.157-4.162-4.119 1.833-1.833 4.155 4.102 4.106-4.16 1.849 1.849-4.1 4.141 4.157 4.104-1.849 1.849z" /></svg>
                                                </td>
                                                <td className="premium-background">
                                                    <svg class="essential" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z" /></svg>
                                                </td>
                                            </tr>

                                            <div style={{ marginTop: "20px", marginBottom: "20px" }}>
                                                <td>
                                                    <Button variant="contained" sx={{ width: "90%", alignSelf: "center", textTransform: "capitalize", border: "1px solid black !important" }} type="button" disabled={isFreeUser ? true : false} onClick={() => gotoCheckout("reg")}>
                                                        {isFreeUser ? "In-Use" : "Get Basic"}
                                                    </Button>

                                                </td>
                                                <td >
                                                    {daysLeft > 0 ? (
                                                        <>
                                                            <br />
                                                            (Premium Purchased)
                                                            <p style={{ color: "red" }}>Only {daysLeft} days to renewal.</p>

                                                        </>
                                                    ) : (

                                                        <Button variant="contained" color="primary" sx={{ width: "100%", alignSelf: "center", backgroundColor: "#38a1f7eb !important", color: "white !important", }} className={`premium-table-btn`} type="button" onClick={() => gotoCheckout()}>Get Premium</Button>

                                                    )}
                                                </td>

                                            </div>

                                        </tbody>
                                    </table>
                                </div>

                                <br />


                            </div>

                    }

                    <br />


                    <div className="db-box-cnt1">
                        <span style={{
                            color: '#05195e', fontSize: "35px", fontFamily: 'Gill Sans, Gill Sans MT, Calibri, Trebuchet MS, sans-serif', fontWeight: 900, textTransform: "capitalize"
                        }}>What people say</span>

                    </div>
                    <br />
                    <div className="db-box-cnt2">
                        <TestimonialScreen />
                    </div>


                </section>



                {/* </div> */}

                <br />
                <br />

                <FooterComponent></FooterComponent>
            </div >
        </div >
    );
};

export default Pricing;


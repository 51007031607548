import React from "react";
import FooterComponent from "../components/footer";
import { NavLink, useNavigate } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import BottomSpace from "./BottomSpace";
import LandingHeader from "../components/LandingHeader";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {
    Container,
    Grid,
    Typography,
    Button,
    Box,
    Paper,
} from '@mui/material';

const AboutScreen = () => {
    const navigate = useNavigate();

    const getStarted = () => {
        navigate("/");
    }

    return (
        <div>
            <HelmetProvider>
                <Helmet>
                    <title>About</title>
                    <meta name="description" content="About - Janma Nakshatra/Tithi Birthday Reminder Service." />
                </Helmet>
            </HelmetProvider>
            <ScrollToTop smooth />
            <LandingHeader />
            <Container maxWidth="lg" sx={{ py: 5, mt: 12 }}>
                <Box mb={4}>
                    <Typography variant="h4" color="primary" align="center" sx={{ fontWeight: "bold", }}>
                        About Us
                    </Typography>
                </Box>

                {/* About Us Section */}
                <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                        <Paper elevation={3} sx={{ p: 4 }}>
                            <Typography variant="h5" gutterBottom color="primary">
                                <strong>Welcome to Shubadinam: Embracing Your Cultural Identity Through Janma Nakshatra (Birth Stars):</strong>
                            </Typography>
                            <Typography >
                                Shubadinam stands as a humble offering toward the noble cause of Retaining and Reclaiming the profound cultural legacy of India. Our mission is to illuminate the importance of celebrating Birth Stars, known as Janma Nakshatra / Janma Tithi. We firmly believe that comprehending and rejoicing in one's Birth Star is a potent means to connect with our identity and pay homage to the timeless wisdom passed down by our ancestors.
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Paper elevation={3} sx={{ p: 4 }}>
                            <Typography variant="h4" gutterBottom color="primary">
                                <strong >Our Journey of Rediscovery:</strong>
                            </Typography>
                            <Typography >
                                As students, we were once influenced by a colonial hangover like many others. It was our exposure to Sanskrit Scholars and Astikas in our family that ignited a deep desire to preserve and celebrate the ancient wisdom of our traditions. Shubadinam was born from this longing.
                            </Typography>
                            <Box mt={2}>
                                <NavLink to="/pricing" style={{ textDecoration: 'none' }}>
                                    <Button variant="contained" color="primary">
                                        Get Started
                                    </Button>
                                </NavLink>
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>

                {/* Action Section */}
                <Grid container spacing={4} alignItems="center" mt={4}>
                    <Grid item xs={12} md={6}>
                        <img
                            src={require("../images/about-us.png")}
                            className="img-fluid"
                            onContextMenu={(e) => e.preventDefault()}
                            style={{ width: '100%', height: 'auto' }}
                            alt="About Us"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Paper elevation={3} sx={{ p: 4 }}>
                            <Typography variant="h4" gutterBottom color="primary">
                                <strong>Action, Not Just Gyaan (Knowledge):</strong>
                            </Typography>
                            <Typography >
                                In our brainstorming sessions on various traditions and rituals, we stumbled upon “Birthday vs Janma dinam”. (<NavLink to="/why-us" style={{ textDecoration: "none" }}>Click here to read more about “Why Shubadinam?”</NavLink>) The most significant challenge we identified was the lack of knowledge about our Indian Calendar (Panchang) within our generation. Remembering a ‘Birthday’ was easier than ‘Janma dinam’. Our solution: Register Once and Receive Reminders for Life.
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
            <BottomSpace />
            <FooterComponent />
        </div>
    );
}

export default AboutScreen;

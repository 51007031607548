import React, { useContext, useEffect, useRef } from "react";
import { useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import FooterComponent from "../../components/footer";
import "bootstrap/dist/css/bootstrap.min.css";
import ScrollToTop from "react-scroll-to-top";
import LandingHeader from "../../components/LandingHeader";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { MuiOtpInput } from 'mui-one-time-password-input'
import axios from "axios";
import Swal from "sweetalert2";
import shubadinamLogo from '../../images/New Shubadinam Logo/Shubadinam Star logo.png';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Grid, Paper, FormControl, FormGroup, FormControlLabel, Checkbox, Container, InputLabel, MenuItem, Select, CircularProgress, InputAdornment, Divider, Dialog, DialogTitle, DialogContent, DialogActions, Alert } from '@mui/material';
import { Country, State } from "country-state-city";
import PhoneInput from "react-phone-input-2";
import { List, ListItem, ListItemIcon } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { NavigateNext, Person } from "@mui/icons-material";
import Email from "@mui/icons-material/Email";
import PaymentIcon from '@mui/icons-material/Payment';
import PhoneVerification from "../../components/PhoneVerification";




const PremiumCheckout = ({ userData, status }) => {
    const inputRef = useRef(null); // Create a reference for the input field

    // Function to handle button click
    const handleButtonClick = () => {
        inputRef.current?.focus();
        inputRef.current?.scrollIntoView({
            behavior: 'smooth',  // Smooth scrolling
            block: 'center',     // Scroll to the center of the viewport
        });
    };

    let defaultCode = "91";

    const [isButtonClicked, setIsButtonClicked] = useState(false);


    const [existingName, setexistingName] = useState("")
    const [existingEmail, setExistingEmail] = useState()
    const [existingId, setexistingId] = useState("")
    const [existingPh, setexistingPh] = useState("")
    const [existingPhCode, setexistingPhCode] = useState("")


    const Navigate = useNavigate();



    const [dynamicState, setDynamicState] = useState({
        userName: "",
        userEmail: "",
        userPhone: "",
        countryCode: "",
        userAddressLine1: "",
        userAddressLine2: "",
        userCountry: "",
        userState: "",
        userCity: "",
        userPincode: "",
        sdinamId: "",
    })

    let isInnerWidthLessThan992 = false; // Default to false


    useEffect(() => {
        setCountries(Country.getAllCountries());

        if (window.innerWidth > 992) {
            isInnerWidthLessThan992 = true;
        }
    }, [])

    useEffect(() => {
        const fkey = localStorage.getItem("fkey");
        const sdId = localStorage.getItem("SD_Id");
        const encodedData = localStorage.getItem("mainMPP");
        const encodedData3 = localStorage.getItem("isAuthenticated");
        const sdName = localStorage.getItem("myDataKey2")

        if (fkey) {
            try {
                const defaultEmail = atob(fkey).replace(/"/g, "").trim();
                setExistingEmail(defaultEmail);
            } catch (error) {
                // console.error("Error decoding or processing fkey:", error);
            }
        }
        if (sdId) {
            setexistingId(sdId);
        }
        if (encodedData) {
            try {
                const decodedData = atob(encodedData);
                const dataObject = JSON.parse(decodedData);

                const phValue = dataObject.userPhone;
                const phCode = dataObject.countryCode;
                setexistingPh(phValue);
                setexistingPhCode(phCode);

            } catch (error) {
                // console.error("Error decoding or parsing data:", error);
            }
        }
        else if (encodedData3) {
            try {
                const decodedData = atob(encodedData3);
                const dataObject = JSON.parse(decodedData);

                const phValue = dataObject.userPhone;
                const phCode = dataObject.countryCode;
                setexistingPh(phValue);
                setexistingPhCode(phCode);

            } catch (error) {
                console.error("Error decoding or parsing data:", error);
            }
        }
        if (sdName) {
            try {
                const nameValue1 = JSON.parse(sdName)
                const sdinamName = nameValue1[0].userName
                setexistingName(sdinamName)
            } catch (error) {
                // console.error("Error decoding or parsing data:", error);
            }
        }
    }, []);

    useEffect(() => {
        if (dynamicState.countryCode === "") {
            setDynamicState(prev => ({
                ...prev, // Don't forget the opening parenthesis here
                countryCode: defaultCode
            }));
        }
    },);


    useEffect(() => {
        if (userData) {

            setDynamicState(prevState => ({
                ...prevState,
                userName: userData.userName || "",
                userEmail: userData.userEmail || "",
                userPhone: userData.userPhone || "",
                countryCode: userData.countryCode || "",
                sdinamId: userData.uniqueId || "",
                userCountry: userData.userCountry || "",
                userState: userData.userState || "",
            }));
        }

    }, [userData]);



    const fkey2 = localStorage.getItem("fkey");
    const encodedData2 = localStorage.getItem("mainMPP");
    const encodedData3 = localStorage.getItem("isAuthenticated");
    const sdName2 = localStorage.getItem("myDataKey2")


    let defaultPhone = "";

    let defaultEmail2 = ""; // Declare defaultEmail2 outside of the if block
    let defaultName = "";
    if (encodedData2) {
        try {
            const decodedData = atob(encodedData2);
            const dataObject = JSON.parse(decodedData);

            defaultPhone = dataObject.userPhone;
            defaultCode = dataObject.countryCode;

        } catch (error) {
            // console.error("Error decoding or parsing data:", error);
        }
    }
    else if (encodedData3) {
        try {
            const decodedData = atob(encodedData3);
            const dataObject = JSON.parse(decodedData);

            defaultPhone = dataObject.userPhone;
            defaultCode = dataObject.countryCode;

        } catch (error) {
            // console.error("Error decoding or parsing data:", error);
        }
    }
    if (sdName2) {
        try {
            const nameValue = JSON.parse(sdName2)
            defaultName = nameValue[0].userName;

        } catch (error) {
            // console.error("Error decoding or parsing data:", error);
        }
    }

    if (fkey2) {
        defaultEmail2 = atob(fkey2).replace(/"/g, "").trim(); // Assign value inside the if block
    }

    const gotoCheckout = () => {
        Navigate("/premium-checkout")
    }


    const steps = [
        'Registration',
        'Verification',
        'Payment',
    ];

    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());

    const isStepOptional = (step) => {
        return step === 1;
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };


    const [isPhoneVerified, setPhoneVerification] = useState(false)
    const [isNotNumber, setIsNotNumber] = useState(false)

    const [otp, setOtp] = useState('')

    const handleChange = (newValue) => {
        if (!/^\d*$/.test(newValue)) {
            newValue = ""; // Set value to an empty string if not a number
            setIsNotNumber(true)
            return;
        }
        setIsNotNumber(false)
        setOtp(newValue)
    }

    const [changesMade, setChangesMade] = useState(false);


    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (changesMade) {
                event.preventDefault();
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [changesMade]);

    const handlePremiumUsers = (e) => {
        setDynamicState({ ...dynamicState, [e.target.name]: e.target.value })
        setChangesMade(true);
    }

    const handlePremiumUsername = (e) => {
        const value = e.target.value;
        // Regular expression to check for "@" or ".com"
        const regex = /[\/\.,;'():"+_\-=&*^$%#@!~`1234567890]|\.com/;

        // Check if the input value contains "@" or ".com"
        if (regex.test(value)) {
            // If it contains "@" or ".com", handle accordingly
            alert("Name only contains alphabets");
            e.target.value = dynamicState[e.target.name] || '';
        } else {
            setDynamicState({ ...dynamicState, [e.target.name]: value });
            setChangesMade(true);
        }
    };


    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedState, setSelectedState] = useState('');



    // country handler
    const handleCountryChange = (event) => {
        const countryCode = event.target.value;
        const country = countries.find(c => c.isoCode === countryCode); // Get full country name

        setSelectedCountry(countryCode);
        setSelectedState(''); // Reset state when country changes
        setDynamicState((prev) => ({
            ...prev,
            userCountry: country ? country.name : '', // Update formData with full country name
            userState: '' // Reset state in formData
        }));

        const countryStates = State.getStatesOfCountry(countryCode);
        setStates(countryStates);
    };

    // state handler
    const handleStateChange = (event) => {
        const stateCode = event.target.value;
        const state = states.find(s => s.isoCode === stateCode); // Get full state name

        setSelectedState(stateCode);
        setDynamicState((prev) => ({
            ...prev,
            userState: state ? state.name : '' // Update formData with full state name
        }));
    };

    // phone handler
    const handlePhoneChange = (value, country) => {
        setDynamicState({
            ...dynamicState,
            userPhone: value, // Store only the phone number
            countryCode: country.dialCode,
        });

    };

    // button styles
    const buttonStyles = {
        fontSize: "1em !important", // Adjust font size for larger screens
        width: 'fit-content',
        m: 2,
        padding: "5px 10px",
        color: "#ffffff",
        fontWeight: "bold",
        background: "linear-gradient(45deg, #8A2BE2, #FF69B4)", // Purple to rose gradient
        boxShadow: "0 4px 12px rgba(138, 43, 226, 0.4)", // Subtle purple shadow
        transition: "transform 0.2s ease, box-shadow 0.2s ease",
        borderRadius: "8px",
        '&:hover': {
            transform: 'scale(1.05)',
            boxShadow: '0 4px 15px rgba(0, 0, 0, 0.25)', // Hover effect
        },
        '&:active': {
            transform: 'scale(1)', // Active state for clicking
            boxShadow: '0 2px 10px rgba(0, 0, 0, 0.15)', // Active shadow
        },
    };


    function isNullOrEmpty(value) {
        return value == null || value.trim() === "";
    }

    const handlePhoneVerification = (e) => {

        const countryCode = dynamicState.countryCode;

        const dialRemovedPhone = dynamicState.userPhone.startsWith(countryCode)
            ? dynamicState.userPhone.slice(countryCode.length) // Remove the dialCode if it’s at the start
            : dynamicState.userPhone; // Keep original if it doesn’t start with dialCode

        setDynamicState((prev) => ({
            ...prev,
            userPhone: dialRemovedPhone
        }));

        e.preventDefault();
        // setPhoneVerification(true)
        setActiveStep((prevActiveStep) => prevActiveStep + 1);

        const url = `https://backend.shubadinam.com/get/premiumotp?userEmail=${dynamicState.userEmail}&userPhone=${dialRemovedPhone}&countryCode=${dynamicState.countryCode}&userName=${dynamicState.userName}&userCountry=${dynamicState.userCountry}&userState=${dynamicState.userState}`;

        axios.get(url)
            .then((response) => {

                // console.log(response);

                if (response.status === 200) {
                    Swal.fire({
                        title: 'Verification Code',
                        // text: "OTP has been sent to your Phone number and Email.",
                        html: "OTP has been sent to your Phone number and Email." + '<br><br><span style="color: red; font-size: 12px;">(* In case you did not receive the SMS OTP, you may use the Email OTP)</span>', // Add your manual text here
                        icon: 'success',
                        confirmButtonText: 'Got it!', // Change the confirm button text
                        confirmButtonColor: '#3085d6', // Change the confirm button color
                        background: '#f5f5f5',
                    })
                    setPhoneVerification(true)
                }

                // console.log("OTP Sent Successfully")
            }).catch((error) => {
                if (error.response.status === 403) {
                    Swal.fire({
                        title: 'User Info',
                        text: error.response.data.message,
                        icon: 'info',
                        confirmButtonText: 'Got it!', // Change the confirm button text
                        confirmButtonColor: '#3085d6', // Change the confirm button color
                        background: '#f5f5f5',
                    })
                }
                else {
                    Swal.fire({
                        title: 'Server Busy',
                        text: error.message,
                        icon: 'error',
                        confirmButtonText: 'Got it!', // Change the confirm button text
                        confirmButtonColor: '#3085d6', // Change the confirm button color
                        background: '#f5f5f5',
                    })
                }
                // console.log(error);
            });
    }


    const handlePaymentVerification = (e) => {

        setIsButtonClicked(true);
        e.preventDefault();
        // setActiveStep((prevActiveStep) => prevActiveStep + 1);

        const url = "https://backend.shubadinam.com/verify/premiumotp";

        axios.post(url, { userOtp: otp, ...dynamicState }) // Include the OTP in the request body
            .then((response) => {
                // console.log("OTP Valid");
                // createOrder();
                if (response.status === 200) {
                    createOrder()
                }
                // Handle response from the server
            })
            .catch((error) => {
                // console.log(`Error! ${error}`);
                const invaildOtp = error.response.data.message
                setIsButtonClicked(false);

                Swal.fire({
                    title: 'Authentication Revoked',
                    text: invaildOtp,
                    icon: 'info',
                    confirmButtonText: 'Got it!', // Change the confirm button text
                    confirmButtonColor: '#3085d6', // Change the confirm button color
                    background: '#f5f5f5', // Change the background color
                    customClass: {
                        container: 'my-custom-container-class', // Add a custom container class
                        title: 'my-custom-title-class', // Add a custom title class
                        content: 'my-custom-content-class', // Add a custom content class
                        confirmButton: 'my-custom-confirm-button-class' // Add a custom confirm button class
                    },
                })
                setOtp('');
            });
    };

    const [orderId, setOrderId] = useState(null);
    const [transactionId, setTransactionId] = useState(null);
    const [qrCodeUrl, setQrCodeUrl] = useState('');


    const createOrder = async () => {
        try {
            const response = await fetch('https://backend.shubadinam.com/create-order', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(dynamicState),
            });
            // console.log(response);
            // if (!response.ok) {
            //     throw new Error('Network response was not ok');
            // }



            const data = await response.json();

            if (response.status === 401) {
                throw new Error(data.message)
            }

            if (data.order.id) {
                setOrderId(data.order.id);
                setTransactionId(data.order.receipt);
                handlePayment(data.order.id, data.order.notes.premiumPayment, data.impId);

            } else {
                alert('Failed to create order');
            }
        } catch (error) {
            alert(error.message || error.response.data.error || "Failed to fetch data");
        }
    };

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://checkout.razorpay.com/v1/checkout.js';
        script.async = true;
        document.body.appendChild(script);

        script.onload = () => {
            document.getElementById('rzp-button1').addEventListener('click', createOrder);
        };
    }, [orderId]);

    const handlePayment = (orderId, premiumPayment, impId) => {

        const premiumUserName = dynamicState.userName;
        const premiumUserPhone = dynamicState.userPhone;
        const PremiumUserEmail = dynamicState.userEmail;
        // console.log(orderId, impId);
        if (orderId) {
            const options = {
                key: 'rzp_live_gp41aILMbhUm19', // Replace with your Razorpay key
                amount: 299 * 100,
                currency: 'INR',
                order_id: orderId,
                name: 'Shubadinam',
                description: 'Shubadinam Transaction',
                image: shubadinamLogo,
                prefill: {
                    name: premiumUserName,
                    email: PremiumUserEmail,
                    contact: premiumUserPhone,
                },
                // redirect: true,
                // callback_url: "http://localhost:3000/PremiumProfile",

                handler: function (response) {
                    // console.log(response);

                    const url = `https://backend.shubadinam.com/razorpayresponse`


                    axios.post(url, {
                        paymentId: response.razorpay_payment_id, orderId: response.razorpay_order_id,
                        signatureId: response.razorpay_signature, dynamicState: dynamicState, impId: impId
                    })
                        .then((response) => {

                            const paymentId = response.data.paymentId
                            if (response.status === 200) {
                                const token = localStorage.getItem("token");
                                if (!token) {
                                    localStorage.setItem("token", response.data.token)
                                }
                                Navigate(`/paymentsuccess?reference=${paymentId}`, { replace: true });
                            }

                        })
                        .catch((error) => {
                            // console.log("Error redirecting : ", error);
                        })
                },
                notes: {
                    premiumPayment: premiumPayment,
                    address: 'Razorpay Corporate Office',
                },
                theme: {
                    color: '#3399cc',
                },
                // method: {
                //     qr: {
                //         url: qrCodeUrl,
                //         format: 'png',
                //         label: 'Scan to Pay',
                //     }
                // }
                config: {
                    display: {
                        hide: [
                            { method: 'paylater' },
                            { method: 'emi' },
                            { method: 'wallet' },
                            { method: 'bank_transfer' },
                        ],
                        preferences: { show_default_blocks: true }
                    }
                },
                method: {
                    //     netbanking: true,
                    //     card: true,
                    //     upi: true,
                    //     wallet: false,
                    //     emi: false,
                    //     bank_transfer: false,
                    // qr: false, // Disable QR Code payment option
                    //     paylater: false // Disable Pay Later option
                }
            };

            const rzp1 = new window.Razorpay(options);

            rzp1.on('payment.success', (response) => {
                // console.log(response);
                // alert(`Payment Successful. Payment ID: ${response.razorpay_payment_id}`);
            });

            rzp1.on('payment.failed', function (response) {
                // alert(response.error.code);
                alert(response.error.description);
                // alert(response.error.source);
                // alert(response.error.step);
                // alert(response.error.reason);
                // alert(response.error.metadata.order_id);
                // alert(response.error.metadata.payment_id);
            })

            rzp1.on('payment.error', (response) => {
                // console.log(response);
                alert(`Payment Failed. Error: ${response.error.description}`);
            });

            rzp1.on('payment.closed', () => {
                console.warn('Payment modal closed by user');
                alert('Payment process was not completed. Please try again.');
            });


            rzp1.open();
        } else {
            alert('Order ID is missing. Please try again.');
        }
    };

    const handleEnterKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    }

    useEffect(() => {

        window.scroll(0, 0);

        document.addEventListener('keypress', handleEnterKeyPress);
        return () => {
            document.removeEventListener('keypress', handleEnterKeyPress);
        };
    }, []);

    const resendOTP = (e) => {
        setIsButtonClicked(false);
        setOtp('');
        // e.preventDefault();
        handlePhoneVerification(e);
    }



    // sign up dialog
    const [signupOpen, setSignupOpen] = useState(false);

    // Functions to handle dialog open and close
    const handleSignupOpen = () => {
        if (status && status === "user") {
            localStorage.clear();
            window.location.href = "/premium-checkout"
        }
        else {
            setSignupOpen(true);
        }
    }
    const handleSignupClose = () => setSignupOpen(false);



    return (
        <Box>
            <HelmetProvider>
                <Helmet>
                    <title>Premium Checkout</title>
                    <meta name="description" content="Premium Checkout  - Janma Nakshatra/Tithi Birthday Reminder Service." />
                </Helmet>
            </HelmetProvider>

            <ScrollToTop smooth />

            <LandingHeader />

            <button style={{ display: "none" }} id="rzp-button1">Pay Now</button>

            {/* <div className="services-container"> */}

            <Container sx={{ mt: 10, py: 5, minHeight: '100vh' }}>
                <Grid container spacing={4} justifyContent="space-evenly">
                    {/* Left Side Information */}
                    <Grid item xs={12} md={5}>
                        <Paper
                            elevation={2}
                            sx={{
                                p: 4,
                                borderRadius: 3,
                                backgroundImage: 'url(https://img.freepik.com/free-vector/decorative-small-golden-stars-with-empty-space-vector_1017-48404.jpg?t=st=1731485298~exp=1731488898~hmac=f44831cefafbf261cd00f9db9108ed21aabf9a1269b6eb5791eb45155e55e718&w=900)', // Replace with your image URL
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                color: 'white', // Adjust text color for contrast
                            }}
                        >
                            <Button sx={buttonStyles}>
                                Unlock Premium
                            </Button>
                            <Typography variant="h6" color="textSecondary">
                                Total: <span style={{ color: 'darkviolet', fontWeight: 'bold', fontSize: "xx-large" }}>₹ 299</span> (including GST) / Yearly
                            </Typography>
                            <Typography component="div" variant="body2" sx={{ mt: 1, fontSize: '1rem', }} color="textSecondary">
                                <List sx={{ paddingLeft: '20px', lineHeight: '1.8', listStyleType: 'none' }} >
                                    <ListItem sx={{ display: 'flex', alignItems: 'center', mb: 1, '&:hover': { backgroundColor: '#f5f5f5', borderRadius: '5px', cursor: 'pointer' } }}>
                                        <ListItemIcon sx={{ color: '#4CAF50', minWidth: '30px' }}>
                                            <CheckCircleIcon />
                                        </ListItemIcon>
                                        <Typography variant="body2" sx={{ fontWeight: 'bold', color: 'textSecondary' }}>
                                            Email Notifications (Globally)
                                        </Typography>
                                    </ListItem>

                                    <ListItem sx={{ display: 'flex', alignItems: 'center', mb: 1, '&:hover': { backgroundColor: '#f5f5f5', borderRadius: '5px', cursor: 'pointer' } }}>
                                        <ListItemIcon sx={{ color: '#4CAF50', minWidth: '30px' }}>
                                            <CheckCircleIcon />
                                        </ListItemIcon>
                                        <Typography variant="body2" sx={{ fontWeight: 'bold', color: 'textSecondary' }}>
                                            SMS (India only)
                                        </Typography>
                                    </ListItem>

                                    <ListItem sx={{ display: 'flex', alignItems: 'center', mb: 1, '&:hover': { backgroundColor: '#f5f5f5', borderRadius: '5px', cursor: 'pointer' } }}>
                                        <ListItemIcon sx={{ color: '#4CAF50', minWidth: '30px' }}>
                                            <CheckCircleIcon />
                                        </ListItemIcon>
                                        <Typography variant="body2" sx={{ fontWeight: 'bold', color: 'textSecondary' }}>
                                            WhatsApp (India and Global)
                                        </Typography>
                                    </ListItem>

                                    <ListItem sx={{ display: 'flex', alignItems: 'center', mb: 1, '&:hover': { backgroundColor: '#f5f5f5', borderRadius: '5px', cursor: 'pointer' } }}>
                                        <ListItemIcon sx={{ color: '#4CAF50', minWidth: '30px' }}>
                                            <CheckCircleIcon />
                                        </ListItemIcon>
                                        <Typography variant="body2" sx={{ fontWeight: 'bold', color: 'textSecondary' }}>
                                            Free Delivery for Merchandise (India only)
                                        </Typography>
                                    </ListItem>

                                    <ListItem sx={{ display: 'flex', alignItems: 'center', mb: 1, '&:hover': { backgroundColor: '#f5f5f5', borderRadius: '5px', cursor: 'pointer' } }}>
                                        <ListItemIcon sx={{ color: '#4CAF50', minWidth: '30px' }}>
                                            <CheckCircleIcon />
                                        </ListItemIcon>
                                        <Typography variant="body2" sx={{ fontWeight: 'bold', color: 'textSecondary' }}>
                                            One Free Virtual Puja Seva Slot
                                        </Typography>
                                    </ListItem>

                                    <ListItem sx={{ display: 'flex', alignItems: 'center', mb: 1, '&:hover': { backgroundColor: '#f5f5f5', borderRadius: '5px', cursor: 'pointer' } }}>
                                        <ListItemIcon sx={{ color: '#4CAF50', minWidth: '30px' }}>
                                            <CheckCircleIcon />
                                        </ListItemIcon>
                                        <Typography variant="body2" sx={{ fontWeight: 'bold', color: 'textSecondary' }}>
                                            Nakshatra / Tithi Finder Assistance (As per English Date of Birth)
                                        </Typography>
                                    </ListItem>
                                </List>
                            </Typography>
                        </Paper>
                    </Grid>

                    {
                        userData && (status && status === "user") ?
                            <Grid item xs={12} md={6}>
                                <Paper
                                    elevation={3}
                                    sx={{
                                        p: 5,
                                        borderRadius: 3,
                                        textAlign: 'center',
                                        maxWidth: 400,
                                        mx: 'auto'
                                    }}
                                >
                                    <Typography variant="h5" color="primary" sx={{ mb: 2, fontWeight: 'bold' }}>
                                        Buy Premium to get Janma Dinam reminders.
                                    </Typography>

                                    <Alert severity="info" sx={{ margin: '20px auto', width: '100%' }}>
                                        You are logged in to Shubadinam and will continue your purchase from the account
                                        <Typography variant="body2" sx={{ fontWeight: 'bold', color: '#1976d2', display: 'inline', marginRight: '8px' }}>{" "}
                                            {userData.userEmail} /{" "}{userData.userPhone}
                                        </Typography>
                                    </Alert>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        startIcon={<PaymentIcon />}
                                        onClick={createOrder}
                                        sx={{
                                            py: 1.5,
                                            mb: 2,
                                            fontSize: '1rem',
                                            fontWeight: 'bold',
                                            textTransform: 'none',
                                        }}
                                    >
                                        Pay Now
                                    </Button>

                                    <Divider sx={{ my: 2 }}>or</Divider>

                                    <Button
                                        variant="text"
                                        color="primary"
                                        onClick={handleSignupOpen}
                                        fullWidth
                                        startIcon={<NavigateNext />}
                                        sx={{
                                            py: 1.5,
                                            fontSize: '1rem',
                                            fontWeight: 'bold',
                                            textTransform: 'none',
                                            justifyContent: 'flex-start', // Align text and icon to the start
                                        }}
                                    >
                                        Use Another Account
                                    </Button>

                                    {/* Signup dialog */}
                                    <Dialog open={signupOpen} onClose={handleSignupClose} maxWidth="xs" fullWidth>
                                        <DialogTitle>
                                            <Typography variant="h5" fontWeight="bold" color="primary" align="center">
                                                Log In
                                            </Typography>
                                        </DialogTitle>
                                        <DialogContent>
                                            {/* Subtitle */}
                                            <Typography variant="body2" color="textSecondary" align="center" sx={{ mb: 2 }}>
                                                Enter your registered phone number to proceed further
                                            </Typography>

                                            {/* Phone Number Input Field */}
                                            <PhoneVerification />
                                        </DialogContent>
                                        <DialogActions sx={{ px: 3, pb: 2 }}>
                                            <Button onClick={handleSignupClose} variant="outlined" color="primary" sx={{ fontWeight: 'bold', textTransform: 'none', borderRadius: 2 }}>
                                                Cancel
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                </Paper>
                            </Grid>
                            :

                            <Grid item xs={12} md={6}>
                                <Paper elevation={3} sx={{
                                    p: 5, borderRadius: 3,
                                }}>
                                    <Typography variant="h6" color="primary" sx={{ p: 2, mb: 1, textAlign: "center", fontSize: "bold" }}>
                                        Enter your profile details to get Janma Dinam reminders.
                                    </Typography>

                                    <Button
                                        variant="text"
                                        color="secondary"
                                        onClick={handleSignupOpen}
                                        fullWidth
                                        startIcon={<NavigateNext />}
                                        sx={{
                                            py: 1.5,
                                            fontSize: '1rem',
                                            fontWeight: 'bold',
                                            textTransform: 'none',
                                            justifyContent: 'flex-start', // Align text and icon to the start
                                        }}
                                    >
                                        Already have an account
                                    </Button>

                                    <Divider sx={{ mb: 2 }}>or</Divider>


                                    {/* Signup dialog */}
                                    <Dialog open={signupOpen} onClose={handleSignupClose} maxWidth="xs" fullWidth>
                                        <DialogTitle>
                                            <Typography variant="h5" fontWeight="bold" color="primary" align="center">
                                                Log In
                                            </Typography>
                                        </DialogTitle>
                                        <DialogContent>
                                            {/* Subtitle */}
                                            <Typography variant="body2" color="textSecondary" align="center" sx={{ mb: 2 }}>
                                                Enter your registered phone number to proceed further
                                            </Typography>

                                            {/* Phone Number Input Field */}
                                            <PhoneVerification />
                                        </DialogContent>
                                        <DialogActions sx={{ px: 3, pb: 2 }}>
                                            <Button onClick={handleSignupClose} variant="outlined" color="primary" sx={{ fontWeight: 'bold', textTransform: 'none', borderRadius: 2 }}>
                                                Cancel
                                            </Button>
                                        </DialogActions>
                                    </Dialog>

                                    <form onSubmit={(e) => handlePhoneVerification(e)}>
                                        <Grid container spacing={2}>
                                            {/* Name Field */}
                                            <Grid item xs={12} md={6}>
                                                <FormControl fullWidth sx={{ mb: 2 }}>
                                                    <TextField
                                                        label="Name"
                                                        name="userName"
                                                        size="small"
                                                        variant="outlined"
                                                        required
                                                        fullWidth
                                                        onChange={(e) => setDynamicState(prevState => ({ ...prevState, userName: e.target.value }))}
                                                        // inputRef={inputRef}
                                                        InputProps={{
                                                            inputMode: "numeric",
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <Person /> {/* Icon */}
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                </FormControl>
                                            </Grid>

                                            {/* Email Field */}
                                            <Grid item xs={12} md={6}>
                                                <FormControl fullWidth sx={{ mb: 2 }}>
                                                    <TextField
                                                        label="Email"
                                                        name="userEmail"
                                                        size="small"
                                                        variant="outlined"
                                                        fullWidth
                                                        required
                                                        onChange={(e) => setDynamicState(prevState => ({ ...prevState, userEmail: e.target.value }))}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <Email /> {/* Icon */}
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                </FormControl>
                                            </Grid>

                                            {/* Phone Number Field */}
                                            <Grid item xs={12} md={6}>
                                                <FormControl fullWidth sx={{ mb: 2 }}>
                                                    <PhoneInput
                                                        validateMobilePrefix={true}
                                                        country={'in'} // Default country code
                                                        value={dynamicState.userPhone} // Display with the country code
                                                        name="userPhone"
                                                        onChange={handlePhoneChange}
                                                        inputStyle={{
                                                            width: '100%',
                                                            height: '40px',
                                                            borderRadius: '5px',
                                                            border: '1px solid #ced4da',
                                                            paddingLeft: '50px', // Space for the country code dropdown
                                                        }}
                                                        dropdownStyle={{
                                                            borderRadius: '5px',
                                                        }}
                                                    />
                                                </FormControl>
                                            </Grid>

                                            {/* Country Select Field */}
                                            <Grid item xs={12} md={6}>
                                                <FormControl fullWidth sx={{ mb: 2, textIndent: 15 }}>
                                                    <InputLabel>Country</InputLabel>
                                                    <Select
                                                        value={selectedCountry}
                                                        onChange={handleCountryChange}
                                                        label="Country"
                                                        placeholder="country"
                                                        name="userCountry"
                                                        required

                                                    >
                                                        {countries.map((country) => (
                                                            <MenuItem key={country.isoCode} value={country.isoCode}>
                                                                {country.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>

                                            {/* State Select Field */}
                                            <Grid item xs={12} md={6}>
                                                <FormControl fullWidth sx={{ mb: 2, textIndent: 15 }}>
                                                    <InputLabel>State</InputLabel>
                                                    <Select
                                                        value={selectedState}
                                                        onChange={handleStateChange}
                                                        label="State"
                                                        disabled={!selectedCountry}
                                                        name="userState"
                                                    >
                                                        {states.map((state) => (
                                                            <MenuItem key={state.isoCode} value={state.isoCode}>
                                                                {state.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>

                                            {/* OTP Section */}
                                            <Grid item xs={12}>
                                                <Box sx={{ p: 3, bgcolor: 'aliceblue', borderRadius: 2 }}>
                                                    {isPhoneVerified ? (
                                                        <Box sx={{ textAlign: 'center', }}>
                                                            <Typography variant="h6" fontWeight="bold" gutterBottom>
                                                                Verification Code
                                                            </Typography>
                                                            <Typography variant="body2" color="textSecondary" mb={2}>
                                                                We have sent a verification code to your registered mobile number and email.
                                                            </Typography>
                                                            <Box sx={{ display: "flex", justifyContent: "center", }}>
                                                                <MuiOtpInput
                                                                    value={otp}
                                                                    onChange={handleChange}
                                                                    length={4}
                                                                    autoFocus
                                                                    inputMode="numeric"
                                                                    TextFieldsProps={() => ({ type: 'tel', sx: { width: '5rem', textAlign: 'center', bgcolor: "white" } })}
                                                                />
                                                                {isNotNumber && (
                                                                    <Typography variant="caption" color="error" display="block" mt={1}>
                                                                        (Note: Please enter numeric values.)
                                                                    </Typography>
                                                                )}
                                                            </Box>
                                                            <Box display="flex" justifyContent="center" gap={2} mt={2}>
                                                                <Button variant="outlined" onClick={resendOTP} size="medium" color="primary">
                                                                    Resend
                                                                </Button>
                                                                <Button
                                                                    variant="contained"
                                                                    color="primary"
                                                                    size="medium"
                                                                    onClick={handlePaymentVerification}
                                                                    disabled={isButtonClicked || otp.length < 4}
                                                                    startIcon={isButtonClicked && <CircularProgress size={20} />}
                                                                >
                                                                    {isButtonClicked ? 'Processing...' : 'Confirm'}
                                                                </Button>
                                                            </Box>
                                                        </Box>
                                                    ) : (
                                                        <Button
                                                            type="submit"
                                                            variant="contained"
                                                            color="primary"
                                                            size="large"
                                                            sx={{ mt: 2, width: '100%' }}
                                                            disabled={
                                                                !dynamicState.userCountry?.trim() ||
                                                                !dynamicState.countryCode?.trim() ||
                                                                !dynamicState.userState?.trim() ||
                                                                !dynamicState.userName?.trim() ||
                                                                !dynamicState.userEmail?.trim() ||
                                                                !dynamicState.userPhone?.trim()
                                                            }
                                                        >
                                                            Verify And Pay
                                                        </Button>
                                                    )}
                                                    <button style={{ display: 'none' }} id="rzp-button1">Pay Now</button>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </Paper>
                            </Grid>

                    }


                </Grid>
            </Container>




            {/* </div> */}


            <FooterComponent></FooterComponent>
        </Box >
    );
};

export default PremiumCheckout;

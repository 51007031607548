import React, { useState } from 'react';
import {
    Container,
    Box,
    Typography,
    Button,
    Grid,
    Paper,
    Card,
    CardContent,
    IconButton,
    Drawer, List, ListItem,
    AppBar,
    Toolbar,
} from '@mui/material';
import { Menu } from '@mui/icons-material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';



const whySection = [
    {
        title: 'Convenient',
        description: 'Perform puja at the convenience of your home at any time, no matter where you are.',
        iconSrc: 'https://img.freepik.com/free-photo/3d-rendering-arrow-hitting-target_23-2151266542.jpg?t=st=1732794918~exp=1732798518~hmac=c0028757d267f6700e6e11e9bad12420042844020663112273594821a1ff5fdb&w=900',
    },
    {
        title: 'Expert Pandits',
        description: 'Our experienced pandits guide you through every step for a spiritually enriching experience.',
        iconSrc: 'https://img.freepik.com/free-photo/3d-rendering-arrow-hitting-target_23-2151266542.jpg?t=st=1732794918~exp=1732798518~hmac=c0028757d267f6700e6e11e9bad12420042844020663112273594821a1ff5fdb&w=900',
    },
    {
        title: 'Affordable',
        description: 'Cost-effective puja options that ensure spiritual experiences without breaking the bank.',
        iconSrc: 'https://img.freepik.com/free-photo/3d-rendering-arrow-hitting-target_23-2151266542.jpg?t=st=1732794918~exp=1732798518~hmac=c0028757d267f6700e6e11e9bad12420042844020663112273594821a1ff5fdb&w=900',
    },
];

const services = [
    {
        title: 'Avahanti Homam',
        description: 'A powerful puja to ensure health, wealth, and prosperity for you and your family.',
        imgUrl: 'https://img.freepik.com/free-photo/vivid-colors-navratri-celebration-festival_23-2151262790.jpg?t=st=1732785886~exp=1732789486~hmac=3ddc15a0432d02b5bd9098c5f2c82509a7c5cbc782932dc6000b5dbbd9735eb1&w=1060',
        buttonText: 'Book Avahanti Homam',
        bgColor: '#e0f7fa',
    },
    {
        title: 'Ganapathi Homam',
        description: 'A powerful puja to ensure health, wealth, and prosperity for you and your family.',
        imgUrl: 'https://img.freepik.com/free-photo/cartoon-portrait-hindu-god_23-2151782053.jpg?t=st=1732786106~exp=1732789706~hmac=823aa2d2d3d09d9741fd4e30ab03620bc598e9257f3e0132b2250b0965eab85f&w=1060',
        buttonText: 'Book Ganapathi Homam',
        bgColor: '#f3a7693b',

    },
    {
        title: 'Surya Namaskar',
        description: 'A powerful puja to ensure health, wealth, and prosperity for you and your family.',
        imgUrl: 'https://img.freepik.com/free-photo/cartoon-portrait-hindu-god_23-2151782053.jpg?t=st=1732786106~exp=1732789706~hmac=823aa2d2d3d09d9741fd4e30ab03620bc598e9257f3e0132b2250b0965eab85f&w=1060',
        buttonText: 'Book Surya Namaskar',
        bgColor: '#f1f8e9',
    },
];


const RoughFive = () => {
    const [openDrawer, setOpenDrawer] = useState(false);

    const toggleDrawer = () => {
        setOpenDrawer(!openDrawer);
    };

    const [isPlaying, setIsPlaying] = useState(false);

    const handlePlayClick = () => {
        setIsPlaying(true);
    };

    return (
        <div style={{ backgroundColor: "aliceblue" }}>
            {/* Hero Section */}
            <Box
                sx={{
                    height: '100vh',
                    position: 'relative',
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' },
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    overflow: 'hidden',
                    padding: { xs: '20px', sm: '40px', md: '60px' },
                    backgroundImage: 'url("https://img.freepik.com/premium-photo/blue-sky-with-plane-flying-distance_1034303-528121.jpg?w=1060")',
                    backgroundSize: 'cover', // Ensures the image covers the entire container
                    backgroundPosition: 'center', // Centers the background image
                    backgroundRepeat: 'no-repeat', // Prevents the image from repeating
                }}
            // background: 'linear-gradient(to right, #38a1f7, #4b769f)',

            >
                {/* Mobile Drawer */}
                <Drawer
                    anchor="left"
                    open={openDrawer}
                    onClose={toggleDrawer}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                    }}
                    PaperProps={{
                        sx: {
                            width: 250, // Apply width here for the Paper component
                            backgroundColor: '#fff', // Optional: set background color if needed
                        }
                    }}
                >
                    <List>
                        <Box sx={{ p: 2 }}>
                            <img src={require("../../images/New Shubadinam Logo/Shubadinam Blue.png")}
                                alt="Logo"
                                style={{ height: "40px", }}
                            />
                        </Box>
                        {['Home', 'Benefits', 'FAQs', 'About'].map((label) => (
                            <ListItem button key={label}>
                                <Typography variant='body5' sx={{ color: '#38a1f7', fontWeight: "bold" }}>{label}</Typography>
                            </ListItem>
                        ))}
                        <ListItem button>
                            <Typography variant='body5' sx={{ color: 'white', bgcolor: "#38a1f7", p: 2, borderRadius: 2 }}>Book Now</Typography>
                        </ListItem>
                    </List>
                </Drawer>

                {/* App Bar for larger screens */}
                <AppBar
                    position="absolute"
                    sx={{
                        top: 0,
                        left: 0,
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                        zIndex: 2,
                        display: { xs: 'none', sm: 'block' },
                    }}
                >
                    <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        {/* Logo or Title */}
                        <Box sx={{ flexGrow: 1 }}>
                            <img src={require("../../images/New Shubadinam Logo/Shubadinam Blue.png")}
                                alt="Logo"
                                style={{ height: "40px", }}
                            />
                        </Box>

                        {/* Menu Buttons */}
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>

                            {['Home', 'Benefits', 'FAQs', 'About'].map((label) => (
                                <Button
                                    key={label}
                                    sx={{
                                        fontSize: '0.9rem',
                                        marginRight: 2,
                                        color: '#fff',
                                        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
                                        padding: '5px 15px',
                                        borderRadius: '20px',
                                        '&:hover': {
                                            backgroundColor: 'rgba(0, 0, 0, 0.7)', // Darker on hover
                                        },
                                    }}
                                >
                                    {label}
                                </Button>
                            ))}

                            {/* Book Now Button */}
                            <Button
                                sx={{
                                    fontSize: '1rem',
                                    color: '#fff', // White text for contrast
                                    backgroundColor: '#38a1f7', // A nice blue color
                                    padding: '10px 30px', // Increased padding for better spacing
                                    borderRadius: '30px', // More rounded corners
                                    fontWeight: '600',
                                    textTransform: 'uppercase', // Capitalize text for emphasis
                                    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)', // Light shadow for depth
                                    transition: 'all 0.3s ease', // Smooth transition for hover and focus
                                    '&:hover': {
                                        backgroundColor: '#2a7bbf', // Darker blue for hover effect
                                        transform: 'scale(1.05)', // Slight scale effect on hover
                                        boxShadow: '0 8px 15px rgba(0, 0, 0, 0.15)', // More shadow for hover
                                    },
                                    '&:focus': {
                                        outline: 'none', // Remove focus outline
                                        border: '2px solid #38a1f7', // Border to show focus state
                                    },
                                    '&:active': {
                                        backgroundColor: '#216fa4', // Even darker blue for active state
                                        transform: 'scale(0.98)', // Slightly shrink button when pressed
                                    },
                                }}
                            >
                                Book Now
                            </Button>

                        </Box>
                    </Toolbar>
                </AppBar>

                {/* Hamburger Menu for Mobile */}
                <Box sx={{ display: { xs: 'flex', sm: 'none' }, flexDirection: "row", justifyContent: "space-between", alignItems: "center", padding: '10px', width: "100%" }}>
                    {/* Logo or Title */}
                    <img src={require("../../images/New Shubadinam Logo/Shubadinam Blue.png")}
                        alt="Logo"
                        style={{ height: "40px", }}
                    />
                    {/* Hamburger Menu */}
                    <IconButton
                        color="inherit"
                        onClick={toggleDrawer}
                        sx={{
                            zIndex: 3,
                        }}
                    >
                        <Menu />
                    </IconButton>
                </Box>


                {/* Left Content */}
                <Box
                    sx={{
                        width: { xs: '100%', sm: '50%' },
                        padding: '20px',
                        zIndex: 2,
                        textAlign: 'left',
                    }}
                >
                    <Typography
                        variant="h1"
                        sx={{
                            color: "#1d76bf",
                            fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' },
                            fontWeight: '600',
                            marginBottom: '15px',
                        }}
                    >
                        Virtual Puja Seva
                    </Typography>
                    <Typography
                        variant="h6"
                        sx={{
                            marginBottom: '20px',
                            fontSize: { xs: '1rem', sm: '1.1rem', md: '1.2rem' },
                        }}
                    >
                        Connect with your spiritual journey from the comfort of your home. Experience the power of virtual puja with our expert pandits.
                    </Typography>
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: '#38a1f7',
                            color: '#fff',
                            padding: { xs: '10px 20px', sm: '12px 25px', md: '12px 30px' },
                            fontSize: { xs: '1rem', sm: '1.05rem', md: '1.1rem' },
                            borderRadius: '30px',
                            '&:hover': {
                                backgroundColor: '#4b769f',
                            },
                        }}
                    >
                        Book Your Seva Now
                    </Button>
                </Box>

                {/* Right YouTube Video */}
                <Box onClick={handlePlayClick}
                    sx={{
                        cursor: "pointer",
                        width: { xs: '100%', sm: '50%' },
                        padding: { xs: '10px', sm: '20px' },
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'relative',
                        borderRadius: '8px', // Add rounded corners for a modern look
                        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Soft shadow for depth
                        overflow: 'hidden', // Ensures that the image and iframe are clipped to rounded corners
                        backgroundImage: !isPlaying
                            ? `url('https://img.freepik.com/free-photo/cartoon-portrait-hindu-god_23-2151782053.jpg?uid=R133306793&ga=GA1.1.1773690977.1730112906&semt=ais_hybrid')`
                            : 'none', // Set the background image when not playing
                        backgroundSize: 'cover', // Ensure the image covers the entire container
                        backgroundPosition: 'center', // Keep the background centered
                        backgroundRepeat: 'no-repeat', // Prevent image repetition
                        height: '315px', // Set a fixed height for the container
                        transition: 'background 0.3s ease', // Smooth transition for the background change
                    }}
                >
                    {/* Play Button */}
                    {!isPlaying && (
                        <IconButton
                            size='large'
                            sx={{
                                position: 'absolute',
                                zIndex: 3,
                                fontSize: '60px', // Larger play button
                                color: '#fff',
                                backgroundColor: "black",
                                opacity: 0.8,
                                transition: 'opacity 0.3s ease',
                                '&:hover': {
                                    opacity: 1, // On hover, make the play button fully visible
                                },
                            }}
                            onClick={handlePlayClick}
                        >
                            <PlayArrowIcon />
                        </IconButton>
                    )}

                    {/* Video Iframe */}
                    {isPlaying && (
                        <iframe
                            width="100%"
                            height="100%"
                            src="https://www.youtube.com/embed/Q5c4s_X6Bbk?autoplay=1"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            style={{ borderRadius: '8px' }} // Keep iframe with rounded corners
                        ></iframe>
                    )}
                </Box>
            </Box>

            {/* Services Section */}
            <Container maxWidth="lg" sx={{ padding: '40px 0' }}>
                <Typography
                    variant="h2"
                    sx={{
                        textAlign: 'start',
                        fontSize: '2rem',
                        fontWeight: '700',
                        marginBottom: '10px',
                        color: '#2264ae',
                        lineHeight: 1.3,
                        p: 1
                    }}
                >
                    Devotional Offerings
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                        textAlign: 'start',
                        fontSize: '1.1rem',
                        color: '#777',
                        lineHeight: 1.5,
                        p: 1
                    }}
                >
                    Explore our wide range of spiritual services designed to bring peace, prosperity, and well-being into your life.
                </Typography>


                <Box sx={{ display: 'flex', justifyContent: 'center', gap: '30px', flexWrap: 'wrap', mt: 3 }}>
                    {services.map((service, index) => (
                        <Card
                            key={index}
                            elevation={3}
                            sx={{
                                maxWidth: '300px',
                                padding: '30px',
                                textAlign: 'center',
                                backgroundColor: service.bgColor,
                                borderRadius: '15px',
                            }}
                            data-aos="fade-up"
                            data-aos-delay={200 * index}
                        >
                            <img
                                src={service.imgUrl}
                                alt={service.title}
                                style={{
                                    width: '100%',
                                    borderRadius: '8px',
                                    marginBottom: '20px',
                                }}
                            />
                            <Typography variant="h5" sx={{ fontWeight: '600', marginBottom: '20px' }}>
                                {service.title}
                            </Typography>
                            <Typography variant="body1" sx={{ fontSize: '1rem', color: '#555' }}>
                                {service.description}
                            </Typography>
                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor: '#38a1f7',
                                    color: '#fff',
                                    marginTop: '20px',
                                    '&:hover': {
                                        backgroundColor: '#4b769f',
                                    },
                                }}
                            >
                                {service.buttonText}
                            </Button>
                        </Card>
                    ))}
                </Box>
            </Container>

            {/* benefits Section */}
            <Container maxWidth="lg" sx={{ padding: '40px 0' }}>
                <Typography
                    variant="h2"
                    sx={{
                        textAlign: 'center',
                        fontSize: '2rem',
                        fontWeight: '700',
                        marginBottom: '10px',
                        color: '#2264ae',
                        lineHeight: 1.3,
                        p: 1
                    }}
                >
                    Bnenefits Of  Virtual Seva
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                        textAlign: 'center',
                        fontSize: '1.1rem',
                        color: '#777',
                        lineHeight: 1.5,
                        p: 1
                    }}
                >
                    Experience the convenience and spiritual benefits of conducting pujas from the comfort of your home, guided by expert priests for your well-being and prosperity.
                </Typography>
                <Grid container spacing={4} sx={{ mt: 3 }}>
                    {/* Left Side Content */}
                    <Grid item xs={12} md={6}>
                        <Grid container spacing={4}>
                            {whySection.map(({ title, description, iconSrc }, index) => (
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    md={12}
                                    key={index}
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                >
                                    <Paper
                                        elevation={2}
                                        sx={{
                                            minWidth: '300px',
                                            padding: '20px',
                                            display: 'flex', // Flexbox layout
                                            alignItems: 'center', // Center content vertically
                                            backgroundColor: '#ffff',
                                            borderRadius: '12px',
                                        }}
                                    >
                                        {/* Image Section */}
                                        <Box
                                            sx={{
                                                width: 100,
                                                height: 100,
                                                marginRight: 3, // Space between image and text content
                                                borderRadius: '50%',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <img src={iconSrc} alt={title} style={{ width: '100%' }} />
                                        </Box>

                                        {/* Content Section (Title and Description) */}
                                        <Box sx={{ flex: 1 }}>
                                            <Typography
                                                variant="h5"
                                                sx={{
                                                    fontWeight: 600,
                                                    marginBottom: 1,
                                                    color: '#2264ae',
                                                    '&:hover': {
                                                        color: '#00796b',
                                                    },
                                                }}
                                            >
                                                {title}
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                sx={{
                                                    fontSize: '1.1rem',
                                                    color: '#555',
                                                    lineHeight: 1.6,
                                                    '&:hover': {
                                                        color: '#00796b',
                                                    },
                                                }}
                                            >
                                                {description}
                                            </Typography>
                                        </Box>
                                    </Paper>

                                </Grid>
                            ))}
                        </Grid>
                    </Grid>


                    {/* Right Side Image (Side Rail) */}
                    <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Grid container spacing={2} sx={{ alignItems: 'center' }}>
                            {/* Left Grid Item for Image */}
                            <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Box
                                    component="img"
                                    src="https://img.freepik.com/premium-photo/silhouette-person-meditating-front-mountain-reflection_1150025-47214.jpg?w=360" // Replace with your actual image
                                    alt="Meditating Person"
                                    sx={{
                                        maxWidth: '100%',
                                        height: 'auto',
                                        borderRadius: '12px',
                                        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                                    }}
                                />
                            </Grid>

                            {/* Right Grid Item for Content */}
                            <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Box
                                    sx={{
                                        textAlign: 'center',
                                        backgroundColor: '#f0f4f8', // Light background color for contrast
                                        padding: '30px',
                                        borderRadius: '12px',
                                        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
                                        width: '80%', // Adjust width as necessary
                                    }}
                                >
                                    <Typography
                                        variant="h4"
                                        sx={{
                                            fontWeight: 700,
                                            marginBottom: 2,
                                            color: '#2264ae', // Blue color for the heading
                                        }}
                                    >
                                        Join Our Community
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            color: '#555',
                                            fontSize: '1.1rem',
                                            marginBottom: 3,
                                            lineHeight: 1.5,
                                        }}
                                    >
                                        Become a part of our growing community and gain access to exclusive content and events. Don't miss out—register today!
                                    </Typography>

                                    {/* Register Now Button */}
                                    <Button
                                        variant="contained"
                                        sx={{
                                            backgroundColor: '#38a1f7',
                                            color: '#fff',
                                            padding: '5px 24px',
                                            fontSize: '1rem',
                                            borderRadius: '30px',
                                            '&:hover': {
                                                backgroundColor: '#00796b', // Darker teal on hover
                                            },
                                        }}
                                    >
                                        Register Now
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>


                    </Grid>

                </Grid>
            </Container>

            {/* Testimonials Section */}
            <Box
                sx={{
                    padding: '60px 0',
                    textAlign: 'center',
                }}
            >
                <Typography variant="h3" sx={{ fontWeight: '600', marginBottom: '20px' }}>
                    What Our Devotees Say
                </Typography>
                <Grid container spacing={4} sx={{ marginTop: '30px' }}>
                    <Grid item xs={12} sm={4}>
                        <Card
                            elevation={3}
                            sx={{
                                padding: '20px',
                                textAlign: 'center',
                            }}
                        >
                            <img
                                src="/path-to-ravi.jpg"
                                alt="Ravi K."
                                style={{
                                    width: '60px',
                                    height: '60px',
                                    borderRadius: '50%',
                                    marginBottom: '20px',
                                }}
                            />
                            <Typography variant="body1" sx={{ fontStyle: 'italic', color: '#555' }}>
                                "A truly blessed experience. I felt connected to the divine even
                                from afar."
                            </Typography>
                            <Typography
                                variant="h6"
                                sx={{ fontWeight: '600', marginTop: '15px' }}
                            >
                                - Ravi K.
                            </Typography>
                        </Card>
                    </Grid>
                    {/* Repeat similar blocks for other testimonials */}
                </Grid>
            </Box>
        </div>
    );
};

export default RoughFive;

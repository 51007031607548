import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import HeaderComponent from "../components/header";
import FooterComponent from "../components/footer";
import Top10px from "./Top10px";
import Top20px from "./Top20px";
import ScrollToTop from "react-scroll-to-top";

import { Helmet, HelmetProvider } from 'react-helmet-async';
import LandingHeader from "../components/LandingHeader";




const PricingPolicy = () => {

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top when component mounts
    }, []);

    return (
        <div>
            <div className="overall-container-whyus">
                <HelmetProvider>
                    <Helmet>
                        <title>Pricing Policy </title>
                        <meta name="description" content="Pricing Policy  - Janma Nakshatra/Tithi Birthday Reminder Service." />
                    </Helmet>
                </HelmetProvider>
                <div className="overall-percent">
                    <ScrollToTop smooth />
                    <LandingHeader />
                    {/* <Top10px /> */}
                    {/* <Top20px /> */}
                    <div className="center-container-whyus">


                        <h2 style={{
                            fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif"
                        }}>Pricing Policy:</h2>
                        <ol>

                            <p className="letters-color-subhead1" >At Shubadinam.com, we are introducing an upcoming premium package. </p>


                            <p className="letters-color-subhead1" >The pricing for the premium package will range from 99 INR to 999 INR. Additional details will be provided shortly.</p>


                        </ol>





                    </div>
                    <Top20px />
                    {/* <div className="empty-container-footer-whyus"> */}
                    <FooterComponent></FooterComponent>
                    {/* </div> */}
                </div>
            </div>
        </div>
    );
};

export default PricingPolicy;